export default {
    select: {
        FR: 'Selectionnez',
        EN: 'Select',
    },
    selectType: {
        FR: 'Veuillez selectionner un type de moteur',
        EN: 'Please select a type of engine',
    },
    quit: {
        FR: 'Ajouter plus tard',
        EN: 'Add later',
    },
    createEngines: {
        FR: 'Créer mes moteurs',
        EN: 'Create engines',
    },
    type: { FR: 'Type', EN: 'Type' },
    year: { FR: 'Année', EN: 'Year' },
    maker: { FR: 'Marque', EN: 'Maker' },
    model: { FR: 'Modèle', EN: 'Model' },
    power: { FR: 'Puissance (CV)', EN: 'Power (CV)' },
    inBoard: {
        FR: 'In-Board',
        EN: 'Inboard',
    },
    outBoard: {
        FR: 'Hors-Bord',
        EN: 'Outboard',
    },
}
