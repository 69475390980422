import React from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import BoatOnComponent from '../common/BoatOnComponent'
import Navigation from '../common/Navigation'
import Footer from '../common/Footer'

import ProfileTab from './ProfileTab'
import ProfileMenu from './ProfileMenu'
import ParkingTab from './ParkingTab'
import PaymentTab from './PaymentTab'
import styles from './Styles/ProfilePageCss'
import dictionary from './Dictionary/ProfilePageDico'
import { fetchData } from '../../actions/api.actions'
import { history } from '../../helpers'
import { API_URL } from '../../services/config.service'
import { userActions } from '../../actions'
import AppRoute from '../../constants/AppRoute'
import { Container, Grid } from '@material-ui/core'
import { getCurrentRouteKey } from '../../languages/LocalizerUtils'

class ProfilePage extends BoatOnComponent {
    constructor(props) {
        super(props)
        this.dictionary = dictionary
        this.state = {
            messageStripe: null,
        }

        this.handleLogout = this.handleLogout.bind(this)
    }

    async componentDidMount() {
        console.log(this.props.user)
    }

    async checkStripeRedirect() {
        const { user } = this.props
        const location = window.location.href

        var index = location.indexOf('?code=')
        if (index !== -1) {
            var StripeAuthID = location.slice(Math.max(0, index + 6))

            while (StripeAuthID.indexOf('?code=') > 0) {
                index = StripeAuthID.indexOf('?code=')
                StripeAuthID = StripeAuthID.slice(Math.max(0, index + 6))
            }

            let data = new FormData()
            data.append('StripeAuthID', StripeAuthID)
            // calculate  urlDashboard
            await this.props.dispatch(
                fetchData(
                    API_URL + `/stripecreateaccountid.php`,
                    'user',
                    'POST',
                    data,
                ),
            )
            alert(
                'Vous avez recu un courrier électronique de la part de BoatOn pour valider votre compte bancaire',
            )
            //history.push('/paymentInfos')
            this.historyPush(history, AppRoute.PaymentInfos)
        }
    }

    componentDidUpdate() {
        if (!this.props.user) {
            this.historyPush(history, AppRoute.Login)
        }
    }

    handleChange(value, key) {
        this.setState({ [key]: value })
    }

    handleLogout() {
        this.props.dispatch(userActions.logout())
    }

    render() {
        const { classes } = this.props
        const url = this.props.location.pathname

        var currentAppRoute = getCurrentRouteKey(
            url,
            this.getContext(),
            this.getMessages(),
        )
        if (currentAppRoute == AppRoute.Profile)
            currentAppRoute = AppRoute.PersonnalInfos

        return (
            <div className={classes.root}>
                <Navigation location={this.props.location}>
                    <Container maxWidth="xl">
                        <Grid container spacing={2}>
                            <Grid
                                item
                                xs={12}
                                md={4}
                                lg={3}
                                classes={{ root: classes.menuRoot }}
                            >
                                <ProfileMenu
                                    onChange={value =>
                                        this.handleChange(value, 'menu')
                                    }
                                    menu={currentAppRoute}
                                />
                            </Grid>
                            <Grid item xs={12} md={8} lg={9}>
                                {currentAppRoute === AppRoute.PersonnalInfos ? (
                                    <ProfileTab />
                                ) : currentAppRoute ===
                                  AppRoute.PaymentInfos ? (
                                    <PaymentTab />
                                ) : (
                                    <div />
                                )}
                            </Grid>
                        </Grid>
                    </Container>
                </Navigation>
                <Footer />
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        user: state.authentication.user,
    }
}

export default connect(mapStateToProps)(withStyles(styles)(ProfilePage))
