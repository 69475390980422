export default theme => ({
    root: {
        margin: '5px',
        width: 'calc(100% - 10px)',
        [theme.breakpoints.down('sm')]: {
            margin: 0,
            marginTop: '15px',
            width: '100%',
        },
    },
    line: Object.assign(
        {
            margin: '10px',
            marginRight: '0',
            marginLeft: '0',
            width: 'calc(100%)',
        },
        theme.flex.row,
        {
            [theme.breakpoints.down('sm')]: {
                flexDirection: 'column',
                marginTop: '0',
                marginBottom: '0',
                margin: 0,
                width: '100%',
            },
        },
    ),
    block: Object.assign(
        {
            margin: 'auto',
            width: '50%',
            justifyContent: 'flex-end',
        },
        theme.flex.rowAlignCenter,
        {
            [theme.breakpoints.down('sm')]: {
                flexDirection: 'column',
                width: '100%',
                marginTop: '10px',
            },
        },
    ),
    field: {
        width: '40%',
        margin: 'auto',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
    text: {
        width: '50%',
        margin: 'auto',
        textAlign: 'right',
        [theme.breakpoints.down('sm')]: {
            textAlign: 'left',
            width: '100%',
        },
    },
    title: {
        marginLeft: '10px',
        fontWeight: '500',
        [theme.breakpoints.down('sm')]: {
            marginLeft: 0,
        },
    },
    subtitle: {
        marginLeft: '20px',
        marginRight: '20px',
        fontWeight: '400',
        [theme.breakpoints.down('sm')]: {
            marginLeft: 0,
        },
    },
    iconAdd: {
        marginTop: '15px',
        display: 'block',
        marginRight: '50px',
        marginLeft: 'auto',
        '&:hover': {
            cursor: 'pointer',
        },
        width: '60px',
        height: '60px',
        [theme.breakpoints.down('sm')]: {
            width: '40px',
            height: '40px',
        },
    },
    fieldSolo: {
        width: '70%',
        margin: 'auto',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
    textSolo: {
        width: '25%',
        margin: 'auto',
        textAlign: 'right',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            textAlign: 'left',
            marginTop: '10px',
        },
    },
    engineAndDelete: Object.assign(
        { margin: 'auto', width: '100%' },
        theme.flex.rowAlignCenter,
    ),
    delete: {
        color: theme.palette.declined.main,
        cursor: 'pointer',
    },
    buttonAdd: {
        margin: '30px 0',
        padding: '10px',
        width: '280px',
        float: 'right',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            float: 'center',
            fontSize: '0.8rem',
            marginTop: '15px',
            marginBottom: '15px',
        },
    },
    buttonSvg: {
        fontSize: '30px!important',
    },
})
