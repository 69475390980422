export default theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        padding: '20px',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            padding: 0,
        },
    },
    panel: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
    },
    line: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '100%',
        padding: '10px',
        [theme.breakpoints.down('sm')]: {
            padding: '5px',
            flexWrap: 'wrap',
        },
    },
    secondLine: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
        },
    },
    datetime: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingTop: '11px',
        width: '100%',
    },
    hideForMobile: {
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
    recherche: {
        width: '40%',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
    field: {
        width: '100%',
        margin: 'auto',
        fontSize: '16px',
    },
    label: {
        width: '200px',
        fontSize: '18px',
        [theme.breakpoints.down('sm')]: {
            fontSize: '12px',
            width: '100%',
        },
    },
    date: {
        width: '20%',
        minWidth: '200px',
        [theme.breakpoints.down('sm')]: {
            width: '10%',
            minWidth: '100px',
        },
    },
    checkup: {
        width: '100%',
        // marginBottom: '25px',
        marginTop: '25px',
        height: '100%',
    },
    enterDate: {
        textAlign: 'left',
        paddingTop: '20px',
        paddingBottom: '20px',
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
    expansionPanel: {
        [theme.breakpoints.down('sm')]: {
            padding: 0,
        },
    },
    input: {
        [theme.breakpoints.down('sm')]: {
            fontSize: '12px',
            padding: 0,
            paddingTop: '10px',
            paddingBottom: '10px',
            paddingLeft: '4px',
        },
    },
    labelInput: {
        [theme.breakpoints.down('sm')]: {
            fontSize: '12px',
        },
    },
    marginInput: {
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
    title: {
        marginTop: 'auto',
        marginBottom: 'auto',
        [theme.breakpoints.down('sm')]: {
            fontSize: '16px',
        },
    },
    icon: {
        width: '30px',
        marginRight: '20px',
        marginLeft: '-9px',
        [theme.breakpoints.down('sm')]: {
            width: '20px',
        },
    },
    paper: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: theme.spacing(70),
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(4),
        outline: 'none',
        background: '#b2dbbf',
    },
    modal: {
        display: 'flex',
        flexDirection: 'column',
        width: '80%',
        alignItems: 'center',
        textAlign: 'center',
        justifyContent: 'center',
        marginRight: 'auto',
        marginLeft: 'auto',
    },
    addTextAndButton: {
        display: 'flex',
        alignItems: 'center',
    },
    iconAdd: {
        marginTop: '15px',
        display: 'block',
        marginRight: '50px',
        marginLeft: 'auto',
        '&:hover': {
            cursor: 'pointer',
        },
        width: '80px',
        height: '80px',
        [theme.breakpoints.down('sm')]: {
            width: '60px',
            height: '60px',
        },
    },
    addAlert: {
        marginTop: '15px',
        marginLeft: '55%',
        fontSize: '20px',
    },
    modalTitle: {
        fontSize: '24px',
        fontWeight: 500,
        textAlign: 'center',
        marginBottom: '10px',
    },
    textField: {
        marginRight: '10px',
        marginLeft: '10px',
        width: 'calc(100% - 20px)',
    },
    tripleField: {
        display: 'flex',
        flexDirection: 'row',
        marginBottom: '5px',
    },
    espaces: {
        paddingTop: '20px',
    },
    textAddDate: {
        fontWeight: 500,
    },
    textAdd: {
        margin: '10px',
        fontWeight: 500,
    },
    addDoc: {
        border: '1px dashed #c4c4c4',
        margin: '10px',
        marginTop: 0,
        '&:hover': {
            cursor: 'pointer',
        },
    },
    doc: {
        margin: 'auto',
        display: 'block',
        padding: '10px',
        width: '50px',
        height: '50px',
        color: '#c4c4c4',
    },
    allPhotos: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
    },
    images: {
        width: '100px',
        height: '100px',
        marginRight: '15px',
        position: 'relative',
    },
    pictures: {
        margin: 'auto',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        border: '0.5px solid #C4C4C4',
        position: 'relative',
    },
    importantImage: {
        width: '30rem',
        margin: 'auto',
        height: '100%',
        position: 'relative',
        display: 'inline-block',
    },
    cross: {
        position: 'absolute',
        right: 0,
        top: 0,
        '&:hover': {
            cursor: 'pointer',
        },
        zIndex: 1,
    },
    validate: {
        display: 'block',
        margin: 'auto',
        marginTop: '20px',
        marginBottom: 0,
    },
    icons: {
        display: 'block',
        marginTop: 'auto',
        marginBottom: 'auto',
        marginLeft: '-7%',
        color: '#000',
        alignItems: 'center',
        width: '5%',
        '&:hover': {
            cursor: 'pointer',
        },
    },
    icone: {
        display: 'block',
        marginTop: 'auto',
        marginBottom: 'auto',
        marginLeft: '-7%',
        width: '5%',
        color: '#000',
        '&:hover': {
            cursor: 'pointer',
        },
    },
    iconDisabled: {
        display: 'block',
        marginTop: 'auto',
        marginBottom: 'auto',
        marginLeft: '-7%',
        width: '5%',
        color: '#C1C1C1',
        '&:hover': {
            cursor: 'pointer',
        },
    },
    buttonAdd: {
        margin: '30px 0',
        padding: '10px',
        width: '260px',
        float: 'right',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
    buttonSvg: {
        fontSize: '30px!important',
    },
})
