export default {
    register: {
        FR: 'Inscription',
        EN: 'Register',
    },
    diffPassword: {
        FR: 'Les deux mots de passe ne sont pas identiques',
        EN: 'The two passwords are not identical',
    },
    repeatPassword: {
        FR: 'Répéter le mot de passe',
        EN: 'Repeat the password',
    },
    nbCharacterPassword: {
        FR: 'Votre mot de passe doit faire au moins 6 caractères',
        EN: 'Your password must be at least 6 characters',
    },
    password: {
        FR: 'Mot de passe',
        EN: 'Password',
    },
    invalidEmail: {
        FR: "L'email entré n'est pas valide !",
        EN: 'The email entered is not valid',
    },
    email: {
        FR: 'E-Mail',
        EN: 'E-Mail',
    },
    firstName: {
        FR: 'Prénom',
        EN: 'Firstname',
    },
    lastName: {
        FR: 'Nom',
        EN: 'Lastname',
    },
}
