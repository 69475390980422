import React from 'react'

import { withStyles, makeStyles } from '@material-ui/core/styles'
import BoatOnComponent from '../../common/BoatOnComponent'

import styles from '../Styles/HomeHead'
import dictionary from '../Dictionary/HomeHeadDico'

import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'

import Container from '@material-ui/core/Container'
import { Grid } from '@material-ui/core'
import Stars from '../../common/UsefullComponents/Stars'

class Reviews extends BoatOnComponent {
    constructor(props) {
        super(props)
        this.dictionary = dictionary

        this.state = {}
    }

    render() {
        const { bob } = this.props
        return (
            <Container maxWidth="lg">
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={4}>
                        <Review
                            name={bob ? 'Denis' : 'Maxime'}
                            title={
                                bob
                                    ? this.displayText('bobUser')
                                    : this.displayText('commentary1Title')
                            }
                            desc={
                                bob
                                    ? this.displayText('commentary1BOB')
                                    : this.displayText('commentary1')
                            }
                        ></Review>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Review
                            name={bob ? 'Bernard' : 'Marion'}
                            title={
                                bob
                                    ? this.displayText('bobUser')
                                    : this.displayText('commentary2Title')
                            }
                            desc={
                                bob
                                    ? this.displayText('commentary2BOB')
                                    : this.displayText('commentary2')
                            }
                        ></Review>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Review
                            name={bob ? 'Julie' : 'Benoît'}
                            title={
                                bob
                                    ? this.displayText('bobUser')
                                    : this.displayText('commentary3Title')
                            }
                            desc={
                                bob
                                    ? this.displayText('commentary3BOB')
                                    : this.displayText('commentary3')
                            }
                        ></Review>
                    </Grid>
                </Grid>
            </Container>
        )
    }
}

const useStyles = makeStyles({
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
})

function Review({ name, title, desc, star = 5 }) {
    const classes = useStyles()
    return (
        <Card variant="elevation" elevation={2} square>
            <CardContent>
                <Typography variant="h5" component="p">
                    {name}
                </Typography>
                <Typography className={classes.pos} color="textSecondary">
                    {title}
                </Typography>
                <Typography variant="body2" component="p">
                    {desc}
                </Typography>
                <div style={{ marginTop: '10px' }}>
                    <Stars star={star} />
                </div>
            </CardContent>
        </Card>
    )
}

export default withStyles(styles)(Reviews)
