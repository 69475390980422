export default theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        padding: '20px',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            padding: 0,
        },
    },

    // line: {
    //     width: 'calc(100% - 20px)',
    //     padding: '10px',
    //     display: 'flex',
    //     flexDirection: 'row',
    //     justifyContent: 'space-between',
    //     height: '50px',
    // },
    // blueLine: {
    //     width: 'calc(100% - 20px)',
    //     padding: '10px',
    //     display: 'flex',
    //     flexDirection: 'row',
    //     justifyContent: 'space-between',
    //     height: '50px',
    //     backgroundColor: 'rgba(48,64,160,0.1)',
    // },

    panel: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        marginBottom: '2%',
    },

    field: {
        width: '100%',
        margin: 'auto',
        fontSize: '16px',
    },

    label: {
        width: '200px',
        fontSize: '18px',
        [theme.breakpoints.down('sm')]: {
            fontSize: '12px',
            width: '100%',
        },
    },

    buttonAdd: {
        margin: '30px 0',
        padding: '10px',
        width: '500px',
        float: 'right',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            fontSize: '0.9em',
        },
    },

    buttonSvg: {
        fontSize: '30px!important',
    },

    blocs: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
        },
    },

    blocGreen: {
        backgroundColor: '#6BC070',
        marginRight: '10px',
        [theme.breakpoints.down('sm')]: {
            marginRight: '0px',
        },
    },

    blocYellow: {
        backgroundColor: '#F4BA5C',
        marginLeft: '10px',
        [theme.breakpoints.down('sm')]: {
            marginLeft: '0px',
        },
    },

    bloc: {
        position: 'relative',
        width: 'calc(33% - 20px)',
        height: '120px',
        borderRadius: '5px',
        marginBottom: '1%',
        display: 'flex',
        flexDirection: 'row',
        boxShadow:
            '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            height: '60px',
            marginBottom: '5%',
        },
    },

    icons: {
        width: '105px',
    },

    iconMenu: {
        display: 'inline-block',
        color: '#fff',
        width: '90px',
        height: '90px',
        paddingTop: '15px',
        paddingLeft: '15px',
        [theme.breakpoints.down('sm')]: {
            display: 'inline-block',
            color: '#fff',
            width: '30px',
            height: '30px',
        },
    },

    infoText: {
        display: 'flex',
        flexDirection: 'column',
        width: 'calc(100% - 120px)',
        marginRight: '15px',
    },

    numberStock: {
        display: 'inline-block',
        color: 'white',
        fontSize: '2.7em',
        textAlign: 'right',
        width: '100%',
        height: '50%',
        marginTop: '15px',
        [theme.breakpoints.down('sm')]: {
            fontSize: '1.2em',
        },
    },

    textStock: {
        display: 'inline-block',
        color: 'white',
        fontSize: '1.6em',
        textAlign: 'right',
        width: '100%',
        height: '50%',
        [theme.breakpoints.down('sm')]: {
            fontSize: '1em',
        },
    },

    // Deuxième ligne avec les fonctions: "Je recherche", "Plus de filtres", "Tout déplier", "Tout sélectionner"
    secondLine: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '100%',
        [theme.breakpoints.down('sm')]: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        },
    },

    searchFilterUnfold: {
        display: 'flex',
        width: '70%',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },

    recherche: {
        width: '40%',
        marginRight: '30px',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            marginRight: 0,
        },
    },

    filtersButton: {
        width: '150px',
        height: '40px',
        backgroundColor: '#303F9F',
        color: 'white',
        '&:hover': {
            backgroundColor: '#fec107',
            color: 'black',
        },
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
    filtersMobileButton: {
        display: 'none',
        [theme.breakpoints.down('sm')]: {
            display: 'block',
        },
    },

    unfoldAll: {
        width: '30%',
        textAlign: 'right',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            textAlign: 'center',
            fontSize: '0.8em',
        },
    },

    // Fin deuxième ligne avec les fonctions: "Je recherche", "Plus de filtres", "Tout déplier", "Tout sélectionner"

    firstPanel: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },

    test123456: {
        width: '15%',
        [theme.breakpoints.down('sm')]: {
            width: '38%',
        },
    },

    categories: {
        width: '175px',
        marginBottom: '8px',
        [theme.breakpoints.down('sm')]: {
            width: '25%',
        },
    },

    categorie: {
        [theme.breakpoints.down('sm')]: {
            fontSize: '0.5em',
            paddingLeft: 0,
        },
    },

    equipementType: {
        width: '40%',
        display: 'flex',
        flexDirection: 'row',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            width: '60%',
        },
    },

    equipements: {
        display: 'inline-bloc',
        width: '50%',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },

    equipement: {
        [theme.breakpoints.down('sm')]: {
            textAlign: 'left',
            fontSize: '0.9em',
        },
    },

    type: {
        [theme.breakpoints.down('sm')]: {
            textAlign: 'left',
            fontSize: '0.7em',
        },
    },

    dateEquipement: {
        width: '400px',
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },

    rangeDatePerso: {
        width: '120px',
        marginRight: '15px',
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },

    detailPanel: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },

    bell: {
        width: '12.5%',
        [theme.breakpoints.down('sm')]: {
            width: '65px',
        },
    },

    equipementDetails: {
        display: 'inline-bloc',
        width: '50%',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },

    equipementTypeDetail: {
        width: '41.5%',
        display: 'flex',
        flexDirection: 'row',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            width: '45%',
        },
    },

    equipementDetail: {
        [theme.breakpoints.down('sm')]: {
            textAlign: 'left',
            fontSize: '0.9em',
        },
    },

    typeEquipement: {
        [theme.breakpoints.down('sm')]: {
            textAlign: 'left',
            fontSize: '0.7em',
        },
    },

    stocks: {
        width: '250px',
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },

    stock: {
        width: '80px',
        marginLeft: '30px',
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },

    cssDate: {
        width: '34%',
        display: 'flex',
        flexDirection: 'row',
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },

    dateBottom: {
        width: '35.5%',
        display: 'flex',
        flexDirection: 'row',
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },

    startDate: {
        width: '140px',
        marginRight: '10px',
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },

    endDate: {
        width: '140px',
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },

    replacehours: {
        width: '130px',
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },

    stockDetails: {
        width: '80px',
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
    redStock: {
        backgroundColor: 'red',
        color: 'white',
    },

    dateTopGreen: {
        width: '50px',
        height: '40px',
        backgroundColor: '#6BC070',
        borderRadius: '5px',
        boxShadow:
            '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)',
        [theme.breakpoints.down('sm')]: {
            marginTop: '10px',
            width: '25px',
            height: '25px',
        },
    },

    dateTopOrange: {
        width: '30px',
        height: '30px',
        backgroundColor: '#F4BA5C',
        borderRadius: '5px',
        boxShadow:
            '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)',
        marginLeft: '25%',
        [theme.breakpoints.down('sm')]: {
            marginTop: '10px',
            width: '25px',
            height: '25px',
        },
    },

    dateAlertGreen: {
        width: '30px',
        height: '30px',
        backgroundColor: '#6BC070',
        borderRadius: '5px',
        boxShadow:
            '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)',
        [theme.breakpoints.down('sm')]: {
            marginTop: '10px',
            width: '25px',
            height: '25px',
        },
    },

    dateAlertOrange: {
        width: '30px',
        height: '30px',
        backgroundColor: '#F4BA5C',
        borderRadius: '5px',
        boxShadow:
            '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)',
        [theme.breakpoints.down('sm')]: {
            marginTop: '10px',
            width: '25px',
            height: '25px',
        },
    },
    dateAlertRed: {
        width: '50px',
        height: '40px',
        backgroundColor: '#E36B61',
        borderRadius: '5px',
        boxShadow:
            '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)',
    },

    bellIconTop: {
        width: '20px',
        height: '20px',
        marginLeft: '0px',
        marginTop: '5px',
        [theme.breakpoints.down('sm')]: {
            width: '15px',
            height: '15px',
            marginBottom: '5px',
        },
    },

    bellIcon: {
        width: '20px',
        height: '20px',
        marginLeft: '0px',
        marginTop: '5px',
        [theme.breakpoints.down('sm')]: {
            width: '15px',
            height: '15px',
            marginBottom: '5px',
        },
    },

    hidde: {
        display: 'none',
    },
    filtersBar: {
        display: 'flex',
        marginTop: '15px',
        [theme.breakpoints.down('sm')]: {
            marginTop: '5px',
            display: 'flex',
            flexDirection: 'column',
        },
    },
    filterButton: {
        backgroundColor: 'white',
        border: '2px solid #303F9F',
        margin: '0 20px',
        [theme.breakpoints.down('sm')]: {
            margin: '0 10px',
        },
    },
    filterButtonFill: {
        backgroundColor: '#303F9F',
        color: 'white',
        margin: '0 20px',
    },

    extension: {
        boxShadow: 'none',
    },

    PanelSummary: {
        boxShadow:
            '0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)',
    },
    container: {
        width: 'calc(100% - 100px)',
        margin: 'auto',
        [theme.breakpoints.down('sm')]: {
            width: 'calc(100% - 30px)',
        },
    },
    panelDetail: {
        boxShadow:
            '0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)',
    },

    divider: {
        marginTop: '15px',
        marginBottom: '15px',
    },

    dividerH: {
        border: '0.5px solid #c4c4c4',
        height: '30px',
        marginRight: '10px',
        marginLeft: '10px',
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },

    moreHoriz: {
        display: 'none',
        [theme.breakpoints.down('sm')]: {
            display: 'block',
            alignItems: 'center',
            marginTop: '10px',
        },
    },

    iconsEdit: {
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },

    globalIcon: {
        [theme.breakpoints.down('sm')]: {
            display: 'flex',
            flexDirection: 'row',
        },
    },

    iconVisibilityEdit: {
        [theme.breakpoints.down('sm')]: {
            marginTop: '10px',
            marginTLeft: '10px',
        },
    },

    iconPopover: {
        [theme.breakpoints.down('sm')]: {
            marginTop: '20px',
        },
    },
    modalTitle: {
        fontSize: '24px',
        fontWeight: 500,
        textAlign: 'center',
        marginBottom: '10px',
    },
    espaces: {
        paddingTop: '20px',
    },
    dateType: {
        marginRight: '10px',
        marginLeft: '10px',
        display: 'flex',
        flexDirection: 'row',
    },
    textAddDate: {
        fontWeight: 500,
    },
    twoTextField: {
        display: 'flex',
        flexDirection: 'row',
    },
    textField: {
        marginRight: '10px',
        marginLeft: '10px',
        width: 'calc(100% - 20px)',
    },
    outlinedIcon: {
        width: '15px',
        height: '15px',
    },
})
