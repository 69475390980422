import React, { Component } from 'react'

import { FaFacebookF } from 'react-icons/fa'
import { FaTwitter } from 'react-icons/fa'
import { FaInstagram } from 'react-icons/fa'
import { FaLinkedinIn } from 'react-icons/fa'
import { FaEnvelope } from 'react-icons/fa'
import { withStyles } from '@material-ui/core/styles'

const styles = {
    icon: {
        color: '#303f9f',
        marginLeft: '10px',
    },
}

class ShareButtons extends Component {
    render() {
        const { classes } = this.props

        return (
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginTop: '1%',
                    alignItems: 'center',
                    marginBottom: '1%',
                }}
            >
                <a
                    href="https://www.facebook.com/boatonpro/"
                    target="_blank"
                    rel="noopener noreferrer"
                    title="facebook"
                >
                    <FaFacebookF size="40px" className={classes.icon} />
                </a>
                <a
                    href="https://twitter.com/BoatOn_Pro"
                    target="_blank"
                    rel="noopener noreferrer"
                    title="twitter"
                >
                    <FaTwitter size="40px" className={classes.icon} />
                </a>
                <a
                    href="https://www.instagram.com/boaton_pro/"
                    target="_blank"
                    rel="noopener noreferrer"
                    title="instragram"
                >
                    <FaInstagram size="40px" className={classes.icon} />
                </a>
                <a
                    href="https://www.linkedin.com/company/24968652/"
                    target="_blank"
                    rel="noopener noreferrer"
                    title="linkedin"
                >
                    <FaLinkedinIn size="40px" className={classes.icon} />
                </a>
                <a
                    href="mailto:?subject=Un super outil pour ton bateau&body=Bonjour%2C%0A%0AJe viens de découvrir un tout nouvel outil pour gérer tous les tracas de ton bateau. Tu peux stocker tes documents%2C gérer ton budget et personnaliser tes opérations de maintenance. Tu devrais y jeter un oeil : https://www.boaton.fr/my-boat-logbook%0A%0AA bientôt"
                    rel="noopener noreferrer"
                    title="mail"
                >
                    <FaEnvelope size="40px" className={classes.icon} />
                </a>
            </div>
        )
    }
}

export default withStyles(styles)(ShareButtons)
