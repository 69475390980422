export default theme => ({
    root: {
        position: 'relative',
        minHeight: 'calc(100vh - 82px)',
        scrollBehavior: 'smooth',
    },
    parking: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
    },
    presentation: Object.assign({}, theme.boxBoatOn, {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        border: '1px solid #c4c4c4',
        width: '85%',
        padding: 0,
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    }),
    ratingAndShare: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'stretch',
        margin: '20px',
        marginTop: 0,
        marginLeft: '4%',
        [theme.breakpoints.down('sm')]: {
            marginLeft: '10%',
        },
    },
    details: {
        width: '95%',
    },
    commentary: {
        width: '95%',
    },
    titleAndPrice: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
        },
    },
    fontTitle: {
        fontSize: '24px',
        margin: '20px',
        marginBottom: '10px',
        fontWeight: '500',
        color: '#3040a0',
    },
    fontSubTitle: {
        fontSize: '22px',
        margin: '20px',
        marginBottom: '10px',
        fontWeight: '500',
        color: '#3040a0',
    },
    fontPrice: {
        fontSize: '24px',
        margin: '20px',
        marginBottom: '10px',
        color: '#3040a0',
        [theme.breakpoints.down('sm')]: {
            fontSize: '20px',
        },
    },
    secondPart: {
        display: 'flex',
        flexDirection: 'row',
        width: '85%',
        position: 'relative',
        justifyContent: 'space-between',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            display: 'block',
        },
    },
    secondPartLeft: {
        width: '55%',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
    secondPartRight: {
        width: '45%',
        maxWidth: '445px',
        boxSizing: 'border-box',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
    pictos: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        margin: '15px',
        marginBottom: '30px',
        [theme.breakpoints.down('xs')]: {
            margin: '5px',
            marginBottom: '15px',
        },
    },
    divider: {
        backgroundColor: '#c4c4c4',
        marginTop: '20px',
    },
    description: {
        margin: '20px',
        marginRight: '0px',
    },
    text: {
        marginLeft: '20px',
        marginRight: '0px',
    },
    picto: {
        margin: '5px',
        maxWidth: '100px',
        [theme.breakpoints.down('sm')]: {
            height: '100%',
            width: '100%',
            maxHeight: '100px',
            maxWidth: '100px',
        },
    },
    pictures: {
        margin: 'auto',
        height: '50vh',
        display: 'flex',
        justifyContent: 'center',
    },
    importantImage: {
        width: '100%',
        margin: 'auto',
        height: '50vh',
        position: 'relative',
    },
    image: {
        width: '100%',
        height: '100%',
    },
    chevronLeft: {
        position: 'absolute',
        top: '0',
        left: 0,
        height: '100%',
    },
    chevronRight: {
        position: 'absolute',
        top: '0',
        right: 0,
        height: '100%',
    },
    map: {
        padding: '20px',
    },
    pictoAndText: {
        maxWidth: '200px',
        minWidth: '40px',
        margin: '2px',
        height: '100%',
        textAlign: 'center',
    },
    textPicto: {
        fontSize: '16px',
        [theme.breakpoints.down('xs')]: {
            display: 'none',
        },
    },
    dimensions: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        margin: '0 15px',
        [theme.breakpoints.down('xs')]: {
            flexDirection: 'column',
        },
    },
    desktop: {
        '@media (max-width: 601px)': {
            display: 'none',
        },
    },
    button: {
        marginBottom: 0,
        margin: 'auto',
        width: 'calc(100% - 20px)',
    },
    mobile: {
        position: 'fixed',
        bottom: 0,
        left: 0,
        width: '100%',
        padding: '10px',
        backgroundColor: '#fff',
        borderTop: '1px solid #c4c4c4',
        '@media (min-width: 600px)': {
            display: 'none',
        },
    },
    paper: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: theme.spacing(30),
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(4),
        outline: 'none',
    },
    modal: {
        display: 'flex',
        flexDirection: 'column',
        width: '50%',
        alignItems: 'center',
        textAlign: 'center',
        justifyContent: 'center',
        marginRight: 'auto',
        marginLeft: 'auto',
    },
    shareButton: {
        backgroundColor: '#f5f5f5',
        fontSize: '15px',
        marginRight: '3%',
        marginLeft: 'auto',
        [theme.breakpoints.down('sm')]: {
            fontSize: '12px',
        },
    },
    share: {
        height: '70px',
        display: 'flex',
        alignItems: 'center',
        textAlign: 'left',
        fontSize: '15px',
        color: '#3040a0',
    },
    shareIcons: {
        width: '50px',
        color: '#303f9f',
        display: 'block',
        marginLeft: '-3%',
    },
    shareText: {
        fontSize: '17px',
        textDecoration: 'none',
        textAlign: 'left',
        color: '#000',
        width: '100%',
        '&:hover': {
            cursor: 'pointer',
        },
    },
    snackbarMessage: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    iconLeft: {
        marginLeft: '0',
        marginRight: '15px',
        height: '50px',
        width: '50px',
        marginBottom: '5px',
    },
})
