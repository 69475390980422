import React from 'react'
import BoatOnComponent from '../../../../common/BoatOnComponent'
import { connect } from 'react-redux'

import { withStyles } from '@material-ui/core/styles'
import dictionary from '../Dictionary/RepairListDico'
import styles from '../Styles/RepairListCss'
import { API_URL } from '../../../../../services/config.service'
import RepairModalTop from './RepairModalTop'
import RepairModalDate from './RepairModalDate'
import Typography from '@material-ui/core/Typography'
import ReactShadowScroll from 'react-shadow-scroll'
import { Button } from '@material-ui/core'

class RepairModalDouble extends BoatOnComponent {
    constructor(props) {
        super(props)
        this.dictionary = dictionary

        this.dateLastSave = false
        this.dateNextSave = false

        this.save = this.save.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.changeNextDate = this.changeNextDate.bind(this)

        this.childDateLast = React.createRef()
        this.childDateNext = React.createRef()
    }

    handleChange(value, key) {
        this.props.handleChange(value, key)
    }

    save() {
        const { repairID } = this.props
        const modal = this

        const childDateLast = this.childDateLast.current
        const childDateNext = this.childDateNext.current

        const runDateSave = repairID => {
            childDateLast.save(repairID).then(() => {
                modal.dateLastSave = true
                modal.tryClose()
            })
            childDateNext.save(repairID).then(() => {
                modal.dateNextSave = true
                modal.tryClose()
            })
        }

        this.props.saveRepair().then(json => {
            if (!repairID && json.repairID) {
                runDateSave(json.repairID)
            }
        })
        if (repairID) runDateSave(repairID)
    }

    changeNextDate(dateType, dateLast) {
        const { checkupTypes, idcheckuptype } = this.props
        let checkupType = null
        const types = checkupTypes.types
        if (dateType == 'last') {
            if (dateLast != '' && idcheckuptype != null) {
                const nextControl = new Date(dateLast)
                for (const i in types) {
                    if (types[i].CheckupTypeID == idcheckuptype) {
                        checkupType = types[i]
                    }
                }
                if (checkupType.CycleType === '1') {
                    nextControl.setFullYear(
                        nextControl.getFullYear(),
                        nextControl.getMonth() +
                            parseFloat(checkupType.CycleLength),
                    )
                } else {
                    nextControl.setTime(
                        nextControl.getTime() +
                            checkupType.CycleLength * 60 * 60 * 1000,
                    )
                }
                this.childDateNext.current.setState({
                    date: nextControl.toISOString().split('T')[0],
                })
            }
        }
    }

    tryClose() {
        if (this.dateLastSave && this.dateNextSave) {
            this.props.closeModal()
        }
    }

    getFiles(document) {
        const { documents } = this.props

        for (let i = 0; i < documents.length; i++) {
            if (documents[i].DocumentID === document.DocumentID) {
                return documents[i].Files.map(f => {
                    return {
                        linkImg: API_URL + '/documents/' + f.Link,
                        id: f.FileID,
                    }
                })
            }
        }
        return []
    }

    render() {
        const {
            classes,
            type,
            typefamily,
            idcheckuptype,
            name,
            modalResponse,
            repairID,
            checkupCategories,
            checkupTypes,
            documents,
        } = this.props
        var idDateLast,
            dateLast,
            linkLast,
            amountLast,
            idTransactionLast,
            idDocumentsLast,
            detailsLast,
            fileLast,
            fileNoUrlLast,
            deleteFileId,
            fileIDsLast,
            idDateNext,
            dateNext,
            linkNext,
            amountNext,
            idTransactionNext,
            idDocumentsNext,
            detailsNext,
            fileNext,
            fileNoUrlNext,
            fileIDsNext = null

        if (modalResponse) {
            const { repairModal } = modalResponse

            idDateLast = repairModal.DateIdLast
            dateLast = repairModal.CheckupDateLast
            detailsLast = repairModal.commentLast
            amountLast = repairModal.AmountLast
            idDateNext = repairModal.DateIdNext
            dateNext = repairModal.CheckupDateNext
            detailsNext = repairModal.commentNext
            amountNext = repairModal.AmountNext

            if (
                repairModal.documentsLast &&
                repairModal.documentsLast.length > 0
            ) {
                idDocumentsLast = repairModal.documentsLast[0].DocumentID
                fileNoUrlLast = repairModal.documentsLast.map(d => null)
                fileIDsLast = repairModal.documentsLast.map(d => d.DocumentID)
                fileLast = this.getFiles(repairModal.documentsLast[0])
            }
            if (
                repairModal.documentsNext &&
                repairModal.documentsNext.length > 0
            ) {
                idDocumentsNext = repairModal.documentsNext[0].DocumentID
                fileNext = this.getFiles(repairModal.documentsNext[0])
                fileNoUrlNext = repairModal.documentsNext.map(d => null)
                fileIDsNext = repairModal.documentsNext.map(d => d.DocumentID)
            }
        }

        var repairTitle = null
        if (checkupTypes != null) {
            if (type != 20 || typefamily == 6) {
                repairTitle = name
            } else {
                let type = checkupTypes.types.find(
                    type => type.CheckupTypeID == idcheckuptype,
                )
                if (type) {
                    repairTitle = this.displayText(type.CheckupType)
                }
            }
        }

        return (
            <div>
                <Typography className={classes.modalTitle}>
                    {repairID
                        ? this.displayText('userEditRepair')
                        : this.displayText('userAddsRepair')}
                </Typography>
                <ReactShadowScroll
                    style={{ paddingTop: '20px', maxHeight: '65vh' }}
                >
                    {/* SELECTEUR */}
                    <RepairModalTop
                        type={type}
                        typefamily={typefamily}
                        idcheckuptype={idcheckuptype}
                        checkupCategories={checkupCategories}
                        checkupTypes={checkupTypes}
                        name={name}
                        handleChange={this.handleChange}
                    ></RepairModalTop>

                    {/* DATE LAST */}
                    <RepairModalDate
                        ref={this.childDateLast}
                        repairID={repairID}
                        idDate={idDateLast}
                        date={dateLast}
                        link={linkLast}
                        amount={amountLast}
                        idTransaction={idTransactionLast}
                        idDocuments={idDocumentsLast}
                        details={detailsLast}
                        file={fileLast}
                        fileNoUrl={fileNoUrlLast}
                        deleteFileId={deleteFileId}
                        fileIDs={fileIDsLast}
                        repairType={type}
                        repairTitle={repairTitle}
                        type={'last'}
                        handleChangeDateValue={this.changeNextDate}
                    ></RepairModalDate>

                    {/* DATE NEXT */}
                    <RepairModalDate
                        ref={this.childDateNext}
                        repairID={repairID}
                        idDate={idDateNext}
                        date={dateNext}
                        link={linkNext}
                        amount={amountNext}
                        idTransaction={idTransactionNext}
                        idDocuments={idDocumentsNext}
                        details={detailsNext}
                        file={fileNext}
                        fileNoUrl={fileNoUrlNext}
                        deleteFileId={deleteFileId}
                        fileIDs={fileIDsNext}
                        repairType={type}
                        repairTitle={repairTitle}
                        type={'next'}
                    ></RepairModalDate>
                </ReactShadowScroll>
                <Button
                    className={classes.validate}
                    variant="contained"
                    color="secondary"
                    onClick={this.save}
                >
                    {this.displayText('validate')}
                </Button>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return { documents: state.bob.documents }
}

export default connect(mapStateToProps)(withStyles(styles)(RepairModalDouble))
