export default {
    engine: {
        FR: 'Moteur',
        EN: 'Engine',
    },
    sailing: {
        FR: 'Voilier',
        EN: 'Sailing',
    },
    pneumatic: {
        FR: 'Semi-Rigide',
        EN: 'Pneumatic',
    },
    catamaran: {
        FR: 'Catamaran',
        EN: 'Catamaran',
    },
    barge: {
        FR: 'Péniche',
        EN: 'Barge',
    },
    jetSki: {
        FR: 'Jet-Ski',
        EN: 'Jet-Ski',
    },
    inBoard: {
        FR: 'In-Board',
        EN: 'Inboard',
    },
    outBoard: {
        FR: 'Hors-Bord',
        EN: 'Outboard',
    },
    boatName: {
        FR: 'Nom du bateau',
        EN: 'Boat name',
    },
    boatType: {
        FR: 'Type du bateau',
        EN: 'Boat type',
    },
    boatYear: {
        FR: 'Année du bateau',
        EN: 'Boat year',
    },
    st: {
        FR: 'er',
        EN: 'st',
    },
    first: {
        FR: 'Le 1',
        EN: 'The 1',
    },
    boatMaker: {
        FR: 'Marque du bateau',
        EN: 'Boat Maker',
    },
    boatModel: {
        FR: 'Modèle du bateau',
        EN: 'Boat model',
    },
    boatRegistration: {
        FR: 'Immatriculation',
        EN: 'Boat registration',
    },
    nbEngine: {
        FR: 'Nombre de moteurs',
        EN: 'Engine numbers',
    },
    boatEngine2Type: {
        FR: 'Type du moteur',
        EN: 'Boat type',
    },
    boatEngine2Year: {
        FR: 'Année du moteur',
        EN: 'Engine year',
    },
    boatEngine2Maker: {
        FR: 'Marque du moteur',
        EN: 'Engine maker',
    },
    boatEngine2Model: {
        FR: 'Modèle du moteur',
        EN: 'Engine model',
    },
    boatEngine2Power: {
        FR: 'Puissance du moteur (CV)',
        EN: 'Engine power (CV)',
    },
    boatEngineTime: {
        FR: 'Heures moteur',
        EN: 'Engine hours',
    },
    homePortOrStorage: {
        FR: 'Port ou lieu de stockage',
        EN: 'Homeport or storage area',
    },
    address: {
        FR: 'Adresse',
        EN: 'Address',
    },
    boatMoreInformations: {
        FR: 'Note sur mon bateau',
        EN: 'Note on my boat',
    },
    importantInformations: {
        FR: "Ecrivez ici d'autres informations importantes sur votre bateau",
        EN: 'Write here other important information about your boat',
    },
    noboat: {
        FR:
            "Oups, il semble que vous n'ayez pas enregistré les informations de base de votre bateau. Cliquez sur le bouton ci-dessous pour les ajouter.",
        EN: "Warning, you've no boat registered",
    },
    SignBoat: {
        FR: 'Enregistrer votre bateau',
        EN: 'Signin your boat',
    },
    addBoat: {
        FR: 'Ajouter un bateau',
        EN: 'Add a new boat',
    },
    boat: {
        FR: 'Mon bateau',
        EN: 'My boat',
    },
    boatAdd: {
        FR: "Ajout d'un bateau",
        EN: 'Add a boat',
    },
    enginesAdd: {
        FR: 'Ajout des moteurs',
        EN: 'Add engines',
    },
}
