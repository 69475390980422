export const userConstants = {
    LOGIN_REQUEST: 'USERS_LOGIN_REQUEST',
    LOGIN_SUCCESS: 'USERS_LOGIN_SUCCESS',
    LOGIN_FAILURE: 'USERS_LOGIN_FAILURE',
    LOGOUT: 'USERS_LOGOUT',
    UPDATE_SUCCESS: 'USER_UPDATE_SUCCESS',
    UPDATE_FAILURE: 'USER_UPDATE_FAILURE',
    REQUEST_USER: 'REQUEST_USER',

    REFRESH_TOKEN: 'REFRESH_TOKEN',
    TOKEN_REFRESH_SUCCESS: 'TOKEN_REFRESH_SUCCESS',
    TOKEN_REFRESH_FAILURE: 'TOKEN_REFRESH_FAILURE',

    SUBSCRIBE_REQUEST: 'USERS_LOGBOOK_REQUEST',
    SUBSCRIBE_SUCCESS: 'USERS_LOGBOOK_SUBSCRIBED',
    SUBSCRIBE_FAILURE: 'USERS_LOGBOOK_FAILURE',

    UPDATE_USER: 'UPDATE_USER',

    SUBSCRIPTIONS_REQUEST: 'SUBSCRIPTIONS_REQUEST',
    SUBSCRIPTIONS_SUCCESS: 'SUBSCRIPTIONS_SUCCESS',
    SUBSCRIPTIONS_FAILURE: 'SUBSCRIPTIONS_FAILURE',
}
