import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import dictionary from './Dictionary/AlertBarDico'
import BoatOnComponent from '../../../common/BoatOnComponent'
import Button from '@material-ui/core/Button'
import BuildIcon from '@material-ui/icons/Build'
import AccessTimeIcon from '@material-ui/icons/AccessTime'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'

const styles = theme => ({
    saveBottom: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        position: 'fixed',
        bottom: 0,
        right: '50%',
        transform: 'translate(+50%)',
        width: '100%',
        backgroundColor: 'white',
        borderTop: '3px solid #c4c4c4',
        borderRight: '3px solid #c4c4c4',
        borderLeft: '3px solid #c4c4c4',
        borderTopLeftRadius: '10px',
        borderTopRightRadius: '10px',
        zIndex: 1499,
        padding: '12px',
        maxHeight: '7%',
    },
    button: {
        color: 'black',
        '&:hover': {
            backgroundColor: '#3040a0',
            color: 'white',
        },
        float: 'right',
        marginRight: '15px',
        marginLeft: '15px',
        width: '27%',
        fontSize: '17px',
        [theme.breakpoints.down('sm')]: {
            fontSize: '9px',
            width: '30%',
            marginRight: '5px',
            marginLeft: '5px',
        },
    },
})

class AlertBar extends BoatOnComponent {
    constructor(props) {
        super(props)
        this.dictionary = dictionary
    }

    render() {
        const { classes } = this.props
        const isMobile = window.innerWidth <= 500

        if (this.props.open) {
            return (
                <div className={classes.saveBottom}>
                    <Button
                        className={classes.button}
                        size="medium"
                        color="secondary"
                        variant="contained"
                        endIcon={!isMobile ? <BuildIcon /> : <></>}
                        onClick={this.props.sendMail}
                    >
                        {this.displayText('quotation')}
                    </Button>
                    <Button
                        className={classes.button}
                        size="medium"
                        color="secondary"
                        variant="contained"
                        endIcon={!isMobile ? <AccessTimeIcon /> : <></>}
                        onClick={() => this.props.openCalendar('last')}
                    >
                        {!isMobile
                            ? this.displayText('lastControl')
                            : this.displayText('lastControlMobile')}
                    </Button>
                    <Button
                        className={classes.button}
                        size="medium"
                        color="secondary"
                        variant="contained"
                        endIcon={!isMobile ? <CheckCircleOutlineIcon /> : <></>}
                        onClick={() => this.props.openCalendar('next')}
                    >
                        {!isMobile
                            ? this.displayText('nextControl')
                            : this.displayText('nextControlMobile')}
                    </Button>
                </div>
            )
        } else {
            return null
        }
    }
}

export default withStyles(styles)(AlertBar)
