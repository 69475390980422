import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Redirect, Route, Router } from 'react-router-dom'
import { MuiThemeProvider } from '@material-ui/core/styles'

import { PrivateRoute } from './components/common/PrivateRoute'

import { history } from './helpers'
import { alertActions, userActions } from './actions'

import LoginPage from './components/LoginPage/LoginPage'
import ProfilePage from './components/ProfilePage/ProfilePage'
import ForgetPage from './components/ForgetPage/ForgetPage'
import LogbookPage from './components/LogbookPage/LogbookPage'
// Static Pages
import Error404StaticPage from './components/StaticPages/Error404Page'

import theme from './helpers/customTheme'

import AppLanguage from './constants/AppLanguage'
import AppRoute from './constants/AppRoute'
import { LocalizedRouter, MultiLangualSwitch, appStrings } from './languages'
import { loadStripe } from '@stripe/stripe-js'
import { STRIPE_PUBLIC_KEY } from './services/config.service'

const stripePromise = loadStripe(STRIPE_PUBLIC_KEY)

class App extends Component {
    constructor(props) {
        super(props)

        const { dispatch } = this.props
        dispatch(userActions.init())

        history.listen((location, action) => {
            dispatch(alertActions.clear())
        })
    }

    render() {
        return (
            <MuiThemeProvider theme={theme}>
                <LocalizedRouter
                    RouterComponent={Router}
                    languages={AppLanguage}
                    appStrings={appStrings}
                    history={history}
                >
                    <div>
                        <MultiLangualSwitch
                            errorRedirection={AppRoute.Error404}
                        >
                            <Route
                                route={AppRoute.Login}
                                component={LoginPage}
                            />
                            <Route
                                route={AppRoute.Register}
                                component={() => <LoginPage register={true} />}
                            />
                            <Route
                                route={AppRoute.Forget}
                                component={ForgetPage}
                            />
                            <PrivateRoute
                                route={AppRoute.Profile}
                                component={ProfilePage}
                            />
                            <PrivateRoute
                                route={AppRoute.PersonnalInfos}
                                component={ProfilePage}
                            />
                            <PrivateRoute
                                route={AppRoute.PaymentInfos}
                                component={ProfilePage}
                            />
                            <Route
                                exact
                                route={AppRoute.Root}
                                component={LogbookPage}
                            />
                            <Route
                                exact
                                route={AppRoute.Home}
                                component={LogbookPage}
                            ></Route>

                            <Route
                                exact
                                path="/cgv"
                                title="cgv"
                                component={() => {
                                    window.location =
                                        'https://www.info.boaton.fr/cgv'
                                    return null
                                }}
                            />

                            <Route
                                route={AppRoute.LogBook.Home}
                                component={LogbookPage}
                            />
                            <Route
                                route={AppRoute.LogBook.Inventory}
                                component={LogbookPage}
                            />
                            <Route path="/my-boat-logbook/boaton-book">
                                <Redirect
                                    status={301}
                                    to="/journal-de-bord/boaton-book"
                                />
                            </Route>
                            <Route path="/my-boat-logbook/my-alerts">
                                <Redirect
                                    status={301}
                                    to="/journal-de-bord/mes-alertes"
                                />
                            </Route>
                            <Route path="/my-boat-logbook/my-informations">
                                <Redirect
                                    status={301}
                                    to="/journal-de-bord/mes-informations"
                                />
                            </Route>
                            <Route path="/my-boat-logbook/my-expenses-and-incomes">
                                <Redirect
                                    status={301}
                                    to="/journal-de-bord/mes-depenses-et-revenus"
                                />
                            </Route>
                            <Route path="/my-boat-logbook/my-documents">
                                <Redirect
                                    status={301}
                                    to="/journal-de-bord/mes-documents"
                                />
                            </Route>
                            <Route path="/my-boat-logbook/">
                                <Redirect status={301} to="/journal-de-bord/" />
                            </Route>

                            <Route path="/search/.hash.horsbord/">
                                <Redirect to="/" />
                            </Route>
                            <Route path="/search/.hash.achat/">
                                <Redirect to="/" />
                            </Route>
                            <Route path="/search/.hash.jetski/">
                                <Redirect to="/" />
                            </Route>
                            <Route path="/search/.hash.moteur/">
                                <Redirect to="/" />
                            </Route>
                            <Route path="/search/.hash.inboard/">
                                <Redirect to="/" />
                            </Route>
                            <Route path="/search/.hash.voile/">
                                <Redirect to="/" />
                            </Route>
                            <Route path="/search/.hash.bateau/">
                                <Redirect to="/" />
                            </Route>

                            {/* VOILA LES SOFTS 404 NE FONCTIONNANT PAS */}

                            <Route
                                exact
                                path="/book"
                                component={() => {
                                    window.location =
                                        'https://www.boaton.fr/my-boat-logbook'
                                    return null
                                }}
                            />
                            <Route
                                exact
                                path="/carnet-tempo"
                                title="carnet d'entretient"
                                component={() => {
                                    window.location =
                                        'https://www.info.boaton.fr/mon-carnet-d-entretien'
                                    return null
                                }}
                            />
                            <Route
                                path="/le-blog/"
                                title="blog"
                                component={value => {
                                    window.location =
                                        'https://www.info.boaton.fr/le-blog/'
                                    return null
                                }}
                            />
                            <Route
                                path="/forum/"
                                title="forum"
                                component={value => {
                                    window.location =
                                        'https://www.info.boaton.fr/forum/'
                                    return null
                                }}
                            />
                            <Route
                                path="/post/"
                                title="post"
                                component={value => {
                                    window.location =
                                        'https://www.info.boaton.fr/post/'
                                    return null
                                }}
                            />

                            <Route
                                route={AppRoute.ParkingSearchPage}
                                component={value => {
                                    window.location = 'https://www.boaton.fr/'
                                    return null
                                }}
                            />

                            <Route
                                route={AppRoute.InsurancePage}
                                component={value => {
                                    window.location = 'https://www.boaton.fr/'
                                    return null
                                }}
                            />

                            <Route
                                route={AppRoute.RentMyParking}
                                component={value => {
                                    window.location = 'https://www.boaton.fr/'
                                    return null
                                }}
                            />

                            <Route
                                exact
                                path="/boatonPro"
                                title="boaton pro"
                                component={() => {
                                    window.location =
                                        'https://www.pro.boaton.fr'
                                    return null
                                }}
                            />

                            <Route
                                route={AppRoute.MainSite}
                                component={value => {
                                    window.location = 'https://www.boaton.fr/'
                                    return null
                                }}
                            />

                            <Route path="*" component={Error404StaticPage} />
                        </MultiLangualSwitch>
                    </div>
                </LocalizedRouter>
            </MuiThemeProvider>
        )
    }
}

export default connect()(App)
