import React from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import { Helmet } from 'react-helmet'
import ChevronLeft from '@material-ui/icons/ChevronLeft'
import ChevronRight from '@material-ui/icons/ChevronRight'
import InsertPhoto from '@material-ui/icons/InsertPhoto'
import Button from '@material-ui/core/Button'
import Drawer from '@material-ui/core/Drawer'
import ReactPixel from 'react-facebook-pixel'
import BoatOnComponent from '../common/BoatOnComponent'
import Modal from '@material-ui/core/Modal'
import AssignmentIcon from '@material-ui/icons/Assignment'
import ShareIcon from '@material-ui/icons/Share'
import SmsIcon from '@material-ui/icons/Sms'
import {
    EmailShareButton,
    FacebookShareButton,
    TwitterShareButton,
    WhatsappShareButton,
} from 'react-share'
import { FaFacebookF, FaTwitter, FaEnvelope } from 'react-icons/fa'
import { IoLogoWhatsapp } from 'react-icons/io'
import { fetchData } from '../../actions/api.actions'
import styles from './Styles/ParkingPageCss'
import dictionary from './Dictionary/ParkingPageDico'
import Navigation from '../common/Navigation'
import Footer from '../common/Footer'
import Stars from '../common/UsefullComponents/Stars'
import pictoAccess from '../../images/pictoAccess.png'
import pictoEau from '../../images/pictoEau.png'
import pictoCloture from '../../images/pictoCloture.png'
import pictoAmarrage from '../../images/pictoAmarrage.png'
import pictoElectricite from '../../images/pictoElectricite.png'
import pictoCamera from '../../images/pictoCamera.png'
import Commentary from '../common/Commentary'
import ParkingMenu from './ParkingMenu'
import ParkingMap from '../common/UsefullComponents/ParkingMap'
import { postData } from '../../actions/api.actions'
import { history } from '../../helpers'
import { API_URL } from '../../services/config.service'
import copy from 'copy-to-clipboard'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import AppRoute from '../../constants/AppRoute'

class ParkingPage extends BoatOnComponent {
    constructor(props) {
        super(props)
        this.dictionary = dictionary

        this.state = {
            actualDisplayedPic: 0,
            mobileOpen: false,
            openModal: false,
            linkCopied: false,
            parking: null,
        }

        const options = {
            autoConfig: true,
            debug: false,
        }
        ReactPixel.init('181677145678471', options)

        ReactPixel.pageView()

        this.handleDrawerToggle = this.handleDrawerToggle.bind(this)
    }

    componentDidUpdate(prevProps) {
        const props = this.props
        if (
            (this.state.parking === null && props.parking !== null) ||
            JSON.stringify(props.parking) !== JSON.stringify(prevProps.parking)
        ) {
            this.setState({ parking: props.parking })
        }
    }

    componentDidMount() {
        this.update()
    }

    update() {
        const data = new FormData()
        data.append('parkingid', this.props.match.params.id)
        this.props.dispatch(
            fetchData(API_URL + '/parkingdetails.php', 'parking', 'POST', data),
        )
        this.props.dispatch(
            fetchData(
                API_URL + '/parkingpicturesget.php',
                'parkingPictures',
                'POST',
                data,
            ),
        )
        this.props.dispatch(
            fetchData(
                API_URL + '/ratings/ratingparkingget.php',
                'parkingRatings',
                'POST',
                data,
            ),
        )
        this.props.dispatch(
            fetchData(
                API_URL + '/parkingstatus.php',
                'parkingStatus',
                'POST',
                data,
            ),
        )
        this.getParkingPrice(this.props.match.params.id)
        if (this.props.location.state && !this.state.dates)
            this.setState({ dates: this.props.location.state.dates })
    }

    handleChangeImage(pos) {
        const parkingPictures = this.props.parkingPictures.pictures || []
        if (
            parkingPictures.length - 1 >= this.state.actualDisplayedPic + pos &&
            parkingPictures.length > 0 &&
            this.state.actualDisplayedPic + pos >= 0
        ) {
            this.setState({
                actualDisplayedPic: this.state.actualDisplayedPic + pos,
            })
        }
    }

    handleDrawerToggle() {
        this.setState({ mobileOpen: !this.state.mobileOpen })
    }

    handleOpenModal = () => {
        this.setState({ openModal: true })
    }

    handleCloseModal = () => {
        this.setState({
            openModal: false,
            linkCopied: false,
        })
    }

    renderParkingMenu(parking, parkingPictures, parkingStatus) {
        const { classes } = this.props
        const { mobileOpen } = this.state

        return (
            <div>
                <div className={classes.desktop}>
                    <ParkingMenu
                        parking={parking}
                        parkingPictures={parkingPictures}
                        indisponibility={parkingStatus.Orders}
                    />
                </div>
                <div className={classes.mobile}>
                    <Button
                        className={classes.button}
                        variant="contained"
                        color="secondary"
                        onClick={this.handleDrawerToggle}
                    >
                        {this.displayText('book')}
                    </Button>
                    <Drawer
                        variant="temporary"
                        anchor="bottom"
                        open={mobileOpen}
                        onClose={this.handleDrawerToggle}
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                        ModalProps={{
                            keepMounted: true, // Better open performance on mobile.
                        }}
                    >
                        <div>
                            <ParkingMenu
                                parking={parking}
                                parkingPictures={parkingPictures}
                                indisponibility={parkingStatus.Orders}
                                dates={this.state.dates}
                            />
                        </div>
                    </Drawer>
                </div>
            </div>
        )
    }

    precise(x) {
        return Number.parseFloat(x).toPrecision(2)
    }

    getParkingPrice(parkingID) {
        const data = new FormData()

        data.append('startdate', Date.parse(0))
        data.append('enddate', Date.parse(24 * 60 * 60 * 1000))
        data.append('Engagement', 0)
        data.append('parkingID', parkingID)
        data.append('mensualisation', 0)

        this.props.dispatch(postData(API_URL + '/parkinggetprice.php', data))
    }

    copyLinkToClipboard(url) {
        copy(url)
        this.setState({
            linkCopied: true,
        })
        // navigator.clipboard.writeText(url).then(
        //     () => {                     //works for most browsers
        //         this.setState({
        //             linkCopied: true,
        //         })
        //     },
        //     (err) => {                  //for older mobile safari versions
        //         function handler(e) {
        //             e.clipboardData.setData('text/plain', url)
        //             e.preventDefault()
        //             document.removeEventListener('copy', handler, true)
        //         }
        //
        //         document.addEventListener('copy', handler, true)
        //         document.execCommand('copy')
        //         this.setState({
        //             linkCopied: true,
        //         })
        //     }
        // )
    }

    renderLinkCopiedModal() {
        setTimeout(
            function() {
                //Start the timer
                this.setState({
                    openModal: false,
                    linkCopied: false,
                })
            }.bind(this),
            1000,
        ) //After 1 second, close modal

        return <Typography>Lien copié !</Typography>
    }

    renderShareModal(shareUrl) {
        return (
            <div className={this.props.classes.Modal}>
                {this.displayText('share')}
                <div className={this.props.classes.share}>
                    <FaFacebookF
                        size="18px"
                        className={this.props.classes.shareIcons}
                    />
                    <FacebookShareButton url={shareUrl} hashtag="#BoatOn">
                        <div className={this.props.classes.shareText}>
                            Facebook
                        </div>
                    </FacebookShareButton>
                </div>
                <div className={this.props.classes.share}>
                    <FaTwitter
                        size="18px"
                        className={this.props.classes.shareIcons}
                    />
                    <TwitterShareButton url={shareUrl} hashtags={['BoatOn']}>
                        <div className={this.props.classes.shareText}>
                            Twitter
                        </div>
                    </TwitterShareButton>
                </div>
                <div className={this.props.classes.share}>
                    <IoLogoWhatsapp
                        size="20px"
                        className={this.props.classes.shareIcons}
                    />
                    <WhatsappShareButton url={shareUrl} hashtags={['BoatOn']}>
                        <div className={this.props.classes.shareText}>
                            Whatsapp
                        </div>
                    </WhatsappShareButton>
                </div>
                <div className={this.props.classes.share}>
                    <FaEnvelope
                        size="18px"
                        className={this.props.classes.shareIcons}
                    />

                    <EmailShareButton
                        url={shareUrl}
                        subject="BoatOn - Place de parking"
                        body="Bonjour,
                                                                                                Je viens de découvrir cet emplacement. Tu devrais y jeter un oeil !
                                                                                                A bientot !"
                    >
                        <div className={this.props.classes.shareText}>
                            E-mail
                        </div>
                    </EmailShareButton>
                </div>
                <div className={this.props.classes.share}>
                    <SmsIcon className={this.props.classes.shareIcons} />
                    <a
                        href={'sms:?&body=' + shareUrl}
                        className={this.props.classes.shareText}
                        title="sms"
                        alt="sms"
                    >
                        <div className={this.props.classes.shareText}>
                            {this.displayText('shareSMS')}
                        </div>
                    </a>
                </div>
                <div className={this.props.classes.share}>
                    <AssignmentIcon className={this.props.classes.shareIcons} />
                    <div
                        className={this.props.classes.shareText}
                        onClick={() => this.copyLinkToClipboard(shareUrl)}
                    >
                        {this.displayText('copyLink')}
                    </div>
                </div>
            </div>
        )
    }

    getProduct(parking) {
        return {
            '@context': 'http://schema.org/',
            '@type': 'Product',
            sku: `${parking.ParkingID || parking.parkingId}`,
            image: `https://www.boaton.fr${this.props.location &&
                this.props.location.pathname}`,
            name: `${parking.Title}`,
            description: `${parking.Description}`,
            brand: {
                '@type': 'Brand',
                name: 'BoatOn',
            },
            offers: {
                '@type': 'Offer',
                priceCurrency: 'EUR',
                price: `${parking.PricePerMonthPerM2}`,
                priceValidUntil: new Date(),
                url: `https://www.boaton.fr/parking/${parking.ParkingID ||
                    parking.parkingId}`,
                availability: 'https://schema.org/InStock',
            },
            aggregateRating: {
                '@type': 'AggregateRating',
                ratingValue: `${parking.Rating}`,
                reviewCount: '0',
            },
            review: {
                '@type': 'Review',
                reviewRating: {
                    '@type': 'Rating',
                    ratingValue: `${parking.Rating}`,
                    bestRating: `${parking.Rating}`,
                },
            },
        }
    }

    render() {
        const { classes, loading, error } = this.props
        const { actualDisplayedPic } = this.state

        if (loading !== 0) {
            this.renderLoading('50%')
        }

        if (error)
            this.historyPush(history, AppRoute.Error404, '', {
                state: {
                    personnalizedError: this.displayText('errorParking'),
                    routeRedirect: AppRoute.ParkingSearchPage,
                    buttonText: this.displayText('errorButtonText'),
                },
            })
        if (
            this.state.parking &&
            this.state.parking.parking &&
            this.props.parkingPictures &&
            this.props.parkingRatings
        ) {
            const { parking } = this.state.parking
            if (parking.Status === '2' || parking.error)
                this.historyPush(history, AppRoute.Error404, '', {
                    state: {
                        personnalizedError: this.displayText('errorParking'),
                        routeRedirect: AppRoute.ParkingSearchPage,
                        buttonText: this.displayText('errorButtonText'),
                    },
                })
            const parkingPictures = this.props.parkingPictures.pictures || []
            const parkingRatings = this.props.parkingRatings || []
            const parkingStatus = this.props.parkingStatus || []
            const shareUrl = window.location.href
            const img =
                parkingPictures.length > 0
                    ? API_URL +
                      `/parkingpictures/${parkingPictures[actualDisplayedPic].PictureURL}`
                    : ''
            const imgId =
                parkingPictures.length > 0
                    ? parkingPictures[actualDisplayedPic].ParkingPictureID
                    : ''
            const full =
                parkingPictures.length > 0
                    ? parkingPictures[actualDisplayedPic].PictureURL.split('.')
                    : []
            const video = ['mp4', 'mov', 'mpg'].includes(full[full.length - 1])
            return (
                <div className={classes.root}>
                    <Helmet
                        script={[
                            {
                                type: 'application/ld+json',
                                innerHTML: `
                        ${JSON.stringify(this.getProduct(parking))}`,
                            },
                        ]}
                    ></Helmet>
                    <Navigation>
                        <div className={classes.parking}>
                            <div className={classes.presentation}>
                                <div className={classes.image}>
                                    {parkingPictures[actualDisplayedPic] ? (
                                        <div className={classes.pictures}>
                                            <div
                                                className={
                                                    classes.importantImage
                                                }
                                            >
                                                <div
                                                    style={{
                                                        backgroundImage: `url(${img})`,
                                                        backgroundColor:
                                                            '#c4c4c4',
                                                        width: '100%',
                                                        height: '100%',
                                                        opacity: '0.5',
                                                        backgroundPosition:
                                                            'center',
                                                        backgroundSize: 'cover',
                                                    }}
                                                />
                                                <Button
                                                    className={
                                                        classes.chevronLeft
                                                    }
                                                    onClick={() => {
                                                        this.handleChangeImage(
                                                            -1,
                                                        )
                                                    }}
                                                >
                                                    <ChevronLeft />
                                                </Button>
                                                {video ? (
                                                    <video
                                                        controls
                                                        style={{
                                                            maxWidth:
                                                                'calc(100% - 14vw)',
                                                            maxHeight: '100%',
                                                            margin: 'auto',
                                                            position:
                                                                'absolute',
                                                            top: 0,
                                                            bottom: 0,
                                                            right: 0,
                                                            left: 0,
                                                        }}
                                                    >
                                                        <source
                                                            src={img}
                                                            alt={imgId}
                                                            type={
                                                                'video/' +
                                                                full[
                                                                    full.length -
                                                                        1
                                                                ]
                                                            }
                                                        ></source>
                                                    </video>
                                                ) : (
                                                    <LazyLoadImage
                                                        src={img}
                                                        alt={imgId}
                                                        style={{
                                                            maxWidth:
                                                                'calc(100% - 14vw)',
                                                            maxHeight: '100%',
                                                            margin: 'auto',
                                                            position:
                                                                'absolute',
                                                            top: 0,
                                                            bottom: 0,
                                                            right: 0,
                                                            left: 0,
                                                        }}
                                                    />
                                                )}
                                                <Button
                                                    className={
                                                        classes.chevronRight
                                                    }
                                                    onClick={() => {
                                                        this.handleChangeImage(
                                                            1,
                                                        )
                                                    }}
                                                >
                                                    <ChevronRight />
                                                </Button>
                                            </div>
                                        </div>
                                    ) : (
                                        <div className={classes.noImage}>
                                            <InsertPhoto
                                                style={{
                                                    width: '20%',
                                                    height: '100%',
                                                    margin: 'auto',
                                                    display: 'block',
                                                }}
                                            />
                                        </div>
                                    )}
                                </div>
                                <div className={classes.titleAndPrice}>
                                    <Typography className={classes.fontTitle}>
                                        {parking.Title}
                                    </Typography>
                                    <Typography className={classes.fontPrice}>
                                        {this.displayText('from')}{' '}
                                        {parking.PricePerDayPerM2.toFixed(2)}{' '}
                                        {this.displayText('euroPerM')}{' '}
                                        {this.displayText('perDay')} /{' '}
                                        {parking.PricePerMonthPerM2.toFixed(2)}{' '}
                                        {this.displayText('euroPerM')}{' '}
                                        {this.displayText('perMonth')}
                                    </Typography>
                                </div>
                                <div className={classes.ratingAndShare}>
                                    <Stars star={parkingRatings.moyenne || 0} />
                                    <Typography>
                                        ({parkingRatings.ratings.length})
                                    </Typography>
                                    <Button
                                        className={classes.shareButton}
                                        onClick={this.handleOpenModal}
                                        startIcon={<ShareIcon />}
                                    >
                                        {this.displayText('share')}
                                    </Button>
                                </div>
                            </div>
                            <div className={classes.secondPart}>
                                <div className={classes.secondPartLeft}>
                                    <div className={classes.details}>
                                        <div className={classes.infos}>
                                            <Typography
                                                className={classes.fontSubTitle}
                                            >
                                                {this.displayText(
                                                    'information',
                                                )}
                                            </Typography>
                                            <div className={classes.pictos}>
                                                {parking.Access247 === '1' ? (
                                                    <div
                                                        className={
                                                            classes.pictoAndText
                                                        }
                                                    >
                                                        <LazyLoadImage
                                                            src={pictoAccess}
                                                            width="90%"
                                                            height="90%"
                                                            alt="pictoAccess"
                                                            className={
                                                                classes.picto
                                                            }
                                                        />
                                                        <Typography
                                                            className={
                                                                classes.textPicto
                                                            }
                                                        >
                                                            {this.displayText(
                                                                'access',
                                                            )}
                                                        </Typography>
                                                    </div>
                                                ) : null}
                                                {parking.DirectAccess ===
                                                '1' ? (
                                                    <div
                                                        className={
                                                            classes.pictoAndText
                                                        }
                                                    >
                                                        <LazyLoadImage
                                                            src={pictoAmarrage}
                                                            width="90%"
                                                            height="90%"
                                                            alt="pictoAmarrage"
                                                            className={
                                                                classes.picto
                                                            }
                                                        />
                                                        <Typography
                                                            className={
                                                                classes.textPicto
                                                            }
                                                        >
                                                            {this.displayText(
                                                                'directAccess',
                                                            )}
                                                        </Typography>
                                                    </div>
                                                ) : null}
                                                {parking.Electricity === '1' ? (
                                                    <div
                                                        className={
                                                            classes.pictoAndText
                                                        }
                                                    >
                                                        <LazyLoadImage
                                                            src={
                                                                pictoElectricite
                                                            }
                                                            width="90%"
                                                            height="90%"
                                                            alt="pictoElectricite"
                                                            className={
                                                                classes.picto
                                                            }
                                                        />
                                                        <Typography
                                                            className={
                                                                classes.textPicto
                                                            }
                                                        >
                                                            {this.displayText(
                                                                'electricity',
                                                            )}
                                                        </Typography>
                                                    </div>
                                                ) : null}
                                                {parking.Water === '1' ? (
                                                    <div
                                                        className={
                                                            classes.pictoAndText
                                                        }
                                                    >
                                                        <LazyLoadImage
                                                            src={pictoEau}
                                                            width="90%"
                                                            height="90%"
                                                            alt="pictoEau"
                                                            className={
                                                                classes.picto
                                                            }
                                                        />
                                                        <Typography
                                                            className={
                                                                classes.textPicto
                                                            }
                                                        >
                                                            {this.displayText(
                                                                'water',
                                                            )}
                                                        </Typography>
                                                    </div>
                                                ) : null}
                                                {parking.Security === '1' ? (
                                                    <div
                                                        className={
                                                            classes.pictoAndText
                                                        }
                                                    >
                                                        <LazyLoadImage
                                                            src={pictoCloture}
                                                            width="90%"
                                                            height="90%"
                                                            alt="pictoCloture"
                                                            className={
                                                                classes.picto
                                                            }
                                                        />
                                                        <Typography
                                                            className={
                                                                classes.textPicto
                                                            }
                                                        >
                                                            {this.displayText(
                                                                'spaceClose',
                                                            )}
                                                        </Typography>
                                                    </div>
                                                ) : null}
                                                {parking.SecurityCamera ===
                                                '1' ? (
                                                    <div
                                                        className={
                                                            classes.pictoAndText
                                                        }
                                                    >
                                                        <LazyLoadImage
                                                            src={pictoCamera}
                                                            width="90%"
                                                            height="90%"
                                                            alt="pictoCamera"
                                                            className={
                                                                classes.picto
                                                            }
                                                        />
                                                        <Typography
                                                            className={
                                                                classes.textPicto
                                                            }
                                                        >
                                                            {this.displayText(
                                                                'camera',
                                                            )}
                                                        </Typography>
                                                    </div>
                                                ) : null}
                                            </div>
                                            <div className={classes.dimensions}>
                                                <Typography
                                                    className={
                                                        classes.textDimension
                                                    }
                                                >
                                                    {this.displayText('length')}{' '}
                                                    : {parking.Length}{' '}
                                                    {this.displayText('meter')}
                                                </Typography>
                                                <Typography
                                                    className={
                                                        classes.textDimension
                                                    }
                                                >
                                                    {this.displayText('width')}{' '}
                                                    : {parking.Width}{' '}
                                                    {this.displayText('meter')}
                                                </Typography>
                                                <Typography
                                                    className={
                                                        classes.textDimension
                                                    }
                                                >
                                                    {this.displayText('height')}{' '}
                                                    :{' '}
                                                    {parking.Height == 0
                                                        ? '-'
                                                        : parking.Height}{' '}
                                                    {this.displayText('meter')}
                                                </Typography>
                                            </div>
                                        </div>
                                        <Divider className={classes.divider} />
                                        <Typography
                                            className={classes.fontSubTitle}
                                        >
                                            {this.displayText('description')}
                                        </Typography>
                                        <Typography
                                            className={classes.description}
                                        >
                                            {parking.Description}
                                        </Typography>
                                        <div className={classes.map}>
                                            <ParkingMap mapStatus />
                                        </div>
                                        <Typography className={classes.text}>
                                            {this.displayText('addressNotShow')}
                                        </Typography>
                                    </div>
                                    <div className={classes.commentary}>
                                        <Divider className={classes.divider} />
                                        <Typography
                                            className={classes.fontSubTitle}
                                        >
                                            {parkingRatings.ratings.length}{' '}
                                            {this.displayText('commentaire')}
                                        </Typography>
                                        <Commentary
                                            commentarys={parkingRatings.ratings}
                                        />
                                    </div>
                                </div>
                                <div className={classes.secondPartRight}>
                                    {this.renderParkingMenu(
                                        parking,
                                        parkingPictures,
                                        parkingStatus,
                                    )}
                                </div>
                            </div>
                        </div>
                    </Navigation>
                    <Modal
                        aria-labelledby="simple-modal-title"
                        aria-describedby="simple-modal-description"
                        open={this.state.openModal}
                        onClose={this.handleCloseModal}
                    >
                        <div id="modal" className={classes.paper}>
                            <Typography
                                variant="subtitle1"
                                id="simple-modal-description"
                            >
                                {(this.state.openModal &&
                                    !this.state.linkCopied &&
                                    this.renderShareModal(shareUrl)) ||
                                    (this.state.openModal &&
                                        this.state.linkCopied &&
                                        this.renderLinkCopiedModal())}
                            </Typography>
                        </div>
                    </Modal>
                    <Footer />
                </div>
            )
        }
        return <div />
    }
}

function getTotalPrice(state) {
    return state.post.datas && state.post.datas.totalPrice
        ? state.post.datas.totalPrice
        : 0
}

function mapStateToProps(state) {
    return {
        parking: state.fetch.parking || null,
        error: state.fetch.error || null,
        loading: state.fetch.loading,
        parkingPictures: state.fetch.parkingPictures,
        parkingRatings: state.fetch.parkingRatings,
        parkingStatus: state.fetch.parkingStatus,
        parkingPrice: getTotalPrice(state),
        filter: state.filter,
    }
}

export default connect(mapStateToProps)(withStyles(styles)(ParkingPage))
