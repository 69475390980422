export default theme => ({
    root: {
        position: 'relative',
    },
    buttonAccept: {
        minWidth: '200px',
        margin: '20px',
    },
    buttonCancel: {
        minWidth: '200px',
        margin: '20px',
        background: theme.palette.waiting.main,
    },
    infos: theme.flex.columnAlignCenter,
})
