export default {
    titleFirstPart: {
        FR: " carnet d'entretien numérique dédié à votre bateau",
        EN: ' digital service book dedicated to your boat',
    },
    st: {
        FR: 'er',
        EN: 'st',
    },
    first: {
        FR: 'Le 1',
        EN: 'The 1',
    },
    money: {
        FR: "de l'argent",
        EN: 'money',
    },
    freetrial: {
        FR: "1 mois d'essai gratuit",
        EN: '1 month free trial',
    },
    tryforfree: {
        FR: 'Essayer gratuitement',
        EN: 'Try it for free',
    },
    tryforfree30days: {
        FR: 'Essayer gratuitement pendant 30 jours',
        EN: 'Try it for free for 30 days',
    },
    documentsTitle: {
        FR: 'Vos documents à portée de main où que vous soyez',
        EN: 'Your documents at your fingertips wherever you are',
    },
    add: {
        FR: 'Ajouter',
        EN: 'Add',
    },
    addDescription: {
        FR:
            'Sélectionnez et ajoutez tous les documents importants de votre bateau depuis votre ordinateur ou téléphone.',
        EN:
            "Select and add all of your boat's important documents from your computer or phone.",
    },
    keep: {
        FR: 'Conserver',
        EN: 'Keep',
    },
    keepDescription: {
        FR:
            'Enregistrez vos permis, carte de circulation, acte de francisation, facture, devis en tout genre ou contrat de location et retrouvez- les en 1 clic.',
        EN:
            'Register your permits, traffic card, French deed, invoice, estimate of any kind or rental contract and find them in 1 click.',
    },
    share: {
        FR: 'Partager',
        EN: 'Share',
    },
    shareDescription: {
        FR:
            'Choisissez et transmettez à un professionnel ou au futur acquéreur n’importe quel document.',
        EN:
            'Choose and transmit any document to a professional or future buyer.',
    },

    budgetTitle: {
        FR: 'Fini les frais innatendus: votre budget est sous contrôle',
        EN: 'No more unexpected expenses:your budget is under control',
    },
    save: {
        FR: 'Enregistrer',
        EN: 'Save',
    },
    saveDescription: {
        FR:
            'Notez tous les revenus et dépenses que vous avez sur votre bateau en quelques secondes.',
        EN:
            'Write down all the income and expenses you have on your boat in seconds.',
    },
    link: {
        FR: 'Relier',
        EN: 'Link',
    },
    linkDescription: {
        FR:
            'Associez tous les justificatifs d’une dépense ou d’un revenu pour les retrouver où que vous soyez.',
        EN:
            'Combine all the supporting documents for an expense or income to find them wherever you are.',
    },
    optimise: {
        FR: 'Optimiser',
        EN: 'Optimize',
    },
    optimiseDescription: {
        FR:
            'Filtrez selon la période ou la catégorie pour optimiser votre budget bateau.',
        EN: 'Filter by period or category to optimize your boat budget.',
    },

    alertsTitle: {
        FR: 'Vos alertes d’entretien personnalisées',
        EN: 'Your personalized maintenance alerts',
    },
    select: {
        FR: 'Sélectionner',
        EN: 'Select',
    },
    selectDescription: {
        FR: 'Choisissez les équipements que vous souhaitez contrôler.',
        EN: 'Choose the equipment you wish to control.',
    },
    configure: {
        FR: 'Paramétrer',
        EN: 'Set up',
    },
    configureDescription: {
        FR: 'Définissez la dernière date de révision ou de contrôle.',
        EN: 'Set the last review or check date.',
    },
    watch: {
        FR: 'Surveiller',
        EN: 'Keep an eyes',
    },
    watchDescription: {
        FR:
            'Recevez des alertes directement sur votre téléphone à J-30 et J-7.',
        EN: 'Receive alerts directly to your phone on D-30 and D-7.',
    },

    bookTitle: {
        FR: "La vie de votre bateau en 1 coup d'œil",
        EN: 'The life of your boat at a glance',
    },
    organise: {
        FR: 'Organiser',
        EN: 'Organize',
    },
    organiseDescription: {
        FR:
            'Tous les éléments enregistrés dans « mes alertes », « mes documents » ou « mon budget » sont automatiquement organisés en une frise chronologique.',
        EN:
            'All items saved in "my alerts", "my documents" or "my budget" are automatically organized in a timeline.',
    },
    visualise: {
        FR: 'Visualiser',
        EN: 'Visualize',
    },
    visualiseDescription: {
        FR:
            'Visualisez en 1 coup d’œil toute la vie de votre bateau. Cliquez sur n’importe quel élément pour accéder à ses détails.',
        EN:
            'Visualize at a glance the whole life of your boat. Click on any item to access its details.',
    },
    enjoy: {
        FR: 'Profiter',
        EN: 'Enjoy',
    },
    enjoyDescription: {
        FR:
            'Transmettez votre BoatOn Book à un futur acquéreur ou tout simplement profitez de cet historique connecté pour mieux bichonner votre bateau.',
        EN:
            'Pass on your BoatOn Book to a future buyer or simply take advantage of this connected history to better pamper your boat.',
    },

    mentions: {
        FR: 'Ils parlent du BoatOn Book',
        EN: 'They talk about the BoatOn Book',
    },
    trynow: {
        FR: 'Testez le BoatOn Book',
        EN: 'Test the BoatOn Book',
    },
    createAccount: {
        FR:
            "Créez votre compte en quelques minutes et bénéficiez d'1 mois d’essai gratuit.<br />" +
            'Abonnement sans engagement. 100% satisfait.',
        EN:
            'Create your account in a few minutes and get 1 month free trial.<br />' +
            'Subscription without commitment. 100% satisfied.',
    },
    trybook: {
        FR: 'Testez le BoatOn Book',
        EN: 'Test the BoatOn Book',
    },

    shareWithFriends: {
        FR: 'Vous trouvez cet outil innovant ? Partagez-le à votre entourage !',
        EN:
            'Do you find this tool innovative? Share it with your friends and family!',
    },

    incorrectCard: {
        FR: 'la carte rentrée est incorrect, veuillez réessayer !',
        EN: 'missing translation',
    },
    subscription: {
        FR: "Je m'inscris",
        EN: "Je m'inscris",
    },
    youWillSubscribe: {
        FR:
            'Bravo, vous êtes sur le point de créer votre BoatOn Book ! Vous allez pouvoir en profiter gratuitement pendant 30 jours et si l’outil vous plaît, vous pourrez continuer à l’utiliser pour seulement 1,99 euros/ mois. Et n’oubliez pas: c’est sans engagement ! Un simple email à paul@boaton.fr et hop votre abonnement s’arrête.',
        EN:
            'Bravo, vous êtes sur le point de créer votre BoatOn Book ! Vous allez pouvoir en profiter gratuitement pendant 30 jours et si l’outil vous plaît, vous pourrez continuer à l’utiliser pour seulement 1,99 euros/ mois. Et n’oubliez pas: c’est sans engagement ! Un simple email à paul@boaton.fr et hop votre abonnement s’arrête.',
    },
    myDocuments: {
        FR: 'Mes Documents',
        EN: 'My Documents',
    },
    myBudget: {
        FR: 'Mon Budget',
        EN: 'My Budget',
    },
    myRepair: {
        FR: 'Mes entretiens',
        EN: 'My repairs',
    },
    myAlerts: {
        FR: 'Mes Alertes',
        EN: 'My Alerts',
    },
    myBob: {
        FR: 'Mon BoatOn Book',
        EN: 'My Boat Logbook',
    },
    activeFree: {
        FR: 'Activez votre mois gratuit',
        EN: 'Activate your free month',
    },
    repairTitle: {
        FR: 'Toutes vos réparations et entretiens au même endroit',
        EN: 'All your repairs and maintenance in one place',
    },
    note: {
        FR: 'Noter',
        EN: 'Note',
    },
    find: {
        FR: 'Retrouver',
        EN: 'Find',
    },
    organize: {
        FR: 'Organiser',
        EN: 'Organize',
    },
    noteDescription: {
        FR:
            "Enregistrez toutes les opérations d'entretien ou d'aménagement de votre bateau avec leurs prix, les factures ou tout autre information importante.",
        EN:
            'Record all maintenance or fitting-out operations on your boat with their prices, invoices or any other important information.',
    },
    findDescription: {
        FR:
            'Retrouvez en 1 clic vos entretiens passés et futurs grâce aux filtres de date et de recherche sur votre ordinateur ou smartphone.',
        EN:
            'Find in 1 click your past and future interviews thanks to date and search filters on your computer or smartphone.',
    },
    organizeDescription: {
        FR:
            "Bénéficiez de catégories pré-établies et créez les vôtres pour organiser votre carnet d'entretien.",
        EN:
            'Take advantage of pre-established categories and create your own to organize your service book.',
    },
    SEOTheFirstTitle: {
        FR:
            'Le 1er Journal de bord et d’entretien intelligent pour votre bateau.',
        EN: 'The 1st intelligent logbook and maintenance log for your boat.',
    },
    SEOTheFirstText: {
        FR:
            'BoatOn est la 1ère application qui facilite la vie aux propriétaires de bateaux. Nous assurons la gestion complète de votre bateau sur tous les plans, notamment en vous proposant de gérer l’ensemble de vos équipements, votre assurance bateau, ou encore votre espace de stockage en un clic. Souhaitant simplifier la vie de tous les propriétaires de bateau, nous intervenons aussi en vous proposant un vrai journal de bord ou carnet d’entretien électronique, qui répertorie l’ensemble des informations de votre bateau.',
        EN:
            'BoatOn is the first application that makes life easier for boat owners. We take care of the complete management of your boat on all levels, including managing all your equipment, your boat insurance, or your storage space with just one click. Wishing to simplify the life of all boat owners, we also intervene by offering you a real logbook or electronic maintenance book, which lists all the information of your boat.',
    },
    SEOWhatIsItTitle: {
        FR: 'Qu’est-ce qu’un journal de bord?',
        EN: 'What is a logbook?',
    },
    SEOWhatIsItText: {
        FR:
            'Un journal de bord reprend l’essentiel des informations de votre bateau. Ces informations peuvent s’avérer capitales pour faire le point avant de prendre la mer, afin d’être certain que vous réunissez l’ensemble des équipements et conditions pour une navigation en toute sécurité. L’application BoatOn est accessible partout en France, où que vous naviguiez.',
        EN:
            'A logbook contains all the essential information about your boat. This information can be crucial to take stock before setting out to sea, to make sure that you have all the equipment and conditions for safe sailing. The BoatOn application is accessible everywhere in France, wherever you sail.',
    },
    SEOMandatoryTitle: {
        FR: 'Le journal de bord est-il obligatoire?',
        EN: 'Is the logbook mandatory?',
    },
    SEOMandatoryText: {
        FR:
            'Dès que vous naviguez à plus de 6 milles de côtes, vous avez pour obligation de détenir un journal de bord sur votre bateau ainsi que de l’accompagner d’un livre de bord qui répertorie l’ensemble des dates des tests effectués sur le bateau (évacuation du bateau, moteur, etc.). Votre journal de bord est considéré comme acte de foi en cas de procès.',
        EN:
            'As soon as you sail more than 6 miles offshore, you are required to keep a logbook on your boat and to accompany it with a logbook that lists all the dates of the tests carried out on the boat (evacuation of the boat, engine, etc.). Your logbook is considered as an act of faith in case of a lawsuit.',
    },
    SEOShouldContainTitle: {
        FR: 'Que doit-il contenir?',
        EN: 'What should it contain?',
    },
    SEOShouldContainText: {
        FR:
            'Le contenu reste cependant assez évasif selon la réglementation, datée du 11 mars 2008 : « un journal de bord contenant les éléments pertinents pour le suivi de la navigation et la sécurité du navire ». À l’image du journal de bord du marin, vous avez pour obligation selon les nombreux articles établis, de tenir à jour votre journal de bord à chaque fois que vous prenez la mer. Vous devez y répertorier : <br />' +
            '- Tout ce qui entoure les conditions météorologiques ; <br />' +
            '- L’ensemble des conditions de votre environnement nautique, avec les conditions de la marée, la vitesse, le cap, l’état de la mer, ainsi que les coordonnées géographiques ; <br />' +
            '- Votre environnement aérien, en précisant l’état du ciel, la force du vent, et l’indication du baromètre ; <br />' +
            '- Votre équipage, soit le skipper ainsi que les équipiers qui le secondent ; <br />' +
            '- Enfin, bien entendu l’heure à laquelle vous quittez le port et la date, sans oublier de préciser votre destination et les escales potentielles réalisées. La tenue de votre journal de bord de navigation doit donc permettre d’assurer la sécurité des passagers sur le bateau, ainsi que de connaître le nombre exact de l’équipage.',
        EN:
            'However, the content remains rather evasive according to the regulation, dated March 11, 2008:"a logbook containing the relevant elements for the follow-up of the navigation and the safety of the vessel". In the image of the newspaper on board of the sailor, you have an obligation according to the many established articles, to keep your newspaper up to date with the on board every time you go to sea. You have to list : <br />' +
            '- All that surrounds the weather; <br />' +
            '- All the conditions of your environment conditions, with tidal conditions, speed, wind speed, wind speed and course, sea state, and coordinates. geographical ; <br />' +
            '- Your air environment, specifying the state of the sky, wind strength, and barometer indication ; <Your crew, either the skipper and the team members who assist him ; <br />' +
            '- Finally, of course, the time you leave the port and the date, without forgetting to specify your destination and the potential stopovers made. The outfit of your navigation logbook must therefore allow for ensure the safety of passengers on the boat, as well as to know the exact number of the crew.',
    },
    SEOHowToTitle: {
        FR: 'Comment rédiger un livre de bord?',
        EN: 'How to write a logbook?',
    },
    SEOHowToText: {
        FR:
            'Le livre de bord vient compléter le journal de bord. Il vient apporter des informations complémentaires concernant la navigation. Si vous avez pour habitude de prendre la mer, vous savez sans aucun doute qu’elle peut devenir très changeante et capricieuse en quelques heures. Pour prévenir de tout incident, il est conseillé de prendre des notes régulières toutes les 3h. Ainsi, vous pouvez facilement repérer un changement sensible de votre environnement (météorologique, technique, marée, etc.)',
        EN:
            'The logbook completes the logbook. It brings additional information concerning the navigation. If you are accustomed to taking to the sea, you undoubtedly know that it can become very changeable and capricious in a few hours. To prevent any incident, it is advisable to take regular notes every 3 hours. In this way, you can easily spot a noticeable change in your environment (weather, technical, tide, etc.)',
    },
    SEOWhyToUseTitle: {
        FR: 'Pourquoi utiliser un journal de bord et d’entretien électronique?',
        EN: 'Why use an electronic logbook and maintenance diary?',
    },
    SEOWhyToUseText: {
        FR:
            'Auparavant, le journal de bord nécessitait de vraies connaissances en astronomie, ainsi que des terrains côtiers. De nos jours, de nombreux propriétaires de bateau n’ont que de faibles connaissances et se fient uniquement à leur GPS, qui n’est pas suffisant pour prendre la mer. C’est un défaut qui n’est pas souvent corrigé, au risque de mauvaises surprises en cas de contrôle. L’application BoatOn vous permet de stocker tous vos documents importants, gérer votre budget, suivre votre entretien, prendre des notes sur une navigation et enregistrera prochainement votre itinéraire si vous le souhaitez.',
        EN:
            'In the past, the logbook required real knowledge of astronomy, as well as coastal terrain. Nowadays, many boat owners have only low knowledge and rely solely on their GPS, which is not enough to go to sea. It is a defect which is not often corrected, at the risk of bad surprises in case of control. The BoatOn application will allows you to store all your important documents, manage your budget, follow your interview, take notes on a budget, follow your interview navigation and will soon save your route if you wish it.',
    },
    SEOAdvantageTitle: {
        FR: 'Les avantages.',
        EN: 'The advantages.',
    },
    SEOAdvantageText: {
        FR:
            'En recourant à un journal de bord et d’entretien électronique, vous avez de nombreux avantages : • Par rapport au contenu papier, vous ne risquez pas de perdre ou de détruire en tachant votre journal de bord. • Les erreurs humaines, telles qu’une mauvaise indication géographique de votre localisation, sont limitées puisque vous pouvez les noter d’après votre GPS. • Vous suivez à distance toutes les étapes réalisées par votre bateau, avec l’enregistrement de vos données concernant les dépenses de carburant par exemple. • En cas de contentieux, vous avez une preuve juridique de votre bonne foi. • C’est un gain de temps ! BoatOn vous répertorie l’ensemble des données nécessaires à votre navigation, vous facilitant la tâche si vous n’avez pas en tête l’ensemble des informations nécessaires à votre départ. Les erreurs humaines et les oublis ne sont pas rares de la part des plaisanciers, qui risquent une forte amende ou la prison en cas de négligence de tenue de leur journal de bord de navigation. Avec un journal de bord et d’entretien électronique tel que BoatOn qui tient dans votre poche, vous pouvez prévoir et connaître l’ensemble des procédures nécessaires, pour prendre la mer en toute sécurité.',
        EN:
            "By using a logbook and maintenance logbook electronics, you have many advantages:- By to the paper content, you do not risk losing or losing your to destroy by staining your logbook. - Errors such as a bad geographical indication of your location, are limited since you can Note according to your GPS. - You follow remotely all the steps carried out by your boat, with the registration of your fuel expense data by example. - In the event of litigation, you have a proof of your good faith. - It's a time saver! BoatOn lists all the data you need for your navigation, making it easier for you if you don't have all the information you need to know about your departure. Human errors and oversights are not uncommon. from boaters, who risk a large fine or prison for neglecting to keep their diaries. on board of navigation. With a logbook and electronic service equipment such as BoatOn that fits into your pocket, you can foresee and know all the necessary procedures, in order to take to the sea in all security.",
    },
    SEOTestTitle: {
        FR: 'Testez BoatOn!',
        EN: 'Test BoatOn!',
    },
    SEOTestText: {
        FR:
            'Notre application BoatOn vous permet de prendre la mer grâce à notre journal de bord et carnet d’entretien électronique, accessible dans votre poche. Si vous découvrez notre application, sachez que nous vous offrons les 30 premiers jours pour tester cette fonctionnalité. Notre offre est ensuite de 1,99€/mois, avec la possibilité d’arrêter dès que vous le souhaitez.',
        EN:
            'Our BoatOn application allows you to take to the sea with our electronic logbook and service log, accessible in your pocket. If you are new to our application, we offer you the first 30 days to test this functionality. Our offer is then 1.99€/month, with the possibility to stop whenever you want.',
    },
}
