export default {
    alerts: {
        FR: 'Mes alertes',
        EN: 'My Alerts',
    },
    inventory: {
        FR: 'Mon inventaire',
        EN: 'My inventory',
    },
    checkup: {
        FR: 'Mes entretiens',
        EN: 'My checkups',
    },
    expense: {
        FR: 'Mes dépenses & mes revenus',
        EN: 'My expenses & income',
    },
    documents: {
        FR: 'Mes documents',
        EN: 'My documents',
    },
    boat: {
        FR: 'Mon bateau',
        EN: 'My boat',
    },
}
