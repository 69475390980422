export default theme => ({
    root: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    textAndField: {
        display: 'flex',
        flexDirection: 'row',
        margin: '5px',
        width: '100%',
    },
    textAndFieldTwoField: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        [theme.breakpoints.down('sm')]: {
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
        },
    },
    text: {
        width: '30%',
        margin: 'auto',
        textAlign: 'right',
        [theme.breakpoints.down('sm')]: {
            width: '40%',
            marginRight: '5px',
        },
    },
    textPro: {
        margin: 'auto',
        marginRight: '10px',
        marginLeft: '10px',
        textAlign: 'right',
        fontSize: '18px',
        [theme.breakpoints.down('sm')]: {
            margin: 'auto',
        },
    },
    title: {
        width: '100%',
        color: '#3040a0',
        fontWeight: 500,
        padding: '10px',
        textAlign: 'left',
    },
    field: {
        width: '65%',
        margin: 'auto',
        [theme.breakpoints.down('sm')]: {
            width: '60%',
        },
    },
    addressGroup: {
        width: '65%',
        margin: 'auto',
        [theme.breakpoints.down('sm')]: {
            width: '60%',
        },
        display: 'flex',
    },
    addressField: {
        flex: 1,
    },
    twoFields: {
        width: '99%',
        display: 'flex',
        flexDirection: 'row',
        margin: '5px',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
        },
    },
    lineWithMultiFieldFirst: {
        width: '30%',
        margin: 'auto',
        [theme.breakpoints.down('sm')]: {
            width: '60%',
            margin: 'auto',
        },
    },
    lineWithMultiTextFirst: {
        width: '59%',
        margin: 'auto',
        textAlign: 'right',
        [theme.breakpoints.down('sm')]: {
            width: '40%',
            margin: 'auto',
            textAlign: 'right',
            marginRight: '5px',
        },
    },
    nextButton: {
        marginTop: '40px',
        marginRight: '10px',
        marginLeft: 'auto',
        width: '20%',
        minWidth: '130px',
    },
    proFields: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        width: '100%',
        marginBottom: '15px',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            marginBottom: 0,
        },
    },
    choice: {
        display: 'flex',
        flexDirection: 'row',
    },
    checkbox: {
        marginTop: 'auto',
        marginBottom: 'auto',
        marginLeft: '25px',
    },
    titleProQuestion: {
        color: '#3040a0',
        fontWeight: 500,
        textAlign: 'left',
        marginTop: 'auto',
        marginBottom: 'auto',
        marginRight: '15px',
    },
    fieldDescription: {
        width: '100%',
        margin: 'auto',
    },
    textInformation: {
        width: '100%',
        margin: 'auto',
        textAlign: 'left',
        fontSize: '16px',
        marginBottom: '20px',
    },
    firstLinePart3field: {
        display: 'flex',
        flexDirection: 'row',
        width: '50%',
        justifyContent: 'space-between',
        [theme.breakpoints.down('sm')]: {
            display: 'flex',
            flexDirection: 'row',
            marginTop: '5px',
            marginBottom: '5px',
            width: '100%',
        },
    },
    secondLinePart3Field: {
        display: 'flex',
        flexDirection: 'row',
        width: '50%',
        justifyContent: 'space-between',
        [theme.breakpoints.down('sm')]: {
            display: 'flex',
            flexDirection: 'row',
            marginTop: '5px',
            marginBottom: '5px',
            width: '100%',
        },
    },
    lineWithMultiField: {
        width: '50%',
        margin: 'auto',
        [theme.breakpoints.down('sm')]: {
            width: '60%',
            margin: 'auto',
        },
    },
    lineWithMultiText: {
        width: '40%',
        margin: 'auto',
        textAlign: 'right',
        [theme.breakpoints.down('sm')]: {
            margin: 'auto',
            textAlign: 'right',
            marginRight: '5px',
        },
    },
    square: {
        marginLeft: '5px',
        margin: 'auto',
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
})
