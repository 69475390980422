import React from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import TopInventory from './TopInventory'
import BoatOnComponent from '../../../common/BoatOnComponent'
import dictionary from './Dictionary/InventoryListDico'
import styles from './Styles/InventoryPageCss'
import InventoryBar from './InventoryBar'

class InventoryPage extends BoatOnComponent {
    constructor(props) {
        super(props)
        this.dictionary = dictionary
        this.state={
            checkedBoxesTop: [],
            checkedBoxesBottom: [],

        }

    this.handleCheckBox = this.handleCheckBox.bind(this)
    this.handleCheckBoxBottom = this.handleCheckBoxBottom.bind(this)

    }

    handleCheckBox(idInventory, event) {
        if (!event.target.checked) {
            this.setState(prevState => ({
                checkedBoxesTop: prevState.checkedBoxesTop.filter(
                    boxID => boxID !== idInventory,
                ),
            }))
        } else {
            this.setState(prevState => ({
                checkedBoxesTop: prevState.checkedBoxesTop.concat([idInventory]),
            }))
        }
    }

    handleCheckBoxBottom(idInventoryTop, idInventoryBottom, event) {
        if (!event.target.checked) {
            this.setState(prevState => ({
                checkedBoxesBottom: prevState.checkedBoxesBottom.filter(
                    box => box.idInventoryTop !== idInventoryTop && box.idInventoryBottom !== idInventoryBottom,
                ),
            }))
        } else {
            this.setState(prevState => ({
                checkedBoxesBottom: prevState.checkedBoxesBottom.concat([{idInventoryTop : idInventoryTop, idInventoryBottom : idInventoryBottom}]),
            }))
        }
    }


    render() {
        const { classes } = this.props

        return (
            <div className={classes.root} key="alerts">
                <div className={classes.main}>
                    <TopInventory
                    handleCheckBox={this.handleCheckBox}
                    handleCheckBoxBottom={this.handleCheckBoxBottom}
                    />
                    <InventoryBar
                    open={this.state.checkedBoxesTop.length + this.state.checkedBoxesBottom.length}
                    />
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {}
}

export default connect(mapStateToProps)(withStyles(styles)(InventoryPage))
