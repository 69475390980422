import React, { Component } from 'react'
import { connect } from 'react-redux'
import classNames from 'classnames'
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Card from '@material-ui/core/Card'
import TextField from '@material-ui/core/TextField'
import { userActions } from '../../actions'
import Navigation from '../common/Navigation'
import Footer from '../common/Footer'
import styles from './Styles/ForgetPageCss'

class ForgetPage extends Component {
    constructor(props) {
        super(props)
        this.state = {
            email: '',
            submitted: false,
            openSnack: true,
        }

        this.handleChange = this.handleChange.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
    }

    handleChange(e) {
        const { name, value } = e.target
        this.setState({ [name]: value })
    }

    onChange(value, key) {
        this.setState({ [key]: value })
    }

    handleSubmit(e) {
        e.preventDefault()

        this.setState({ submitted: true })
        if (this.state.email) {
            this.props.dispatch(userActions.forget(this.state.email))
        }
    }

    render() {
        const { classes } = this.props
        const { email, submitted } = this.state
        return (
            <div>
                <Navigation>
                    <Grid
                        container
                        justify="center"
                        className="container loginPage"
                    >
                        <Card className={classes.card}>
                            <h3 className={classes.toCenter}>
                                Profitez de votre bateau, nous nous occupons du
                                reste
                            </h3>
                            <form name="form" onSubmit={this.handleSubmit}>
                                <div
                                    className={`form-group${
                                        submitted && !email ? ' has-error' : ''
                                    }`}
                                >
                                    <TextField
                                        id="standard-textarea"
                                        required
                                        label="Email"
                                        value={email}
                                        onChange={e => {
                                            this.onChange(
                                                e.target.value,
                                                'email',
                                            )
                                        }}
                                        className={classes.allWidth}
                                        margin="normal"
                                    />
                                </div>
                                <div className="form-group">
                                    <button
                                        className={classNames(
                                            'btn btn-primary',
                                            classes.buttonPrimary,
                                            classes.allWidth,
                                        )}
                                    >
                                        Envoyer
                                    </button>
                                </div>
                            </form>
                        </Card>
                    </Grid>
                </Navigation>
                <Footer />
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        alert: state.alert,
        state: state.open,
    }
}

export default withStyles(styles)(connect(mapStateToProps)(ForgetPage))
