import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import BoatOnComponent from './BoatOnComponent'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'

const styles = {
    list: {
        overflow: 'auto',
        maxHeight: '150px',
        border: '1px solid #c4c4c4',
        width: '100%',
        borderBottom: 0,
        marginTop: '-150px',
        backgroundColor: 'white',
        zIndex: '1000',
    },
    listInfoPageRight: {
        overflow: 'auto',
        maxHeight: '150px',
        border: '1px solid #c4c4c4',
        width: '30%',
        borderBottom: 0,
        marginLeft: '70%',
        backgroundColor: 'white',
    },
    listInfoPageLeft: {
        overflow: 'auto',
        maxHeight: '150px',
        border: '1px solid #c4c4c4',
        marginLeft: '20%',
        width: '30%',
        borderBottom: 0,
        backgroundColor: 'white',
    },
}

class AutoComplete extends BoatOnComponent {
    render() {
        const { classes, searchResult, page, type } = this.props
        const isMobile = window.innerWidth <= 500
        let marginTop
        if (searchResult.length > 0) {
            if (searchResult.length <= 3)
                marginTop = (searchResult.length * -50).toString() + 'px'
            else marginTop = '-150px'
        }

        return (
            <>
                {searchResult.length > 0 ? (
                    <List
                        className={
                            page === 'BoatInfoPageMaker' && !isMobile
                                ? classes.listInfoPageLeft
                                : page === 'BoatInfoPageModel' && !isMobile
                                ? classes.listInfoPageRight
                                : classes.list
                        }
                        style={{ marginTop: marginTop }}
                    >
                        {searchResult.map((result, index) => (
                            <ListItem
                                button
                                onClick={() =>
                                    this.props.handleListSelection(result, type)
                                }
                                key={index}
                            >
                                <ListItemText
                                    primary={
                                        result.InsuranceName ||
                                        result.EngineName ||
                                        result.libelleModeleBateau ||
                                        result
                                    }
                                />
                            </ListItem>
                        ))}
                    </List>
                ) : (
                    <div />
                )}
            </>
        )
    }
}

export default withStyles(styles)(AutoComplete)
