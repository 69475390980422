import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import Drawer from '@material-ui/core/Drawer'
import IconButton from '@material-ui/core/IconButton'
import MenuIcon from '@material-ui/icons/Menu'
import Divider from '@material-ui/core/Divider'
import List from '@material-ui/core/List'
import classNames from 'classnames'
import ChevronLeft from '@material-ui/icons/ChevronLeft'
import styles from './Styles/SideNavigationCss'
import BoatOnComponent from './BoatOnComponent'

class SideNavigation extends BoatOnComponent {
    constructor(props) {
        super(props)

        this.state = {
            open: false,
        }

        this.handleClick = this.handleClick.bind(this)
    }

    handleClick() {
        this.setState({ open: !this.state.open })
    }

    render() {
        const { classes, list, main } = this.props
        const { open } = this.state

        return (
            <div className={classes.root}>
                <Drawer
                    variant="permanent"
                    classes={{
                        paper: classNames(
                            classes.drawerPaper,
                            !open && classes.drawerPaperClose,
                        ),
                    }}
                    open={open}
                >
                    <div
                        className={
                            open ? classes.toolbarRight : classes.toolbar
                        }
                    >
                        <IconButton
                            onClick={() => this.handleClick()}
                            className={classes.button}
                        >
                            {open ? (
                                <ChevronLeft
                                    style={{
                                        marginRight: '0',
                                        marginLeft: 'auto',
                                    }}
                                />
                            ) : (
                                <MenuIcon />
                            )}
                        </IconButton>
                    </div>
                    <Divider />
                    <List>{list}</List>
                </Drawer>
                <main className={classes.content}>
                    <div className={classes.toolbar}>{main}</div>
                </main>
            </div>
        )
    }
}

export default withStyles(styles)(SideNavigation)
