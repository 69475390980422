import React from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem'
import ListItemText from '@material-ui/core/ListItemText'
import dictionary from './Dictionary/BoatSelecterDico'
import styles from './Styles/BoatSelecterCss'
import BoatOnComponent from '../../common/BoatOnComponent'
import homepage from '../../../images/homepageiphone.jpg'
import voiliericon from '../../../images/voiliericon.svg'
import moteurboaticon from '../../../images/moteurboaticon.svg'
import semirigideicon from '../../../images/semirigideicon.svg'
import catamaranicon from '../../../images/catamaranicon.svg'
import penicheicon from '../../../images/penicheicon.svg'
import jetskyicon from '../../../images/jetskyicon.svg'
import { Typography } from '@material-ui/core'
import { fetchDataBOB } from '../../../actions/bob.actions'
import { boatActions } from '../../../actions/boat.actions'
import { userActions } from '../../../actions/user.actions'
import { typesActions } from '../../../actions/types.actions'

class BoatSelecter extends BoatOnComponent {
    constructor(props) {
        super(props)
        this.dictionary = dictionary

        this.state = {
            openModalNoBoat: false,
            totalExpense: 0,
            totalIncome: 0,
        }
    }

    componentWillMount() {
        const { user, boats, events, eventTypes } = this.props

        var data = new FormData()
        if (!boats) {
            this.props.dispatch(boatActions.requestBoats())
        }
        if (user != null) {
            data.append('UserID', user.UserID)
        }

        if (events && eventTypes) {
            this.setState({ totalExpense: this.getTotalExpense() })
            this.setState({ totalIncome: this.getTotalIncome() })
        }
    }

    componentDidUpdate(prevProps) {
        const {
            boats,
            loadingBoats,
            loadingEvents,
            events,
            eventTypes,
        } = this.props

        if (!boats && prevProps.loadingBoats > 0 && loadingBoats === 0) {
            this.props.dispatch(boatActions.requestBoats())
        }

        if (
            events &&
            eventTypes &&
            prevProps.loadingEvents > 0 &&
            loadingEvents === 0
        ) {
            this.setState({ totalExpense: this.getTotalExpense() })
            this.setState({ totalIncome: this.getTotalIncome() })
        }
    }

    getTotalIncome() {
        const { events, eventTypes } = this.props
        var total = 0

        events.map(event => {
            const eventType = typesActions.getEventTypeById(
                eventTypes,
                event.eventType.id,
            )
            if (
                eventType &&
                eventType.earning &&
                event.delimitedDate &&
                event.delimitedDate.startDate &&
                new Date(event.delimitedDate.startDate).getFullYear() ===
                    new Date().getFullYear()
            )
                total += parseFloat(Math.abs(event.transaction.amountIt))
        })
        return total
    }

    getTotalExpense() {
        const { events, eventTypes } = this.props
        var total = 0

        events.map(event => {
            const eventType = typesActions.getEventTypeById(
                eventTypes,
                event.eventType.id,
            )
            if (
                eventType &&
                eventType.spending &&
                event.delimitedDate &&
                event.delimitedDate.startDate &&
                event.transaction &&
                new Date(event.delimitedDate.startDate).getFullYear() ===
                    new Date().getFullYear()
            )
                total += parseFloat(event.transaction.amountIt)
        })
        return total
    }

    processCheckups() {
        const checkupsArray = this.props.checkups
        const checkups = checkupsArray ? checkupsArray.result : []

        const date3Month = new Date().setMonth(new Date().getMonth() + 3)
        const tmp = []
        for (let i in checkups) {
            const date = new Date(checkups[i].Date)
            if (date > new Date() && date < date3Month) tmp.push(checkups[i])
        }
        return tmp
    }

    getBoatById(id) {
        const { boats } = this.props

        return boats.find(boat => boat.id === id)
    }

    renderBoatSelecter() {
        const {
            classes,
            boats,
            boat,
            user,
            style,
            subscriptions,
            events,
        } = this.props
        const { totalExpense, totalIncome } = this.state
        const comingCheckups = this.processCheckups()

        if (!boat || !boats || !events) {
            return <></>
        }
        const engineTime = 0
        const entretien = comingCheckups.length > 1 ? 'entretiens' : 'entretien'
        let lastCheckupDateDay = ''
        let lastCheckupDateMonth = ''
        let lastCheckupDateYear = ''

        if (comingCheckups && comingCheckups[comingCheckups.length - 1]) {
            const lastCheckupDate = new Date(
                comingCheckups[comingCheckups.length - 1].Date,
            )
            lastCheckupDateDay = lastCheckupDate.getDate()
            lastCheckupDateMonth = lastCheckupDate.toLocaleString('default', {
                month: 'long',
            })
            lastCheckupDateYear = lastCheckupDate.getFullYear()
        }

        return (
            <div className={classes.root} style={style ? style : {}}>
                <div className={classes.title}>
                    <img src={homepage} alt="homepage" />
                    <div className={classes.iconBoat}>
                        {boat.boatType != null ? (
                            boat.boatType.id === 2 ? (
                                <img src={voiliericon} alt="voilier" />
                            ) : boat.boatType.id === 1 ? (
                                <img
                                    src={moteurboaticon}
                                    alt="moteurboaticon"
                                />
                            ) : boat.boatType.id === 3 ? (
                                <img
                                    src={semirigideicon}
                                    alt="semirigideicon"
                                />
                            ) : boat.boatType.id === 4 ? (
                                <img src={catamaranicon} alt="catamaranicon" />
                            ) : boat.boatType.id === 5 ? (
                                <img src={penicheicon} alt="penicheicon" />
                            ) : boat.boatType.id === 6 ? (
                                <img src={jetskyicon} alt="jetskyicon" />
                            ) : (
                                <div />
                            )
                        ) : (
                            <div />
                        )}
                    </div>
                </div>
                <TextField
                    id="boatName"
                    value={boat.id}
                    onChange={e => {
                        console.log(e.target.value)
                        this.props.dispatch(
                            fetchDataBOB(
                                this.getBoatById(e.target.value),
                                user,
                            ),
                        )
                    }}
                    margin="dense"
                    variant="outlined"
                    select
                    fullWidth
                    label={this.displayText('boatName')}
                    className={classes.name}
                    InputLabelProps={{
                        shrink: true,
                    }}
                >
                    {userActions.checkSubscriptionMultiBoat(subscriptions) ? (
                        this.props.boats.map((boat, id) => (
                            <MenuItem id={id} key={id} value={boat.id}>
                                <ListItemText>{boat.name}</ListItemText>
                            </MenuItem>
                        ))
                    ) : (
                        <MenuItem id={0} key={0} value={this.props.boats[0].id}>
                            <ListItemText>
                                {this.props.boats[0].name}
                            </ListItemText>
                        </MenuItem>
                    )}
                </TextField>
                {userActions.checkSubscriptionBOB(subscriptions) ? (
                    <>
                        {comingCheckups.length > 0 ? (
                            <Typography className={classes.text}>
                                <span className={classes.info}>
                                    {comingCheckups.length}
                                </span>{' '}
                                {entretien} à venir avant le{' '}
                                {lastCheckupDateDay} {lastCheckupDateMonth}{' '}
                                {lastCheckupDateYear}
                            </Typography>
                        ) : (
                            <Typography className={classes.text}>
                                {this.displayText('noMaintenance')}
                            </Typography>
                        )}
                        <Typography className={classes.text}>
                            <span className={classes.info}>{engineTime}</span>{' '}
                            {this.displayText('hours')} {new Date().getDate()}{' '}
                            {new Date().toLocaleString('default', {
                                month: 'long',
                            })}
                        </Typography>
                        <Typography className={classes.text}>
                            <span className={classes.info}>{totalExpense}</span>{' '}
                            {this.displayText('spent')}{' '}
                            {new Date().getFullYear()}
                        </Typography>
                        <Typography className={classes.text}>
                            <span className={classes.info}>{totalIncome}</span>{' '}
                            {this.displayText('earned')}{' '}
                            {new Date().getFullYear()}
                        </Typography>
                    </>
                ) : (
                    <></>
                )}
            </div>
        )
    }

    render() {
        return this.renderBoatSelecter()
    }
}

function mapStateToProps(state) {
    return {
        user: state.authentication.user,
        boats: state.boat.boats,
        loadingBoats: state.boat.loading,
        checkups: state.bob.activecheckups,
        spendings: state.bob.spendings,
        boat: state.bob.boat,
        loading: state.bob.loading,
        subscriptions: state.authentication.subscriptions,
        eventTypes: typesActions.getEventTypeTransaction(
            state.types.eventTypes,
        ),
        loadingEvents: state.bob.loading,
        events: state.bob.events,
    }
}

export default connect(mapStateToProps)(withStyles(styles)(BoatSelecter))
