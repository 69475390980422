export default theme => ({
    root: Object.assign({}, theme.boxBoatOn, {
        position: 'sticky !important',
        top: 170,
        width: '100%',
        marginRight: '10px',
        padding: 0,
    }),
    refreshMap: Object.assign({}, theme.boxBoatOn, {
        position: 'absolute',
        top: 0,
        zIndex: 500,
        width: '350px',
        left: '50%',
        transform: 'translate(calc(-50% + 20px))',
        marginTop: '20px',
        backgroundColor: 'white',
        padding: '2px',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    }),
    refreshMapText: {
        fontSize: '14px',
    },
})
