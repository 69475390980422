export default {
    confirmText: {
        FR:
            "Bravo, vous bénéficiez de 3 mois d'abonnement offerts au BoatOn Book à compter de ce jour ! La seule chose que nous vous demandons est de donner votre avis. Écrivez à paul@boaton.fr pour nous faire part de vos suggestions d'améliorations ou félicitations ☺.",
        EN: '',
    },
    confirmBtn: {
        FR: 'Oui, je confirme !',
        EN: '',
    },
    youWillSubscribeTitle: {
        FR: 'Bravo, vous êtes sur le point de créer votre BoatOn Book !',
        EN: 'Bravo, vous êtes sur le point de créer votre BoatOn Book !',
    },
    st: {
        FR: 'er',
        EN: 'st',
    },
    youWillSubscribeMonth: {
        FR:
            'Vous allez pouvoir en profiter gratuitement pendant 30 jours et si l’outil vous plaît, vous pourrez continuer à l’utiliser pour seulement 1,99 euros/ mois. Et n’oubliez pas: c’est sans engagement ! Un simple email à paul@boaton.fr et hop votre abonnement s’arrête.',
        EN:
            'Vous allez pouvoir en profiter gratuitement pendant 30 jours et si l’outil vous plaît, vous pourrez continuer à l’utiliser pour seulement 1,99 euros/ mois. Et n’oubliez pas: c’est sans engagement ! Un simple email à paul@boaton.fr et hop votre abonnement s’arrête.',
    },
    youWillSubscribe: {
        FR: 'Renseignez votre moyen de paiement ci-dessous pour profiter du 1',
        EN: 'Enter your payment method below to take advantage of the 1',
    },
    youWillSubscribe2: {
        FR: " carnet d'entretien digital dédié au bateau.",
        EN: 'digital maintenance book dedicated to the boat.',
    },
    incorrectCard: {
        FR: 'la carte rentrée est incorrect, veuillez réessayer !',
        EN: 'la carte rentrée est incorrect, veuillez réessayer !',
    },
    subscription: {
        FR: "Je m'inscris",
        EN: "Je m'inscris",
    },
    afterFreeTryText: {
        FR: 'À la suite de votre essai gratuit',
        EN: 'Following your free trial',
    },
    subTextDetailsAnnual: {
        FR: 'Abonnement Annuel',
        EN: 'Annual Subscription',
    },
    subTextDetailsMonthly: {
        FR: 'Abonnement Mensuel',
        EN: 'Monthly Subscription',
    },
    subPricingAnnual: {
        FR: '19.99€',
        EN: '19.99€',
    },
    subPricingMonthly: {
        FR: '1.99€',
        EN: '1.99€',
    },
}
