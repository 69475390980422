export default {
    personnalInfos: {
        FR: 'Mes informations personnelles',
        EN: 'Personnals informations',
    },
    boatInfos: {
        FR: 'Informations sur mon bateau',
        EN: 'My boat informations',
    },
    parkingsInfos: {
        FR: 'Mes emplacements',
        EN: 'My places',
    },
    paymentInfos: {
        FR: 'Mes informations de virement',
        EN: 'My payment informations',
    },
    inqueries: {
        FR: 'Mes demandes',
        EN: 'My inqueries',
    },
    messages: {
        FR: 'Mes messages',
        EN: 'My messages',
    },
}
