import React from 'react'
import BoatOnComponent from '../../../common/BoatOnComponent'
import { connect } from 'react-redux'
import AddAPhotoIcon from '@material-ui/icons/AddAPhoto'
import { withStyles } from '@material-ui/core/styles'
import {
    removeFilesFromBOBEvent,
    postBOBEvents,
    updateStateEvent,
    putBOBEvents,
    setBOBStateEvent,
    deleteFileFromBobEvent,
    addFileToBobEvent,
} from '../../../../actions/bob.actions'
import styles from './Styles/MyDocumentListCss'
import dictionary from './Dictionary/MyDocumentListDico'
import Typography from '@material-ui/core/Typography'
import RangDate from '../../../common/UsefullComponents/RangDate'
import stockageIcon from '../../../../images/storageIcon.png'
import insuranceIcon from '../../../../images/iconInsurance.png'
import repairIcon from '../../../../images/repairIcon.png'
import expertiseIcon from '../../../../images/expertiseIcon.png'
import equipmentBuyIcon from '../../../../images/equipmentBuyIcon.png'
import amenagementIcon from '../../../../images/amenagementIcon.png'
import carburantIcon from '../../../../images/carburantIcon.png'
import adminIcon from '../../../../images/adminIcon.png'
import achatIcon from '../../../../images/achatIcon.png'
import locationIcon from '../../../../images/locationIcon.png'
import venteIcon from '../../../../images/venteIcon.png'
import otherIcon from '../../../../images/otherIcon.png'
import permisIcon from '../../../../images/permisIcon.png'
import carteCircuIcon from '../../../../images/carteCircuIcon.png'
import attestationIcon from '../../../../images/attestationIcon.png'
import manuelMoteurIcon from '../../../../images/manuelMoteurIcon.png'
import manuelProIcon from '../../../../images/manuelProIcon.png'
import reportIcon from '../../../../images/reportIcon.png'
import maintenanceIcon from '../../../../images/MaintenanceIcon.png'
import addIcon from '../../../../images/Add_monogram_dark_blue.png'
import TextField from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem'
import Button from '@material-ui/core/Button'
import AttachFile from '@material-ui/icons/AttachFile'
import Delete from '@material-ui/icons/Delete'
import Edit from '@material-ui/icons/Edit'
import BoatOnModal from '../../../common/BoatOnModal'
import BoatOnImageRender from '../../../common/BoatOnImageRender'
import DisplayerImgModal from '../../DisplayerImgModal'
import InputAdornment from '@material-ui/core/InputAdornment'
import { typesActions } from '../../../../actions/types.actions'

class MyDocumentList extends BoatOnComponent {
    constructor(props) {
        super(props)
        this.dictionary = dictionary

        this.state = {
            events: [],
            event: null,
            openModal: false,
            fileNoUrl: [],
            displayNewFiles: [],
            save: false,
            openDoc: false,
            deletedFiles: [],
            search: null,
        }

        this.reset = this.reset.bind(this)
        this.clickDocument = this.clickDocument.bind(this)
        this.deleteDocumentNoUp = this.deleteDocumentNoUp.bind(this)
        this.addDocument = this.addDocument.bind(this)
        this.handleOpenModalMyDocument = this.handleOpenModalMyDocument.bind(
            this,
        )
        this.handleOpenModal = this.handleOpenModal.bind(this)
        this.handleChangeDate = this.handleChangeDate.bind(this)
        this.handleChangeSearch = this.handleChangeSearch.bind(this)
        this.save = this.save.bind(this)
    }

    componentDidUpdate(prevProps) {
        const { events, eventTypes, loadingEvents } = this.props

        if (
            events &&
            eventTypes &&
            loadingEvents === 0 &&
            prevProps.loadingEvents !== 0
        ) {
            const documents = []
            events.forEach(event => {
                const eventType = typesActions.getEventTypeById(
                    eventTypes,
                    event.eventType.id,
                )
                if (
                    event.files &&
                    eventType &&
                    event.files.length > 0 &&
                    eventType.document
                ) {
                    documents.push(event)
                }
            })
            this.setState({ events: documents })
        }
    }

    componentDidMount() {
        const { events, eventTypes } = this.props

        if (events && eventTypes && events.length) {
            const documents = []
            events.forEach(event => {
                const eventType = typesActions.getEventTypeById(
                    eventTypes,
                    event.eventType.id,
                )
                if (
                    event.files &&
                    eventType &&
                    event.files.length > 0 &&
                    eventType.document
                ) {
                    documents.push(event)
                }
            })
            this.setState({ events: documents })
        }
    }

    handleChangeDate(startDate, endDate) {
        this.setState({ startDate: startDate, endDate: endDate })
    }

    handleOpenModalMyDocument(document) {
        this.setState({ openModal: 'showDocs', openDoc: document })
    }

    handleChangeSearch(value) {
        this.setState({ search: value })
    }

    reset() {
        this.setState({
            openModal: false,
            event: null,
            fileNoUrl: [],
            displayNewFiles: [],
            deletedFiles: [],
            openDoc: false,
        })
    }

    handleOpenModal() {
        this.setState({ openModal: 'add', openDoc: false })
        setBOBStateEvent(this)
    }

    deleteDocumentNoUp(i) {
        deleteFileFromBobEvent(this, i, {
            deletedFiles: this.state.deletedFiles,
            displayNewFiles: this.state.displayNewFiles,
            fileNoUrl: this.state.fileNoUrl,
            files: this.state.event.files,
        })
    }

    clickDocument() {
        this.refs.fileUploader.click()
    }

    addDocument(event) {
        addFileToBobEvent(this, event, {
            fileNoUrl: this.state.fileNoUrl,
            displayNewFiles: this.state.displayNewFiles,
        })
    }

    save() {
        const { deletedFiles, fileNoUrl, event } = this.state
        const {
            delimitedDate,
            title,
            description,
            transaction,
            eventType,
        } = event
        const { boat } = this.props
        if (event.id) {
        }
        if (
            (fileNoUrl.length || event.files.length > 0) > 0 &&
            event.files.length + fileNoUrl.length > deletedFiles.length
        ) {
            if (event.id) {
                this.props.dispatch(
                    putBOBEvents(event.id, title, {
                        newFiles: fileNoUrl,
                        files: event.files,
                        delimitedDate: delimitedDate,
                        transaction: transaction,
                        description: description,
                        eventTypeId: eventType.id,
                        deletedFiles: deletedFiles,
                    }),
                )
            } else {
                this.props.dispatch(
                    postBOBEvents(boat.id, eventType.id, title, {
                        files: fileNoUrl,
                        delimitedDate: delimitedDate,
                        transaction: transaction,
                        description: description,
                    }),
                )
            }
            this.reset()
        } else {
            alert('Au moins un document doit être ajouté')
            this.reset()
        }
    }

    renderDocuments() {
        const { openDoc } = this.state
        const { classes } = this.props
        let imgs = []

        if (openDoc && openDoc.files && openDoc.files.length > 0) {
            for (let i = 0; i < openDoc.files.length; i++) {
                imgs.push({
                    link: '/uploads/' + openDoc.files[i].link,
                    blop: openDoc.files[i].blop,
                    id: openDoc.files[i].id,
                })
            }
        }

        return (
            <div className={classes.imagesModal}>
                <BoatOnImageRender vertical multi bottomImage imgs={imgs} />
            </div>
        )
    }

    renderAddModal() {
        const { classes, eventTypes } = this.props

        const eventType = typesActions.getEventTypeById(
            eventTypes,
            this.state.event && this.state.event.eventType.id,
        )
        return (
            <div>
                <Typography className={classes.title}>
                    {this.displayText('youAddDocument')}
                </Typography>
                <TextField
                    id="type"
                    variant="outlined"
                    label={this.displayText('choiceType')}
                    margin="dense"
                    required
                    select
                    value={
                        this.state.event &&
                        this.state.event.eventType &&
                        this.state.event.eventType.id
                            ? this.state.event.eventType.id
                            : -1
                    }
                    onChange={e => {
                        updateStateEvent(this, { eventTypeId: e.target.value })
                    }}
                    className={classes.textField}
                    InputProps={{
                        classes: {
                            input: classes.input,
                            marginDense: classes.marginInput,
                        },
                    }}
                    InputLabelProps={{
                        classes: {
                            root: classes.labelInput,
                        },
                    }}
                >
                    <MenuItem value={-1}>
                        <em>Choisissez un type d'évènement</em>
                    </MenuItem>
                    {eventTypes.map(type => {
                        return (
                            <MenuItem key={type.id} value={type.id}>
                                {type.eventTypeFr}
                            </MenuItem>
                        )
                    })}
                </TextField>
                <Typography className={classes.ifNeeded}>
                    {this.displayText('ifNeeded')}
                </Typography>
                <div className={classes.twoTextField}>
                    <TextField
                        id="date"
                        variant="outlined"
                        type="date"
                        label={this.displayText('date')}
                        margin="dense"
                        value={
                            this.state.event &&
                            this.state.event.delimitedDate &&
                            this.state.event.delimitedDate.startDate &&
                            new Date(this.state.event.delimitedDate.startDate)
                                .toISOString()
                                .split('T')[0]
                        }
                        onChange={e => {
                            updateStateEvent(this, {
                                delimitedDate: { startDate: e.target.value },
                            })
                        }}
                        className={classes.textField}
                        InputProps={{
                            classes: {
                                input: classes.input,
                                marginDense: classes.marginInput,
                            },
                        }}
                        InputLabelProps={{
                            shrink: true,
                            classes: {
                                root: classes.labelInput,
                            },
                        }}
                    />
                    {(!this.state.event ||
                        !this.state.event.eventType ||
                        !this.state.event.eventType.id ||
                        (eventType &&
                            (eventType.spending || eventType.earning))) && (
                        <TextField
                            id="amount"
                            variant="outlined"
                            label={this.displayText('amount')}
                            margin="dense"
                            value={
                                this.state.event &&
                                this.state.event.transaction &&
                                this.state.event.transaction.amountIt
                                    ? this.state.event.transaction.amountIt
                                    : ''
                            }
                            onChange={e => {
                                updateStateEvent(this, {
                                    transaction: { amountIt: e.target.value },
                                })
                            }}
                            className={classes.textField}
                            InputProps={{
                                classes: {
                                    input: classes.input,
                                    marginDense: classes.marginInput,
                                },
                                startAdornment: (
                                    <InputAdornment position="start">
                                        {this.state.event && eventType
                                            ? eventType.spending
                                                ? '-'
                                                : '+'
                                            : ''}
                                    </InputAdornment>
                                ),
                            }}
                            InputLabelProps={{
                                classes: {
                                    root: classes.labelInput,
                                },
                            }}
                        />
                    )}
                </div>
                <TextField
                    id="title"
                    variant="outlined"
                    label={this.displayText('title')}
                    margin="dense"
                    required
                    value={this.state.event && this.state.event.title}
                    onChange={e => {
                        updateStateEvent(this, {
                            title: e.target.value,
                        })
                    }}
                    className={classes.textField}
                    InputProps={{
                        classes: {
                            input: classes.input,
                            marginDense: classes.marginInput,
                        },
                    }}
                    InputLabelProps={{
                        classes: {
                            root: classes.labelInput,
                        },
                    }}
                />
                <TextField
                    id="details"
                    variant="outlined"
                    label={this.displayText('details')}
                    margin="dense"
                    multiline
                    rows={3}
                    value={
                        (this.state.event && this.state.event.description) || ''
                    }
                    onChange={e => {
                        updateStateEvent(this, {
                            description: e.target.value,
                        })
                    }}
                    className={classes.textField}
                    InputProps={{
                        classes: {
                            input: classes.input,
                            marginDense: classes.marginInput,
                        },
                    }}
                    InputLabelProps={{
                        classes: {
                            root: classes.labelInput,
                        },
                    }}
                />
                <Typography className={classes.textAdd}>
                    {this.displayText('addDocument')}
                </Typography>
                <div className={classes.addDoc} onClick={this.clickDocument}>
                    <AddAPhotoIcon className={classes.doc} />
                </div>
                <input
                    onChange={this.addDocument}
                    type="file"
                    id="file"
                    ref="fileUploader"
                    style={{ display: 'none' }}
                    multiple
                />
                <DisplayerImgModal
                    file={
                        this.state.event &&
                        this.state.event.files &&
                        this.state.displayNewFiles
                            ? [
                                  ...this.state.event.files,
                                  ...this.state.displayNewFiles,
                              ]
                            : []
                    }
                    deleteDocumentNoUp={this.deleteDocumentNoUp}
                />
                <Button
                    className={classes.validate}
                    variant="contained"
                    color="secondary"
                    onClick={this.save}
                >
                    {this.displayText('validate')}
                </Button>
            </div>
        )
    }

    edit(document) {
        var newArray = []
        var arrayNoUrl = []

        for (let i = 0; document && i < document.files.length; i++) {
            newArray.push({
                linkImg: '/uploads/' + document.files[i].link,
                id: document.files[i].id,
            })
        }

        this.setState({
            event: document,
            openModal: 'add',
            edit: true,
            fileNoUrl: arrayNoUrl,
        })
    }

    delete(document) {
        this.props.dispatch(removeFilesFromBOBEvent(document.id))
    }

    render() {
        const { classes, loadingEvents, eventTypes } = this.props
        const { openModal, search, startDate, endDate, events } = this.state

        if (loadingEvents || !eventTypes) return <></>

        return (
            <div className={classes.root}>
                <Typography className={classes.title}>
                    {this.displayText('documents')}
                </Typography>
                <div className={classes.firstLine}>
                    <RangDate
                        onDateChange={this.handleChangeDate}
                        desactiveUnavailable
                    />
                    <div className={classes.recherche}>
                        <TextField
                            id="search"
                            margin="dense"
                            required
                            variant="outlined"
                            placeholder={this.displayText('search')}
                            value={this.state.search || ''}
                            onChange={e => {
                                this.handleChangeSearch(e.target.value)
                            }}
                            className={classes.field}
                        />
                    </div>
                </div>
                <div className={classes.infos}>
                    {!events || events.length === 0 ? (
                        <Typography className={classes.noDoc}>
                            Vous n'avez aucun document
                        </Typography>
                    ) : (
                        events
                            .filter(doc => {
                                if (
                                    search &&
                                    !RegExp(search.toLowerCase()).test(
                                        doc.title.toLowerCase(),
                                    )
                                ) {
                                    return false
                                }
                                if (
                                    startDate &&
                                    new Date(startDate).getTime() >
                                        new Date(doc.Date).getTime()
                                )
                                    return false
                                if (
                                    endDate &&
                                    new Date(endDate).getTime() <
                                        new Date(doc.Date).getTime()
                                )
                                    return false
                                return true
                            })
                            .map((document, index) => {
                                const eventType = typesActions.getEventTypeById(
                                    eventTypes,
                                    document.eventType.id,
                                )
                                return (
                                    <div
                                        key={index}
                                        className={
                                            index % 2 === 1
                                                ? classes.line
                                                : classes.blueLine
                                        }
                                    >
                                        <div className={classes.dates}>
                                            <div
                                                className={
                                                    classes.datesDayMonth
                                                }
                                            >
                                                <Typography
                                                    className={classes.date}
                                                >
                                                    <b>
                                                        {document.delimitedDate &&
                                                        document.delimitedDate
                                                            .startDate
                                                            ? new Date(
                                                                  document.delimitedDate.startDate,
                                                              ).getDate() +
                                                              ' ' +
                                                              new Date(
                                                                  document.delimitedDate.startDate,
                                                              ).toLocaleString(
                                                                  'default',
                                                                  {
                                                                      month:
                                                                          'short',
                                                                  },
                                                              )
                                                            : this.displayText(
                                                                  'noDateSet',
                                                              )}
                                                    </b>
                                                </Typography>
                                            </div>
                                            <Typography
                                                className={classes.date}
                                            >
                                                {document.delimitedDate &&
                                                document.delimitedDate
                                                    .startDate ? (
                                                    new Date(
                                                        document.delimitedDate.startDate,
                                                    ).toLocaleString(
                                                        'default',
                                                        {
                                                            year: 'numeric',
                                                        },
                                                    )
                                                ) : (
                                                    <></>
                                                )}
                                            </Typography>
                                        </div>
                                        <img
                                            src={
                                                eventType.photo
                                                    ? require(`images/EventBobIcon/${eventType.photo}`)
                                                    : ''
                                            }
                                            alt={eventType.eventTypeFr}
                                            className={classes.icon}
                                        />
                                        <div className={classes.titleAndType}>
                                            <Typography
                                                noWrap
                                                className={classes.subtitle}
                                            >
                                                {document.title}
                                            </Typography>
                                            <Typography
                                                className={classes.type}
                                            >
                                                {document.eventType &&
                                                    document.eventType
                                                        .eventTypeFr}
                                            </Typography>
                                        </div>
                                        <div className={classes.dividerH}></div>
                                        <AttachFile
                                            className={classes.link}
                                            onClick={() =>
                                                this.handleOpenModalMyDocument(
                                                    document,
                                                )
                                            }
                                        />
                                        <Edit
                                            className={classes.link}
                                            onClick={() => {
                                                this.edit(document)
                                            }}
                                        />
                                        <Delete
                                            className={classes.link}
                                            onClick={() => {
                                                if (
                                                    window.confirm(
                                                        this.displayText(
                                                            'validateDelete',
                                                        ),
                                                    )
                                                )
                                                    this.delete(document)
                                            }}
                                        />
                                    </div>
                                )
                            })
                    )}
                </div>
                <img
                    src={addIcon}
                    alt="ajouter"
                    className={classes.iconAdd}
                    onClick={this.handleOpenModal}
                />
                <BoatOnModal
                    openCondition={openModal}
                    handleClose={this.reset}
                    rendersModal={{
                        add: this.renderAddModal(),
                        showDocs: this.renderDocuments(),
                    }}
                    titles={{ add: 'Mes documents', showDocs: 'Mes documents' }}
                />
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        user: state.authentication.user,
        boats: state.fetch.boats,
        documents: state.bob.documents,
        spendings: state.bob.spendings,
        bob: state.bob,
        boat: state.bob.boat,
        events: state.bob.events,
        loadingEvents: state.bob.loading,
        eventTypes: typesActions.getEventTypeDocument(state.types.eventTypes),
    }
}

export default connect(mapStateToProps)(withStyles(styles)(MyDocumentList))
