export default theme => ({
    login: {
        backgroundColor: '#fec107',
        borderRadius: '25px',
    },
    card: {
        marginTop: '50px',
        paddingLeft: '100px',
        paddingRight: '100px',
        marginBottom: '5%',
        paddingBottom: '50px',
    },
    allWidth: {
        width: '100%',
        marginTop: '10px',
        height: '50px',
        fontSize: '15px',
    },
    toCenter: {
        textAlign: 'center',
        fontSize: '30px',
        fontWeight: 'normal',
    },
    buttonPrimary: {
        backgroundColor: '#fec107',
        borderColor: '#fec107',
        marginTop: '20px',
        fontSize: '20px',
        color: '#1d1d1d',
        '&:hover': {
            backgroundColor: '#3040a0',
            color: 'white',
            borderColor: '#3040a0',
            cursor: 'pointer',
        },
    },
})
