export default {
    documents: {
        FR: 'Mes documents',
        EN: 'My documents',
    },
    youAddDocument: {
        FR: 'Ajouter un document',
        EN: 'Add a document',
    },
    choiceType: {
        FR: 'Choisir un type de document',
        EN: 'Choose a document type',
    },
    date: {
        FR: 'Date',
        EN: 'Date',
    },
    amount: {
        FR: 'Montant',
        EN: 'Amount',
    },
    title: {
        FR: 'Libellé du document',
        EN: 'Document title',
    },
    details: {
        FR: 'Commentaires',
        EN: 'Details',
    },
    addDocument: {
        FR: 'Télécharger le document',
        EN: 'Upload the document',
    },
    validate: {
        FR: 'Je valide',
        EN: 'Validate',
    },
    ifNeeded: {
        FR:
            'Si besoin, vous pouvez ajouter une date et un montant associés au document. Ils se retrouveront enregistrés dans votre tableau de dépenses et revenus.',
        EN:
            'If necessary, you can add a date and an amount associated with the document. They will be saved in your expenditure and income table.',
    },
    validateDelete: {
        FR: 'Voulez-vous vraiment supprimer ce document ?',
        EN: 'Do you really want to delete this document ?',
    },
    search: {
        FR: 'Je recherche',
        EN: 'Search',
    },
    noDateSet: {
        FR: 'Pas de date',
        EN: 'No date',
    },
}
