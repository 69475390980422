export default {
    creditCard: {
        FR: 'Carte de crédit',
        EN: 'Credit card',
    },
    cardNumber: {
        FR: 'Numéro de carte',
        EN: 'Card number',
    },
    date: {
        FR: "Date d'expiration",
        EN: "Date d'expiration",
    },
    cvv: {
        FR: 'CVV',
        EN: 'CVV',
    },
}
