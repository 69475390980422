import React from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import MenuItem from '@material-ui/core/MenuItem'
import BoatOnComponent from '../../BoatOnComponent'
import styles from './Styles/ModalRenderSignInCss'
import dictionary from './Dictionary/ModalRenderSignInDico'
import AutoComplete from '../../AutoComplete'
import { userActions } from '../../../../actions'
import { postData } from '../../../../actions/api.actions'
import { fetchData } from '../../../../actions/api.actions'
import { API_URL } from '../../../../services/config.service'
import BoatOnModal from '../../BoatOnModal'
import RenderBoatCreate from '../RenderBoatCreate'
import RenderEngineCreate from '../RenderEngineCreate'
import RenderAccountCreate from '../RenderAccountCreate'

class ModalRenderSignIn extends BoatOnComponent {
    constructor(props) {
        super(props)
        this.dictionary = dictionary

        this.state = {
            openModal: 'personnalInfos',
            boatId: null,
        }

        this.valid = this.valid.bind(this)
        this.handleClose = this.handleClose.bind(this)
    }

    valid(next, boatId) {
        if (next) this.setState({ openModal: next, boatId: boatId })
        else {
            this.handleClose()
        }
    }

    handleClose() {
        this.setState({ openModal: false }, () => this.props.handleClose())
    }

    render() {
        return (
            <BoatOnModal
                openCondition={this.state.openModal}
                handleClose={this.handleClose}
                rendersModal={{
                    personnalInfos: <RenderAccountCreate valid={this.valid} />,
                    boatInfos: <RenderBoatCreate valid={this.valid} />,
                    enginesInfos: (
                        <RenderEngineCreate
                            valid={this.valid}
                            boatId={this.state.boatId}
                        />
                    ),
                }}
                titles={{
                    personnalInfos: this.displayText('accountCreate'),
                    boatInfos: this.displayText('boatAdd'),
                    enginesInfos: this.displayText('enginesAdd'),
                }}
                disableFullscreen
            />
        )
    }
}

function mapStateToProps(state) {
    return {
        alert: state.alert,
        state: state.open,
        listBoats: state.fetch.listboatmodels,
    }
}

export default connect(mapStateToProps)(withStyles(styles)(ModalRenderSignIn))
