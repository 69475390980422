import React from 'react'
import BoatOnComponent from '../../../../common/BoatOnComponent'
import { connect } from 'react-redux'

import { withStyles } from '@material-ui/core/styles'
import styles from '../Styles/RepairListCss'
import { API_URL } from '../../../../../services/config.service'
import dictionary from '../Dictionary/RepairListDico'
import Typography from '@material-ui/core/Typography'
import RepairModalTop from './RepairModalTop'
import RepairModalDate from './RepairModalDate'
import { Button } from '@material-ui/core'

class RepairModalSimple extends BoatOnComponent {
    constructor(props) {
        super(props)

        this.dictionary = dictionary
        this.date = null

        this.childDate = React.createRef()

        this.save = this.save.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.handleChangeDate = this.handleChangeDate.bind(this)
    }

    handleChange(value, key) {
        this.props.handleChange(value, key)
    }

    handleChangeDate(date) {
        this.date = date
    }

    save() {
        const { repairID } = this.props
        const modal = this

        this.props.saveRepair().then(json => {})
        this.childDate.current.save(repairID).then(() => {
            modal.props.closeModal()
        })
    }

    render() {
        const {
            classes,
            type,
            typefamily,
            idcheckuptype,
            modalResponse,
            repairID,
            checkupCategories,
            checkupTypes,
        } = this.props
        var idDate,
            date,
            link,
            amount,
            idTransaction,
            idDocuments,
            details,
            file,
            fileNoUrl,
            deleteFileId,
            fileIDs = null

        if (modalResponse) {
            const { repairModal } = modalResponse

            idDate = repairModal.DateId
            date = repairModal.CheckupDate
            details = repairModal.comment
            amount = repairModal.Amount

            if (repairModal.documents) {
                file = repairModal.documents.map(
                    d => API_URL + '/documents/' + d.Link,
                )
                fileNoUrl = repairModal.documents.map(d => null)
                fileIDs = repairModal.documents.map(d => d.DocumentID)
            }
        }

        return (
            <div>
                <Typography className={classes.modalTitle}>
                    {this.displayText('userAddsRepair')}
                </Typography>
                <RepairModalTop
                    type={type}
                    typefamily={typefamily}
                    idcheckuptype={idcheckuptype}
                    checkupCategories={checkupCategories}
                    checkupTypes={checkupTypes}
                    handleChange={this.handleChange}
                ></RepairModalTop>

                <RepairModalDate
                    ref={this.childDate}
                    repairID={repairID}
                    idDate={idDate}
                    handleChangeDate={this.handleChangeDate}
                    date={date}
                    link={link}
                    amount={amount}
                    idTransaction={idTransaction}
                    idDocuments={idDocuments}
                    details={details}
                    file={file}
                    fileNoUrl={fileNoUrl}
                    fileIDs={fileIDs}
                    type={'last'}
                    deleteFileId={deleteFileId}
                ></RepairModalDate>

                <Button
                    className={classes.validate}
                    variant="contained"
                    color="secondary"
                    onClick={this.save}
                >
                    {this.displayText('validate')}
                </Button>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {}
}

export default connect(mapStateToProps)(withStyles(styles)(RepairModalSimple))
