export default theme => ({
    stars: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
    },
    starOffButton: {
        width: '5vw',
        height: '5vw',
        color: 'grey',
        marginLeft: '-10px',
        marginRight: '-10px',
    },
    starOnButton: {
        width: '5vw',
        height: '5vw',
        marginLeft: '-10px',
        marginRight: '-10px',
    },
    starOff: {
        color: 'grey',
        height: '25px',
        width: '25px',
    },
    starOn: {
        height: '25px',
        width: '25px',
    },
})
