export default {
    usServices: {
        FR: 'Les services Boaton',
        EN: 'Boaton services',
    },
    aboutUs: {
        FR: 'A propos',
        EN: 'About us',
    },
    professional: {
        FR: 'Professionnel',
        EN: 'Professional',
    },
    goodPlan: {
        FR: 'Conseils & bons plans',
        EN: 'Tips & good plans',
    },
    downloadApp: {
        FR: "Télécharger l'application gratuite",
        EN: 'Download the app for free',
    },
    maintenanceLogbook: {
        FR: "Mon carnet d'entretien bateau en ligne",
        EN: 'My boat maintenance logbook online',
    },
    maintainBoat: {
        FR: 'Entretenir mon bateau',
        EN: 'maintain my boat',
    },
    storeBoat: {
        FR: 'Trouver une place pour mon bateau',
        EN: 'Find a place for my boat',
    },
    insureBoat: {
        FR: 'Assurer mon bateau',
        EN: 'Insure my boat',
    },
    rentPlace: {
        FR: 'Louer mon emplacement',
        EN: 'Rent my place',
    },
    gtcs: {
        FR: 'CGV',
        EN: 'GT & CS',
    },
    contact: {
        FR: 'Nous contacter',
        EN: 'Contact us',
    },
    partners: {
        FR: 'Nos partenaires',
        EN: 'Our partners',
    },
    accessPro: {
        FR: 'Accès compte pro',
        EN: 'Pro account access',
    },
    blog: {
        FR: 'Le blog',
        EN: 'Blog',
    },
    quizz: {
        FR: 'Le quizz',
        EN: 'Quizz',
    },
    nationalSurvey: {
        FR: 'Grande enquête nationale',
        EN: 'Major national survey',
    },
    insureBoatPrice: {
        FR: 'Assurer mon bateau au meilleur prix',
        EN: 'Insure my boat at the best price',
    },
    launchSail: {
        FR: "10 conseils pour réussir la mise à l'eau de son voilier",
        EN: '10 tips for a successful launch of your sail',
    },
    fairing: {
        FR: '5 conseils pour un carénage réussi',
        EN: '5 tips for a successful fairing',
    },
    boatNeeded: {
        FR: "10 questions pour acheter le bateau qu'il vous faut",
        EN: '10 questions to buy the boat you need',
    },
    followUs: {
        FR: 'Suivez-nous sur les réseaux : ',
        EN: 'Follow-us',
    },
    boaton: {
        FR:
            "BoatOn ce sont aussi des passionnés de navigation qui vous tiennent au courant des dernières actualités du monde nautique, partagent des infos insolites et vous donnent les clés pour profiter de votre bateau toute l'année !",
        EN:
            'BoatOn is also a group of sailing enthusiasts who keep you up to date with the latest news from the nautical world, share unusual information and give you the keys to enjoy your boat all year round!',
    },
    logbook: {
        FR: "Carnet d'entretien",
        EN: 'Logbook',
    },
    maintenanceRequest: {
        FR: "Demande d'entretien",
        EN: 'Maintenance request',
    },
}
