import React from 'react'
import BoatOnComponent from '../../../common/BoatOnComponent'
import { connect } from 'react-redux'

import { withStyles } from '@material-ui/core/styles'
import styles from './Styles/MovementTabCss'
import dictionary from './Dictionary/MovementTabDico'
import Typography from '@material-ui/core/Typography'
import AttachFile from '@material-ui/icons/AttachFile'
import Delete from '@material-ui/icons/Delete'
import Edit from '@material-ui/icons/Edit'
import { API_URL } from '../../../../services/config.service'
import BoatOnImageRender from '../../../common/BoatOnImageRender'
import BoatOnModal from '../../../common/BoatOnModal'
import { typesActions } from '../../../../actions/types.actions'

class MovementTab extends BoatOnComponent {
    constructor(props) {
        super(props)
        this.dictionary = dictionary

        this.state = {
            openDoc: null,
            openModal: false,
        }

        this.handleCloseModal = this.handleCloseModal.bind(this)
        this.handleOpenModalMyDocument = this.handleOpenModalMyDocument.bind(
            this,
        )
    }

    handleOpenModalMyDocument(document) {
        this.setState({ openModal: 'showDocs', openDoc: document })
    }

    handleCloseModal() {
        this.setState({
            openModal: false,
            openDoc: null,
        })
    }

    toRealTransaction(event) {
        const { eventTypes } = this.props
        const eventType = typesActions.getEventTypeById(
            eventTypes,
            event.eventType.id,
        )
        if (eventTypes) {
            if (eventType && eventType.spending && !eventType.earning) {
                return Math.abs(event.transaction.amountIt) * -1
            } else if (eventType && eventType.earning && !eventType.spending) {
                return Math.abs(event.transaction.amountIt)
            }
        }
        return Math.abs(event.transaction.amountIt)
    }

    renderDocuments() {
        const { openDoc } = this.state
        const { classes } = this.props
        let imgs = []

        if (openDoc && openDoc.files && openDoc.files.length > 0) {
            for (let i = 0; i < openDoc.files.length; i++) {
                imgs.push({
                    link: API_URL + '/uploads/' + openDoc.files[i].link,
                    id: openDoc.files[i].id,
                })
            }
        }

        return (
            <div className={classes.imagesModal}>
                <BoatOnImageRender vertical multi bottomImage imgs={imgs} />
            </div>
        )
    }

    render() {
        const { classes, filter, eventTypes } = this.props
        var { spendings, documents } = this.props
        const { openModal } = this.state
        //const tab = [{title: "test", type: "3", amount: "660", date: "05 Août"}, {title: "test2", type: "2", amount: "60", date: "11 Août"}, {title: "test3", type: "4", amount: "609", date: "21 Août"}];

        if (!documents) {
            documents = []
        }

        return (
            <div className={classes.root}>
                <div className={classes.firstLine}>
                    <Typography className={classes.title}>
                        {this.displayText('movement')}
                    </Typography>
                </div>
                <div className={classes.infos}>
                    {spendings
                        .filter(spending => {
                            if (
                                filter &&
                                filter.search &&
                                !RegExp(filter.search.toLowerCase()).test(
                                    spending.Title.toLowerCase(),
                                )
                            ) {
                                return false
                            }
                            if (
                                filter &&
                                filter.startDate &&
                                new Date(filter.startDate).getTime() >
                                    new Date(spending.Date).getTime()
                            )
                                return false
                            if (
                                filter &&
                                filter.endDate &&
                                new Date(filter.endDate).getTime() <
                                    new Date(spending.Date).getTime()
                            )
                                return false
                            return true
                        })
                        .map((transaction, index) => {
                            const eventType = typesActions.getEventTypeById(
                                eventTypes,
                                transaction.eventType.id,
                            )
                            return (
                                <div
                                    className={
                                        index % 2 === 1
                                            ? classes.line
                                            : classes.blueLine
                                    }
                                    key={index}
                                >
                                    <div className={classes.dates}>
                                        <div className={classes.datesDayMonth}>
                                            <Typography
                                                className={classes.date}
                                            >
                                                <b>
                                                    {transaction.delimitedDate &&
                                                    transaction.delimitedDate
                                                        .startDate
                                                        ? new Date(
                                                              transaction.delimitedDate.startDate,
                                                          ).getDate() +
                                                          ' ' +
                                                          new Date(
                                                              transaction.delimitedDate.startDate,
                                                          ).toLocaleString(
                                                              'default',
                                                              {
                                                                  month:
                                                                      'short',
                                                              },
                                                          )
                                                        : this.displayText(
                                                              'noDateSet',
                                                          )}
                                                </b>
                                            </Typography>
                                        </div>
                                        <Typography className={classes.date}>
                                            {transaction.delimitedDate &&
                                            transaction.delimitedDate
                                                .startDate ? (
                                                new Date(
                                                    transaction.delimitedDate.startDate,
                                                ).toLocaleString('default', {
                                                    year: 'numeric',
                                                })
                                            ) : (
                                                <></>
                                            )}
                                        </Typography>
                                    </div>
                                    <img
                                        src={
                                            eventType.photo
                                                ? require(`images/EventBobIcon/${eventType.photo}`)
                                                : ''
                                        }
                                        alt={eventType.eventTypeFr}
                                        className={classes.icon}
                                    />
                                    <div className={classes.titleAndType}>
                                        <Typography
                                            noWrap
                                            className={classes.subtitle}
                                        >
                                            {transaction.title}
                                        </Typography>
                                        <Typography className={classes.type}>
                                            {eventType.eventTypeFr}
                                        </Typography>
                                    </div>
                                    <div className={classes.dividerH}></div>
                                    <Typography className={classes.amount}>
                                        {this.toRealTransaction(transaction)} €
                                    </Typography>
                                    <div className={classes.dividerH}></div>
                                    {transaction.files &&
                                    transaction.files.length > 0 ? (
                                        <div className={classes.icone}>
                                            <AttachFile
                                                onClick={() =>
                                                    this.handleOpenModalMyDocument(
                                                        transaction,
                                                    )
                                                }
                                            />
                                        </div>
                                    ) : (
                                        <div className={classes.iconDisabled}>
                                            <AttachFile />
                                        </div>
                                    )}
                                    <Edit
                                        className={classes.icons}
                                        onClick={() => {
                                            this.props.edit(
                                                transaction,
                                                documents,
                                            )
                                        }}
                                    />
                                    <Delete
                                        className={classes.icons}
                                        onClick={() => {
                                            if (
                                                window.confirm(
                                                    this.displayText(
                                                        'validateDelete',
                                                    ),
                                                )
                                            )
                                                this.props.delete(transaction)
                                        }}
                                    />
                                </div>
                            )
                        })}
                </div>
                <BoatOnModal
                    openCondition={openModal}
                    handleClose={this.handleCloseModal}
                    rendersModal={{ showDocs: this.renderDocuments() }}
                    titles={{ showDocs: 'Mes documents' }}
                />
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        boats: state.fetch.boats,
        documents: state.bob.documents,
        bob: state.bob,
        eventTypes: typesActions.getEventTypeTransaction(
            state.types.eventTypes,
        ),
    }
}

export default connect(mapStateToProps)(withStyles(styles)(MovementTab))
