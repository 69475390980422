export default theme => ({
    root: {
        display: 'flex',
        flexFlow: 'row wrap',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
            maxWidth: '300px',
            width: '100%',
        },
    },
    day: {
        width: '34px',
        minWidth: '34px',
        height: '34px',
        minHeight: '34px',
        margin: '2px',
        textAlign: 'center',
        lineHeight: 0,
        fontWeight: 400,
    },
    unavailable: {
        width: '34px',
        color: 'grey',
        minWidth: '34px',
        height: '34px',
        minHeight: '34px',
        margin: '2px',
        textAlign: 'center',
        lineHeight: 0,
        fontWeight: 400,
    },
    case: {
        width: '100%',
    },
    daySelected: {
        width: '34px',
        minWidth: '34px',
        height: '34px',
        minHeight: '34px',
        margin: '2px',
        textAlign: 'center',
        backgroundColor: '#3040a0',
        borderRadius: '50%',
        color: '#fff',
        lineHeight: 0,
        fontWeight: 400,
        '&:hover': {
            backgroundColor: '#222e72',
        },
    },
    startDate: {
        marginBottom: '10px',
        marginTop: '10px',
        width: '115px',
        [theme.breakpoints.down('sm')]: {
            marginLeft: 0,
            marginRight: 0,
        },
    },
    endDate: {
        marginLeft: '5px',
        width: '115px',
        marginBottom: '10px',
        marginTop: '10px',
        [theme.breakpoints.down('sm')]: {
            marginRight: 0,
            marginLeft: 0,
        },
    },
    page: {
        display: 'flex',
        flexFlow: 'row wrap',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        [theme.breakpoints.down('sm')]: {
            justifyContent: 'space-between',
        },
    },
    all: {
        marginRight: '5px',
        padding: '3px 15px',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            marginRight: 0,
        },
    },
    test44: {
        paddingBottom: '26548px',
    },
})
