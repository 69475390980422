export default {
    parkingTitle: {
        FR: 'Titre',
        EN: 'Title',
    },
    questionDetails: {
        FR: 'Quels sont les détails de votre emplacement ?',
        EN: 'What are the details of your location?',
    },
    length: {
        FR: 'Longueur',
        EN: 'Length',
    },
    width: {
        FR: 'Largeur',
        EN: 'Width',
    },
    height: {
        FR: 'Hauteur',
        EN: 'Height',
    },
    surface: {
        FR: 'Surface totale',
        EN: 'Total space',
    },
    address: {
        FR: 'Adresse',
        EN: 'Address',
    },
    placeholderTitle: {
        FR: 'Grand espace dans le jardin',
        EN: 'Big place in the garden',
    },

    placeholderAddress: {
        FR: "L'adresse de mon emplacement",
        EN: 'My place address',
    },
    emptyField: {
        FR: 'Tous les champs ne sont pas remplis !',
        EN: 'One or more field are empty',
    },
    questionPro: {
        FR: 'Vous êtes :',
        EN: 'Your are:',
    },
    description: {
        FR: 'Description',
        EN: 'Description',
    },
    placeholderDescription: {
        FR: 'Décrivez votre emplacement pour le rendre attractif',
        EN: 'Describe your place to make it attractive',
    },
    addressNotValid: {
        FR: 'Adresse non valide',
        EN: 'Invalid address',
    },
    professional: {
        FR: 'Professionnel',
        EN: 'Professional',
    },
    notProfessional: {
        FR: 'Particulier',
        EN: 'Not a professional',
    },
    information: {
        FR:
            "Donnez ici les informations sur la totalité de l'espace que vous désirez mettre en location.",
        EN:
            'Give here the information on the totality of the space you wish to rent out.',
    },
}
