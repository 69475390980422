import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import { Box } from '@material-ui/core'

const styles = theme => ({
    screenBox: {
        display: 'flex',
        flexDirection: 'row',
        margin: '20px 0',
        width: '100%',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
        },
    },
    screenBoxReverse: {
        display: 'flex',
        flexDirection: 'row-reverse',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
        },
    },
    screenshot: {
        margin: '0 30px',
        marginRight: '45px',
        height: 'auto',
        flex: '0 0 25%',
        objectFit: 'contain',
        maxWidth: '250px',
        minWidth: '250px',
        [theme.breakpoints.down('sm')]: {
            margin: '0 auto',
            marginBottom: '20px',
        },
    },
    screenshotReverse: {
        margin: '0 30px',
        marginLeft: '45px',
        height: 'auto',
        flex: '0 0 25%',
        objectFit: 'contain',
        maxWidth: '250px',
        minWidth: '250px',
        [theme.breakpoints.down('sm')]: {
            margin: '0 auto',
            marginBottom: '20px',
        },
    },
    shadowed: {
        '-webkit-filter': 'drop-shadow(12px 12px 25px rgba(0,0,0,0.5))',
        '-webkit-appearance': 'none',
        filter: 'url(#drop-shadow)',
        borderRadiuts: '1px',
    },
    featureBox: {
        display: 'flex',
        flexDirection: 'column',
        flex: '3 1 auto',
        padding: '0 30px',
        [theme.breakpoints.down('sm')]: {
            padding: '0',
        },
    },
    title: {
        textAlign: 'left',
        marginBottom: '40px',
    },
    featureList: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    feature: {
        display: 'flex',
        flexDirection: 'column',
        width: '25%',
    },
    featureMobile: {
        marginLeft: '0',
    },
    featureIcon: {
        height: '50px',
        width: '50px',
        objectFit: 'contain',
    },
    screenBoxTitle: {
        fontSize: '22px',
        fontWeight: '500',
    },
    screenBoxTitleMobile: {
        fontSize: '17px',
        fontWeight: '500',
        textAlign: 'center',
        marginBottom: '-15%',
        marginTop: '5%',
    },
    screenBoxText: {
        fontSize: '16px',
        textAlign: 'justify',
        [theme.breakpoints.down('sm')]: {
            textAlign: 'left',
        },
    },
    screenBoxTextMobile: {
        fontSize: '15px',
        textAlign: 'left',
    },
    screenBoxList: {
        marginLeft: '-13%',
    },
})

class ScreenshotViewer extends Component {
    render() {
        const { classes, title, features, reverse, isMobile } = this.props

        return (
            <Box
                className={
                    isMobile
                        ? classes.screenBoxMobile
                        : reverse
                        ? classes.screenBoxReverse
                        : classes.screenBox
                }
            >
                <div
                    className={`${
                        reverse ? classes.screenshotReverse : classes.screenshot
                    } ${classes.shadowed}`}
                >
                    <img
                        className={classes.screenshotImg}
                        alt={title}
                        src={this.props.screenshot}
                        title={title}
                    />
                </div>
                <Box className={classes.featureBox}>
                    <h2 className={classes.title}>
                        <Typography
                            className={
                                isMobile
                                    ? classes.screenBoxTitleMobile
                                    : classes.screenBoxTitle
                            }
                        >
                            {title}
                        </Typography>
                    </h2>
                    <Box
                        className={
                            isMobile
                                ? classes.featureListMobile
                                : classes.featureList
                        }
                    >
                        {features.map((feature, i) => {
                            return (
                                <Box
                                    key={i}
                                    className={
                                        isMobile
                                            ? classes.featureMobile
                                            : classes.feature
                                    }
                                >
                                    {!isMobile ? (
                                        <>
                                            <img
                                                className={classes.featureIcon}
                                                alt={title}
                                                src={feature.icon}
                                                title={title}
                                            />
                                            <h3>
                                                <Typography
                                                    className={
                                                        classes.screenBoxText
                                                    }
                                                >
                                                    <strong>
                                                        {feature.title}
                                                    </strong>
                                                </Typography>
                                            </h3>
                                            <Typography
                                                className={
                                                    classes.screenBoxText
                                                }
                                            >
                                                {feature.description}
                                            </Typography>
                                        </>
                                    ) : (
                                        <ul>
                                            <li
                                                className={
                                                    classes.screenBoxList
                                                }
                                            >
                                                <Typography
                                                    className={
                                                        classes.screenBoxTextMobile
                                                    }
                                                >
                                                    {feature.description}
                                                </Typography>
                                            </li>
                                        </ul>
                                    )}
                                </Box>
                            )
                        })}
                    </Box>
                </Box>
            </Box>
        )
    }
}

export default withStyles(styles)(ScreenshotViewer)
