import React from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import Divider from '@material-ui/core/Divider'
import Notifications from '@material-ui/icons/Notifications'
import Book from '@material-ui/icons/Book'
import AttachFile from '@material-ui/icons/AttachFile'
import { Elements, StripeProvider } from 'react-stripe-elements'
import BoatOnComponent from '../common/BoatOnComponent'

import SideNavigation from '../common/SideNavigation'
import styles from './Styles/LogbookNavigationCss'
import boaticon from '../../images/boaticon.png'
import AlertPage from './LogbookNavigationPage/AlertPageV2/AlertPage'
import InventoryPage from './LogbookNavigationPage/Inventory/InventoryPage'
import RepairPage from './LogbookNavigationPage/RepairPage/RepairPage'
import BoatOnBookPage from './LogbookNavigationPage/BoatOnBookPage/BoatOnBookPage'
import BoatInfoPage from './LogbookNavigationPage/BoatInfoPage/BoatInfoPage'
import ExpIncPage from './LogbookNavigationPage/ExpIncPage/ExpIncPage'
import MyDocumentPage from './LogbookNavigationPage/MyDocumentPage/MyDocumentPage'
import NotSubscribedPage from './LogbookNavigationPage/NotSubscribedPage/NotSubscribedPage'
import creditcard from '../../images/creditcard.png'
import openbox from '../../images/openbox.png'
import repairer from '../../images/repairer.png'
import { STRIPE_PUBLIC_KEY } from '../../services/config.service'

import AppRoute from '../../constants/AppRoute'
import { appStrings } from '../../languages'
import { LocalizedLink } from '../../languages'
import PricingPage from './LogbookNavigationPage/PricingPage/PricingPage'
import dictionary from './Dictionary/LogBookNavigationDico'
import BoatSelecter from './LogbookNavigationPage/BoatSelecter'
import { userActions } from '../../actions/user.actions'
import { typesActions } from '../../actions/types.actions'

class LogbookPage extends BoatOnComponent {
    componentDidMount() {
        const { user, eventTypes } = this.props
        this.dictionary = dictionary

        if (user) {
            this.props.dispatch(userActions.requestSubscriptions())
        }

        if (!eventTypes) this.props.dispatch(typesActions.requestEventTypes())
    }

    componentDidUpdate() {
        const { user, subscriptions, loadingUser, eventTypes } = this.props

        if (user && !subscriptions && !loadingUser) {
            this.props.dispatch(userActions.requestSubscriptions())
        }

        if (!eventTypes) this.props.dispatch(typesActions.requestEventTypes())
    }

    getMain() {
        let { pathname, subscriptions, user } = this.props

        if (
            !user ||
            (!userActions.checkSubscriptionBOB(subscriptions) &&
                pathname !== this.getLogBookPath(AppRoute.LogBook.Pricing))
        ) {
            return (
                <StripeProvider apiKey={STRIPE_PUBLIC_KEY}>
                    <Elements>
                        <NotSubscribedPage />
                    </Elements>
                </StripeProvider>
            )
        }
        if (pathname === this.getLogBookPath(AppRoute.LogBook.Pricing)) {
            return (
                <StripeProvider apiKey={STRIPE_PUBLIC_KEY}>
                    <Elements>
                        <PricingPage />
                    </Elements>
                </StripeProvider>
            )
        }
        if (pathname === this.getLogBookPath(AppRoute.LogBook.Alert)) {
            return <AlertPage />
        }
        if (pathname === this.getLogBookPath(AppRoute.LogBook.Inventory)) {
            return <InventoryPage />
        }
        if (pathname === this.getLogBookPath(AppRoute.LogBook.Repair)) {
            return <RepairPage />
        }
        if (
            pathname === this.getLogBookPath(AppRoute.LogBook.ExpensesAndIncome)
        ) {
            return <ExpIncPage />
        }
        if (pathname === this.getLogBookPath(AppRoute.LogBook.Documents)) {
            return <MyDocumentPage />
        }
        if (pathname === this.getLogBookPath(AppRoute.LogBook.Informations)) {
            return <BoatInfoPage />
        }
        // if (pathname === '/my-boat-logbook/boaton-book') {
        //   return <BoatOnBookPage />
        // }
        return <BoatOnBookPage />
    }

    getLogBookPath(route) {
        let context = this.getContext()
        return `/${context}` + appStrings[context][route]
    }

    menu() {
        const { classes } = this.props

        return (
            <div>
                <List className={classes.nav} component="nav">
                    <ListItem
                        button
                        component={LocalizedLink}
                        to={AppRoute.LogBook.Book}
                    >
                        <ListItemIcon className={classes.icon}>
                            <Book />
                        </ListItemIcon>
                        <ListItemText primary="BoatOn Book" disableTypography />
                    </ListItem>
                    <Divider />
                    <ListItem
                        button
                        component={LocalizedLink}
                        to={AppRoute.LogBook.Alert}
                    >
                        <ListItemIcon className={classes.icon}>
                            <Notifications />
                        </ListItemIcon>
                        <ListItemText
                            primary={this.displayText('alerts')}
                            disableTypography
                        />
                    </ListItem>
                    <Divider />
                    <ListItem
                        button
                        component={LocalizedLink}
                        to={AppRoute.LogBook.Inventory}
                    >
                        <ListItemIcon className={classes.icon}>
                            <img
                                src={openbox}
                                alt="openbox"
                                title="openbox"
                                className={classes.iconMenu}
                            />
                        </ListItemIcon>
                        <ListItemText
                            primary={this.displayText('inventory')}
                            disableTypography
                        />
                    </ListItem>
                    <Divider />
                    <ListItem
                        button
                        component={LocalizedLink}
                        to={AppRoute.LogBook.Repair}
                    >
                        <ListItemIcon className={classes.icon}>
                            <img
                                src={repairer}
                                alt="repairer"
                                title="repairer"
                                className={classes.iconRepairMenu}
                            />
                        </ListItemIcon>
                        <ListItemText
                            primary={this.displayText('checkup')}
                            disableTypography
                        />
                    </ListItem>
                    <Divider />
                    <ListItem
                        button
                        component={LocalizedLink}
                        to={AppRoute.LogBook.ExpensesAndIncome}
                    >
                        <ListItemIcon className={classes.icon}>
                            <img
                                src={creditcard}
                                alt="creditcard"
                                title="creditcard"
                                className={classes.iconMenu}
                            />
                        </ListItemIcon>
                        <ListItemText
                            primary={this.displayText('expense')}
                            disableTypography
                        />
                    </ListItem>
                    <Divider />
                    <ListItem
                        button
                        component={LocalizedLink}
                        to={AppRoute.LogBook.Documents}
                    >
                        <ListItemIcon className={classes.icon}>
                            <AttachFile />
                        </ListItemIcon>
                        <ListItemText
                            primary={this.displayText('documents')}
                            disableTypography
                        />
                    </ListItem>
                    <Divider />
                    <ListItem
                        button
                        component={LocalizedLink}
                        to={AppRoute.LogBook.Informations}
                    >
                        <ListItemIcon className={classes.icon}>
                            <img
                                src={boaticon}
                                alt="boaticon"
                                title="boaticon"
                                className={classes.iconMenu}
                            />
                        </ListItemIcon>
                        <ListItemText
                            primary={this.displayText('boat')}
                            disableTypography
                        />
                    </ListItem>
                    <Divider />
                </List>
            </div>
        )
    }

    render() {
        const { classes, subscriptions, loadingUser } = this.props

        if (!subscriptions && loadingUser) return this.renderLoading()

        return (
            <div className={classes.root}>
                <div className={classes.content}>
                    <SideNavigation list={this.menu()} main={this.getMain()} />
                </div>
                {<BoatSelecter />}
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        user: state.authentication.user,
        subscriptions: state.authentication.subscriptions,
        loadingUser: state.authentication.loading,
        eventTypes: state.types.eventTypes,
    }
}

export default connect(mapStateToProps)(withStyles(styles)(LogbookPage))
