export default {
    email: {
        FR: 'E-Mail',
        EN: 'E-Mail',
    },
    phoneNumber: {
        FR: 'Téléphone',
        EN: 'Number phone',
    },
    password: {
        FR: 'Mot de passe',
        EN: 'Password',
    },
    boatName: {
        FR: 'Nom du bateau',
        EN: 'Boat name',
    },
    boatType: {
        FR: 'Type du bateau',
        EN: 'Boat type',
    },
    boatYear: {
        FR: 'Année du bateau',
        EN: 'Boat year',
    },
    boatMaker: {
        FR: 'Marque du bateau',
        EN: 'Boat Maker',
    },
    boatModel: {
        FR: 'Modèle du bateau',
        EN: 'Boat model',
    },
    boatRegistration: {
        FR: 'Immatriculation',
        EN: 'Boat registration',
    },
    boatEngine: {
        FR: 'Moteur',
        EN: 'Engine',
    },
    homePortOrStorage: {
        FR: 'Port ou lieu de stockage',
        EN: 'Homeport or storage area',
    },
    validate: {
        FR: 'Valider mon inscription',
        EN: 'Validate my registration',
    },
    enjoyBoating: {
        FR: 'Profitez de votre bateau, nous nous occupons du reste',
        EN: 'Enjoy your boat, we take care of the rest',
    },
    connection: {
        FR: 'Connexion',
        EN: 'Connection',
    },
    forgetPassword: {
        FR: 'Vous avez oublié votre mot de passe ?',
        EN: 'Forgot your password ?',
    },
    noAccount: {
        FR: "Vous n'avez pas encore de compte ?",
        EN: 'Do not have an account yet ?',
    },
    createOne: {
        FR: 'Créez-en un',
        EN: 'Create one',
    },
    missingInfo: {
        FR:
            'Les informations suivantes sont nécessaires à fin de créer votre bateau: Nom, Marque, Modèle et Adresse.',
        EN:
            'The following information is required to create your boat: Name, Make, Model and Address.',
    },
    accountCreate: {
        FR: "Création d'un compte",
        EN: 'Account creation',
    },
    boatAdd: {
        FR: "Ajout d'un bateau",
        EN: 'Add a boat',
    },
    enginesAdd: {
        FR: 'Ajout des moteurs',
        EN: 'Add engines',
    },
}
