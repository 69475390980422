import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import styles from './Styles/DisplayerImgModalCss'
import Clear from '@material-ui/icons/Clear'
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf'
import BoatOnImageRender from '../common/BoatOnImageRender'
import BoatOnModal from '../common/BoatOnModal'

class DisplayerImgModal extends Component {
    constructor(props) {
        super(props)
        this.state = {
            openDisplayerImg: false,
        }

        this.handleCloseDisplayer = this.handleCloseDisplayer.bind(this)
        this.handleOpenDisplayer = this.handleOpenDisplayer.bind(this)
    }

    componentDidMount() {
        var { file } = this.props
        for (let i = 0; i < file.length; i++) {
            file[i].link = file[i].link || file[i].blop
        }
    }

    componentDidUpdate() {
        var { file } = this.props
        for (let i = 0; i < file.length; i++) {
            file[i].link = file[i].link || file[i].blop
        }
    }

    handleCloseDisplayer() {
        this.setState({ openDisplayerImg: false, index: 0 })
    }

    handleOpenDisplayer(index) {
        this.setState({ openDisplayerImg: 'displayImg', index })
    }

    render() {
        const { classes, file } = this.props
        const { index, openDisplayerImg } = this.state

        console.log(file)
        return (
            <div className={classes.allPhotos}>
                {file &&
                    file.map((img, i) => {
                        var pdf = false
                        if (img && img.link) {
                            let full = img.link.split('.')
                            if (full[full.length - 1] === 'pdf') {
                                pdf = true
                            }
                        } else if (
                            img &&
                            img.infos &&
                            img.infos.type === 'application/pdf'
                        ) {
                            pdf = true
                        }
                        return (
                            <div key={i} className={classes.images}>
                                <div key={i} className={classes.pictures}>
                                    <div
                                        key={i}
                                        className={classes.importantImage}
                                    >
                                        <Clear
                                            className={classes.cross}
                                            onClick={() =>
                                                this.props.deleteDocumentNoUp(i)
                                            }
                                        />
                                        {pdf ? (
                                            <React.Fragment
                                                key={i}
                                                onClick={() => {
                                                    this.handleOpenDisplayer(i)
                                                }}
                                            >
                                                <PictureAsPdfIcon
                                                    style={{
                                                        width: '100%',
                                                        height: '100%',
                                                    }}
                                                />
                                            </React.Fragment>
                                        ) : (
                                            <>
                                                <div
                                                    style={{
                                                        backgroundImage:
                                                            'url(' + img.link
                                                                ? img.link
                                                                : img.blop +
                                                                  ')',
                                                        width: '100%',
                                                        height: '100%',
                                                        opacity: '0.5',
                                                        backgroundPosition:
                                                            'center',
                                                        backgroundSize: 'cover',
                                                    }}
                                                />
                                                <img
                                                    src={
                                                        img.link
                                                            ? img.link
                                                            : img.blop
                                                    }
                                                    alt={
                                                        img.link
                                                            ? img.link
                                                            : img.blop
                                                    }
                                                    style={{
                                                        maxWidth: '100%',
                                                        maxHeight: '100%',
                                                        margin: 'auto',
                                                        position: 'absolute',
                                                        top: 0,
                                                        bottom: 0,
                                                        right: 0,
                                                        left: 0,
                                                    }}
                                                    onClick={() => {
                                                        this.handleOpenDisplayer(
                                                            i,
                                                        )
                                                    }}
                                                />
                                            </>
                                        )}
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                <BoatOnModal
                    openCondition={openDisplayerImg}
                    handleClose={this.handleCloseDisplayer}
                    rendersModal={{
                        displayImg: (
                            <BoatOnImageRender
                                vertical
                                multi
                                bottomImage
                                imgs={file}
                                index={index}
                            />
                        ),
                    }}
                    titles={{ displayImg: 'Mes documents' }}
                />
            </div>
        )
    }
}

export default withStyles(styles)(DisplayerImgModal)
