import React from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import TextField from '@material-ui/core/TextField'
import Checkbox from '@material-ui/core/Checkbox'
import RangDate from '../../../common/UsefullComponents/RangDate'
import dictionary from './Dictionary/AlertListDico'
import styles from './Styles/AlertListCss'
import Button from '@material-ui/core/Button'
import MenuItem from '@material-ui/core/MenuItem'
import Clear from '@material-ui/icons/Clear'
import BoatOnComponent from '../../../common/BoatOnComponent'
import AutoComplete from '../../../common/AutoComplete'
import electriciteicon from '../../../../images/electriciteicon.svg'
import moteuricon from '../../../../images/moteuricon.svg'
import electronicicon from '../../../../images/electroniqueicon.svg'
import coqueicon from '../../../../images/coqueicon.svg'
import circuiteauicon from '../../../../images/circuiteauicon.svg'
import accastillageicon from '../../../../images/accastillageicon.svg'
import conforticon from '../../../../images/comforticon.png'
import { API_URL } from '../../../../services/config.service'
import eyeicon from '../../../../images/eyeicon.svg'
import Edit from '@material-ui/icons/Edit'
import AttachFile from '@material-ui/icons/AttachFile'
import AddAPhotoIcon from '@material-ui/icons/AddAPhoto'
import moment from 'moment'
import RepairModal from '../RepairPage/Modal/RepairModal'
import AddIcon from '@material-ui/icons/Add'
import Delete from '@material-ui/icons/Delete'
import {
    postDataBOBRepairs,
    removeDataBOBCheckups,
} from '../../../../actions/bob.actions'
import BoatOnModal from '../../../common/BoatOnModal'
import BoatOnImageRender from '../../../common/BoatOnImageRender'
import DisplayerImgModal from '../../DisplayerImgModal'

const categoriesIcon = {
    1: moteuricon,
    2: electriciteicon,
    3: electronicicon,
    4: circuiteauicon,
    5: coqueicon,
    7: accastillageicon,
    8: conforticon,
}

const tabsName = {
    1: 'engineTab',
    2: 'electricityTab',
    3: 'electronicTab',
    4: 'waterTab',
    5: 'hullTab',
    7: 'sailingTab',
    8: 'comfortTab',
}

class AlertList extends BoatOnComponent {
    constructor(props) {
        super(props)
        this.dictionary = dictionary
        this.state = {
            expanded: [],
            openModal: false,
            type: '',
            familyType: '',
            title: '',
            lastDate: null,
            nextDate: null,
            amount: '',
            details: '',
            file: [],
            fileNoUrl: [],
            update: 0,
            edit: false,
            searchResult: [],
            dateLastSearch: null,
            dateNextSearch: null,
            search: '',
            checkupsearch: null,
            isSearch: false,
            checkup: props.checkup,

            modalIDRepair: null,
            typefamily: null,
            idcheckuptype: null,
        }

        this.saveNewAlert = this.saveNewAlert.bind(this)
        this.addDocument = this.addDocument.bind(this)
        this.clickDocument = this.clickDocument.bind(this)
        this.deleteDocumentNoUp = this.deleteDocumentNoUp.bind(this)
        this.handleListSelection = this.handleListSelection.bind(this)
        this.handleSearchList = this.handleSearchList.bind(this)
        this.isFile = this.isFile.bind(this)
        this.handleOpenModalMyDocument = this.handleOpenModalMyDocument.bind(
            this,
        )
    }

    handlePanel = category => (event, expanded) => {
        let expandedArray = this.state.expanded
        if (expanded) expandedArray.push(category.CheckupTypeID)
        else
            expandedArray = expandedArray.filter(
                c => c !== category.CheckupTypeID,
            )
        this.setState({
            expanded: expandedArray,
        })
    }

    expanded(category) {
        const { startDate, endDate, textSearch } = this.props
        const searchActive =
            startDate != null ||
            endDate != null ||
            (textSearch != null && textSearch != '')
        return (
            this.state.expanded.includes(category.CheckupTypeID) || searchActive
        )
    }

    saveNewAlert() {
        const {
            repairID,
            title,
            lastDate,
            nextDate,
            idcheckuptype,
            familyType,
            file,
            fileNoUrl,
            details,
        } = this.state
        let { amount } = this.state
        const { boat } = this.props
        var data = new FormData()

        this.props.dispatch(
            postDataBOBRepairs({
                familyType,
                idcheckuptype,
                title,
                lastDate,
                nextDate,
                repairID,
                details,
                amount,
                boat,
            }),
        )

        for (var i = 0; i < file.length; i++) {
            data.append('Link', fileNoUrl[i].name)
            data.append('picture', fileNoUrl[i])
        }
        this.handleCloseModal()
    }

    handleListSelection(value) {
        this.setState({
            title: value,
            searchResult: [],
        })
    }

    handleOpenModalMyDocument(document) {
        this.setState({ openModal: 'showDocs', openDoc: document })
    }

    handleChange(value, key) {
        this.setState({ [key]: value })
        if (
            key === 'dateLastSearch' ||
            key === 'dateNextSearch' ||
            key === 'search'
        ) {
            if (value !== '') {
                const data = new FormData()
                if (this.state.dateLastSearch != null) {
                    data.append('dateLast', this.state.dateLastSearch)
                } else if (key === 'dateLastSearch') {
                    data.append('dateLast', value)
                }
                if (this.state.dateNextSearch != null) {
                    data.append('dateNext', this.state.dateNextSearch)
                } else if (key === 'dateNextSearch') {
                    data.append('dateNext', value)
                }
                if (this.state.search != null) {
                    data.append('textSearch', this.state.search)
                } else if (key === 'search') {
                    data.append('textSearch', value)
                }
            } else {
                this.setState({ isSearch: false })
            }
        } else {
            this.setState({ isSearch: false })
        }
    }

    handleSearchList(value, key) {
        if (!value) {
            // no value no results
            return this.setState({ searchResult: [], title: value })
        }

        const familyTypes = [
            this.displayText('electricityTab'),
            this.displayText('engineTab'),
            this.displayText('electronicTab'),
            this.displayText('waterTab'),
            this.displayText('hullTab'),
            this.displayText('sailingTab'),
            this.displayText('comfortTab'),
        ]

        const familyType = familyTypes[this.state.familyType]
        const allCheckups = this.getAllCheckups()
        let matches = []
        if (key === 'title') {
            for (var i in allCheckups) {
                if (this.displayText(allCheckups[i].name) === familyType) {
                    for (var j in allCheckups[i].labelTab) {
                        if (
                            this.displayText(allCheckups[i].labelTab[j])
                                .toLowerCase()
                                .includes(value.toLowerCase())
                        ) {
                            matches.push(
                                this.displayText(allCheckups[i].labelTab[j]),
                            )
                        }
                    }
                }
            }
            this.setState({
                searchResult: matches.length < 50 ? matches : [],
                title: value,
            })
        }
    }

    edit(checkupType) {
        var modalIDRepair = checkupType.checkup.CheckupID
        this.setState({
            openModal: 'edit',
            modalIDRepair: modalIDRepair < 500000 ? modalIDRepair : null,
            typefamily: checkupType.CheckupCategory,
            idcheckuptype: checkupType.CheckupTypeID,
        })
    }

    delete(id, other) {
        const { boat } = this.props
        this.props.dispatch(removeDataBOBCheckups(id, other, boat))
    }

    editOther(modalIDRepair) {
        this.setState({
            openModal: 'edit',
            modalIDRepair: modalIDRepair,
            typefamily: 6,
        })
    }

    handleOpenModal = () => {
        this.setState({
            openModal: 'edit',
            modalIDRepair: null,
            typefamily: null,
            idcheckuptype: null,
        })
    }

    handleCloseModal = () => {
        this.setState({
            openModal: false,
            modalIDRepair: null,
            openDoc: null,
        })
    }

    clickDocument() {
        this.refs.fileUploader.click()
    }

    addDocument(event) {
        const newArray = this.state.file.slice()
        const arrayNoUrl = this.state.fileNoUrl.slice()
        for (let i = 0; i < event.target.files.length; i++) {
            if (event.target.files[i].size < 4000000) {
                newArray.push({
                    blop: URL.createObjectURL(event.target.files[i]),
                    infos: event.target.files[i],
                })
                arrayNoUrl.push(event.target.files[i])
                this.setState({ file: newArray })
                this.setState({ fileNoUrl: arrayNoUrl })
            } else {
                alert('Votre fichier ne doit pas dépasser 4 MO')
            }
        }
    }

    deleteDocumentNoUp(i) {
        let { deleteFileId } = this.state
        if (this.state.file[i] && this.state.file[i].linkImg)
            deleteFileId.push(this.state.file[i].id)
        delete this.state.file[i]
        delete this.state.fileNoUrl[i]
        if (deleteFileId.length > 0)
            this.setState({ deleteFileId: deleteFileId })
        else alert('Vous devez avoir au moins un document')
        this.setState({ file: this.state.file })
        this.setState({ fileNoUrl: this.state.fileNoUrl })
    }

    renderAddAlert() {
        const { classes } = this.props

        const types = [
            {
                intType: 20,
                title: this.displayText('maintenance'),
                type: 'maintenance',
            },
            {
                intType: 2,
                title: this.displayText('reparation'),
                type: 'repair',
            },
            {
                intType: 5,
                title: this.displayText('amenagement'),
                type: 'layout',
            },
        ]

        const familyTypes = [
            {
                intFamily: 1,
                title: this.displayText('engineTab'),
                type: 'maintenance',
            },
            {
                intFamily: 2,
                title: this.displayText('electricityTab'),
                type: 'maintenance',
            },
            {
                intFamily: 3,
                title: this.displayText('electronicTab'),
                type: 'maintenance',
            },
            {
                intFamily: 4,
                title: this.displayText('waterTab'),
                type: 'maintenance',
            },
            {
                intFamily: 5,
                title: this.displayText('hullTab'),
                type: 'maintenance',
            },
            { intFamily: 6, title: this.displayText('other'), type: 'all' },
            {
                intFamily: 7,
                title: this.displayText('sailingTab'),
                type: 'maintenance',
            },
            {
                intFamily: 8,
                title: this.displayText('comfortTab'),
                type: 'maintenance',
            },
        ]

        const engineLabel = [
            {
                intEngineLabel: 1,
                title: this.displayText('remplacementLiquideRefroidissement'),
                type: 'maintenance',
            },
            {
                intEngineLabel: 2,
                title: this.displayText('nettoyageFiltreCarburant'),
                type: 'maintenance',
            },
            {
                intEngineLabel: 3,
                title: this.displayText('nettoyageFiltreEauCrepine'),
                type: 'maintenance',
            },
            {
                intEngineLabel: 4,
                title: this.displayText('vidangeHuileMoteur'),
                type: 'maintenance',
            },
            {
                intEngineLabel: 5,
                title: this.displayText('remplacementFiltreHuile'),
                type: 'maintenance',
            },
            {
                intEngineLabel: 6,
                title: this.displayText('niveauHuileInverseur'),
                type: 'maintenance',
            },
            {
                intEngineLabel: 7,
                title: this.displayText('vidangeHuileInverseur'),
                type: 'maintenance',
            },
            {
                intEngineLabel: 8,
                title: this.displayText('remplacementFiltreGazoil'),
                type: 'maintenance',
            },
            {
                intEngineLabel: 9,
                title: this.displayText(
                    'nettoyageVidangeFiltreDecanteurCarburant',
                ),
                type: 'maintenance',
            },
            {
                intEngineLabel: 10,
                title: this.displayText('nettoyageReservoirCarburant'),
                type: 'maintenance',
            },
            {
                intEngineLabel: 11,
                title: this.displayText('nettoyageThermostat'),
                type: 'maintenance',
            },
            {
                intEngineLabel: 12,
                title: this.displayText('reglageTensionsCourroies'),
                type: 'maintenance',
            },
            {
                intEngineLabel: 13,
                title: this.displayText('remplacementCourroies'),
                type: 'maintenance',
            },
            {
                intEngineLabel: 14,
                title: this.displayText('controleCablageElectrique'),
                type: 'maintenance',
            },
            {
                intEngineLabel: 15,
                title: this.displayText('remplacementAnodesAnticorrosion'),
                type: 'maintenance',
            },
            {
                intEngineLabel: 16,
                title: this.displayText('remplacementTurbinePompeAEau'),
                type: 'maintenance',
            },
            {
                intEngineLabel: 17,
                title: this.displayText('serrageBoulonsFixationMoteur'),
                type: 'maintenance',
            },
            {
                intEngineLabel: 18,
                title: this.displayText('verificationSilentblocs'),
                type: 'maintenance',
            },
            {
                intEngineLabel: 19,
                title: this.displayText('reglageJeuSoupapes'),
                type: 'maintenance',
            },
            {
                intEngineLabel: 20,
                title: this.displayText('revisionLigneArbre'),
                type: 'maintenance',
            },
            {
                intEngineLabel: 21,
                title: this.displayText('revisionHelices'),
                type: 'maintenance',
            },
            {
                intEngineLabel: 22,
                title: this.displayText('remplacementBagueHydrolube'),
                type: 'maintenance',
            },
            {
                intEngineLabel: 23,
                title: this.displayText('remplacementJointsSafran'),
                type: 'maintenance',
            },
        ]

        const electricityLabel = [
            {
                intElectricityLabel: 1,
                title: this.displayText('protectionContactsElectriques'),
                type: 'maintenance',
            },
            {
                intElectricityLabel: 2,
                title: this.displayText('remplacementBatterieMoteur'),
                type: 'maintenance',
            },
            {
                intElectricityLabel: 3,
                title: this.displayText('remplacementBatterieServitude'),
                type: 'maintenance',
            },
            {
                intElectricityLabel: 4,
                title: this.displayText('revisionFeuxNavigation'),
                type: 'maintenance',
            },
            {
                intElectricityLabel: 5,
                title: this.displayText('revisionPanneauxSolaires'),
                type: 'maintenance',
            },
        ]

        const electronicLabel = [
            {
                intElectronicLabel: 1,
                title: this.displayText('verificationFixationAeriens'),
                type: 'maintenance',
            },
            {
                intElectronicLabel: 2,
                title: this.displayText('deposeProtectionContacts'),
                type: 'maintenance',
            },
            {
                intElectronicLabel: 3,
                title: this.displayText('nettoyageHeliceLochSpeedo'),
                type: 'maintenance',
            },
        ]

        const waterLabel = [
            {
                intWaterLabel: 1,
                title: this.displayText('graissageVannesEvacuation'),
                type: 'maintenance',
            },
            {
                intWaterLabel: 2,
                title: this.displayText('serrageColliers'),
                type: 'maintenance',
            },
            {
                intWaterLabel: 3,
                title: this.displayText('nettoyageFiltresPompes'),
                type: 'maintenance',
            },
            {
                intWaterLabel: 4,
                title: this.displayText('traitementAntibacterienReservoirs'),
                type: 'maintenance',
            },
            {
                intWaterLabel: 5,
                title: this.displayText('purificationGraissagePompesWC'),
                type: 'maintenance',
            },
        ]

        const hullLabel = [
            {
                intHullLabel: 1,
                title: this.displayText(
                    'verificationChandeliersBalconsFilieres',
                ),
                type: 'maintenance',
            },
            {
                intHullLabel: 2,
                title: this.displayText('carenage'),
                type: 'maintenance',
            },
            {
                intHullLabel: 3,
                title: this.displayText('antifouling'),
                type: 'maintenance',
            },
            {
                intHullLabel: 4,
                title: this.displayText('remplacementAnodes'),
                type: 'maintenance',
            },
            {
                intHullLabel: 5,
                title: this.displayText('changementVannesPasseCoque'),
                type: 'maintenance',
            },
            {
                intHullLabel: 6,
                title: this.displayText('refectionVernis'),
                type: 'maintenance',
            },
            {
                intHullLabel: 7,
                title: this.displayText('entretienTeck'),
                type: 'maintenance',
            },
            {
                intHullLabel: 8,
                title: this.displayText('polishOeuvresMortes'),
                type: 'maintenance',
            },
        ]

        const sailingLabel = [
            {
                intSailingLabel: 1,
                title: this.displayText('demontageGraissageWinches'),
                type: 'maintenance',
            },
            {
                intSailingLabel: 2,
                title: this.displayText('verificationGoupillesRidoirs'),
                type: 'maintenance',
            },
            {
                intSailingLabel: 3,
                title: this.displayText('verificationVisuelHaubannage'),
                type: 'maintenance',
            },
            {
                intSailingLabel: 4,
                title: this.displayText('verificationGreementCourant'),
                type: 'maintenance',
            },
            {
                intSailingLabel: 5,
                title: this.displayText('verificationVoiles'),
                type: 'maintenance',
            },
        ]

        const comfortLabel = [
            {
                intComfortLabel: 1,
                title: this.displayText('verficatonInstallationGaz'),
                type: 'maintenance',
            },
            {
                intComfortLabel: 2,
                title: this.displayText('verificationExtincteurs'),
                type: 'maintenance',
            },
            {
                intComfortLabel: 3,
                title: this.displayText('verificationLignesVie'),
                type: 'maintenance',
            },
            {
                intComfortLabel: 4,
                title: this.displayText('verificationGiletsSauvetage'),
                type: 'maintenance',
            },
            {
                intComfortLabel: 5,
                title: this.displayText('revisionDessalinisateur'),
                type: 'maintenance',
            },
        ]

        return (
            <div>
                <Typography className={classes.modalTitle}>
                    {this.displayText('userAddsAlert')}
                </Typography>
                <TextField
                    id="type"
                    variant="outlined"
                    label={this.displayText('choiceType')}
                    margin="dense"
                    required
                    select
                    value={
                        this.state.type == 20
                            ? types[0].title
                            : this.state.type == 2
                            ? types[1].title
                            : this.state.type == 5
                            ? types[2].title
                            : ''
                    }
                    onChange={(e, d) => {
                        this.handleChange(d.props.id, 'type')
                    }}
                    className={classes.textField}
                    InputProps={{
                        classes: {
                            input: classes.input,
                            marginDense: classes.marginInput,
                        },
                    }}
                    InputLabelProps={{
                        classes: {
                            root: classes.labelInput,
                        },
                    }}
                    style={{ marginBottom: '5px' }}
                >
                    {types.map(type => {
                        return (
                            <MenuItem id={type.intType} value={type.title}>
                                {type.title}
                            </MenuItem>
                        )
                    })}
                </TextField>
                <TextField
                    id="familyType"
                    variant="outlined"
                    label={this.displayText('choiceFamilyType')}
                    margin="dense"
                    required
                    select
                    value={
                        this.state.familyType == 1
                            ? familyTypes[0].title
                            : this.state.familyType == 2
                            ? familyTypes[1].title
                            : this.state.familyType == 3
                            ? familyTypes[2].title
                            : this.state.familyType == 4
                            ? familyTypes[3].title
                            : this.state.familyType == 5
                            ? familyTypes[4].title
                            : this.state.familyType == 6
                            ? familyTypes[5].title
                            : this.state.familyType == 7
                            ? familyTypes[6].title
                            : this.state.familyType == 8
                            ? familyTypes[7].title
                            : ''
                    }
                    onChange={(e, d) => {
                        this.handleChange(d.props.id, 'familyType')
                    }}
                    className={classes.textField}
                    InputProps={{
                        classes: {
                            input: classes.input,
                            marginDense: classes.marginInput,
                        },
                    }}
                    InputLabelProps={{
                        classes: {
                            root: classes.labelInput,
                        },
                    }}
                    style={{ marginBottom: '5px' }}
                >
                    {familyTypes.map(type => {
                        return (
                            <MenuItem id={type.intFamily} value={type.title}>
                                {type.title}
                            </MenuItem>
                        )
                    })}
                </TextField>
                <AutoComplete
                    searchResult={this.state.searchResult}
                    handleListSelection={this.handleListSelection}
                />
                {this.state.type === '20' && this.state.familyType !== '6' ? (
                    <TextField
                        id="title"
                        variant="outlined"
                        label={this.displayText('equipmentName')}
                        margin="dense"
                        required
                        select
                        value={
                            this.state.familyType == 1
                                ? this.state.idcheckuptype == 1
                                    ? engineLabel[0].title
                                    : this.state.idcheckuptype == 2
                                    ? engineLabel[1].title
                                    : this.state.idcheckuptype == 3
                                    ? engineLabel[2].title
                                    : this.state.idcheckuptype == 4
                                    ? engineLabel[3].title
                                    : this.state.idcheckuptype == 5
                                    ? engineLabel[4].title
                                    : this.state.idcheckuptype == 6
                                    ? engineLabel[5].title
                                    : this.state.idcheckuptype == 7
                                    ? engineLabel[6].title
                                    : this.state.idcheckuptype == 8
                                    ? engineLabel[7].title
                                    : this.state.idcheckuptype == 9
                                    ? engineLabel[8].title
                                    : this.state.idcheckuptype == 10
                                    ? engineLabel[9].title
                                    : this.state.idcheckuptype == 11
                                    ? engineLabel[10].title
                                    : this.state.idcheckuptype == 12
                                    ? engineLabel[11].title
                                    : this.state.idcheckuptype == 13
                                    ? engineLabel[12].title
                                    : this.state.idcheckuptype == 14
                                    ? engineLabel[13].title
                                    : this.state.idcheckuptype == 15
                                    ? engineLabel[14].title
                                    : this.state.idcheckuptype == 16
                                    ? engineLabel[15].title
                                    : this.state.idcheckuptype == 17
                                    ? engineLabel[16].title
                                    : this.state.idcheckuptype == 18
                                    ? engineLabel[17].title
                                    : this.state.idcheckuptype == 19
                                    ? engineLabel[18].title
                                    : this.state.idcheckuptype == 20
                                    ? engineLabel[19].title
                                    : this.state.idcheckuptype == 21
                                    ? engineLabel[20].title
                                    : this.state.idcheckuptype == 22
                                    ? engineLabel[21].title
                                    : this.state.idcheckuptype == 23
                                    ? engineLabel[22].title
                                    : ''
                                : this.state.familyType == 2
                                ? this.state.idcheckuptype == 1
                                    ? electricityLabel[0].title
                                    : this.state.idcheckuptype == 2
                                    ? electricityLabel[1].title
                                    : this.state.idcheckuptype == 3
                                    ? electricityLabel[2].title
                                    : this.state.idcheckuptype == 4
                                    ? electricityLabel[3].title
                                    : this.state.idcheckuptype == 5
                                    ? electricityLabel[4].title
                                    : ''
                                : this.state.familyType == 3
                                ? this.state.idcheckuptype == 1
                                    ? electronicLabel[0].title
                                    : this.state.idcheckuptype == 2
                                    ? electronicLabel[1].title
                                    : this.state.idcheckuptype == 3
                                    ? electronicLabel[2].title
                                    : ''
                                : this.state.familyType == 4
                                ? this.state.idcheckuptype == 1
                                    ? waterLabel[0].title
                                    : this.state.idcheckuptype == 2
                                    ? waterLabel[1].title
                                    : this.state.idcheckuptype == 3
                                    ? waterLabel[2].title
                                    : this.state.idcheckuptype == 4
                                    ? waterLabel[3].title
                                    : this.state.idcheckuptype == 5
                                    ? waterLabel[4].title
                                    : ''
                                : this.state.familyType == 5
                                ? this.state.idcheckuptype == 1
                                    ? hullLabel[0].title
                                    : this.state.idcheckuptype == 2
                                    ? hullLabel[1].title
                                    : this.state.idcheckuptype == 3
                                    ? hullLabel[2].title
                                    : this.state.idcheckuptype == 4
                                    ? hullLabel[3].title
                                    : this.state.idcheckuptype == 5
                                    ? hullLabel[4].title
                                    : this.state.idcheckuptype == 6
                                    ? hullLabel[5].title
                                    : this.state.idcheckuptype == 7
                                    ? hullLabel[6].title
                                    : this.state.idcheckuptype == 8
                                    ? hullLabel[7].title
                                    : ''
                                : this.state.familyType == 7
                                ? this.state.idcheckuptype == 1
                                    ? sailingLabel[0].title
                                    : this.state.idcheckuptype == 2
                                    ? sailingLabel[1].title
                                    : this.state.idcheckuptype == 3
                                    ? sailingLabel[2].title
                                    : this.state.idcheckuptype == 4
                                    ? sailingLabel[3].title
                                    : this.state.idcheckuptype == 5
                                    ? sailingLabel[4].title
                                    : ''
                                : this.state.familyType == 8
                                ? this.state.idcheckuptype == 1
                                    ? comfortLabel[0].title
                                    : this.state.idcheckuptype == 2
                                    ? comfortLabel[1].title
                                    : this.state.idcheckuptype == 3
                                    ? comfortLabel[2].title
                                    : this.state.idcheckuptype == 4
                                    ? comfortLabel[3].title
                                    : this.state.idcheckuptype == 5
                                    ? comfortLabel[4].title
                                    : ''
                                : ''
                        }
                        onChange={(e, d) => {
                            this.handleChange(d.props.id, 'idcheckuptype')
                        }}
                        className={classes.textField}
                        InputProps={{
                            classes: {
                                input: classes.input,
                                marginDense: classes.marginInput,
                            },
                        }}
                        InputLabelProps={{
                            classes: {
                                root: classes.labelInput,
                            },
                        }}
                        style={{ marginBottom: '5px' }}
                    >
                        {electricityLabel.map(type => {
                            if (
                                this.state.familyType === '2' &&
                                this.state.type === '20'
                            ) {
                                return (
                                    <MenuItem
                                        id={type.intElectricityLabel}
                                        value={type.title}
                                    >
                                        {type.title}
                                    </MenuItem>
                                )
                            }
                            return <div />
                        })}

                        {engineLabel.map(type => {
                            if (
                                this.state.familyType === '1' &&
                                this.state.type === '20'
                            ) {
                                return (
                                    <MenuItem
                                        key={type.intEngineLabel}
                                        value={type.title}
                                    >
                                        {type.title}
                                    </MenuItem>
                                )
                            }
                            return <div />
                        })}

                        {electronicLabel.map(type => {
                            if (
                                this.state.familyType === '3' &&
                                this.state.type === '20'
                            ) {
                                return (
                                    <MenuItem
                                        key={type.intElectronicLabel}
                                        value={type.title}
                                    >
                                        {type.title}
                                    </MenuItem>
                                )
                            }
                            return <div />
                        })}

                        {waterLabel.map(type => {
                            if (
                                this.state.familyType === '4' &&
                                this.state.type === '20'
                            ) {
                                return (
                                    <MenuItem
                                        key={type.intWaterLabel}
                                        value={type.title}
                                    >
                                        {type.title}
                                    </MenuItem>
                                )
                            }
                            return <div />
                        })}

                        {hullLabel.map(type => {
                            if (
                                this.state.familyType === '5' &&
                                this.state.type === '20'
                            ) {
                                return (
                                    <MenuItem
                                        key={type.intHullLabel}
                                        value={type.title}
                                    >
                                        {type.title}
                                    </MenuItem>
                                )
                            }
                            return <div />
                        })}

                        {sailingLabel.map(type => {
                            if (
                                this.state.familyType === '7' &&
                                this.state.type === '20'
                            ) {
                                return (
                                    <MenuItem
                                        key={type.intSailingLabel}
                                        value={type.title}
                                    >
                                        {type.title}
                                    </MenuItem>
                                )
                            }
                            return <div />
                        })}

                        {comfortLabel.map(type => {
                            if (
                                this.state.familyType === '8' &&
                                this.state.type === '20'
                            ) {
                                return (
                                    <MenuItem
                                        key={type.intComfortLabel}
                                        value={type.title}
                                    >
                                        {type.title}
                                    </MenuItem>
                                )
                            }
                            return <div />
                        })}
                    </TextField>
                ) : (
                    <TextField
                        id="title"
                        variant="outlined"
                        label={this.displayText('equipmentName')}
                        margin="dense"
                        required
                        value={this.state.title}
                        onChange={e => {
                            this.handleChange(e.target.value, 'title')
                        }}
                        className={classes.textField}
                        InputProps={{
                            classes: {
                                input: classes.input,
                                marginDense: classes.marginInput,
                            },
                        }}
                        InputLabelProps={{
                            classes: {
                                root: classes.labelInput,
                            },
                        }}
                        style={{ marginBottom: '5px' }}
                    />
                )}

                <div className={classes.espaces}>
                    <Typography className={classes.textAddDate}>
                        {this.displayText('lastControl')}
                    </Typography>
                    <div className={classes.tripleField}>
                        <TextField
                            id="date"
                            variant="outlined"
                            type="date"
                            margin="dense"
                            required
                            value={this.state.dateLast}
                            onChange={e => {
                                this.handleChange(e.target.value, 'dateLast')
                            }}
                            className={classes.textField}
                            InputProps={{
                                classes: {
                                    input: classes.input,
                                    marginDense: classes.marginInput,
                                },
                            }}
                            InputLabelProps={{
                                shrink: true,
                                classes: {
                                    root: classes.labelInput,
                                },
                            }}
                        />
                        <TextField
                            id="amount"
                            variant="outlined"
                            label={this.displayText('amount')}
                            margin="dense"
                            type="Number"
                            value={this.state.amount}
                            onChange={e => {
                                this.handleChange(e.target.value, 'amount')
                            }}
                            className={classes.textField}
                            InputProps={{
                                classes: {
                                    input: classes.input,
                                    marginDense: classes.marginInput,
                                },
                            }}
                            InputLabelProps={{
                                classes: {
                                    root: classes.labelInput,
                                },
                            }}
                        />
                    </div>
                </div>
                <TextField
                    id="details"
                    variant="outlined"
                    label={this.displayText('detailsDernierControle')}
                    margin="dense"
                    multiline
                    rows={3}
                    value={this.state.details || ''}
                    onChange={e => {
                        this.handleChange(e.target.value, 'details')
                    }}
                    className={classes.textField}
                    InputProps={{
                        classes: {
                            input: classes.input,
                            marginDense: classes.marginInput,
                        },
                    }}
                    InputLabelProps={{
                        classes: {
                            root: classes.labelInput,
                        },
                    }}
                />
                <Typography className={classes.textAdd}>
                    {this.displayText('addDocumentLast')}
                </Typography>
                <div className={classes.addDoc} onClick={this.clickDocument}>
                    <AddAPhotoIcon className={classes.doc} />
                </div>
                <input
                    onChange={this.addDocument}
                    type="file"
                    id="file"
                    ref="fileUploader"
                    style={{ display: 'none' }}
                    multiple
                />
                <DisplayerImgModal
                    file={this.state.file}
                    deleteDocumentNoUp={this.deleteDocumentNoUp}
                />
                {/* Rajouter le montant pour l'ancien entretien */}
                {/* Rajouter le detail du dernier contrôle */}
                <div className={classes.espaces}>
                    <Typography className={classes.textAddDate}>
                        {this.displayText('nextControl')}
                    </Typography>
                    <div className={classes.tripleField}>
                        <TextField
                            id="date"
                            variant="outlined"
                            type="date"
                            margin="dense"
                            required
                            value={this.state.dateNext}
                            onChange={e => {
                                this.handleChange(e.target.value, 'dateNext')
                            }}
                            className={classes.textField}
                            InputProps={{
                                classes: {
                                    input: classes.input,
                                    marginDense: classes.marginInput,
                                },
                            }}
                            InputLabelProps={{
                                shrink: true,
                                classes: {
                                    root: classes.labelInput,
                                },
                            }}
                        />
                        {/* Rajouter le montant pour le prochain entretien */}
                        {/* Rajouter le champs des details pour le prochain contrôle */}
                        <TextField
                            id="amount"
                            variant="outlined"
                            label={this.displayText('amount')}
                            margin="dense"
                            type="Number"
                            value={this.state.amount}
                            onChange={e => {
                                this.handleChange(e.target.value, 'amount')
                            }}
                            className={classes.textField}
                            InputProps={{
                                classes: {
                                    input: classes.input,
                                    marginDense: classes.marginInput,
                                },
                            }}
                            InputLabelProps={{
                                classes: {
                                    root: classes.labelInput,
                                },
                            }}
                        />
                    </div>
                </div>
                <TextField
                    id="details"
                    variant="outlined"
                    label={this.displayText('detailsProchainControle')}
                    margin="dense"
                    multiline
                    rows={3}
                    value={this.state.details || ''}
                    onChange={e => {
                        this.handleChange(e.target.value, 'details')
                    }}
                    className={classes.textField}
                    InputProps={{
                        classes: {
                            input: classes.input,
                            marginDense: classes.marginInput,
                        },
                    }}
                    InputLabelProps={{
                        classes: {
                            root: classes.labelInput,
                        },
                    }}
                />
                <Typography className={classes.textAdd}>
                    {this.displayText('addDocumentNext')}
                </Typography>
                <div className={classes.addDoc} onClick={this.clickDocument}>
                    <AddAPhotoIcon className={classes.doc} />
                </div>
                <input
                    onChange={this.addDocument}
                    multiple
                    type="file"
                    id="file"
                    ref="fileUploader"
                    style={{ display: 'none' }}
                />
                <DisplayerImgModal
                    file={this.state.file}
                    deleteDocumentNoUp={this.deleteDocumentNoUp}
                />
                <Button
                    className={classes.validate}
                    variant="contained"
                    color="secondary"
                    onClick={this.saveNewAlert}
                >
                    {this.displayText('validate')}
                </Button>
            </div>
        )
    }

    isFile(openDoc) {
        const { documents } = this.props

        if (
            documents &&
            openDoc &&
            openDoc.CheckupDates &&
            openDoc.CheckupDates.length > 0
        ) {
            for (let i = 0; i < documents.length; i++) {
                if (
                    documents[i].TransactionID ===
                    openDoc.CheckupDates[0].transactionID
                ) {
                    return true
                } else if (
                    openDoc.CheckupDates.length > 1 &&
                    documents[i].TransactionID ===
                        openDoc.CheckupDates[1].transactionID
                ) {
                    return true
                }
            }
        }
        return false
    }

    renderList(category) {
        const {
            classes,
            checkups,
            selectedChekups,
            startDate,
            endDate,
            textSearch,
        } = this.props

        const nameList = category.CheckupType
        const tabName = category.tabName
        const checkupTypes = category.types
        const imgList = category.icon

        const searchActive =
            startDate != null ||
            endDate != null ||
            (textSearch != null && textSearch != '')
        var tabActive = !searchActive
        const checkupsTab = checkupTypes.map((checkupType, id) => {
            var checkup = checkups
                ? checkups.find(e => e.CheckupType == checkupType.CheckupTypeID)
                : {}
            checkupType.checkup = checkup
            //Recherche par texte déplacé temporairement en front, il faut metre à jour les textes en back
            var text = this.displayText(checkupType.CheckupType)
            checkup.SearchMatchFront =
                textSearch == null ||
                textSearch == '' ||
                text.toLowerCase().includes(textSearch.toLowerCase())
            checkup.SearchMatchFrontDate =
                startDate == null ||
                endDate == null ||
                (new Date(startDate).getTime() <=
                    new Date(checkup.CheckupLastDate).getTime() &&
                    new Date(endDate).getTime() >=
                        new Date(checkup.CheckupLastDate).getTime()) ||
                (new Date(startDate).getTime() <=
                    new Date(checkup.CheckupNextDate).getTime() &&
                    new Date(endDate).getTime() >=
                        new Date(checkup.CheckupNextDate).getTime())
            tabActive =
                checkup.SearchMatch &&
                checkup.SearchMatchFront &&
                checkup.SearchMatchFrontDate
                    ? true
                    : tabActive
            return checkupType
        })

        if (!tabActive) return false
        return (
            <div className={classes.checkup} key={nameList}>
                <ExpansionPanel
                    expanded={this.expanded(category)}
                    onChange={this.handlePanel(category)}
                >
                    <ExpansionPanelSummary
                        expandIcon={<ExpandMoreIcon color="primary" />}
                        style={{ height: '48px' }}
                    >
                        <img
                            src={imgList}
                            alt="icon"
                            className={classes.icon}
                        />
                        <Typography className={classes.title}>
                            {this.displayText(tabName)}
                        </Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails className={classes.expansionPanel}>
                        <div className={classes.panel}>
                            {checkupsTab
                                .filter(
                                    checkupType =>
                                        checkupType.checkup.SearchMatch &&
                                        checkupType.checkup.SearchMatchFront &&
                                        checkupType.checkup
                                            .SearchMatchFrontDate,
                                )
                                .map((checkupType, id) => {
                                    const checkup = checkupType.checkup
                                    return (
                                        <div className={classes.line} key={id}>
                                            <Typography
                                                className={classes.label}
                                            >
                                                {this.displayText(
                                                    checkupType.CheckupType,
                                                )}
                                            </Typography>
                                            <TextField
                                                id={checkup.CheckupTypeID}
                                                value={
                                                    checkup.CheckupLastDate ==
                                                    null
                                                        ? ''
                                                        : checkup.CheckupLastDate
                                                }
                                                type="date"
                                                onChange={e => {
                                                    this.props.handleChangeDate(
                                                        e.target.value,
                                                        checkup.CheckupType,
                                                        'last',
                                                    )
                                                }}
                                                margin="dense"
                                                variant="outlined"
                                                label={this.displayText(
                                                    'lastControl',
                                                )}
                                                className={classes.date}
                                                inputProps={{
                                                    classes: {
                                                        input: classes.input,
                                                        marginDense:
                                                            classes.marginInput,
                                                    },
                                                    max: `${moment().format(
                                                        'YYYY-MM-DD',
                                                    )}`,
                                                }}
                                                InputLabelProps={{
                                                    shrink: true,
                                                    classes: {
                                                        root:
                                                            classes.labelInput,
                                                    },
                                                }}
                                            />
                                            <TextField
                                                id={checkup.CheckupType}
                                                value={
                                                    checkup.CheckupNextDate ==
                                                    null
                                                        ? ''
                                                        : checkup.CheckupNextDate
                                                }
                                                type="date"
                                                onChange={e => {
                                                    this.props.handleChangeDate(
                                                        e.target.value,
                                                        checkup.CheckupType,
                                                        'next',
                                                    )
                                                }}
                                                margin="dense"
                                                variant="outlined"
                                                label={this.displayText(
                                                    'nextControl',
                                                )}
                                                className={classes.date}
                                                inputProps={{
                                                    classes: {
                                                        input: classes.input,
                                                        marginDense:
                                                            classes.marginInput,
                                                    },
                                                    min: `${moment().format(
                                                        'YYYY-MM-DD',
                                                    )}`,
                                                }}
                                                InputLabelProps={{
                                                    shrink: true,
                                                    classes: {
                                                        root:
                                                            classes.labelInput,
                                                    },
                                                }}
                                            />
                                            {this.isFile(checkup) ? (
                                                <div className={classes.icone}>
                                                    <AttachFile
                                                        onClick={() =>
                                                            this.handleOpenModalMyDocument(
                                                                checkup,
                                                            )
                                                        }
                                                    />
                                                </div>
                                            ) : (
                                                <div
                                                    className={
                                                        classes.iconDisabled
                                                    }
                                                >
                                                    <AttachFile />
                                                </div>
                                            )}
                                            <Edit
                                                className={classes.icons}
                                                onClick={() => {
                                                    this.edit(checkupType)
                                                }}
                                            />

                                            <Delete
                                                className={
                                                    checkup.CheckupID < 500000
                                                        ? classes.icons
                                                        : classes.iconDisabled
                                                }
                                                onClick={() => {
                                                    if (
                                                        checkup.CheckupID <
                                                        500000
                                                    ) {
                                                        if (
                                                            window.confirm(
                                                                this.displayText(
                                                                    'validateDelete',
                                                                ),
                                                            )
                                                        )
                                                            this.delete(
                                                                checkup.CheckupID,
                                                            )
                                                    }
                                                }}
                                            />

                                            <Checkbox
                                                id={checkup.CheckupType}
                                                className={classes.icons}
                                                checked={selectedChekups.includes(
                                                    checkupType.CheckupTypeID,
                                                )}
                                                onChange={e =>
                                                    this.props.handleCheckBox(
                                                        checkupType.CheckupTypeID,
                                                        e,
                                                    )
                                                }
                                            />
                                        </div>
                                    )
                                })}
                        </div>
                    </ExpansionPanelDetails>
                </ExpansionPanel>
            </div>
        )
    }

    renderDocuments() {
        const { classes, documents } = this.props
        const { openDoc } = this.state

        let imgs = []
        if (
            documents &&
            openDoc &&
            openDoc.CheckupDates &&
            openDoc.CheckupDates.length > 0
        ) {
            for (let i = 0; i < documents.length; i++) {
                if (
                    documents[i].TransactionID ===
                    openDoc.CheckupDates[0].transactionID
                ) {
                    imgs = imgs.concat(
                        documents[i].Files.map(f => {
                            return {
                                link: API_URL + '/documents/' + f.Link,
                                id: f.FileID,
                            }
                        }),
                    )
                }
                if (
                    openDoc.CheckupDates.length > 1 &&
                    documents[i].TransactionID ===
                        openDoc.CheckupDates[1].transactionID
                ) {
                    imgs = imgs.concat(
                        documents[i].Files.map(f => {
                            return {
                                link: API_URL + '/documents/' + f.Link,
                                id: f.FileID,
                            }
                        }),
                    )
                }
            }
        }

        return (
            <div className={classes.imagesModal}>
                <BoatOnImageRender vertical multi bottomImage imgs={imgs} />
            </div>
        )
    }

    renderListOther(checkupList, imgList) {
        const { classes, startDate, endDate, textSearch } = this.props
        const nameList = 'other'
        if (checkupList == undefined || checkupList == null) {
            checkupList = []
        }

        const searchActive =
            startDate != null ||
            endDate != null ||
            (textSearch != null && textSearch != '')
        var tabActive = !searchActive
        const checkupsTab = checkupList.map((checkup, id) => {
            //Recherche par texte déplacé temporairement en front, il faut metre à jour les textes en back
            const text = checkup.titleRepair
            checkup.match =
                checkup.SearchMatch &&
                (textSearch == null ||
                    textSearch == '' ||
                    text.toLowerCase().includes(textSearch.toLowerCase()))
            tabActive = checkup.match ? true : tabActive
            return checkup
        })
        if (!tabActive) return false

        return (
            <div className={classes.checkup} key={nameList}>
                <ExpansionPanel
                    expanded={this.expanded(nameList)}
                    onChange={this.handlePanel(nameList)}
                >
                    <ExpansionPanelSummary
                        expandIcon={<ExpandMoreIcon color="primary" />}
                        style={{ height: '48px' }}
                    >
                        <img
                            src={imgList}
                            alt="icon"
                            className={classes.icon}
                        />
                        <Typography className={classes.title}>
                            {this.displayText(nameList)}
                        </Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails className={classes.expansionPanel}>
                        <div className={classes.panel}>
                            {checkupsTab
                                .filter(checkup => checkup.match)
                                .map((checkup, id) => (
                                    <div
                                        className={classes.line}
                                        key={checkup.CheckupID}
                                    >
                                        <Typography className={classes.label}>
                                            {checkup.titleRepair}
                                        </Typography>
                                        <TextField
                                            id={checkup.CheckupID}
                                            value={
                                                checkup.CheckupLastDate == null
                                                    ? ''
                                                    : checkup.CheckupLastDate
                                            }
                                            type="date"
                                            onChange={e => {
                                                this.editOther(
                                                    checkup.CheckupID,
                                                )
                                            }}
                                            margin="dense"
                                            variant="outlined"
                                            label={this.displayText(
                                                'lastControl',
                                            )}
                                            className={classes.date}
                                            InputProps={{
                                                classes: {
                                                    input: classes.input,
                                                    marginDense:
                                                        classes.marginInput,
                                                },
                                            }}
                                            InputLabelProps={{
                                                shrink: true,
                                                classes: {
                                                    root: classes.labelInput,
                                                },
                                            }}
                                        />
                                        <TextField
                                            id={checkup.CheckupID}
                                            value={
                                                checkup.CheckupNextDate == null
                                                    ? ''
                                                    : checkup.CheckupNextDate
                                            }
                                            type="date"
                                            onChange={e => {
                                                this.editOther(
                                                    checkup.CheckupID,
                                                )
                                            }}
                                            margin="dense"
                                            variant="outlined"
                                            label={this.displayText(
                                                'nextControl',
                                            )}
                                            className={classes.date}
                                            InputProps={{
                                                classes: {
                                                    input: classes.input,
                                                    marginDense:
                                                        classes.marginInput,
                                                },
                                            }}
                                            InputLabelProps={{
                                                shrink: true,
                                                classes: {
                                                    root: classes.labelInput,
                                                },
                                            }}
                                        />
                                        {this.isFile(checkup) ? (
                                            <div className={classes.icone}>
                                                <AttachFile
                                                    onClick={() =>
                                                        this.handleOpenModalMyDocument(
                                                            checkup,
                                                        )
                                                    }
                                                />
                                            </div>
                                        ) : (
                                            <div
                                                className={classes.iconDisabled}
                                            >
                                                <AttachFile />
                                            </div>
                                        )}
                                        <Edit
                                            className={classes.icons}
                                            onClick={() => {
                                                this.editOther(
                                                    checkup.CheckupID,
                                                )
                                            }}
                                        />

                                        <Delete
                                            className={
                                                checkup.CheckupID < 500000
                                                    ? classes.icons
                                                    : classes.iconDisabled
                                            }
                                            onClick={() => {
                                                if (
                                                    checkup.CheckupID < 500000
                                                ) {
                                                    if (
                                                        window.confirm(
                                                            this.displayText(
                                                                'validateDelete',
                                                            ),
                                                        )
                                                    )
                                                        this.delete(
                                                            checkup.CheckupID,
                                                            true,
                                                        )
                                                }
                                            }}
                                        />

                                        <Checkbox
                                            id={checkup.CheckupID}
                                            className={classes.icons}
                                        />
                                    </div>
                                ))}
                        </div>
                    </ExpansionPanelDetails>
                </ExpansionPanel>
            </div>
        )
    }

    getIcon(categoryID) {}

    getAllCheckups() {
        const { checkupCategories, checkupTypes } = this.props
        const categories = {}
        const types = checkupTypes.types
        for (const i in checkupCategories) {
            const category = checkupCategories[i]
            category.types = []
            category.icon = categoriesIcon[category.CheckupTypeID]
            category.tabName = tabsName[category.CheckupTypeID]
            categories[category.CheckupTypeID] = category
        }
        for (const i in types) {
            const type = types[i]
            categories[type.CheckupCategory].types.push(type)
        }

        return categories
    }

    renderCheckup() {
        const allTab = this.getAllCheckups()
        return Object.values(allTab)
            .filter(category => category.CheckupTypeID != 6)
            .map(category => this.renderList(category))
    }

    renderCheckupOther() {
        const allTab = this.props.checkupsothers
        if (allTab == null) return null
        return this.renderListOther(allTab, eyeicon)
    }

    onDateChange(startDate, endDate) {
        this.setState({ startDate: startDate, endDate: endDate })
    }

    render() {
        const { classes, checkupCategories, checkupTypes } = this.props

        if (!checkupCategories || !checkupTypes) {
            return <div />
        }

        return (
            <div className={classes.root}>
                <Typography className={classes.hideForMobile}>
                    <b>{this.displayText('noRisk')}</b>
                </Typography>
                <Typography className={classes.hideForMobile}>
                    {this.displayText('allNeeded')}
                </Typography>
                <Typography className={classes.enterDate}>
                    {this.displayText('enterDate')}
                </Typography>
                <div className={classes.secondLine}>
                    <div className={classes.datetime}>
                        <RangDate
                            onDateChange={this.props.onDateChange}
                            desactiveUnavailable
                        />
                    </div>
                    <div className={classes.recherche}>
                        <TextField
                            id="search"
                            margin="dense"
                            required
                            variant="outlined"
                            placeholder={this.displayText('search')}
                            value={this.props.textSearch || ''}
                            onChange={e => {
                                this.props.handleChange(
                                    e.target.value,
                                    'textSearch',
                                )
                            }}
                            className={classes.field}
                        />
                    </div>
                </div>
                {this.renderCheckup()}
                {this.renderCheckupOther()}
                <div>
                    <Button
                        className={classes.buttonAdd}
                        variant="contained"
                        color="primary"
                        onClick={this.handleOpenModal}
                        endIcon={<AddIcon className={classes.buttonSvg} />}
                    >
                        {this.displayText('addAlert')}
                    </Button>
                </div>
                <BoatOnModal
                    openCondition={this.state.openModal}
                    handleClose={this.handleCloseModal}
                    rendersModal={{
                        showDocs: this.renderDocuments(),
                        edit: (
                            <RepairModal
                                checkupTypes={this.props.checkupTypes}
                                checkupCategories={this.props.checkupCategories}
                                repairID={this.state.modalIDRepair}
                                dateID={this.state.modalIDDateRepair}
                                typefamily={this.state.typefamily}
                                idcheckuptype={this.state.idcheckuptype}
                                closeModal={this.handleCloseModal}
                            ></RepairModal>
                        ),
                    }}
                    titles={{
                        edit: 'Informations',
                        showDocs: 'Mes documents',
                    }}
                />
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        user: state.authentication.user,
        boats: state.fetch.boats,
        checkupsearch: state.fetch.checkupsearch,
        boat: state.bob.boat,
        documents: state.bob.documents,
        checkupsothers: state.bob.checkupsothers,
    }
}

export default connect(mapStateToProps)(withStyles(styles)(AlertList))
