export default theme => ({
    base: {
        width: '800px',
        marginRight: 'auto',
        marginLeft: 'auto',
    },
    title: {
        backgroundColor: '#3040a0',
        height: '195px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    h2: {
        fontSize: '30px',
        whiteSpace: 'pre-line',
        fontWeight: 300,
    },
    text: {
        fontSize: '16px',
        marginTop: '10px',
        paddingBottom: '5px',
        paddingTop: '5px',
        fontWeight: 300,
        whiteSpace: 'pre-line',
    },
    special: {
        fontSize: '20px',
        fontWeight: 300,
    },
    list: {
        marginBottom: '30px',
    },
    parafIcon: {
        marginTop: '30px',
        display: 'flex',
        flexDirection: 'row',
        marginBottom: '50px',
    },
    paraf: {
        width: '613px',
        marginTop: 'auto',
        marginBottom: 'auto',
    },
    imgRight: {
        margin: 'auto',
        marginRight: 0,
    },
    imgLeft: {
        margin: 'auto',
        marginLeft: 0,
    },
    applications: {
        width: '453px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        margin: 'auto',
        marginTop: '20px',
        marginBottom: '40px',
    },
})
