import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import BoatOnComponent from './BoatOnComponent'
import styles from './Styles/PhoneFieldCss'
import dictionary from './Dictionary/PhoneFieldDico'
import PhoneInput from 'react-phone-number-input'
import { getCountries } from 'react-phone-number-input/input'
import fr from 'react-phone-number-input/locale/fr'
import PhoneIcon from '@material-ui/icons/Phone'
import TextField from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem'
import InputAdornment from '@material-ui/core/InputAdornment'

class PhoneField extends BoatOnComponent {
    constructor(props) {
        super(props)
        this.dictionary = dictionary
    }

    render() {
        const { error, onChange, value, classes, placeholder } = this.props
        return (
            <PhoneInput
                defaultCountry="FR"
                id="phone"
                international
                placeholder={placeholder}
                label={this.displayText('phoneNumber')}
                value={value}
                onChange={onChange}
                inputComponent={PhoneInputComp}
                helperText={this.displayText('errorPhone')}
                countrySelectComponent={CountrySelect}
                error={error}
                className={classes.phoneInput}
            />
        )
    }
}

const PhoneInputComp = React.forwardRef((props, ref) => {
    const { value, onChange, label, error, helperText, placeholder } = props
    return (
        <TextField
            margin="normal"
            required
            variant="outlined"
            placeholder={placeholder}
            fullWidth
            inputRef={ref}
            value={value}
            label={label}
            error={error}
            helperText={error ? helperText : ''}
            onChange={e => {
                onChange(e)
            }}
            InputProps={{
                startAdornment: (
                    <InputAdornment position="start">
                        <PhoneIcon />
                    </InputAdornment>
                ),
            }}
        />
    )
})

const CountrySelect = ({ value, onChange, labels, iconComponent, ...rest }) => (
    <TextField
        {...rest}
        margin="normal"
        required
        variant="outlined"
        select
        value={value}
        onChange={(e, d) => {
            onChange(d.props.country || undefined)
        }}
        style={{ width: '75px', minWidth: '75px', marginRight: '10px' }}
        SelectProps={{
            renderValue: value => (
                <img
                    src={'https://flag.pk/flags/4x3/' + value + '.svg'}
                    height="100%"
                />
            ),
        }}
    >
        {getCountries().map(country => (
            <MenuItem key={country} country={country} value={country}>
                {fr[country]}
            </MenuItem>
        ))}
    </TextField>
)

export default withStyles(styles)(PhoneField)
