import React from 'react'
import BoatOnComponent from '../../../../common/BoatOnComponent'
import { connect } from 'react-redux'

import { withStyles } from '@material-ui/core/styles'
import styles from '../Styles/TopInventoryCss'
import InputLabelInventory from '../../../../common/UsefullComponents/InputLabelInventory'
import Typography from '@material-ui/core/Typography'
import dictionary from '../Dictionary/InventoryListDico'
import TextField from '@material-ui/core/TextField'
import DisplayerImgModal from '../../../DisplayerImgModal'
import AddAPhotoIcon from '@material-ui/icons/AddAPhoto'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import { API_URL } from '../../../../../services/config.service'
import Tooltip from '@material-ui/core/Tooltip'

import { userService } from '../../../../../services/user.service'
import { postDataBOBRepairsV2 } from '../../../../../actions/bob.actions'

class InventoryModal extends BoatOnComponent {
    constructor(props) {
        super(props)
        this.dictionary = dictionary

        this.state = {}
    }

    render() {
        const { classes } = this.props

        const HtmlTooltip = withStyles(theme => ({
            tooltip: {
                backgroundColor: '#3040a0',
                color: 'white',
                maxWidth: 300,
                fontSize: theme.typography.pxToRem(12),
                border: '1px solid #dadde9',
            },
        }))(Tooltip)

        return (
            <div>
                <Typography className={classes.modalTitle}>
                    {this.displayText('completingInventory')}
                </Typography>
                <InputLabelInventory />

                <div className={classes.espaces}>
                    <div className={classes.dateType}>
                        <Typography className={classes.textAddDate}>
                            {this.displayText('dateModal')}
                        </Typography>

                        <HtmlTooltip
                            title={
                                <>
                                    <Typography color="inherit">
                                        {this.displayText('indicateDate')}
                                    </Typography>
                                </>
                            }
                        >
                            <InfoOutlinedIcon
                                className={classes.outlinedIcon}
                            />
                        </HtmlTooltip>
                    </div>
                    <div className={classes.twoTextField}>
                        <TextField
                            id="amount"
                            type="date"
                            variant="outlined"
                            // label={this.displayText('commissioning')}
                            margin="dense"
                            className={classes.textField}
                        />
                        <TextField
                            id="amount"
                            type="date"
                            variant="outlined"
                            // label={this.displayText('replacement')}
                            margin="dense"
                            className={classes.textField}
                        />
                    </div>
                    <div className={classes.twoTextField}>
                        <TextField
                            id="amount"
                            variant="outlined"
                            label={this.displayText('actuelNbrHours')}
                            margin="dense"
                            className={classes.textField}
                        />
                        <TextField
                            id="amount"
                            variant="outlined"
                            label={this.displayText('replaceHours')}
                            margin="dense"
                            className={classes.textField}
                        />
                    </div>
                </div>

                <div className={classes.espaces}>
                    <div className={classes.dateType}>
                        <Typography className={classes.textAddDate}>
                            {this.displayText('details')}
                        </Typography>
                    </div>
                    <div className={classes.twoTextField}>
                        <TextField
                            id="amount"
                            variant="outlined"
                            label={this.displayText('brand')}
                            margin="dense"
                            className={classes.textField}
                        />
                        <TextField
                            id="amount"
                            variant="outlined"
                            label={this.displayText('model')}
                            margin="dense"
                            className={classes.textField}
                        />
                    </div>
                    <div className={classes.twoTextField}>
                        <TextField
                            id="amount"
                            variant="outlined"
                            label={ <div>{this.displayText('serialNumber')} </div>}
                            margin="dense"
                            className={classes.textField}
                        />
                        <TextField
                            id="amount"
                            variant="outlined"
                            label={this.displayText('internalReference')}
                            margin="dense"
                            className={classes.textField}
                        />

                        {/* <HtmlTooltip
                            title={
                                <React.Fragment>
                                    <Typography color="inherit">
                                        {this.displayText('createReference')}
                                    </Typography>
                                </React.Fragment>
                            }
                        >
                                                    <TextField
                            id="amount"
                            variant="outlined"
                            label={this.displayText('internalReference')}
                            margin="dense"
                            className={classes.textField}
                        />
                        </HtmlTooltip> */}
                    </div>
                    <div className={classes.twoTextField}>
                        <TextField
                            id="amount"
                            variant="outlined"
                            label={this.displayText('supplier')}
                            margin="dense"
                            className={classes.textField}
                        />
                        <TextField
                            id="amount"
                            variant="outlined"
                            label={this.displayText('amountVAT')}
                            margin="dense"
                            className={classes.textField}
                        />
                    </div>
                    <div className={classes.twoTextField}>
                        <TextField
                            id="amount"
                            variant="outlined"
                            label={this.displayText('datePurchase')}
                            margin="dense"
                            className={classes.textField}
                        />
                        <TextField
                            id="amount"
                            variant="outlined"
                            label={this.displayText('stockModal')}
                            margin="dense"
                            className={classes.textField}
                        />
                    </div>

                </div>

                <div className={classes.espaces}>
                    <div className={classes.dateType}>
                        <Typography className={classes.textAddDate}>
                            {this.displayText('docPho')}
                        </Typography>
                    </div>
                </div>

                {/* <div className={classes.addDoc}>
                    <AddAPhotoIcon className={classes.doc} />
                </div>
                <input
                    onChange={this.addDocument}
                    multiple
                    type="file"
                    id="fileLast"
                    ref="fileUploader"
                    style={{ display: 'none' }}
                />
                <DisplayerImgModal

                /> */}

                <div className={classes.espaces}>
                    <div className={classes.dateType}>
                        <Typography className={classes.textAddDate}>
                            {this.displayText('notes')}
                        </Typography>
                    </div>
                </div>

                <TextField
                    id="details"
                    variant="outlined"
                    label={this.displayText('importantInformation')}
                    margin="dense"
                    multiline
                    rows={2}
                    rowsMax={5}
                    className={classes.textField}
                />
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {}
}

export default connect(mapStateToProps)(withStyles(styles)(InventoryModal))
