import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Typography, Checkbox } from '@material-ui/core'
import dictionary from './Dictionary/CategorieSelecterDico'
import styles from './Styles/CategorieSelecterCss'
import BoatOnComponent from '../BoatOnComponent'

import moteuricon from '../../../images/moteuricon.svg'
import electriciteicon from '../../../images/electriciteicon.svg'
import electronicicon from '../../../images/electroniqueicon.svg'
import coqueicon from '../../../images/coqueicon.svg'
import circuiteauicon from '../../../images/circuiteauicon.svg'
import accastillageicon from '../../../images/accastillageicon.svg'
import conforticon from '../../../images/comforticon.png'

class CategorieSelecter extends BoatOnComponent {
    constructor(props) {
        super(props)
        this.dictionary = dictionary

        this.state = {
            categoriesChecked: this.props.categoriesChecked,
        }

        this.handleChange = this.handleChange.bind(this)
    }

    handleChange(value, id) {
        let { categoriesChecked } = this.state
        categoriesChecked[id] = value

        this.setState({ categoriesChecked: categoriesChecked }, () => {
            this.props.onChange(categoriesChecked)
        })
    }

    render() {
        const { classes } = this.props

        const categories = [
            {
                id: 1,
                text: this.displayText('lightning'),
                icon: electriciteicon,
            },
            {
                id: 2,
                text: this.displayText('engine'),
                icon: moteuricon,
            },
            {
                id: 3,
                text: this.displayText('electronics'),
                icon: electronicicon,
            },
            {
                id: 4,
                text: this.displayText('water'),
                icon: circuiteauicon,
            },
            {
                id: 5,
                text: this.displayText('hull'),
                icon: coqueicon,
            },
            {
                id: 6,
                text: this.displayText('fitting'),
                icon: accastillageicon,
            },
            {
                id: 7,
                text: this.displayText('safety'),
                icon: conforticon,
            },
        ]

        return (
            <div className={classes.root}>
                {categories.map(categorie => (
                    <div className={classes.pictoAndText}>
                        <img
                            src={categorie.icon}
                            width="30px"
                            height="30px"
                            alt={categorie.text}
                            className={classes.picto}
                        />
                        <div className={classes.text}>
                            <Typography>{categorie.text}</Typography>
                        </div>
                    </div>
                ))}
            </div>
        )
    }
}

export default withStyles(styles)(CategorieSelecter)
