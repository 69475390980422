import { requestApi } from '../../services/api.service'
import { updateStateEvent } from '../bob.actions'

export const removeDataBOBDocuments = async (
    files,
    handleAlert = json => {},
) => {
    const promises = []
    files.forEach(async file => {
        promises.push(
            await requestApi('/files/' + file, 'DELETE').then(
                _ => {
                    return file
                },
                error => {
                    console.error('cannot delete file')
                    return error
                },
            ),
        )
    })
    return await Promise.all(promises)
}

export const deleteFileFromBobEvent = (
    props,
    index,
    { deletedFiles = [], displayNewFiles = [], fileNoUrl = [], files = [] },
) => {
    if (files && index > files.length - 1) {
        displayNewFiles.splice(index - files.length - 1, 1)
        fileNoUrl.splice(index - files.length - 1, 1)
        props.setState({ displayNewFiles: displayNewFiles })
        props.setState({ fileNoUrl: displayNewFiles })
    } else if (files) {
        if (files[index] && files[index].link && files[index].id)
            deletedFiles.push(files[index].id)
        files.splice(index, 1)
        updateStateEvent(props, {
            files: files,
            deletedFiles: deletedFiles.length > 0 ? deletedFiles : null,
        })
    }
}

export const addFileToBobEvent = (
    props,
    event,
    { fileNoUrl = [], displayNewFiles = [] },
) => {
    var arrayNoUrl = fileNoUrl.slice()
    var newArray = displayNewFiles.slice()
    for (let i = 0; i < event.target.files.length; i++) {
        if (event.target.files[i].size < 4000000) {
            newArray.push({
                blop: URL.createObjectURL(event.target.files[i]),
                infos: event.target.files[i],
            })
            arrayNoUrl.push(event.target.files[i])
            props.setState({ displayNewFiles: newArray })
            props.setState({ fileNoUrl: arrayNoUrl })
        } else {
            alert('Votre fichier ne doit pas dépasser 4 MO')
        }
    }
}

export const postDataBOBDocuments = async (
    files,
    filesType,
    handleUpload = json => {},
) => {
    var formData = new FormData()
    files.forEach((file, i) => {
        formData.append('files', file, file.name)
    })
    return await requestApi(
        '/files/uploadFile/fileType/' + filesType,
        'FILES',
        formData,
    ).then(
        result => {
            console.log(result)
            return result
        },
        error => {
            console.error('cannot upload file')
            return error
        },
    )
} // to remove
