export default {
    boatName: {
        FR: 'Nom du bateau',
        EN: 'Boat name',
    },
    boatType: {
        FR: 'Type du bateau',
        EN: 'Boat type',
    },
    boatYear: {
        FR: 'Année du bateau',
        EN: 'Boat year',
    },
    boatMaker: {
        FR: 'Marque du bateau',
        EN: 'Boat Maker',
    },
    boatModel: {
        FR: 'Modèle du bateau',
        EN: 'Boat model',
    },
    boatRegistration: {
        FR: 'Immatriculation',
        EN: 'Boat registration',
    },
    homePortOrStorage: {
        FR: 'Port ou lieu de stockage',
        EN: 'Homeport or storage area',
    },
    createBoat: {
        FR: 'Créer mon bateau',
        EN: 'Create my boat',
    },
    select: {
        FR: 'Selectionnez',
        EN: 'Select',
    },
    selectType: {
        FR: 'Veuillez selectionner un type de bateau',
        EN: 'Please select a type of boat',
    },
    quit: {
        FR: 'Ajouter plus tard',
        EN: 'Add later',
    },
}
