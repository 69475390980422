import React from 'react'
import { connect } from 'react-redux'
import MenuItem from '@material-ui/core/MenuItem'
import { withStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import BoatOnComponent from '../../BoatOnComponent'
import styles from './Styles/ModalBoatsCss'
import dictionary from './Dictionary/ModalBoatDico'
import AutoComplete from '../../AutoComplete'
import AddressSearch from '../../../RentParkingPage/RentNavigationPages/AddressSearch'

import { fetchData, postData } from '../../../../actions/api.actions'
import { API_URL } from '../../../../services/config.service'

class ModalCreateBoat extends BoatOnComponent {
    constructor(props) {
        super(props)
        this.dictionary = dictionary

        this.state = {
            boatNameCreate: '',
            makerCreate: '',
            modelCreate: '',
            yearCreate: '',
            engineCreate: '',
            stockageCreate: '',
            boatTypeCreate: '',
            portCreate: '',
            lat: 0,
            lng: 0,
            searchResult: [],
            searchResultMaker: [],
        }

        this.handleChange = this.handleChange.bind(this)
        this.signin = this.signin.bind(this)
        this.handleSearch = this.handleSearch.bind(this)
        this.handleListSelection = this.handleListSelection.bind(this)
        this.createAddress = this.createAddress.bind(this)
    }

    componentDidMount() {
        this.props.dispatch(
            fetchData(
                API_URL + '/listboatmodels.php',
                'listboatmodels',
                'POST',
            ),
        )
    }

    handleSearchList(value, key) {
        if (!value) {
            // no value no results
            return this.setState({ searchResult: [], modelCreate: value })
        }

        const { listBoats } = this.props
        let matches = []
        if (key === 'modelCreate') {
            for (let i in listBoats.boatModels) {
                if (
                    listBoats.boatModels[i].libelleModeleBateau
                        .toLowerCase()
                        .includes(value.toLowerCase())
                ) {
                    matches.push(listBoats.boatModels[i])
                }
            }
            this.setState({
                searchResult: matches.length < 50 ? matches : [],
                modelCreate: value,
            })
        } else {
            for (let i in listBoats.boatModels) {
                if (
                    listBoats.boatModels[i].libelleConstructeur
                        .toLowerCase()
                        .includes(value.toLowerCase())
                ) {
                    matches.push(listBoats.boatModels[i].libelleConstructeur)
                }
            }
            matches = [...new Set(matches)]
            this.setState({
                searchResultMaker: matches.length < 50 ? matches : [],
                makerCreate: value,
            })
        }
    }

    handleChange(value, key) {
        if (key === 'modelCreate' || key === 'makerCreate')
            this.handleSearchList(value, key)
        this.setState({ [key]: value })
    }

    handleSearch(addressData, fullAddressInfo) {
        if (
            addressData &&
            addressData.geometry &&
            addressData.geometry.coordinates &&
            addressData.geometry.coordinates.length === 2
        )
            this.setState({
                portCreate: fullAddressInfo,
                lat: addressData.geometry.coordinates[1],
                lng: addressData.geometry.coordinates[0],
            })
        else
            this.setState({
                portCreate: fullAddressInfo,
            })
    }

    createAddress(value) {
        this.setState({
            portCreate: value,
        })
    }

    signin() {
        const data = new FormData()

        data.append('boatname', this.state.boatNameCreate)
        data.append('model', this.state.modelCreate)
        data.append('type', this.state.boatTypeCreate)
        data.append('maker', this.state.makerCreate)
        data.append('year', this.state.yearCreate)
        data.append('engine', this.state.engineCreate)
        data.append('fulladdress', this.state.portCreate)
        data.append('lat', this.state.lat)
        data.append('lng', this.state.lng)
        if (
            this.state.boatNameCreate !== '' &&
            this.state.makerCreate !== '' &&
            this.state.modelCreate !== '' &&
            this.state.portCreate !== ''
        ) {
            this.props
                .dispatch(postData(API_URL + '/boatcreate.php', data))
                .then(() => {
                    this.props.handleClose()
                    this.props.dispatch(
                        fetchData(API_URL + '/boatgetall.php', 'boats', 'POST'),
                    )
                })
        } else {
            alert(this.displayText('missingInfo'))
        }
    }

    handleListSelection(value, type) {
        if (value.libelleGenre === 'Bateau à moteur / jetski')
            value.libelleGenre = this.state.boatTypeCreate

        if (type === 'maker') {
            this.setState({
                makerCreate: value,
                searchResultMaker: [],
            })
        } else {
            this.setState({
                modelCreate: value.libelleModeleBateau,
                boatTypeCreate: value.libelleGenre,
                makerCreate: value.libelleConstructeur,
                searchResult: [],
            })
        }
    }

    render() {
        const { classes } = this.props
        const type = [
            this.displayText('engine'),
            this.displayText('sailing'),
            this.displayText('pneumatic'),
            this.displayText('catamaran'),
            this.displayText('barge'),
            this.displayText('jetSki'),
        ]

        return (
            <form key="boat">
                <div className={classes.fields}>
                    <TextField
                        id="boatName"
                        label={this.displayText('boatName')}
                        margin="normal"
                        required
                        value={this.state.boatNameCreate}
                        onChange={e => {
                            this.handleChange(e.target.value, 'boatNameCreate')
                        }}
                        fullWidth
                    />
                    <TextField
                        id="type"
                        select
                        label={this.displayText('boatType')}
                        value={this.state.boatTypeCreate}
                        onChange={e => {
                            this.handleChange(e.target.value, 'boatTypeCreate')
                        }}
                        margin="normal"
                        fullWidth
                    >
                        {type.map(result => (
                            <MenuItem key={result} value={result}>
                                {result}
                            </MenuItem>
                        ))}
                    </TextField>
                    <AutoComplete
                        type="maker"
                        searchResult={this.state.searchResultMaker}
                        handleListSelection={this.handleListSelection}
                    />
                    <TextField
                        id="maker"
                        label={this.displayText('boatMaker')}
                        margin="normal"
                        required
                        value={this.state.makerCreate}
                        onChange={e => {
                            this.handleChange(e.target.value, 'makerCreate')
                        }}
                        fullWidth
                    />
                    <AutoComplete
                        searchResult={this.state.searchResult}
                        handleListSelection={this.handleListSelection}
                    />
                    <TextField
                        id="model"
                        label={this.displayText('boatModel')}
                        margin="normal"
                        required
                        value={this.state.modelCreate}
                        onChange={e => {
                            this.handleChange(e.target.value, 'modelCreate')
                        }}
                        fullWidth
                    />
                    <TextField
                        id="year"
                        label={this.displayText('boatYear')}
                        margin="normal"
                        type="number"
                        required
                        value={this.state.yearCreate}
                        onChange={e => {
                            this.handleChange(e.target.value, 'yearCreate')
                        }}
                        fullWidth
                    />
                    <TextField
                        id="engine"
                        label={this.displayText('boatEngine')}
                        margin="normal"
                        required
                        value={this.state.engineCreate}
                        onChange={e => {
                            this.handleChange(e.target.value, 'engineCreate')
                        }}
                        fullWidth
                        style={{ marginBottom: '24px' }}
                    />
                    <AddressSearch
                        id="port"
                        margin="normal"
                        style={{
                            width: '100%',
                            marginTop: '16px',
                            marginBottom: '28px',
                            textAlign: 'left',
                        }}
                        onDataAddressChange={this.handleSearch}
                        createAddress={this.createAddress}
                        parkingAddress={null}
                        fullAddressInfo={this.state.portCreate}
                        notOutlined={true}
                        fullWidth
                        placeholder={this.displayText('storage')}
                    />
                </div>
                <div className={classes.buttons}>
                    <Button
                        className={classes.button}
                        color="secondary"
                        onClick={() => {
                            this.signin()
                        }}
                        variant="contained"
                    >
                        {' '}
                        {this.displayText('createBoat')}{' '}
                    </Button>
                </div>
            </form>
        )
    }
}

function mapStateToProps(state) {
    return {
        alert: state.alert,
        state: state.open,
        listBoats: state.fetch.listboatmodels,
    }
}

export default connect(mapStateToProps)(withStyles(styles)(ModalCreateBoat))
