import React from 'react'
import { connect } from 'react-redux'
import MenuItem from '@material-ui/core/MenuItem'
import { withStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import Divider from '@material-ui/core/Divider'
import BoatOnComponent from '../BoatOnComponent'
import styles from './Styles/RenderEngineCreateCss'
import dictionary from './Dictionary/RenderEngineCreateDico'
import InputAdornment from '@material-ui/core/InputAdornment'
import InfoIcon from '@material-ui/icons/Info'
import DeleteIcon from '@material-ui/icons/Delete'
import Typography from '@material-ui/core/Typography'
import AddBoxIcon from '@material-ui/icons/AddBox'
import EventIcon from '@material-ui/icons/Event'
import BuildIcon from '@material-ui/icons/Build'
import SpeedIcon from '@material-ui/icons/Speed'
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import { postBOBEngines } from '../../../actions/bob.actions'
import { typesActions } from '../../../actions'

class RenderEngineCreate extends BoatOnComponent {
    constructor(props) {
        super(props)
        this.dictionary = dictionary

        this.state = {
            engines: [
                {
                    model: '',
                    maker: '',
                    power: '',
                    engineTypeId: -1,
                    year: '1999',
                },
            ],
            saveEngineIdDelete: false,
            focused: false,
        }

        this.handleChange = this.handleChange.bind(this)
        this.handleChangeNumber = this.handleChangeNumber.bind(this)
        this.handleChangeYear = this.handleChangeYear.bind(this)
        this.valid = this.valid.bind(this)
    }

    componentDidMount() {
        const { engineTypes } = this.props
        if (!engineTypes) {
            this.props.dispatch(typesActions.requestEngineTypes())
        }
    }

    handleChange(value, key, i) {
        let { engines } = this.state
        this.setState({ focused: key })
        engines[i][key] = value
        this.setState({ engines })
    }

    handleChangeYear(value, key, i) {
        let { engines } = this.state
        this.setState({ focused: key })
        engines[i][key] = value.getFullYear().toString()
        this.setState({ engines })
    }

    handleChangeNumber(value, key, i) {
        if (value.match(/^[0-9]+$/) != null || value === '') {
            let { engines } = this.state
            this.setState({ focused: key })
            engines[i][key] = value
            this.setState({ engines })
        }
    }

    addEngine() {
        let { engines } = this.state
        this.setState({ focused: false })
        engines.push({
            model: '',
            maker: '',
            power: '',
            engineTypeId: -1,
            year: '1999',
        })
        this.setState({ engines })
    }

    deleteEngine(i) {
        let { engines } = this.state
        this.setState({ focused: false })
        engines.splice(i, 1)
        this.setState({ engines })
    }

    valid(e) {
        const { valid, boatId } = this.props
        const { engines } = this.state
        this.setState({ focused: false })

        e.preventDefault()
        this.props.dispatch(postBOBEngines(engines, boatId))
        valid()
    }

    render() {
        const { classes, engineTypes, loadingTypes } = this.props
        if (loadingTypes !== 0 || !engineTypes) {
            return <></>
        }

        return (
            <form key="boat" onSubmit={this.valid}>
                <div className={classes.modal}>
                    <div className={classes.infos}>
                        {this.state.engines.map((engine, i) => (
                            <div key={i} className={classes.info}>
                                <div className={classes.engineAndDelete}>
                                    <Typography className={classes.subtitle}>
                                        {this.displayText('engine')} {i + 1}
                                    </Typography>
                                    {i > 0 ? (
                                        <DeleteIcon
                                            className={classes.delete}
                                            onClick={() => this.deleteEngine(i)}
                                        />
                                    ) : (
                                        ''
                                    )}
                                    {i + 1 === this.state.engines.length ? (
                                        <AddBoxIcon
                                            className={classes.add}
                                            onClick={() => this.addEngine()}
                                        />
                                    ) : (
                                        ''
                                    )}
                                </div>
                                <TextField
                                    id="maker"
                                    label={this.displayText('maker')}
                                    margin="normal"
                                    required
                                    placeholder="Suzuki"
                                    variant="outlined"
                                    value={engine.maker}
                                    onChange={e => {
                                        this.handleChange(
                                            e.target.value,
                                            'maker',
                                            i,
                                        )
                                    }}
                                    fullWidth
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <InfoIcon />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                                <TextField
                                    id="model"
                                    label={this.displayText('model')}
                                    margin="normal"
                                    required
                                    placeholder="DF140A"
                                    variant="outlined"
                                    value={engine.model}
                                    onChange={e => {
                                        this.handleChange(
                                            e.target.value,
                                            'model',
                                            i,
                                        )
                                    }}
                                    fullWidth
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <InfoIcon />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                                <div className={classes.typeAndYear}>
                                    <TextField
                                        id="type"
                                        label={this.displayText('type')}
                                        margin="normal"
                                        required
                                        select
                                        variant="outlined"
                                        value={engine.engineTypeId}
                                        onChange={e => {
                                            this.handleChange(
                                                e.target.value,
                                                'engineTypeId',
                                                i,
                                            )
                                        }}
                                        fullWidth
                                        style={{
                                            marginRight: '5px',
                                            minWidth: '150px',
                                            textAlign: 'left',
                                        }}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <BuildIcon />
                                                </InputAdornment>
                                            ),
                                        }}
                                    >
                                        <MenuItem value={-1}>
                                            <em>
                                                Choisissez un type de moteur
                                            </em>
                                        </MenuItem>
                                        {engineTypes.map(type => (
                                            <MenuItem
                                                key={type.id}
                                                value={type.id}
                                            >
                                                {type.typeFR}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                    <MuiPickersUtilsProvider
                                        utils={DateFnsUtils}
                                    >
                                        <DatePicker
                                            autoOk
                                            okLabel=""
                                            cancelLabel=""
                                            views={['year']}
                                            label={this.displayText('year')}
                                            value={new Date(engine.year)}
                                            onChange={e => {
                                                this.handleChangeYear(
                                                    e,
                                                    'year',
                                                    i,
                                                )
                                            }}
                                            fullWidth
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <EventIcon />
                                                    </InputAdornment>
                                                ),
                                            }}
                                            required
                                            inputVariant="outlined"
                                            placeholder="1999"
                                            margin="normal"
                                            style={{ marginLeft: '5px' }}
                                        />
                                    </MuiPickersUtilsProvider>
                                </div>
                                <TextField
                                    id="power"
                                    label={this.displayText('power')}
                                    margin="normal"
                                    required
                                    placeholder="140"
                                    variant="outlined"
                                    value={engine.power}
                                    onChange={e => {
                                        this.handleChangeNumber(
                                            e.target.value,
                                            'power',
                                            i,
                                        )
                                    }}
                                    fullWidth
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <SpeedIcon />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                                {i + 1 !== this.state.engines.length ? (
                                    <Divider style={{ marginTop: '10px' }} />
                                ) : (
                                    <></>
                                )}
                            </div>
                        ))}
                    </div>
                    <div className={classes.buttons}>
                        <Button
                            variant="contained"
                            onClick={() => {
                                this.props.valid()
                            }}
                        >
                            {' '}
                            {this.displayText('quit')}{' '}
                        </Button>
                        <Button
                            className={classes.button}
                            type="submit"
                            color="primary"
                            variant="contained"
                        >
                            {' '}
                            {this.displayText('createEngines')}{' '}
                        </Button>
                    </div>
                </div>
            </form>
        )
    }
}

function mapStateToProps(state) {
    return {
        user: state.authentication.user,
        engineTypes: state.types.engineTypes,
        loadingTypes: state.types.loading,
    }
}

export default connect(mapStateToProps)(withStyles(styles)(RenderEngineCreate))
