import { requestApi } from '../../services/api.service'
import { boatConstants } from '../../constants/boat.constants'
import { boatsConstants } from '../../constants'

export const postBOBBoat = (boat, handleAlert = json => {}) => {}

export const removeBOBBoat = (boat, handleAlert = json => {}) => {}

export const getBOBBoat = (boatId, handleAlert = json => {}) => {
    return dispatch => {
        dispatch({ type: boatConstants.REQUEST_BOAT })
        return requestApi('/boats/' + boatId, 'GET').then(
            result => {
                dispatch({
                    type: boatConstants.BOAT_SUCCESS,
                    boat: result,
                })
            },
            error => {
                dispatch({ type: boatConstants.BOAT_FAILURE, error })
            },
        )
    }
}

export const updateBOBBoat = (boat, handleAlert = json => {}) => {
    return dispatch => {
        dispatch({ type: boatConstants.UPDATE_BOAT })
        editBoatInfo(boat, dispatch)
    }

    function editBoatInfo(boat, dispatch) {
        const postUrl = '/boats/' + boat.id
        const address =
            boat.address && boat.address.fullText
                ? {
                      fullAddress: boat.address.fullText,
                      latitude: boat.address.latitude.toString(),
                      longitude: boat.address.longitude.toString(),
                  }
                : null
        const body = {
            name: boat.name,
            maker: boat.maker,
            model: boat.model,
            year: parseInt(boat.year),
            description: boat.description,
            serialNumber: boat.serialNumber,
            boatType: {
                id:
                    boat.boatTypeId && boat.boatTypeId >= 0
                        ? parseInt(boat.boatTypeId)
                        : null,
            },
        }

        if (address) {
            body.address = address
        }
        requestApi(postUrl, 'PUT', body)
            .then(response => {
                dispatch({
                    type: boatConstants.BOAT_SUCCESS,
                    boat: response,
                })
                dispatch({
                    type: boatsConstants.BOATS_UPDATE_SUCCESS,
                    boat: response,
                })
            })
            .catch(error => {
                dispatch({ type: boatConstants.BOAT_FAILURE, error: error })
            })
    }
}
