export default theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        width: 'calc(100% - 40px)',
        padding: '20px',
        paddingTop: 0,
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            padding: 0,
            margin: 0,
        },
    },
    title: {
        fontWeight: 500,
        marginBottom: '10px',
    },
    subtitle: {
        color: '#b4b4b4',
        padding: '10px',
        [theme.breakpoints.down('sm')]: {
            fontSize: '18px',
        },
    },
    priceTotal: {
        fontSize: '22px',
        fontWeight: 500,
        padding: '10px',
        paddingRight: 0,
        [theme.breakpoints.down('sm')]: {
            fontSize: '18px',
        },
    },
    text: {
        fontSize: '16px',
    },
    price: {
        fontSize: '16px',
        fontWeight: 500,
    },
    infos: {
        border: '1px solid #c4c4c4',
        boxShadow: '0px 2px 10px 0.3px #c4c4c4',
        padding: '10px',
        width: 'calc(100% - 20px)',
    },
    divider: {
        marginTop: '10px',
        marginBottom: '10px',
    },
    income: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginRight: '25px',
        [theme.breakpoints.down('sm')]: {
            margin: 0,
        },
    },
    expense: {
        display: 'flex',
        flexDirection: 'column',
        marginRight: '25px',
        [theme.breakpoints.down('sm')]: {
            margin: 0,
        },
    },
    expenseHead: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    expenseBody: {
        display: 'flex',
        flexDirection: 'row',
        marginTop: '20px',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
        },
    },
    doughnut: {
        width: '50%',
        marginRight: '-20%',
        marginLeft: '20%',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            margin: 0,
        },
    },
    compte: {
        width: '30%',
        textAlign: 'right',
        marginTop: 'auto',
        marginBottom: 'auto',
        marginRight: 0,
        marginLeft: 'auto',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            marginTop: '10px',
        },
    },
    line: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        justifyContent: 'flex-end',
        [theme.breakpoints.down('sm')]: {
            justifyContent: 'center',
        },
    },
    texts: {
        width: '125px',
        minWidth: '125px',
        marginTop: 'auto',
        display: 'block',
        marginBottom: 'auto',
        paddingTop: '10px',
        paddingBottom: '10px',
    },
    firstLine: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
        },
    },
    icon: {
        margin: 'auto',
        marginLeft: '50%',
        [theme.breakpoints.down('sm')]: {
            margin: 0,
            marginTop: 'auto',
            marginBottom: 'auto',
        },
    },
    field: {
        width: '98%',
        margin: 'auto',
        fontSize: '16px',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
    recherche: {
        width: '40%',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            marginBottom: '15px',
        },
    },
})
