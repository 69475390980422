import React from 'react'
import { connect } from 'react-redux'
import { fetchData } from '../../../../actions/api.actions'

import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import Modal from '@material-ui/core/Modal'
import ReactPixel from 'react-facebook-pixel'
import dictionary from './Dictionary/ModalSubscribeDico'
import styles from './Styles/ModalSubscribeCss'
import BoatOnComponent from '../../BoatOnComponent'
import { userActions } from '../../../../actions'
import CreditCard from '../../../common/UsefullComponents/CreditCard'
import TextField from '@material-ui/core/TextField'
import chargement from '../../../../images/anchorLoader.gif'
import { injectStripe } from 'react-stripe-elements'
import { Divider } from '@material-ui/core'
import { history } from '../../../../helpers'
import AppRoute from '../../../../constants/AppRoute'
import { API_URL } from '../../../../services/config.service'

class ModalSubscribe extends BoatOnComponent {
    constructor(props) {
        super(props)
        this.dictionary = dictionary
        this.state = {
            selectedBoat: 0,
            openModalConnection: false,
            openModalSubscribe: false,
            notSay: true,
            send: false,
            reductionCode: '',
        }
        const options = {
            autoConfig: true,
            debug: false,
        }
        ReactPixel.init('181677145678471', options)
        this.handleChange = this.handleChange.bind(this)
        this.validate = this.validate.bind(this)
    }
    handleChange(value, key) {
        this.setState({ [key]: value })
    }

    async validate() {
        const { subscribeType } = this.props
        this.setState({ send: true })
        let { token } = await this.props.stripe.createToken()
        if (!token) {
            alert(this.displayText('incorrectCard'))
            this.setState({ send: false })
        } else if (token.id) {
            ReactPixel.track('Log book subscription')
            this.props.dispatch(
                userActions.setupSubscriptions(token.id, {
                    year: subscribeType == 0 ? false : true,
                    month: subscribeType == 0 ? true : false,
                }),
            )
        }
    }

    render() {
        const { classes, subscribeType } = this.props

        return (
            <Modal open={this.props.open} onClose={this.props.handleClose}>
                <div id="modal" className={classes.paper}>
                    <Typography
                        variant="subtitle1"
                        id="simple-modal-description"
                    >
                        <div>
                            <Typography
                                variant="h5"
                                component="p"
                                align="center"
                                gutterBottom
                            >
                                {this.displayText('youWillSubscribeTitle')}
                            </Typography>
                            <Typography className={classes.youWillSubscribe}>
                                {subscribeType == 0
                                    ? this.displayText('youWillSubscribeMonth')
                                    : this.displayText('youWillSubscribe')}
                                {subscribeType != 0 ? (
                                    <span className={classes.st}>
                                        {this.displayText('st')}
                                    </span>
                                ) : (
                                    ''
                                )}{' '}
                                {subscribeType != 0
                                    ? this.displayText('youWillSubscribe2')
                                    : ''}
                            </Typography>
                            <CreditCard />
                            <div
                                style={{ textAlign: 'center' }}
                                className={classes.footerValidate}
                            >
                                <TextField
                                    id="reductionCode"
                                    margin="normal"
                                    placeholder="Code de reduction"
                                    value={this.state.reductionCode}
                                    onChange={e => {
                                        this.handleChange(
                                            e.target.value,
                                            'reductionCode',
                                        )
                                    }}
                                    className={classes.reduction}
                                    style={{ textAlign: 'center' }}
                                />
                            </div>

                            <div className={classes.subscribeTypeBox}>
                                <Divider
                                    classes={{ root: classes.subscribeDivider }}
                                />
                                <div className={classes.subscribeType}>
                                    <div className={classes.subTypeDetails}>
                                        <Typography
                                            className={classes.afterFreeTryText}
                                        >
                                            {this.displayText(
                                                'afterFreeTryText',
                                            )}
                                        </Typography>
                                        <Typography
                                            className={classes.subTextDetails}
                                        >
                                            {subscribeType == 0
                                                ? this.displayText(
                                                      'subTextDetailsMonthly',
                                                  )
                                                : this.displayText(
                                                      'subTextDetailsAnnual',
                                                  )}
                                        </Typography>
                                    </div>
                                    <Typography className={classes.subPricing}>
                                        {subscribeType == 0
                                            ? this.displayText(
                                                  'subPricingMonthly',
                                              )
                                            : this.displayText(
                                                  'subPricingAnnual',
                                              )}
                                    </Typography>
                                </div>
                                <Divider
                                    classes={{ root: classes.subscribeDivider }}
                                />
                            </div>
                            {this.state.send ? (
                                <div style={{ textAlign: 'center' }}>
                                    <img
                                        src={chargement}
                                        alt="loading"
                                        className={classes.loading}
                                    />
                                </div>
                            ) : (
                                <div></div>
                            )}
                            <div
                                style={{ textAlign: 'center' }}
                                className={classes.footerValidate}
                            >
                                {this.state.send ? (
                                    <div>
                                        <Button
                                            disabled
                                            color="secondary"
                                            variant="contained"
                                            className={classes.validate}
                                        >
                                            {this.displayText('subscription')}
                                        </Button>
                                    </div>
                                ) : (
                                    <Button
                                        onClick={this.validate}
                                        color="secondary"
                                        variant="contained"
                                        className={classes.validate}
                                    >
                                        {this.displayText('subscription')}
                                    </Button>
                                )}
                            </div>
                        </div>
                    </Typography>
                </div>
            </Modal>
        )
    }
}

function mapStateToProps(state) {
    return {
        user: state.authentication.user,
        subscribed: state.fetch.subscribed,
    }
}

export default injectStripe(
    connect(mapStateToProps)(withStyles(styles)(ModalSubscribe)),
)
