export default {
    meter: {
        FR: 'm',
        EN: 'm',
    },
    description: {
        FR: 'Description de votre hôte',
        EN: 'Description',
    },
    commentary: {
        FR: 'Commentaire',
        EN: 'Commentary',
    },
    euroPerM: {
        FR: '€ par m²',
        EN: '€ per sqm',
    },
    perDay: {
        FR: 'par jour',
        EN: 'per day',
    },
    perMonth: {
        FR: 'par mois',
        EN: 'per month',
    },
    information: {
        FR: 'Informations essentielles',
        EN: 'Essential information',
    },
    addressNotShow: {
        FR: 'Emplacement exact indiqué après confirmation de la réservation',
        EN: 'Exact location indicated after confirmation of the reservation',
    },
    height: {
        FR: 'Hauteur',
        EN: 'Height',
    },
    width: {
        FR: 'Largeur',
        EN: 'Width',
    },
    length: {
        FR: 'Longueur',
        EN: 'Length',
    },
    book: {
        FR: 'Réserver',
        EN: 'Book',
    },
    share: {
        FR: 'Partager cette offre',
        EN: 'Share this offer',
    },
    copyLink: {
        FR: 'Copier le lien',
        EN: 'Copy link',
    },
    shareSMS: {
        FR: 'Partager par SMS',
        EN: 'Share by SMS',
    },
    errorParking: {
        FR:
            "Cet espace de stockage n'existe pas ou a été supprimé. Vous pouvez en trouver d'autres en cliquant sur le bouton ci-dessous.",
        EN:
            'This storage space does not exist or has been deleted. You can find more by clicking on the button below.',
    },
    errorButtonText: {
        FR: 'Trouver un espace',
        EN: 'Find a place',
    },
    commentaire: {
        FR: 'commentaires',
        EN: 'comments',
    },
    directAccess: {
        FR: 'Accès direct',
        EN: 'Direct access',
    },
    spaceClose: {
        FR: 'Espace cloturé',
        EN: 'Closed space',
    },
    camera: {
        FR: 'Caméra',
        EN: 'Camera',
    },
    water: {
        FR: 'Eau',
        EN: 'Water',
    },
    electricity: {
        FR: 'Electricité',
        EN: 'Electricity',
    },
    access: {
        FR: 'Accès 24h 7/7',
        EN: '24/7 access',
    },
    from: {
        FR: 'Dés',
        EN: 'From',
    },
}
