import { API_URL } from '../services/config.service'
import { userService } from '../services/user.service'

const requestBoats = () => {
    return fetch(API_URL + '/boats/user', {
        method: 'GET',
        headers: userService.getAuthHeader(),
    })
        .then(async result => {
            const json = await result.json()
            if (json.statusCode === 401) {
                return Promise.reject(json.message)
            }
            return json
        })
        .catch(error => {
            console.error(error)
            return Promise.reject(error)
        })
}

export const boatsService = {
    requestBoats,
}
