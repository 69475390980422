export default theme => ({
    root: {
        display: 'flex',
        flexDirection: 'row',
        marginBottom: '20px',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column-reverse',
            marginTop: '-20px',
        },
    },
    main: {
        width: '100%',
    },
    header: {
        marginBottom: '40px',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column-reverse',
            marginBottom: '25px',
        },
    },
    pricing: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'stretch',
        position: 'relative',
        marginTop: '20px',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
        },
    },
    paper: {
        position: 'relative',
        width: '275px',
        display: 'flex',
        margin: '0 50px',
        flexDirection: 'column',
        [theme.breakpoints.down('sm')]: {
            width: '90%',
            margin: '10px auto',
        },
    },
    paperContent: {
        flex: 1,
        marginBottom: '20px',
    },
    title: {
        fontSize: '35px',
        fontWeight: 500,
        textAlign: 'center',
        [theme.breakpoints.down('sm')]: {
            fontSize: '28px',
        },
    },
    subtitle: {
        fontSize: '35px',
        fontWeight: 500,
        textAlign: 'center',
        [theme.breakpoints.down('sm')]: {
            fontSize: '17px',
        },
    },
    icons: {
        display: 'flex',
        justifyContent: 'center',
        margin: '20px 0',
        [theme.breakpoints.down('xs')]: {
            justifyContent: 'space-around',
            flexWrap: 'wrap',
        },
    },
    iconLogbookBox: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '80px',
        width: '80px',
        backgroundColor: '#303F9F',
        borderRadius: '50%',
        margin: '0 20px',
        [theme.breakpoints.down('sm')]: {
            width: '70px',
            height: '70px',
        },
        [theme.breakpoints.down('xs')]: {
            width: '50px',
            height: '50px',
            margin: '0 10px',
        },
    },
    iconlogbookText: {
        textAlign: 'center',
        margin: '15px 0',
        fontWeight: '600',
        [theme.breakpoints.down('xs')]: {
            fontSize: '14px',
        },
    },
    boatIcon: {
        width: '35px',
        height: '35px',
        marginRight: 'auto',
        marginLeft: 'auto',
        zIndex: '2',
        [theme.breakpoints.down('sm')]: {
            width: '40px',
            height: '40px',
        },
        [theme.breakpoints.down('xs')]: {
            width: '20px',
            height: '20px',
        },
    },
    freemonth: {
        backgroundColor: '#303F9F',
        borderRadius: '2px',
        color: 'white',
        padding: '4px 6px',
    },
    head: {
        margin: '10px 0 20px 0',
    },
    cardtitle: {
        fontWeight: '600',
        fontSize: '26px',
    },
    cardsubtitle: {
        fontWeight: '400',
        fontSize: '18px',
        lineHeight: '18px',
    },
    cardadvantages: {
        listStyle: 'none',
        padding: '0',
    },
    cardadvantage: {
        display: 'flex',
        marginBottom: '12px',
    },
    cardadvantagetext: {
        marginLeft: '10px',
        fontWeight: '500',
    },
    st: {
        verticalAlign: 'super',
        fontSize: '70%',
    },
    blockIcon: Object.assign(theme.flex.columnSpacebetween, {
        [theme.breakpoints.down('xs')]: {
            alignItems: 'center',
        },
    }),
})
