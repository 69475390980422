export default {
    control: {
        FR: 'CREER UN CONTROLE',
        EN: 'CREATE A CONTROL',
    },
    download: {
        FR: 'TELECHARGER',
        EN: 'DOWNLOAD',
    },
    email: {
        FR: 'EMAIL',
        EN: 'EMAIL',
    },
    orderForm: {
        FR: 'BON DE COMMANDE',
        EN: 'ORDER FORM',
    },
    delete:{
        FR: 'Supprimer',
        EN: 'Delete'
    }
}
