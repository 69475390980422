import { typesConstants } from '../constants'

const initialState = {
    listBoatModels: null,
    boatTypes: null,
    engineTypes: null,
    eventTypes: null,
    loading: 0,
}

export default function(prevState = initialState, action) {
    switch (action.type) {
        case typesConstants.REQUEST_EVENTTYPES:
            return {
                ...prevState,
                eventTypes: null,
                loading: prevState.loading + 1,
            }
        case typesConstants.REQUEST_EVENTTYPES_SUCCESS:
            return {
                ...prevState,
                eventTypes: action.eventTypes,
                loading: prevState.loading - 1,
            }
        case typesConstants.REQUEST_EVENTTYPES_FAILURE:
            return {
                ...prevState,
                eventTypes: null,
                loading: prevState.loading - 1,
            }
        case typesConstants.REQUEST_LISTBOATMODELS:
            return {
                ...prevState,
                loading: prevState.loading + 1,
            }
        case typesConstants.LISTBOATMODELS_SUCCESS:
            return {
                ...prevState,
                loading: prevState.loading - 1,
            }
        case typesConstants.LISTBOATMODELS_FAILURE:
            return {
                ...prevState,
                loading: prevState.loading - 1,
            }
        case typesConstants.REQUEST_BOATTYPES:
            return {
                ...prevState,
                boatTypes: null,
                loading: prevState.loading + 1,
            }
        case typesConstants.REQUEST_BOATTYPES_SUCCESS:
            return {
                ...prevState,
                boatTypes: action.boatTypes,
                loading: prevState.loading - 1,
            }
        case typesConstants.REQUEST_BOATTYPES_FAILURE:
            return {
                ...prevState,
                boatTypes: null,
                loading: prevState.loading - 1,
            }
        case typesConstants.REQUEST_ENGINETYPES:
            return {
                ...prevState,
                engineTypes: null,
                loading: prevState.loading + 1,
            }
        case typesConstants.REQUEST_ENGINETYPES_SUCCESS:
            return {
                ...prevState,
                engineTypes: action.engineTypes,
                loading: prevState.loading - 1,
            }
        case typesConstants.REQUEST_ENGINETYPES_FAILURE:
            return {
                ...prevState,
                engineTypes: null,
                loading: prevState.loading - 1,
            }
        default:
            return prevState
    }
}
