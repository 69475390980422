import React from 'react'
import ReactGA from 'react-ga'
import ReactPixel from 'react-facebook-pixel'

import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'

import BoatOnComponent from '../../../common/BoatOnComponent'
import ShareButtons from '../../../common/ShareButtons'
import ModalConnection from '../../../common/UsefullComponents/ModalConnection/ModalConnection'

import styles from './Styles/NotSubscribedPageCss'
import dictionary from './Dictionary/NotSubscribedPageDico'

import documentsScreen from '../../../../images/logbook/screenshot_documents.png'
import addIcon from '../../../../images/logbook/icons/Other.png'
import keepIcon from '../../../../images/logbook/icons/Lock.png'
import shareIcon from '../../../../images/logbook/icons/share.png'

import budgetScreen from '../../../../images/logbook/screenshot_budget.png'
import saveIcon from '../../../../images/logbook/icons/Disquette.png'
import linkIcon from '../../../../images/logbook/icons/Lien.png'
import optimiseIcon from '../../../../images/logbook/icons/Coins.png'

import alertsScreen from '../../../../images/logbook/screenshot_alerts.png'
import selectIcon from '../../../../images/logbook/icons/Doigt_qui_clique.png'
import configureIcon from '../../../../images/logbook/icons/Clock.png'
import watchIcon from '../../../../images/logbook/icons/Check.png'

import bookScreen from '../../../../images/logbook/screenshot_book.png'
import repairScreen from '../../../../images/logbook/screenshot_repair.png'
import organiseIcon from '../../../../images/logbook/icons/Ecrous.png'
import visualiseIcon from '../../../../images/logbook/icons/Loupe.png'
import enjoyIcon from '../../../../images/logbook/icons/Barre_capitaine.png'
import pressLogos from '../../../../images/logbook/press_logos.png'
import styloIcon from '../../../../images/logbook/icons/Stylo.png'

import ScreenshotViewer from './ScreenshotViewer'
import { Box } from '@material-ui/core'
import AppRoute from '../../../../constants/AppRoute'
import { LocalizedLink } from '../../../../languages'

import Notifications from '@material-ui/icons/Notifications'
import AttachFile from '@material-ui/icons/AttachFile'
import creditcard from '../../../../images/creditcard.png'
import repaircard from '../../../../images/repair.png'
import Book from '@material-ui/icons/Book'
import Reviews from '../../../HomePage/Head/Reviews'

class BoatOnBookPage extends BoatOnComponent {
    constructor(props) {
        super(props)
        this.dictionary = dictionary

        // gogole analytics
        ReactGA.initialize('UA-93941518-1')
        ReactGA.pageview('/logbook/home')

        // React pixels
        ReactPixel.init('181677145678471', {
            autoConfig: true,
            debug: false,
        })
        ReactPixel.pageView()

        this.state = {
            openModalConnection: false,
            openModalSubscribe: false,
            subscriptionRequested: false,
        }

        this.handleConnectionClose = this.handleConnectionClose.bind(this)
        this.handleSubscribeClose = this.handleSubscribeClose.bind(this)
        this.handleClick = this.handleClick.bind(this)
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { user } = this.props
        const { subscriptionRequested, openModalSubscribe } = this.state

        if (user && subscriptionRequested && !openModalSubscribe) {
            this.setState({
                openModalConnection: false,
                openModalSubscribe: true,
            })
        }
    }

    handleClick() {
        const { user } = this.props
        if (!user) {
            this.setState({
                openModalConnection: true,
                openModalSubscribe: false,
                subscriptionRequested: true,
            })
        } else {
            this.setState({
                openModalConnection: false,
                openModalSubscribe: true,
                subscriptionRequested: true,
            })
        }
    }

    handleConnectionClose() {
        this.setState({
            openModalConnection: false,
        })
    }

    handleSubscribeClose() {
        this.setState({
            subscriptionRequested: false,
            openModalSubscribe: false,
        })
    }

    renderIconText(iconText, i) {
        const { classes } = this.props

        return (
            <div key={i} className={classes.flexRow}>
                <div className={classes.iconLogbookBox}>
                    {iconText.children}
                </div>
                <Typography className={classes.iconlogbookText}>
                    {iconText.text}
                </Typography>
            </div>
        )
    }

    render() {
        const { classes } = this.props
        const mobile = window.innerWidth <= 500
        const iconTexts = [
            {
                text: this.displayText('myDocuments'),
                children: (
                    <AttachFile
                        classes={{ root: classes.boatIcon }}
                        style={{ color: 'white' }}
                    />
                ),
            },
            {
                text: this.displayText('myBudget'),
                children: (
                    <img
                        src={creditcard}
                        alt="creditcard"
                        title="creditcard"
                        className={classes.boatIcon}
                    />
                ),
            },
            {
                text: this.displayText('myRepair'),
                children: (
                    <img
                        src={repaircard}
                        alt="repaircard"
                        title="repaircard"
                        className={classes.boatIcon}
                    />
                ),
            },
            {
                text: this.displayText('myAlerts'),
                children: (
                    <Notifications
                        classes={{ root: classes.boatIcon }}
                        style={{ color: 'white' }}
                    />
                ),
            },
            {
                text: this.displayText('myBob'),
                children: (
                    <Book
                        classes={{ root: classes.boatIcon }}
                        style={{ color: 'white' }}
                    />
                ),
            },
        ]

        return (
            <div className={classes.page}>
                <h1>
                    <Typography className={classes.title}>
                        {this.displayText('first')}
                        <span className={classes.st}>
                            {this.displayText('st')}
                        </span>
                        <span
                            dangerouslySetInnerHTML={{
                                __html: this.displayText('titleFirstPart'),
                            }}
                        ></span>
                    </Typography>
                </h1>

                <div className={classes.icons}>
                    {iconTexts.map((iconText, i) =>
                        this.renderIconText(iconText, i),
                    )}
                </div>

                <h2>
                    <Typography className={classes.freetrial}>
                        {this.displayText('freetrial')}
                    </Typography>
                </h2>

                <LocalizedLink
                    to={AppRoute.LogBook.Pricing}
                    variant="contained"
                    color="secondary"
                    className={classes.linked}
                >
                    <Button
                        className={classes.buttonTry}
                        variant="contained"
                        color="secondary"
                        style={{ marginTop: 0 }}
                    >
                        {this.displayText('tryforfree')}
                    </Button>
                </LocalizedLink>

                <div style={{ textAlign: 'left' }}>
                    <Reviews bob />
                </div>

                <LocalizedLink
                    to={AppRoute.LogBook.Pricing}
                    variant="contained"
                    color="secondary"
                    className={classes.linked}
                >
                    <Button
                        className={classes.buttonTry}
                        variant="contained"
                        color="secondary"
                        style={{ marginTop: '40px' }}
                    >
                        {this.displayText('activeFree')}
                    </Button>
                </LocalizedLink>

                <div
                    className={
                        mobile
                            ? classes.screenViewerMobile
                            : classes.screenViewer
                    }
                >
                    <ScreenshotViewer
                        title={this.displayText('repairTitle')}
                        screenshot={repairScreen}
                        reverse
                        features={[
                            {
                                icon: styloIcon,
                                title: this.displayText('note'),
                                description: this.displayText(
                                    'noteDescription',
                                ),
                            },
                            {
                                icon: visualiseIcon,
                                title: this.displayText('find'),
                                description: this.displayText(
                                    'findDescription',
                                ),
                            },
                            {
                                icon: organiseIcon,
                                title: this.displayText('organize'),
                                description: this.displayText(
                                    'organizeDescription',
                                ),
                            },
                        ]}
                        isMobile={mobile}
                    />
                </div>

                <div
                    className={
                        mobile
                            ? classes.screenViewerMobile
                            : classes.screenViewer
                    }
                >
                    <ScreenshotViewer
                        title={this.displayText('documentsTitle')}
                        screenshot={documentsScreen}
                        features={[
                            {
                                icon: addIcon,
                                title: this.displayText('add'),
                                description: this.displayText('addDescription'),
                            },
                            {
                                icon: keepIcon,
                                title: this.displayText('keep'),
                                description: this.displayText(
                                    'keepDescription',
                                ),
                            },
                            {
                                icon: shareIcon,
                                title: this.displayText('share'),
                                description: this.displayText(
                                    'shareDescription',
                                ),
                            },
                        ]}
                        isMobile={mobile}
                    />
                </div>
                <div
                    className={
                        mobile
                            ? classes.screenViewerMobile
                            : classes.screenViewer
                    }
                >
                    <ScreenshotViewer
                        title={this.displayText('budgetTitle')}
                        screenshot={budgetScreen}
                        reverse={true}
                        features={[
                            {
                                icon: saveIcon,
                                title: this.displayText('save'),
                                description: this.displayText(
                                    'saveDescription',
                                ),
                            },
                            {
                                icon: linkIcon,
                                title: this.displayText('link'),
                                description: this.displayText(
                                    'linkDescription',
                                ),
                            },
                            {
                                icon: optimiseIcon,
                                title: this.displayText('optimise'),
                                description: this.displayText(
                                    'optimiseDescription',
                                ),
                            },
                        ]}
                        isMobile={mobile}
                    />
                </div>
                <div
                    className={
                        mobile
                            ? classes.screenViewerMobile
                            : classes.screenViewer
                    }
                >
                    <ScreenshotViewer
                        title={this.displayText('alertsTitle')}
                        screenshot={alertsScreen}
                        features={[
                            {
                                icon: selectIcon,
                                title: this.displayText('select'),
                                description: this.displayText(
                                    'selectDescription',
                                ),
                            },
                            {
                                icon: configureIcon,
                                title: this.displayText('configure'),
                                description: this.displayText(
                                    'configureDescription',
                                ),
                            },
                            {
                                icon: watchIcon,
                                title: this.displayText('watch'),
                                description: this.displayText(
                                    'watchDescription',
                                ),
                            },
                        ]}
                        isMobile={mobile}
                    />
                </div>
                <div
                    className={
                        mobile
                            ? classes.screenViewerMobile
                            : classes.screenViewer
                    }
                >
                    <ScreenshotViewer
                        title={this.displayText('bookTitle')}
                        screenshot={bookScreen}
                        reverse={true}
                        features={[
                            {
                                icon: organiseIcon,
                                title: this.displayText('organise'),
                                description: this.displayText(
                                    'organiseDescription',
                                ),
                            },
                            {
                                icon: visualiseIcon,
                                title: this.displayText('visualise'),
                                description: this.displayText(
                                    'visualiseDescription',
                                ),
                            },
                            {
                                icon: enjoyIcon,
                                title: this.displayText('enjoy'),
                                description: this.displayText(
                                    'enjoyDescription',
                                ),
                            },
                        ]}
                        isMobile={mobile}
                    />
                </div>

                <LocalizedLink
                    to={AppRoute.LogBook.Pricing}
                    variant="contained"
                    color="secondary"
                    className={classes.linked}
                >
                    <Button
                        className={classes.buttonTry}
                        color="secondary"
                        variant="contained"
                        style={{ marginTop: '20px' }}
                    >
                        {this.displayText('tryforfree30days')}
                    </Button>
                </LocalizedLink>

                <h2>
                    <Typography className={classes.secondaryTitle}>
                        {this.displayText('mentions')}
                    </Typography>
                    <hr className={classes.separator} />
                </h2>

                <Box>
                    <img
                        className={classes.pressLogos}
                        src={pressLogos}
                        alt="Logos de presse"
                        title="Logos de presse"
                    />
                </Box>

                <h2>
                    <Typography className={classes.secondaryTitle}>
                        {this.displayText('trybook')}
                    </Typography>
                    <hr className={classes.separator} />
                </h2>

                <Typography
                    className={classes.secondarySubtitle}
                    dangerouslySetInnerHTML={{
                        __html: this.displayText('createAccount'),
                    }}
                />

                <LocalizedLink
                    to={AppRoute.LogBook.Pricing}
                    variant="contained"
                    color="secondary"
                    className={classes.linked}
                >
                    <Button
                        className={classes.buttonTry}
                        variant="contained"
                        color="secondary"
                    >
                        {this.displayText('trybook')}
                    </Button>
                </LocalizedLink>

                <Typography className={classes.share}>
                    {this.displayText('shareWithFriends')}
                </Typography>

                <ShareButtons />

                <Box className={classes.SEO}>
                    <h2 className={classes.SEOtitle}>
                        {this.displayText('SEOTheFirstTitle')}
                    </h2>
                    <br />
                    {this.displayText('SEOTheFirstText')}
                    <br />
                    <br />
                    <h2 className={classes.SEOtitle}>
                        {this.displayText('SEOWhatIsItTitle')}
                    </h2>
                    <br />
                    {this.displayText('SEOWhatIsItText')}
                    <br />
                    <br />
                    <h2 className={classes.SEOtitle}>
                        {this.displayText('SEOMandatoryTitle')}
                    </h2>
                    <br />
                    {this.displayText('SEOMandatoryText')}
                    <br />
                    <br />
                    <h2 className={classes.SEOtitle}>
                        {this.displayText('SEOShouldContainTitle')}
                    </h2>
                    <br />
                    <Typography
                        className={classes.SEOTypography}
                        dangerouslySetInnerHTML={{
                            __html: this.displayText('SEOShouldContainText'),
                        }}
                    />
                    <br />
                    <h2 className={classes.SEOtitle}>
                        {this.displayText('SEOHowToTitle')}
                    </h2>
                    <br />
                    {this.displayText('SEOHowToText')}
                    <br />
                    <br />
                    <h2 className={classes.SEOtitle}>
                        {this.displayText('SEOWhyToUseTitle')}
                    </h2>
                    <br />
                    {this.displayText('SEOWhyToUseText')}
                    <br />
                    <br />
                    <h2 className={classes.SEOtitle}>
                        {this.displayText('SEOAdvantageTitle')}
                    </h2>
                    <br />
                    {this.displayText('SEOAdvantageText')}
                    <br />
                    <br />
                    <h2 className={classes.SEOtitle}>
                        {this.displayText('SEOTestTitle')}
                    </h2>
                    <br />
                    {this.displayText('SEOTestText')}
                </Box>

                <ModalConnection
                    open={this.state.openModalConnection}
                    handleClose={this.handleConnectionClose}
                />
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        boats: state.fetch.boats,
        user: state.authentication.user,
        sub: state.fetch.sub,
    }
}

export default connect(mapStateToProps)(withStyles(styles)(BoatOnBookPage))
