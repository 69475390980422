export default {
    save: {
        FR: 'Enregistrer',
        EN: 'Save',
    },
    delete: {
        FR: 'Effacer',
        EN: 'Delete',
    },
}
