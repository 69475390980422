export default {
    engine: {
        FR: 'Moteur',
        EN: 'Engine',
    },
    lightning: {
        FR: 'Eclair',
        EN: 'Lightning',
    },
    electronics: {
        FR: 'Electronique',
        EN: 'Electronics',
    },
    water: {
        FR: 'Circuit eau douce',
        EN: 'Fresh water circuit',
    },
    hull: {
        FR: 'Coque',
        EN: 'Hull',
    },
    fitting: {
        FR: 'Accastilllage et greement',
        EN: 'Fittings and rigging',
    },
    safety: {
        FR: 'Confort et sécurité',
        EN: 'Comfort and safety',
    },
}
