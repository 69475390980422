import React from 'react'
import BoatOnComponent from '../../../../common/BoatOnComponent'
import Switch from '@material-ui/core/Switch'

import { withStyles } from '@material-ui/core/styles'
import styles from '../Styles/RepairListCss'
import AddAPhotoIcon from '@material-ui/icons/AddAPhoto'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import Clear from '@material-ui/icons/Clear'
import moment from 'moment'
import dictionary from '../Dictionary/RepairListDico'
import {
    postDataBOBRepairsDate,
    postDataBOBDocuments,
} from '../../../../../actions/bob.actions'
import { connect } from 'react-redux'
import DisplayerImgModal from '../../../DisplayerImgModal'
import InputDateHours from '../../../../common/UsefullComponents/InputDateHours'

class RepairModalDate extends BoatOnComponent {
    constructor(props) {
        super(props)

        this.dictionary = dictionary
        const {
            date,
            link,
            amount,
            idTransaction,
            idDocuments,
            details,
            file,
            fileNoUrl,
            fileIDs,
            deleteFileId,
        } = this.props

        this.state = {
            deleteFileId: deleteFileId ? deleteFileId : [],
            date: date,
            link: link,
            amount: amount,
            transactionId: idTransaction,
            idDocuments: idDocuments,
            details: details ? details : '',
            file: file ? file : [],
            fileNoUrl: fileNoUrl ? fileNoUrl : [],
            fileIDs: fileIDs ? fileIDs : [],
        }

        this.save = this.save.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.clickDocument = this.clickDocument.bind(this)
        this.addDocument = this.addDocument.bind(this)
        this.deleteDocumentNoUp = this.deleteDocumentNoUp.bind(this)
        this.saveDocuments = this.saveDocuments.bind(this)
    }

    handleChange(value, key) {
        this.setState({ [key]: value })
        if (this.props.handleChangeDateValue && key == 'date')
            this.props.handleChangeDateValue(this.props.type, value)
    }

    handleChangeNumber(value, key) {
        var reg = new RegExp('^([0-9.,-])+$')
        if (reg.test(value) || value === '') {
            this.setState({ [key]: value })
        }
    }

    addDocument(event) {
        const newArray = this.state.file.slice()
        const arrayNoUrl = this.state.fileNoUrl.slice()
        for (let i = 0; i < event.target.files.length; i++) {
            if (event.target.files[i].size < 4000000) {
                newArray.push({
                    blop: URL.createObjectURL(event.target.files[i]),
                    infos: event.target.files[i],
                })
                arrayNoUrl.push(event.target.files[i])
                this.setState({ file: newArray })
                this.setState({ fileNoUrl: arrayNoUrl })
            } else {
                alert('Votre fichier ne doit pas dépasser 4 MO')
            }
        }
    }

    deleteDocumentNoUp(i) {
        let { deleteFileId } = this.state
        if (this.state.file[i] && this.state.file[i].linkImg)
            deleteFileId.push(this.state.file[i].id)
        delete this.state.file[i]
        delete this.state.fileNoUrl[i]
        if (deleteFileId.length > 0)
            this.setState({ deleteFileId: deleteFileId })
        this.setState({ file: this.state.file })
        this.setState({ fileNoUrl: this.state.fileNoUrl })
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.idDate != prevProps.idDate) {
            const {
                date,
                link,
                amount,
                idTransaction,
                idDocuments,
                details,
                file,
                fileNoUrl,
            } = this.props
            this.setState({
                date: date,
                link: link,
                amount: amount,
                idTransaction: idTransaction,
                idDocuments: idDocuments,
                details: details ? details : '',
                file: file ? file : [],
                fileNoUrl: fileNoUrl ? fileNoUrl : [],
            })
        }
    }

    saveDocuments(transactionId) {
        const {
            details,
            date,
            fileNoUrl,
            amount,
            deleteFileId,
            idDocuments,
        } = this.state
        const { repairTitle, repairType, user, boat } = this.props

        this.props.dispatch(
            postDataBOBDocuments({
                user,
                boat,
                title: repairTitle,
                details,
                date,
                type: repairType,
                transactionId,
                fileNoUrl,
                deleteFileId,
                amount: amount || '0',
                documentID: idDocuments,
            }),
        )
    }

    async save(repairID) {
        const { idDate, type, user, boat } = this.props
        const { date, amount, details, file } = this.state

        if (repairID == null) repairID = this.props.repairID

        var today = moment()
        var safeDate = date

        if (type == 'last' && today < safeDate) {
            //Permet de ne pas envoyer de date future dans date last
            safeDate = this.props.date
        } else if (type == 'next' && today.isAfter(safeDate)) {
            //Permet de ne pas envoyer de date passé dans date next
            safeDate = this.props.date
        }

        const infos = {
            repairTitle: this.props.repairTitle,
            type: this.props.repairType,
            details,
            amount,
            file,
            dateType: type,
            safeDate,
            repairID,
            idDate,
            user,
            boat,
        }

        new Promise((resolve, reject) => {
            this.props.dispatch(postDataBOBRepairsDate(infos, resolve, reject))
        }).then(e => this.saveDocuments(e.idTransaction))
    }

    clickDocument() {
        this.refs.fileUploader.click()
    }

    render() {
        const { classes, type } = this.props

        var max = '2100-01-01'
        var min = '2000-01-01'
        var today = moment().format('YYYY-MM-DD')

        if (type == 'last') {
            max = today
        } else if (type == 'next') {
            min = today
        }

        return (
            <>
                <div className={classes.espaces}>
                    <div className={classes.dateType}>
                        <Typography className={classes.textAddDate}>
                            {type == 'last'
                                ? this.displayText('dateLast')
                                : this.displayText('dateNext')}
                        </Typography>
                    </div>
                    <div className={classes.twoTextField}>
                        <InputDateHours />

                        <TextField
                            id="amount"
                            variant="outlined"
                            label={this.displayText('amount')}
                            margin="dense"
                            value={
                                this.state.amount != null
                                    ? this.state.amount
                                    : ''
                            }
                            onChange={e => {
                                this.handleChangeNumber(
                                    e.target.value,
                                    'amount',
                                )
                            }}
                            className={classes.textField}
                            InputProps={{
                                classes: {
                                    input: classes.input,
                                    marginDense: classes.marginInput,
                                },
                            }}
                            InputLabelProps={{
                                classes: {
                                    root: classes.labelInput,
                                },
                            }}
                        />
                    </div>
                </div>
                <TextField
                    id="details"
                    variant="outlined"
                    label={this.displayText('detailsDernierControle')}
                    margin="dense"
                    multiline
                    rows={2}
                    rowsMax={5}
                    value={this.state.details || ''}
                    onChange={e => {
                        this.handleChange(e.target.value, 'details')
                    }}
                    className={classes.textField}
                    InputProps={{
                        classes: {
                            input: classes.input,
                            marginDense: classes.marginInput,
                        },
                    }}
                    InputLabelProps={{
                        classes: {
                            root: classes.labelInput,
                        },
                    }}
                />

                <Typography className={classes.textAdd}>
                    {type == 'last'
                        ? this.displayText('addDocumentLast')
                        : this.displayText('addDocumentNext')}
                </Typography>

                <div className={classes.addDoc} onClick={this.clickDocument}>
                    <AddAPhotoIcon className={classes.doc} />
                </div>
                <input
                    onChange={this.addDocument}
                    multiple
                    type="file"
                    id="fileLast"
                    ref="fileUploader"
                    style={{ display: 'none' }}
                />
                <DisplayerImgModal
                    file={this.state.file}
                    deleteDocumentNoUp={this.deleteDocumentNoUp}
                />

                <TextField
                    id="type"
                    variant="outlined"
                    label={this.displayText('chosePerson')}
                    margin="dense"
                    required
                    select
                    onChange={(e, d) => {
                        this.handleChange(d.props.id, 'type')
                    }}
                    className={classes.textField}
                ></TextField>

                <div className={classes.testSwitch}>
                    <div className={classes.dateType}>
                        <Typography className={classes.textRepair}>
                            {type == 'last'
                                ? this.displayText('controlCarried')
                                : this.displayText('parts')}
                        </Typography>
                    </div>
                    {type == 'last' ? (
                        <Switch color="primary" />
                    ) : (
                        this.displayText('parts')
                    )}
                </div>

                {type == 'last' ? (
                    <div className={classes.twoTextField}>
                        <TextField
                            id="details"
                            variant="outlined"
                            label={this.displayText('reasonsDelay')}
                            margin="dense"
                            multiline
                            rows={2}
                            rowsMax={5}
                            value={this.state.details || ''}
                            onChange={e => {
                                this.handleChange(e.target.value, 'details')
                            }}
                            className={classes.textField}
                            InputProps={{
                                classes: {
                                    input: classes.input,
                                    marginDense: classes.marginInput,
                                },
                            }}
                            InputLabelProps={{
                                classes: {
                                    root: classes.labelInput,
                                },
                            }}
                        />
                    </div>
                ) : (
                    this.displayText('parts')
                )}
            </>
        )
    }
}

function mapStateToProps(state) {
    return {
        boat: state.bob.boat,
        user: state.authentication.user,
    }
}

export default connect(mapStateToProps, null, null, {
    forwardRef: true,
})(withStyles(styles)(RepairModalDate))
