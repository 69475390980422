import React from 'react'
import BoatOnComponent from '../../../../common/BoatOnComponent'
import { connect } from 'react-redux'

import { withStyles } from '@material-ui/core/styles'
import styles from '../Styles/RepairListCss'
import { API_URL } from '../../../../../services/config.service'

import RepairModalSimple from './RepairModalSimple'
import RepairModalDouble from './RepairModalDouble'

import { userService } from '../../../../../services/user.service'
import { postDataBOBRepairsV2 } from '../../../../../actions/bob.actions'

class RepairModal extends BoatOnComponent {
    constructor(props) {
        super(props)

        const { repairID, dateID, typefamily, idcheckuptype } = this.props

        this.state = {
            repairID: repairID, //Cible de l'édition, SI null: Ajouter
            dateID: dateID,

            modalResponse: null,
            name: '',
            type: 20, //Le Type d'entretien:  | Mainteance | Réparation | Aménagement |
            typefamily: typefamily || '', //La famille d'équipement:  Moteur, Electricité, etc...
            idcheckuptype: idcheckuptype, //Le checkup selectionné (si le champ équipement n'est pas libre)
        }

        this.modalLoaded = false
        this.handleChange = this.handleChange.bind(this)
        this.saveRepair = this.saveRepair.bind(this)
        this.getModalForRepair = this.getModalForRepair.bind(this)
    }

    componentDidMount() {
        this.getModalForRepair()
    }

    getModalForRepair() {
        const { dateID, name, type, typefamily, idcheckuptype } = this.state
        const { repairID, boat } = this.props //On recupere le repairID initial

        let data = new FormData()
        if (repairID) {
            data.append('idRepair', repairID)
            if (dateID) data.append('idDateRepair', dateID)
            // this.props.dispatch(
            //     fetchData(API_URL + '/getRepairForModal.php', 'modalResponse', 'POST', data)
            // )
        }
        if (type) data.append('idType', type)
        if (typefamily) data.append('idCategory', typefamily)
        if (idcheckuptype) data.append('idCheckupType', idcheckuptype)
        if (name) data.append('name', name)
        data.append('boatid', boat.BoatID)

        const modal = this
        fetch(API_URL + '/getRepairForModalv2.php', {
            method: 'POST',
            headers: userService.getAuthHeader(),
            body: data,
        })
            .then(response => {
                return response.json()
            })
            .then(json => {
                var modalResponse = json
                if (modalResponse.repairModal) {
                    var newRepairID = modalResponse.repairModal.CheckupID
                        ? modalResponse.repairModal.CheckupID
                        : this.props.repairID
                        ? this.props.repairID
                        : null
                    if (
                        !modal.modalLoaded ||
                        modal.state.repairID != newRepairID
                    ) {
                        modal.modalLoaded = true
                        modal.setState({
                            repairID: newRepairID, //Permet de changer la cible de l'édition SI la réparation selectionné existe déjà

                            modalResponse: modalResponse,
                            name: modalResponse.repairModal.titleRepair,
                            type: modalResponse.repairModal.idTypeRepair,
                            typefamily: modalResponse.repairModal.idCategory,
                            idcheckuptype:
                                modalResponse.repairModal.idcheckuptype,
                        })
                    }
                }
            })
    }

    componentDidUpdate(prevProps, prevState) {
        const { name, type, typefamily, idcheckuptype } = this.state
        if (
            prevState.name != name ||
            prevState.type != type ||
            prevState.typefamily != typefamily ||
            prevState.idcheckuptype != idcheckuptype
        ) {
            if (type && typefamily) {
                if ((type != 20 || typefamily == 6) && name)
                    this.getModalForRepair()
                else if (idcheckuptype) this.getModalForRepair()
            }
        }
    }

    saveRepair() {
        const { repairID, name, type, typefamily, idcheckuptype } = this.state
        const { boat } = this.props

        return new Promise((resolve, reject) => {
            this.props.dispatch(
                postDataBOBRepairsV2(
                    {
                        type,
                        typefamily,
                        repairID,
                        idcheckuptype,
                        name,
                        boat,
                    },
                    resolve,
                ),
            )
        })
    }

    handleChange(value, key) {
        this.setState({ [key]: value })
    }

    render() {
        const { classes, checkupTypes, checkupCategories } = this.props
        const { repairID, modalResponse } = this.state

        /*if (
            modalResponse == null ||
            checkupCategories == null ||
            checkupTypes == null
        )
            return null

        const typeModal = modalResponse.typeModal

        if (typeModal == 1) {
            return (
                <div id="modal" className={classes.paper}>
                    <RepairModalSimple
                        type={this.state.type}
                        typefamily={this.state.typefamily}
                        idcheckuptype={this.state.idcheckuptype}
                        name={this.state.name}
                        modalResponse={modalResponse}
                        repairID={repairID}
                        checkupTypes={checkupTypes}
                        checkupCategories={checkupCategories}
                        handleChange={this.handleChange}
                        closeModal={this.props.closeModal}
                        saveRepair={this.saveRepair}
                    ></RepairModalSimple>
                </div>
            )
        } else if (typeModal == 2) {
            return (
                <div id="modal" className={classes.paper}>
                    <RepairModalDouble
                        type={this.state.type}
                        typefamily={this.state.typefamily}
                        idcheckuptype={this.state.idcheckuptype}
                        name={this.state.name}
                        modalResponse={modalResponse}
                        repairID={repairID}
                        checkupTypes={checkupTypes}
                        checkupCategories={checkupCategories}
                        handleChange={this.handleChange}
                        closeModal={this.props.closeModal}
                        saveRepair={this.saveRepair}
                    ></RepairModalDouble>
                </div>
            )
        }*/
        return (
            <div id="modal" className={classes.paper}>
                <RepairModalDouble
                    type={this.state.type}
                    typefamily={this.state.typefamily}
                    idcheckuptype={this.state.idcheckuptype}
                    name={this.state.name}
                    modalResponse={modalResponse}
                    repairID={repairID}
                    checkupTypes={checkupTypes}
                    checkupCategories={checkupCategories}
                    handleChange={this.handleChange}
                    closeModal={this.props.closeModal}
                    saveRepair={this.saveRepair}
                ></RepairModalDouble>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        modalResponse: state.fetch.modalResponse,
        boat: state.bob.boat,
    }
}

export default connect(mapStateToProps)(withStyles(styles)(RepairModal))
