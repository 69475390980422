export default {
    date: {
        FR: 'Date',
        EN: 'Date',
    },
    or: {
        FR: 'ou',
        EN: 'or',
    },
    hours: {
        FR: "nombre d'heures",
        EN: 'Hours',
    },
    seeAll: {
        FR: 'Voir tout',
        EN: 'See all',
    },
    operation: {
        FR: "Renseigner l'opération à effectuer",
        EN: 'Enter the operation to be performed',
    },
    chooseFamilyEquipment: {
        FR: "Choisir une famille d'équipements",
        EN: 'Choose a family of equipment',
    },
    chooseEquipment: {
        FR: 'Choisir un équipement',
        EN: 'Choose an equipment',
    },
    choosePart: {
        FR: 'Choisir une pièce',
        EN: 'Choose a part',
    },
}
