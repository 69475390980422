export default {
    book: {
        FR: 'Réserver',
        EN: 'Book',
    },
    sendMessage: {
        FR: 'Envoyer un message',
        EN: 'Send a message',
    },
    euroPerDay: {
        FR: '€ / jour',
        EN: '€ / day',
    },
    euro: {
        FR: '€',
        EN: '€',
    },
    serviceFees: {
        FR: 'Frais de service',
        EN: 'Service fees',
    },
    total: {
        FR: 'Total TTC',
        EN: 'Total VAT included',
    },
    totalMensualisation: {
        FR: 'Prix par mois TTC',
        EN: 'Price per month VAT included',
    },
    tva: {
        FR: 'TVA',
        EN: 'TVA',
    },
    priceFor: {
        FR: 'Prix pour',
        EN: 'Price for',
    },
    days: {
        FR: 'jour(s)',
        EN: 'day(s)',
    },
    startDate: {
        FR: 'Début de la location',
        EN: 'Start',
    },
    endDate: {
        FR: 'Fin de la location',
        EN: 'End',
    },
    empty: {
        FR: "Aujourd'hui",
        EN: 'Today',
    },
    lengthNeed: {
        FR: 'Longueur souhaitée',
        EN: 'Length needed',
    },
    widthNeed: {
        FR: 'Largeur souhaitée',
        EN: 'Width needed',
    },
    surfaceNeed: {
        FR: 'Surface souhaitée',
        EN: 'Space needed',
    },
    metreCarre: {
        FR: 'm²',
        EN: 'sqm',
    },
    surfaceNotAvailable: {
        FR:
            'Mince, il ne reste pas autant de surface disponible pour les dates que vous avez choisies! Changez vos dates ou réduisez la longueur et la largeur pour pouvoir reserver une place sur ce site',
        EN:
            "Damn, there's not as much space left for the dates you've chosen! Change your dates or reduce the length and width to be able to reserve a space on this site.",
    },
    twoDaysMinimum: {
        FR:
            "Vous n'allez pas rester si peu de temps ! Sélectionnez au moins deux jours consécutifs pour effectuer votre réservation.",
        EN:
            "You won't stay so short! Select at least two consecutive days to make your reservation.",
    },
    perDay: {
        FR: 'Par jour',
        EN: 'Per day',
    },
    perMonth: {
        FR: 'Par mois',
        EN: 'Per month',
    },
    benefit: {
        FR:
            'Pour bénéficier du tarif annuel, vous devez vous engagez pour un minimum de 12 mois. Néanmoins, vous pouvez choisir une durée de location plus courte ci-dessous:',
        EN:
            'To benefit from the annual rate, you must commit for a minimum of 12 months. However, you can choose a shorter rental period below:',
    },
    startDateMonth: {
        FR: 'Date de début',
        EN: 'Start Date',
    },
    duration: {
        FR: 'Durée (mois) :',
        EN: 'Duration (months) :',
    },
    m: {
        FR: 'm',
        EN: 'm',
    },
    sqm: {
        FR: 'm²',
        EN: 'sqm',
    },
}
