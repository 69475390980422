export default theme => ({
    icon: {
        color: '#fff',
    },
    iconMenu: {
        color: '#fff',
        width: '30px',
        height: '30px',
    },
    iconRepairMenu: {
        color: '#fff',
        width: '23px',
        height: '23px',
    },
    title: {
        fontSize: '16px',
    },
    root: Object.assign({ width: '100%' }, theme.flex.rowSpacebetween, {
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column-reverse',
        },
    }),
    content: {
        width: '100%',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            marginTop: '20px',
        },
    },
})
