export default theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        position: 'relative',
        [theme.breakpoints.down('sm')]: {
            padding: 0,
            marginBottom: '25px',
        },
    },
    textAndField: {
        display: 'flex',
        flexDirection: 'row',
        margin: '10px',
        marginRight: '0',
        marginLeft: '0',
        width: 'calc(100%)',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            margin: 0,
        },
    },
    st: {
        verticalAlign: 'super',
        fontSize: '70%',
    },
    text: {
        width: '25%',
        margin: 'auto',
        textAlign: 'right',
        fontSize: '16px',
        [theme.breakpoints.down('sm')]: {
            textAlign: 'left',
            width: '100%',
        },
    },
    field: {
        width: '70%',
        margin: 'auto',
        fontSize: '16px',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
    popuptext: {
        textAlign: 'center',
        paddingTop: '10px',
        paddingBottom: '10px',
        zIndex: '1',
        [theme.breakpoints.down('sm')]: {
            fontSize: '16px',
        },
        whiteSpace: 'pre-line',
    },
    texts: {
        width: '90%',
        margin: 'auto',
        marginTop: '13vw',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        padding: '10px',
        position: 'relative',
        [theme.breakpoints.down('sm')]: {
            padding: 0,
            width: 'calc(100% - 10px)',
        },
        backgroundColor: '#fff',
        border: '5px solid #3040a0',
        boxShadow: '0px 1px 20px 0.1px #000',
        borderRadius: 5,
    },
    all: {
        backgroundColor: 'hsla(231, 9%, 55%, 0.4)',
        zIndex: '11000001',
        width: '100%',
        height: '100%',
        marginBottom: '5px',
        [theme.breakpoints.down('sm')]: {
            backgroundColor: '#fff',
        },
    },
    changeButton: {
        width: '50%',
        margin: '20px',
    },
    firstPart: {
        display: 'flex',
        flexDirection: 'row',
        width: '50%',
        justifyContent: 'space-between',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            width: '100%',
        },
    },
    secondPart: {
        display: 'flex',
        flexDirection: 'row',
        width: '50%',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            marginTop: '10px',
            width: '100%',
        },
    },
    lineWithMultiField: {
        width: '40%',
        margin: 'auto',
        fontSize: '16px',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
    lineWithMultiTextLeft: {
        width: '50%',
        margin: 'auto',
        textAlign: 'right',
        fontSize: '16px',
        [theme.breakpoints.down('sm')]: {
            textAlign: 'left',
            width: '100%',
        },
    },
    lineWithMultiTextRight: {
        width: '50%',
        margin: 'auto',
        textAlign: 'right',
        fontSize: '16px',
        [theme.breakpoints.down('sm')]: {
            textAlign: 'left',
            width: '100%',
        },
    },
    lineWithMultiTextCenter: {
        width: '50%',
        marginTop: '2%',
        marginBottom: '2%',
        marginLeft: '30%',
        textAlign: 'center',
        fontSize: '20px',
        [theme.breakpoints.down('sm')]: {
            textAlign: 'left',
            width: '100%',
        },
    },
    infos: Object.assign(
        {
            display: 'flex',
            flexDirection: 'column',
            margin: '10px',
            marginBottom: '20px',
            paddingBottom: '15px',
        },
        theme.boxBoatOn,
        {
            width: 'calc(100% - 80px)',
            [theme.breakpoints.down('sm')]: {
                margin: 0,
            },
        },
    ),
    newBoatButton: {
        color: 'black',
        '&:hover': {
            backgroundColor: '#3040a0',
            color: 'white',
        },
    },
    title: {
        marginLeft: '10px',
        fontWeight: '500',
        [theme.breakpoints.down('sm')]: {
            marginLeft: 0,
        },
    },
    boat: {
        width: 'calc(100% - 10px)',
        margin: '5px',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            margin: 0,
        },
    },
    titleAndButton: Object.assign(
        {
            alignItems: 'center',
            marginBottom: '20px',
            marginRight: 'auto',
            width: '100%',
        },
        theme.flex.rowSpacebetween,
        {
            [theme.breakpoints.down('sm')]: {
                flexDirection: 'column',
                alignItems: 'flex-start',
            },
        },
    ),
    buttonAddCenter: Object.assign(
        { alignItems: 'center', margin: '10px', marginTop: '15px' },
        theme.flex.rowSpacebetween,
    ),
    buttonAdd: {
        margin: '30px 0',
        padding: '10px',
        width: '280px',
        float: 'right',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            float: 'center',
            fontSize: '0.8rem',
            marginTop: '15px',
            marginBottom: '15px',
        },
    },
    bottom: {
        margin: '5px',
        width: 'calc(100% - 80px)',
        padding: '0 20px',
        [theme.breakpoints.down('sm')]: {
            width: 'calc(100% - 40px)',
            margin: 0,
        },
    },
    searchAddress: {
        width: '70%',
        margin: 'auto',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
    buttonSvg: {
        fontSize: '30px!important',
    },
})
