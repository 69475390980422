export default theme => ({
    root: Object.assign({}, theme.boxBoatOn, {
        display: 'flex',
        flexDirection: 'column',
        width: '90%',
        border: '1px solid #c4c4c4',
        marginTop: '20px',
        position: 'sticky',
        top: 110,
        bottom: 0,
        padding: '5%',
    }),
    box: {
        border: '1px solid #C4C4C4',
        paddingTop: '3%',
        paddingBottom: '3%',
        paddingLeft: '5%',
        paddingRight: '5%',
    },
    tabs: {
        minWidth: '200px',
        textAlign: 'center',
        fontSize: '15px',
        [theme.breakpoints.down('sm')]: {
            minWidth: '150px',
            display: 'block',
        },
    },
    button: {
        marginBottom: 0,
        marginTop: '15px',
    },
    buttonMessage: {
        fontSize: '20px',
        textTransform: 'lowercase',
    },
    dates: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '100%',
    },
    dateRight: {
        display: 'flex',
        flexDirection: 'column',
        marginLeft: '2.5%',
        width: '50%',
    },
    dateLeft: {
        display: 'flex',
        flexDirection: 'column',
        marginRight: '2.5%',
        width: '50%',
    },
    dateFont: {
        fontSize: '16px',
    },
    prices: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        paddingTop: '5px',
        paddingBottom: '5px',
    },
    priceFont: {
        color: '#000',
    },
    indispo: {
        color: 'red',
        marginTop: '10px',
    },
    divider: {
        backgroundColor: '#c4c4c4',
        marginTop: '20px',
        marginBottom: '20px',
    },
    textField: {
        marginLeft: '5%',
    },
    dayPrice: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        paddingTop: '20px',
        paddingBottom: '20px',
    },
    labelText: {
        margin: 'auto',
        marginLeft: '2%',
        marginRight: 'auto',
        width: '50%',
    },
    paddingChange: {
        padding: 10.5,
    },
    flexColumnCenter: theme.flexColumnCenter,
    field: {
        marginLeft: 'auto',
        marginRight: '2%',
    },
    iconInfo: {
        fontSize: '18px',
        width: '25px',
        height: 'auto',
    },
})
