export default {
    boatOnBook: {
        FR:
            'Le BoatOn Book est le carnet de santé de votre bateau ! Retrouvez-y tous les événements importants tels que: entretiens passés et à venir, sorties que vous avez renseignées, dépenses et revenus.',
        EN:
            "The BoatOn Book is your boat's health book! Here you can find all the important events such as: past and future checkups,cruises, expenses and income.",
    },
    allNeed: {
        FR:
            'entretiens passés et à venir, sorties que vous avez renseignées, dépenses et revenus.',
        EN:
            'past and future interviews, outings that you entered, expenses and income.',
    },
    noBoat: {
        FR:
            'Bravo, vous pouvez dès à présent profiter du BoatOn Book ! Commencez par ajouter les informations sur votre bateau en cliquant sur le bouton ci-dessous.',
        EN:
            'Congratulations, you can now enjoy the BoatOn Book! Start by adding information about your boat by clicking on the button below.',
    },
    st: {
        FR: 'er',
        EN: 'st',
    },
    first: {
        FR: 'Le 1',
        EN: 'The 1',
    },
}
