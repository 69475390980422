import React from 'react'
import { connect } from 'react-redux'

import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import Divider from '@material-ui/core/Divider'
import TextField from '@material-ui/core/TextField'
import AppBar from '@material-ui/core/AppBar'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import InputAdornment from '@material-ui/core/InputAdornment'

import CryptoJS from 'crypto-js'
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers'
import dateUtils from '@date-io/date-fns'
import Locale from 'date-fns/locale/fr'
import styles from './Styles/ParkingMenuCss'
import dictionary from './Dictionary/ParkingMenuDico'
import ModalConnection from '../common/UsefullComponents/ModalConnection/ModalConnection'
import { history } from '../../helpers'
import BoatOnComponent from '../common/BoatOnComponent'
import { postData } from '../../actions/api.actions'
import { API_URL } from '../../services/config.service'
import AppRoute from '../../constants/AppRoute'
import { fetchData } from '../../actions/api.actions'
import EventIcon from '@material-ui/icons/Event'
import AccessTimeIcon from '@material-ui/icons/AccessTime'
import Icon from '@material-ui/core/Icon'

class ParkingMenu extends BoatOnComponent {
    constructor(props) {
        super(props)
        this.dictionary = dictionary

        this.state = {
            surface:
                props.filter && props.filter.surface
                    ? props.filter.surface
                    : {},
            startDate:
                props.filter && props.filter.dates && props.filter.dates[0]
                    ? props.filter.dates[0]
                    : new Date(),
            endDate:
                props.filter && props.filter.dates && props.filter.dates[1]
                    ? props.filter.dates[1]
                    : new Date(Date.now() + 48 * 60 * 60 * 1000),
            available: true,
            checkAvailable: false,
            openModalConnection: false,
            promo: '',
            length:
                props.filter &&
                props.filter.surface &&
                props.filter.surface.length
                    ? props.filter.surface.length
                    : 1,
            width:
                props.filter &&
                props.filter.surface &&
                props.filter.surface.width
                    ? props.filter.surface.width
                    : 1,
            mensualisation: 0,
            Engagement: 12,
            updated: false,
            parking: null,
        }

        this.handleClose = this.handleClose.bind(this)
        this.handleChangeSurface = this.handleChangeSurface.bind(this)
        this.bookParking = this.bookParking.bind(this)
        this.getParkingPrice = this.getParkingPrice.bind(this)
        this.handleChangeMenu = this.handleChangeMenu.bind(this)
    }

    componentWillMount() {
        if (this.props.dates) {
            if (this.props.dates.startDate)
                this.setState({ startDate: this.props.dates.startDate })
            if (this.props.dates.endDate)
                this.setState({ endDate: this.props.dates.endDate })
        }
        this.setState({ parking: this.props.parking })
    }

    componentDidMount() {
        this.getParkingPrice({})
    }

    componentDidUpdate() {
        if (this.state.parking !== this.props.parking) {
            this.getParkingPrice({})
            this.setState({ parking: this.props.parking })
        }
        if (
            this.props.availableSearch &&
            this.props.availableSearch.success === true &&
            this.state.checkAvailable &&
            this.props.availableSearch.areaRequired == null
        ) {
            this.bookParking()
        }
    }

    nbDaySelected() {
        const startDate = new Date(this.state.startDate)
        const endDate = new Date(this.state.endDate)
        const oneDay = 24 * 60 * 60 * 1000
        return (
            Math.round(
                Math.abs((startDate.getTime() - endDate.getTime()) / oneDay),
            ) + 1
        )
    }

    handleChangeSurface(value, key) {
        // si le step === 0.5 dans ce cas la on check bien que c'est un float, si non check si c'est bien un int et enfin qu'il est entre min et max
        if (/^[+-]?([0-9]+([.][0,5]|[.])?)$/g.test(value) || value === '') {
            this.setState({ [key]: value }, () => {
                this.getParkingPrice({})
            })
        }
    }

    handleChange(value, key) {
        const { startDate, endDate } = this.state

        if (key === 'startDate') {
            if (Date.parse(value) > Date.parse(endDate)) {
                const newState = {
                    endDate: new Date(
                        Date.parse(value) + 2 * 24 * 60 * 60 * 1000,
                    ),
                    [key]: value,
                }
                this.setState(newState)
                this.getParkingPrice(newState)
                return
            }
        }
        if (key === 'endDate') {
            if (Date.parse(startDate) > Date.parse(value)) {
                const newState = {
                    startDate: value,
                    [key]: value,
                }
                this.setState(newState)
                this.getParkingPrice(newState)
                return
            }
        }
        this.setState({ [key]: value })
        this.getParkingPrice({ [key]: value })
    }

    handleChangeMenu(event, mensualisation) {
        this.setState({ mensualisation })
        this.getParkingPrice({ mensualisation })
    }

    handleClose(mensualisation) {
        const { user } = this.props
        this.setState({ openModalConnection: false })
        if (user && user.UserID) {
            this.available(mensualisation)
        }
    }

    bookParking() {
        const { parking, parkingPictures } = this.props
        const { user, availableSearch } = this.props
        let {
            startDate,
            endDate,
            Engagement,
            mensualisation,
            width,
            length,
        } = this.state
        startDate = startDate || new Date()
        endDate = endDate || new Date(Date.now() + 24 * 60 * 60 * 1000)

        if (this.state.checkAvailable) {
            if (mensualisation === 0) {
                if (
                    availableSearch &&
                    availableSearch.success &&
                    user &&
                    user.UserID
                ) {
                    localStorage.setItem(
                        'bookingInfo',
                        CryptoJS.AES.encrypt(
                            JSON.stringify({
                                state: 0,
                                parking,
                                parkingPictures,
                                dates: { startDate, endDate, Engagement },
                                nbDay: this.nbDaySelected(),
                                mensualisation,
                                length: length,
                                width: width,
                            }),
                            'BoatOnDTCFGVBHJNLgjh345FHVBJHKNjkn6789BoatOn',
                        ),
                    )
                    //history.push('/booking')
                    this.historyPush(history, AppRoute.Booking)
                } else if (availableSearch && availableSearch.success) {
                    this.setState({ openModalConnection: true })
                } else {
                    this.forceUpdate()
                }
            } else if (mensualisation === 1) {
                if (
                    availableSearch &&
                    availableSearch.success &&
                    user &&
                    user.UserID
                ) {
                    localStorage.setItem(
                        'bookingInfo',
                        CryptoJS.AES.encrypt(
                            JSON.stringify({
                                state: 1,
                                parking,
                                parkingPictures,
                                dates: { startDate, endDate, Engagement },
                                PricePerMonth: parking.PricePerMonth,
                                mensualisation,
                                length: length,
                                width: width,
                            }),
                            'BoatOnDTCFGVBHJNLgjh345FHVBJHKNjkn6789BoatOn',
                        ),
                    )
                    //history.push('/booking')
                    this.historyPush(history, AppRoute.Booking)
                } else if (availableSearch && availableSearch.success) {
                    this.setState({ openModalConnection: true })
                } else {
                    this.forceUpdate()
                }
            }
            this.setState({ checkAvailable: false })
        }
    }

    available(mensualisation) {
        const { startDate, endDate, Engagement, width, length } = this.state
        const { ParkingID } = this.props.parking

        const data = new FormData()
        data.append('parkingid', ParkingID)
        data.append('parkingLength', length)
        data.append('parkingWidth', width)
        data.append('start', new Date(startDate).toISOString())
        data.append('finish', new Date(endDate).toISOString())
        if (mensualisation) data.append('Engagement', Engagement)

        this.props.dispatch(
            fetchData(
                API_URL + '/parkingOrderIsPossible.php',
                'availableSearch',
                'POST',
                data,
            ),
        )
        this.setState({ checkAvailable: true })
        this.setState({ mensualisation: mensualisation })
    }

    getParkingPrice(param) {
        const data = new FormData()
        const state = { ...this.state, ...param }
        const { ParkingID } = this.props.parking
        const {
            Engagement,
            endDate,
            startDate,
            mensualisation,
            width,
            length,
        } = state
        data.append('startdate', Date.parse(startDate))
        data.append('enddate', Date.parse(endDate))
        data.append('parkingID', ParkingID)
        if (Engagement) {
            data.append('mensualisation', mensualisation)
            data.append('Engagement', Engagement)
        }
        if (width) data.append('width', width)
        if (length) data.append('length', length)
        this.props.dispatch(postData(API_URL + '/parkinggetprice.php', data))
    }

    renderSurface() {
        const { classes } = this.props
        const { length, width } = this.state

        return (
            <div className={classes.flexColumnCenter}>
                <div className={classes.dates}>
                    <Typography className={classes.labelText}>
                        {this.displayText('lengthNeed')}
                    </Typography>
                    <TextField
                        className={classes.field}
                        type="text"
                        variant="outlined"
                        value={length}
                        margin="dense"
                        placeholder="1"
                        onChange={e => {
                            this.handleChangeSurface(e.target.value, 'length')
                        }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <Icon className={classes.iconInfo}>
                                        {this.displayText('m')}
                                    </Icon>
                                </InputAdornment>
                            ),
                        }}
                    />
                </div>
                <div className={classes.dates}>
                    <Typography className={classes.labelText}>
                        {this.displayText('widthNeed')}
                    </Typography>
                    <TextField
                        className={classes.field}
                        type="text"
                        margin="dense"
                        variant="outlined"
                        value={width}
                        placeholder="1"
                        onChange={e => {
                            this.handleChangeSurface(e.target.value, 'width')
                        }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <Icon className={classes.iconInfo}>
                                        {this.displayText('m')}
                                    </Icon>
                                </InputAdornment>
                            ),
                        }}
                    />
                </div>
                <div className={classes.dates}>
                    <Typography
                        style={{ color: 'rgba(0, 0, 0, 0.38)' }}
                        className={classes.labelText}
                    >
                        {this.displayText('surfaceNeed')}
                    </Typography>
                    <TextField
                        className={classes.field}
                        type="text"
                        variant="outlined"
                        value={length * width}
                        margin="dense"
                        disabled
                        placeholder="1"
                        onChange={e => {
                            this.handleChangeSurface(e.target.value, 'length')
                        }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <Icon className={classes.iconInfo}>
                                        {this.displayText('sqm')}
                                    </Icon>
                                </InputAdornment>
                            ),
                        }}
                    />
                </div>
                <Divider className={classes.divider} />
            </div>
        )
    }

    render() {
        const { classes } = this.props
        const { mensualisation, width, length } = this.state
        let { parkingPrice } = this.props

        if (
            this.state.endDate.getDate() - this.state.startDate.getDate() < 2 &&
            this.state.startDate.getMonth() === this.state.endDate.getMonth() &&
            this.state.startDate.getFullYear() ===
                this.state.endDate.getFullYear() &&
            mensualisation === 0
        )
            parkingPrice = 0

        return (
            <div className={classes.root}>
                <AppBar
                    position="static"
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        marginBottom: '20px',
                        backgroundColor: 'white',
                        color: 'black',
                    }}
                >
                    <Tabs
                        value={mensualisation}
                        onChange={this.handleChangeMenu}
                    >
                        <Tab
                            label={this.displayText('perDay')}
                            className={classes.tabs}
                        />
                        <Tab
                            label={this.displayText('perMonth')}
                            className={classes.tabs}
                        />
                    </Tabs>
                </AppBar>
                {mensualisation === 0 ? (
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                        }}
                    >
                        {this.renderSurface()}
                        <div className={classes.dates}>
                            <Typography
                                style={{
                                    margin: 'auto',
                                    marginLeft: '2%',
                                    marginRight: 'auto',
                                    width: '50%',
                                }}
                            >
                                {this.displayText('startDate')}
                            </Typography>
                            <MuiPickersUtilsProvider
                                utils={dateUtils}
                                locale={Locale}
                            >
                                <DatePicker
                                    emptyLabel={this.displayText('empty')}
                                    margin="dense"
                                    cancelLabel="Annuler"
                                    okLabel="Confirmer"
                                    disableToolbar
                                    maxDate={new Date().setFullYear(
                                        new Date().getFullYear() + 1,
                                    )}
                                    label={this.displayText('startDate')}
                                    onChange={date => {
                                        this.handleChange(date, 'startDate')
                                    }}
                                    value={this.state.startDate}
                                    format="dd/MM/yyyy"
                                    inputVariant="outlined"
                                    style={{
                                        marginLeft: 'auto',
                                        marginRight: '2%',
                                    }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <EventIcon
                                                    className={classes.iconInfo}
                                                />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </MuiPickersUtilsProvider>
                        </div>
                        <div className={classes.dates}>
                            <Typography
                                style={{
                                    margin: 'auto',
                                    marginLeft: '2%',
                                    marginRight: 'auto',
                                    width: '50%',
                                }}
                            >
                                {this.displayText('endDate')}
                            </Typography>
                            <MuiPickersUtilsProvider
                                utils={dateUtils}
                                locale={Locale}
                            >
                                <DatePicker
                                    emptyLabel={this.displayText('empty')}
                                    margin="dense"
                                    cancelLabel="Annuler"
                                    okLabel="Confirmer"
                                    disableToolbar
                                    label={this.displayText('endDate')}
                                    onChange={date => {
                                        this.handleChange(date, 'endDate')
                                    }}
                                    value={this.state.endDate}
                                    format="dd/MM/yyyy"
                                    inputVariant="outlined"
                                    style={{
                                        marginLeft: 'auto',
                                        marginRight: '2%',
                                    }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <EventIcon
                                                    className={classes.iconInfo}
                                                />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </MuiPickersUtilsProvider>
                        </div>
                        {this.state.checkAvailable &&
                        this.props.availableSearch &&
                        this.props.availableSearch.success === false ? (
                            <Typography className={classes.indispo}>
                                {this.displayText('surfaceNotAvailable')}
                            </Typography>
                        ) : (
                            <div />
                        )}
                        {parkingPrice === 0 ? (
                            <Typography className={classes.indispo}>
                                {this.displayText('twoDaysMinimum')}.
                            </Typography>
                        ) : (
                            <></>
                        )}
                        <Divider className={classes.divider} />
                        <div className={classes.prices}>
                            <Typography
                                className={classes.priceFont}
                                style={{ fontWeight: '500' }}
                            >
                                {this.displayText('total')} :
                            </Typography>
                            &nbsp;
                            <Typography
                                className={classes.priceFont}
                                style={{ fontWeight: '500' }}
                            >
                                {parkingPrice.toFixed(2)}{' '}
                                {this.displayText('euro')}
                            </Typography>
                        </div>
                        <Button
                            className={classes.button}
                            variant="contained"
                            color="secondary"
                            onClick={() => this.available(mensualisation)}
                        >
                            {this.displayText('book')}
                        </Button>
                        <ModalConnection
                            open={this.state.openModalConnection}
                            handleClose={() => {
                                this.handleClose(mensualisation)
                            }}
                        />
                    </div>
                ) : (
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                        }}
                    >
                        {this.renderSurface()}
                        <Typography
                            style={{
                                textAlign: 'left',
                                fontSize: '12',
                                marginBottom: '10px',
                            }}
                        >
                            {this.displayText('benefit')}
                        </Typography>
                        <div className={classes.dates}>
                            <Typography
                                style={{
                                    margin: 'auto',
                                    marginLeft: '2%',
                                    marginRight: 'auto',
                                    width: '50%',
                                }}
                            >
                                {this.displayText('startDateMonth')}
                            </Typography>
                            <MuiPickersUtilsProvider
                                utils={dateUtils}
                                locale={Locale}
                            >
                                <DatePicker
                                    emptyLabel={this.displayText('empty')}
                                    margin="dense"
                                    disableToolbar
                                    cancelLabel="Annuler"
                                    okLabel="Confirmer"
                                    maxDate={new Date().setFullYear(
                                        new Date().getFullYear() + 1,
                                    )}
                                    label={this.displayText('startDate')}
                                    onChange={date => {
                                        this.handleChange(date, 'startDate')
                                    }}
                                    value={this.state.startDate}
                                    format="dd/MM/yyyy"
                                    inputVariant="outlined"
                                    style={{
                                        marginLeft: 'auto',
                                        marginRight: '2%',
                                    }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <EventIcon
                                                    className={classes.iconInfo}
                                                />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </MuiPickersUtilsProvider>
                        </div>
                        <div className={classes.dates}>
                            <Typography
                                style={{
                                    margin: 'auto',
                                    marginLeft: '2%',
                                    marginRight: 'auto',
                                    width: '50%',
                                }}
                            >
                                {this.displayText('duration')}
                            </Typography>
                            <TextField
                                id="Engagement"
                                margin="dense"
                                type="number"
                                required
                                variant="outlined"
                                placeholder="12"
                                value={this.state.Engagement || 12}
                                onChange={e => {
                                    if (e.target.value > 0)
                                        this.handleChange(
                                            e.target.value,
                                            'Engagement',
                                        )
                                }}
                                style={{
                                    marginLeft: 'auto',
                                    marginRight: '2%',
                                }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <AccessTimeIcon
                                                className={classes.iconInfo}
                                            />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </div>
                        {this.state.checkAvailable &&
                        this.props.availableSearch &&
                        this.props.availableSearch.success === false ? (
                            <Typography className={classes.indispo}>
                                {this.displayText('surfaceNotAvailable')}
                            </Typography>
                        ) : (
                            <div />
                        )}
                        {parkingPrice === 0 ? (
                            <Typography className={classes.indispo}>
                                {this.displayText('twoDaysMinimum')}
                            </Typography>
                        ) : (
                            <></>
                        )}
                        <Divider className={classes.divider} />
                        <div className={classes.prices}>
                            <Typography
                                className={classes.priceFont}
                                style={{ fontWeight: '500' }}
                            >
                                {this.displayText('totalMensualisation')} :
                            </Typography>
                            &nbsp;
                            <Typography
                                className={classes.priceFont}
                                style={{ fontWeight: '500' }}
                            >
                                {parkingPrice.toFixed(2)}{' '}
                                {this.displayText('euro')}
                            </Typography>
                        </div>
                        <Button
                            className={classes.button}
                            variant="contained"
                            color="secondary"
                            onClick={() => this.available(mensualisation)}
                        >
                            {this.displayText('book')}
                        </Button>
                        <ModalConnection
                            open={this.state.openModalConnection}
                            handleClose={() => {
                                this.handleClose(mensualisation)
                            }}
                        />
                    </div>
                )}
            </div>
        )
    }
}

function getTotalPrice(state) {
    return state.post.datas && state.post.datas.totalPrice
        ? state.post.datas.totalPrice
        : 0
}

function mapStateToProps(state) {
    return {
        user: state.authentication.user,
        parkingPrice: getTotalPrice(state),
        filter: state.filter,
        availableSearch: state.fetch.availableSearch,
    }
}

export default connect(mapStateToProps)(withStyles(styles)(ParkingMenu))
