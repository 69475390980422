import React from 'react'
import BoatOnComponent from '../../../common/BoatOnComponent'
import { connect } from 'react-redux'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'
import styles from './Styles/PricingPageCss'
import dictionary from './Dictionary/PricingPageDico'
import ModalConnection from '../../../common/UsefullComponents/ModalConnection/ModalConnection'
import ReactGA from 'react-ga'
import ReactPixel from 'react-facebook-pixel'
import { STRIPE_PUBLIC_KEY } from '../../../../services/config.service'
import ModalSubscribe from '../../../common/UsefullComponents/ModalConnection/ModalSubscribe'

import Notifications from '@material-ui/icons/Notifications'
import AttachFile from '@material-ui/icons/AttachFile'
import creditcard from '../../../../images/creditcard.png'
import repaircard from '../../../../images/repair.png'
import Book from '@material-ui/icons/Book'
import {
    Card,
    CardContent,
    CardActions,
    Button,
    Divider,
} from '@material-ui/core'
import CheckIcon from '@material-ui/icons/Check'
import { history } from '../../../../helpers'
import AppRoute from '../../../../constants/AppRoute'

class PricingPage extends BoatOnComponent {
    constructor(props) {
        super(props)
        this.dictionary = dictionary

        ReactGA.initialize('UA-93941518-1')
        ReactGA.pageview('/my-boat-logbook/')

        ReactPixel.init('181677145678471', null, {
            autoConfig: true,
            debug: false,
        })
        ReactPixel.pageView()

        this.state = {
            openModalConnection: false,
            openModalNoBoat: false,
            openModalNothing: false,
            subscribeType: 0,
            update: 0,
            yearShow: null,
            fullTab: null,
            spendings: null,
            checkups: null,
            stripe: null,
        }

        this.handleClose = this.handleClose.bind(this)
        this.handleCloseConnect = this.handleCloseConnect.bind(this)
        this.subscribeToBoatonBook = this.subscribeToBoatonBook.bind(this)
    }

    componentDidMount() {
        window.scrollTo(0, 0)
        if (window.Stripe) {
            this.setState({ stripe: window.Stripe(STRIPE_PUBLIC_KEY) })
        } else {
            document
                .querySelector('#stripe-js')
                .addEventListener('load', () => {
                    // Create Stripe instance once Stripe.js loads
                    this.setState({ stripe: window.Stripe(STRIPE_PUBLIC_KEY) })
                })
        }
    }

    handleClose($key) {
        this.setState({
            openModalConnection: false,
            openModalNoBoat: false,
            openModalNothing: false,
            openModalSubscribe: false,
        })
    }

    handleCloseConnect($key) {
        const { user } = this.props
        if (user && user.LogbookSubscribed !== '1') {
            this.setState({
                openModalConnection: false,
                openModalNoBoat: false,
                openModalNothing: false,
                openModalSubscribe: true,
            })
        } else if (user && user.LogbookSubscribed === '1') {
            this.historyPush(history, AppRoute.LogBook.Home)
        } else {
            this.setState({
                openModalConnection: false,
                openModalNoBoat: false,
                openModalNothing: false,
                openModalSubscribe: false,
            })
        }
    }

    getYear() {
        return 1900 + new Date().getYear()
    }

    subscribeToBoatonBook(subscribeType = 0) {
        const { user } = this.props
        if (!user) {
            this.setState({
                openModalConnection: true,
                subscribeType: subscribeType,
            })
        } else {
            this.setState({
                openModalSubscribe: true,
                subscribeType: subscribeType,
            })
        }
    }

    render() {
        const { classes } = this.props
        const {
            openModalConnection,
            openModalSubscribe,
            subscribeType,
        } = this.state
        this.yearEl = []

        return (
            <div className={classes.root} key="alerts">
                <div className={classes.main}>
                    <div className={classes.header}>
                        <Typography
                            variant="h1"
                            classes={{ root: classes.title }}
                        >
                            {this.displayText('first')}
                            <span className={classes.st}>
                                {this.displayText('st')}
                            </span>
                            {this.displayText('boatOnBook')}
                        </Typography>
                        <div className={classes.icons}>
                            <IconLogbookBox parent={this} text="documents">
                                <AttachFile
                                    classes={{ root: classes.boatIcon }}
                                    style={{ color: 'white' }}
                                />
                            </IconLogbookBox>
                            <IconLogbookBox parent={this} text="budgets">
                                <img
                                    src={creditcard}
                                    alt="creditcard"
                                    className={classes.boatIcon}
                                />
                            </IconLogbookBox>
                            <IconLogbookBox parent={this} text="repair">
                                <img
                                    src={repaircard}
                                    alt="repaircard"
                                    className={classes.boatIcon}
                                />
                            </IconLogbookBox>
                            <IconLogbookBox parent={this} text="alerts">
                                <Notifications
                                    classes={{ root: classes.boatIcon }}
                                    style={{ color: 'white' }}
                                />
                            </IconLogbookBox>
                            <IconLogbookBox parent={this} text="logbook">
                                <Book
                                    classes={{ root: classes.boatIcon }}
                                    style={{ color: 'white' }}
                                />
                            </IconLogbookBox>
                        </div>
                    </div>
                    <Typography
                        variant="h1"
                        classes={{ root: classes.subtitle }}
                    >
                        {this.displayText('offers')}
                    </Typography>
                    <div className={classes.pricing}>
                        <PricingCard
                            parent={this}
                            title="offerTitleYear"
                            desc="offerDescYear"
                            advantages={[
                                'offerAdvantages1Year',
                                'offerAdvantages2Year',
                                'offerAdvantages3Year',
                            ]}
                            subscribeType={1}
                        />

                        <PricingCard
                            parent={this}
                            title="offerTitleMonth"
                            desc="offerDescMonth"
                            advantages={[
                                'offerAdvantages1Month',
                                'offerAdvantages2Month',
                            ]}
                            subscribeType={0}
                        />
                    </div>
                </div>
                <ModalConnection
                    open={openModalConnection}
                    handleClose={this.handleCloseConnect}
                />
                <ModalSubscribe
                    open={openModalSubscribe}
                    subscribeType={subscribeType}
                    handleClose={this.handleClose}
                    user={this.props.user}
                />
            </div>
        )
    }
}

function PricingCard({ parent, title, desc, advantages, subscribeType }) {
    const { classes } = parent.props
    return (
        <Card className={classes.paper} elevation={3}>
            <CardContent className={classes.paperContent}>
                <span className={classes.freemonth}>
                    {parent.displayText('monthFree')}
                </span>
                <div className={classes.head}>
                    <Typography className={classes.cardtitle}>
                        {parent.displayText(title)}
                    </Typography>
                    <Typography className={classes.cardsubtitle}>
                        {parent.displayText(desc)}
                    </Typography>
                </div>
                <Divider />
                <ul className={classes.cardadvantages}>
                    {advantages.map(a => {
                        return (
                            <li className={classes.cardadvantage}>
                                <CheckIcon fontSize="large"></CheckIcon>
                                <Typography
                                    className={classes.cardadvantagetext}
                                >
                                    {parent.displayText(a)}
                                </Typography>
                            </li>
                        )
                    })}
                </ul>
            </CardContent>
            <CardActions style={{ marginBottom: '15px' }}>
                <Button
                    onClick={e => parent.subscribeToBoatonBook(subscribeType)}
                    variant="contained"
                    size="small"
                    color="secondary"
                    fullWidth
                >
                    {parent.displayText('gosubscribe')}
                </Button>
            </CardActions>
            <br />
        </Card>
    )
}

function IconLogbookBox({ parent, text, children }) {
    const { classes } = parent.props
    return (
        <div className={classes.blockIcon}>
            <div className={classes.iconLogbookBox}>{children}</div>
            <Typography classes={{ root: classes.iconlogbookText }}>
                {parent.displayText(text)}
            </Typography>
        </div>
    )
}

function mapStateToProps(state) {
    return {
        user: state.authentication.user,
        sub: state.fetch.sub,
    }
}

export default connect(mapStateToProps)(withStyles(styles)(PricingPage))
