export default theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        padding: '20px',
        paddingTop: 0,
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            padding: 0,
            margin: 0,
        },
    },
    noDoc: {
        textAlign: 'center',
        padding: '15px',
        fontSize: '22px',
    },
    line: {
        width: 'calc(100% - 20px)',
        padding: '10px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        height: '50px',
    },
    textField: {
        marginRight: '10px',
        marginLeft: '10px',
        width: 'calc(100% - 20px)',
    },
    twoTextField: {
        display: 'flex',
        flexDirection: 'row',
    },
    blueLine: {
        width: 'calc(100% - 20px)',
        padding: '10px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        height: '50px',
        backgroundColor: 'rgba(48,64,160,0.1)',
    },
    infos: {
        width: 'calc(100% - 2px)',
        display: 'flex',
        flexDirection: 'column',
        border: '1px solid #c4c4c4',
        boxShadow: '0px 2px 10px 0.3px #c4c4c4',
    },
    title: {
        fontWeight: 500,
        marginBottom: '10px',
    },
    subtitle: {
        textAlign: 'left',
        marginTop: 'auto',
        marginBottom: 'auto',
        fontSize: '22px',
        [theme.breakpoints.down('sm')]: {
            fontSize: '18px',
        },
    },
    type: {
        textAlign: 'left',
        marginTop: 'auto',
        marginBottom: 'auto',
        fontSize: '16px',
        [theme.breakpoints.down('sm')]: {
            fontSize: '12px',
        },
    },
    titleAndType: {
        width: '60%',
        [theme.breakpoints.down('sm')]: {
            width: 'calc(60% - 60px)',
        },
    },
    validate: {
        display: 'block',
        margin: 'auto',
        marginTop: '20px',
        marginBottom: 0,
        [theme.breakpoints.down('sm')]: {
            marginTop: '5px',
        },
    },
    date: {
        width: '100%',
        fontSize: '20px',
        whiteSpace: 'nowrap',
        [theme.breakpoints.down('sm')]: {
            fontSize: '16px',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'clip',
        },
    },
    dates: {
        textAlign: 'center',
        marginTop: 'auto',
        marginBottom: 'auto',
        width: '8%',
        [theme.breakpoints.down('sm')]: {
            fontSize: '18px',
            width: '15%',
        },
    },
    link: {
        textAlign: 'center',
        marginTop: 'auto',
        marginBottom: 'auto',
        textDecoration: 'none',
        color: '#000',
        width: '7%',
        '&:hover': {
            cursor: 'pointer',
        },
        [theme.breakpoints.down('sm')]: {
            //fontSize: '16px',
        },
    },
    firstLine: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
        },
    },
    dividerH: {
        border: '0.5px solid #c4c4c4',
        height: '50px',
        marginRight: '10px',
        marginLeft: '10px',
    },
    icon: {
        width: '40px',
        height: '40px',
        marginLeft: 'auto',
        marginRight: 'auto',
        [theme.breakpoints.down('sm')]: {
            width: '30px',
            height: '30px',
            padding: '5px',
        },
    },
    iconAdd: {
        marginTop: '15px',
        display: 'block',
        marginRight: '50px',
        marginLeft: 'auto',
        '&:hover': {
            cursor: 'pointer',
        },
        width: '80px',
        height: '80px',
        [theme.breakpoints.down('sm')]: {
            width: '60px',
            height: '60px',
        },
    },
    paper: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '40vw',
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(4),
        outline: 'none',
        [theme.breakpoints.down('sm')]: {
            padding: '5px',
            width: '90vw',
        },
    },
    addDoc: {
        border: '1px dashed #c4c4c4',
        margin: '10px',
        marginTop: 0,
        '&:hover': {
            cursor: 'pointer',
        },
        [theme.breakpoints.down('sm')]: {
            margin: '5px',
        },
    },
    textAdd: {
        margin: '10px',
        fontWeight: 500,
    },
    doc: {
        margin: 'auto',
        display: 'block',
        padding: '10px',
        width: '50px',
        height: '50px',
        color: '#C4C4C4',
    },
    allPhotos: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
    },
    images: {
        width: '100px',
        height: '100px',
        marginRight: '15px',
        position: 'relative',
    },
    imagesModal: {
        width: '100%',
        maxWidth: 'calc((100 / 23 * 19vw) - 20px)',
        margin: 'auto',
    },
    pictures: {
        margin: 'auto',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        border: '0.5px solid #C4C4C4',
        position: 'relative',
    },
    importantImage: {
        width: '30rem',
        margin: 'auto',
        height: '100%',
        position: 'relative',
        display: 'inline-block',
    },
    cross: {
        position: 'absolute',
        right: 0,
        top: 0,
        '&:hover': {
            cursor: 'pointer',
        },
        zIndex: 1,
    },
    ifNeeded: {
        padding: '10px',
        fontWeight: 500,
        fontSize: '18px',
        [theme.breakpoints.down('sm')]: {
            fontSize: '14px',
        },
    },
    input: {
        [theme.breakpoints.down('sm')]: {
            fontSize: '14px',
            padding: 0,
            paddingTop: '10px',
            paddingBottom: '10px',
            paddingLeft: '4px',
        },
    },
    labelInput: {
        [theme.breakpoints.down('sm')]: {
            fontSize: '14px',
        },
    },
    marginInput: {
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
    loading: {
        textAlign: 'center',
    },
    field: {
        width: '98%',
        margin: 'auto',
        fontSize: '16px',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
    recherche: {
        width: '40%',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            marginBottom: '15px',
        },
    },
})
