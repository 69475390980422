export default theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        padding: '20px',
        paddingTop: 0,
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            padding: 0,
            margin: 0,
        },
    },
    noDoc: {
        textAlign: 'center',
        padding: '15px',
        fontSize: '22px',
    },
    hidden: {
        display: 'none',
    },
    menu: {
        width: '275px',
        display: 'flex',
        flexDirection: 'column',
        marginRight: '50px',
        marginLeft: 'auto',
        padding: '10px',
        paddingRight: 0,
    },
    button: {
        margin: '10px',
        marginRight: 0,
        padding: '10px',
    },
    buttonAdd: {
        margin: '30px 0',
        padding: '10px',
        width: '280px',
        float: 'right',
    },
    buttonSvg: {
        fontSize: '30px!important',
    },
    line: {
        width: 'calc(100% - 20px)',
        padding: '10px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        height: '50px',
    },
    textField: {
        marginRight: '10px',
        marginLeft: '10px',
        width: 'calc(100% - 20px)',
    },
    dateType: {
        marginRight: '10px',
        marginLeft: '10px',
    },
    twoTextField: {
        display: 'flex',
        flexDirection: 'row',
    },
    espaces: {
        paddingTop: '20px',
    },
    blueLine: {
        width: 'calc(100% - 20px)',
        padding: '10px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        height: '50px',
        backgroundColor: 'rgba(48,64,160,0.1)',
    },
    infos: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        border: '1px solid #c4c4c4',
        boxShadow: '0px 2px 10px 0.3px #c4c4c4',
    },
    title: {
        fontWeight: 500,
    },
    subtitle: {
        textAlign: 'left',
        marginTop: 'auto',
        marginBottom: 'auto',
        fontSize: '22px',
        [theme.breakpoints.down('sm')]: {
            fontSize: '18px',
        },
    },
    type: {
        textAlign: 'left',
        marginTop: 'auto',
        marginBottom: 'auto',
        fontSize: '16px',
        [theme.breakpoints.down('sm')]: {
            fontSize: '12px',
        },
    },
    titleAndType: {
        width: '45%',
        [theme.breakpoints.down('sm')]: {
            width: 'calc(60% - 60px)',
        },
    },
    validate: {
        display: 'block',
        margin: 'auto',
        marginTop: '20px',
        marginBottom: 0,
        [theme.breakpoints.down('sm')]: {
            marginTop: '5px',
        },
    },
    date: {
        width: '100%',
        fontSize: '20px',
        whiteSpace: 'nowrap',
        [theme.breakpoints.down('sm')]: {
            fontSize: '16px',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'clip',
        },
    },
    dates: {
        textAlign: 'center',
        marginTop: 'auto',
        marginBottom: 'auto',
        width: '8%',
        [theme.breakpoints.down('sm')]: {
            fontSize: '18px',
            width: '15%',
        },
    },
    link: {
        textAlign: 'center',
        marginTop: 'auto',
        marginBottom: 'auto',
        textDecoration: 'none',
        color: '#000',
        width: '4%',
        '&:hover': {
            cursor: 'pointer',
        },
        [theme.breakpoints.down('sm')]: {
            //fontSize: '16px',
        },
    },
    firstLine: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
        },
    },
    texte: {
        paddingBottom: '18px',
        paddingTop: '12px',
    },
    secondLine: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        height: '55px',
        borderRadius: '5px',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            height: '100%',
        },
    },
    datetime: {
        display: 'flex',
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '40%',
        marginLeft: '5px',
        paddingTop: '11px',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            margin: 0,
        },
    },
    recherche: {
        width: '40%',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
    field: {
        width: '98%',
        margin: 'auto',
        fontSize: '16px',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
    dividerH: {
        border: '0.5px solid #c4c4c4',
        height: '50px',
        marginRight: '10px',
        marginLeft: '10px',
    },
    icon: {
        width: '40px',
        height: '40px',
        marginRight: '15px',
        [theme.breakpoints.down('sm')]: {
            width: '30px',
            height: '30px',
            padding: '5px',
        },
    },
    iconAdd: {
        marginTop: '15px',
        display: 'block',
        marginRight: '50px',
        marginLeft: 'auto',
        '&:hover': {
            cursor: 'pointer',
        },
        width: '80px',
        height: '80px',
        [theme.breakpoints.down('sm')]: {
            width: '60px',
            height: '60px',
        },
    },
    paper: {
        width: '100%',
    },
    addDoc: {
        border: '1px dashed #c4c4c4',
        margin: '10px',
        marginTop: 0,
        '&:hover': {
            cursor: 'pointer',
        },
        [theme.breakpoints.down('sm')]: {
            margin: '5px',
        },
    },
    textAddDate: {
        fontWeight: 500,
    },
    textRepair: {
        fontWeight: 500,
        marginTop: '10px',
    },
    textAdd: {
        margin: '10px',
        marginBottom: '0',
        fontWeight: 200,
    },
    doc: {
        margin: 'auto',
        display: 'block',
        padding: '10px',
        width: '50px',
        height: '50px',
        color: '#C4C4C4',
    },
    allPhotos: {
        width: '100%',
        minHeight: '5%',
        padding: '0 10px',
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
    },
    images: {
        width: '100px',
        height: '100px',
        marginRight: '15px',
        position: 'relative',
    },
    pictures: {
        margin: 'auto',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        border: '0.5px solid #C4C4C4',
        position: 'relative',
    },
    importantImage: {
        width: '30rem',
        margin: 'auto',
        height: '100%',
        position: 'relative',
        display: 'inline-block',
    },
    cross: {
        position: 'absolute',
        right: 0,
        top: 0,
        '&:hover': {
            cursor: 'pointer',
        },
        zIndex: 1,
    },
    ifNeeded: {
        padding: '10px',
        fontWeight: 500,
        fontSize: '18px',
        [theme.breakpoints.down('sm')]: {
            fontSize: '14px',
        },
    },
    input: {
        [theme.breakpoints.down('sm')]: {
            fontSize: '14px',
            padding: 0,
            paddingTop: '10px',
            paddingBottom: '10px',
            paddingLeft: '4px',
        },
    },
    iconDisabled: {
        display: 'block',
        marginTop: 'auto',
        marginBottom: 'auto',
        width: '3%',
        color: '#C1C1C1',
        '&:hover': {
            cursor: 'pointer',
        },
    },
    icone: {
        display: 'block',
        marginTop: 'auto',
        marginBottom: 'auto',
        color: '#000',
        alignItems: 'center',
        width: '3%',
        '&:hover': {
            cursor: 'pointer',
        },
    },
    labelInput: {
        [theme.breakpoints.down('sm')]: {
            fontSize: '14px',
        },
    },
    marginInput: {
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
    loading: {
        textAlign: 'center',
    },
    amount: {
        textAlign: 'center',
        marginTop: 'auto',
        marginBottom: 'auto',
        width: '15%',
        [theme.breakpoints.down('sm')]: {
            fontSize: '13px',
            width: '12%',
        },
    },
    modalTitle: {
        fontSize: '24px',
        fontWeight: 500,
        textAlign: 'center',
        marginBottom: '10px',
    },
    dividerHidden: {
        border: '0.5px solid #c4c4c4',
        height: '50px',
        marginRight: '10px',
        marginLeft: '10px',
        visibility: 'hidden',
    },
    amountHidden: {
        textAlign: 'center',
        marginTop: 'auto',
        marginBottom: 'auto',
        width: '15%',
        [theme.breakpoints.down('sm')]: {
            fontSize: '13px',
            width: '12%',
        },
        visibility: 'hidden',
    },
    rootRangDate: {
        display: 'flex',
        flexFlow: 'row wrap',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
    day: {
        width: '34px',
        minWidth: '34px',
        height: '34px',
        minHeight: '34px',
        margin: '2px',
        textAlign: 'center',
        lineHeight: 0,
        fontWeight: 400,
    },
    unavailable: {
        width: '34px',
        color: 'grey',
        minWidth: '34px',
        height: '34px',
        minHeight: '34px',
        margin: '2px',
        textAlign: 'center',
        lineHeight: 0,
        fontWeight: 400,
    },
    case: {
        width: '100%',
    },
    daySelected: {
        width: '34px',
        minWidth: '34px',
        height: '34px',
        minHeight: '34px',
        margin: '2px',
        textAlign: 'center',
        backgroundColor: '#3040a0',
        borderRadius: '50%',
        color: '#fff',
        lineHeight: 0,
        fontWeight: 400,
        '&:hover': {
            backgroundColor: '#222e72',
        },
    },
    startDate: {
        marginLeft: '5px',
        marginRight: '5px',
        marginBottom: '10px',
        width: '138px',
        marginTop: 0,
        [theme.breakpoints.down('sm')]: {
            marginLeft: 0,
            marginRight: 0,
        },
    },
    endDate: {
        marginLeft: '30px',
        marginRight: '5px',
        width: '138px',
        marginBottom: '10px',
        marginTop: 0,
        [theme.breakpoints.down('sm')]: {
            marginRight: 0,
            marginLeft: 0,
        },
    },
    page: {
        display: 'flex',
        flexFlow: 'row wrap',
        justifyContent: 'center',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
            justifyContent: 'space-between',
            width: '100%',
        },
    },
    all: {
        width: '100px',
        marginTop: 0,
        marginBottom: 'auto',
        marginRight: '10px',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            marginRight: 0,
            marginBottom: '10px',
        },
    },
    testSwitch: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
})
