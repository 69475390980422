export default {
    electricityTab: {
        FR: 'Eclair',
        EN: 'ELECTRICITY',
    },
    engineTab: {
        FR: 'MOTEUR',
        EN: 'ENGINE',
    },
    electronicTab: {
        FR: 'ELECTRONIQUE',
        EN: 'ELECTRONIC',
    },
    waterTab: {
        FR: 'CIRCUIT EAU DOUCE',
        EN: 'FRESHWATER CIRCUIT',
    },
    hullTab: {
        FR: 'COQUE',
        EN: 'HULL',
    },
    sailingTab: {
        FR: 'ACCASTILLAGE ET GREEMENT',
        EN: 'FITTINGS AND RIGGING',
    },
    comfortTab: {
        FR: 'CONFORT ET SECURITE',
        EN: 'COMFORT AND SAFETY',
    },
    other: {
        FR: 'AUTRE',
        EN: 'OTHER',
    },
    noRisk: {
        FR: 'Plus de risque de rater une révision avec les alertes BoatOn ! ',
        EN: 'No more risk of missing a checkup with BoatOn alerts! ',
    },
    allNeeded: {
        FR:
            "Tous les équipements du bateau sont déjà listés et il suffit de renseigner la dernière date de contrôle. L'algorithme calcule automatiquement la prochaine échéance et vous recevez des alertes à J-1 mois et à J-7 sur votre adresse mail.",
        EN:
            "All the boat's equipment is already listed and all you have to do is enter the last inspection date. The algorithm automatically calculates the next due date and you receive alerts on M-1 month and M-7 on your email address.",
    },
    enterDate: {
        FR:
            "Entrez les dates de vos dernières révisions et laissez l'algorithme trouver les dates de vos prochaines révisions.",
        EN:
            'Enter the dates of your last revisions and let the algorithm find the dates of your next.',
    },
    noAlerts: {
        FR:
            "Oups, il semble que vous n'ayez pas enregistré les informations de base de votre bateau. Ajoutez-les pour pouvoir paramétrer vos alertes.",
        EN:
            "Oups, il semble que vous n'ayez pas enregistré les informations de base de votre bateau. Ajoutez-les pour pouvoir paramétrer vos alertes.",
    },
    addAlert: {
        FR: 'Ajoutez une alerte',
        EN: 'add an alert',
    },
    userAddsAlert: {
        FR:
            "Vous êtes en train d'ajouter un équipement à contrôler, à vous de jouer !",
        EN:
            'You are adding adding an equipment to control, it is up to you to play !',
    },
    maintenace: {
        FR: 'Maintenance',
        EN: 'Maintenance',
    },
    reparation: {
        FR: 'Réparation',
        EN: 'Reparation',
    },
    amenagement: {
        FR: 'Aménagement',
        EN: 'development',
    },
    choiceType: {
        FR: "Choisir un type d'entretien",
        EN: 'Choose a checkup type',
    },
    choiceFamilyType: {
        FR: "Choisir une famille d'équipement",
        EN: 'Choose an equipment family',
    },
    equipmentName: {
        FR: "Nom de l'équipement",
        EN: 'Name of the equipment',
    },
    amount: {
        FR: 'Montant',
        EN: 'Amount',
    },
    detailsDernierControle: {
        FR: 'Détails du dernier contrôle',
        EN: 'Commentary of the last check',
    },
    detailsProchainControle: {
        FR: 'Détails du prochain contrôle',
        EN: 'Commentary of the next check',
    },
    addDocumentLast: {
        FR: 'Documents du dernier contrôle',
        EN: 'Document of the last check',
    },
    addDocumentNext: {
        FR: 'Documents du prochain contrôle',
        EN: 'Document of the next check',
    },
    validate: {
        FR: 'Je valide',
        EN: 'Validate',
    },
    search: {
        FR: 'Je recherche',
        EN: 'Search',
    },
    validateDelete: {
        FR: 'Voulez-vous vraiment supprimer cet entretien ?',
        EN: 'Do you really want to delete this repair ?',
    },
}
