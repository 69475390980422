export default {
    budget: {
        FR: 'Mon budget',
        EN: 'My budget',
    },
    income: {
        FR: 'Mes revenus',
        EN: 'My incomes',
    },
    expense: {
        FR: 'Mes dépenses',
        EN: 'My expenses',
    },
    search: {
        FR: 'Je recherche',
        EN: 'Search',
    },
}
