import React from 'react'
import { connect } from 'react-redux'
import BoatOnComponent from '../../../common/BoatOnComponent'
import styles from './Styles/EngineInfoPageCss'
import dictionary from './Dictionary/EngineInfoPageDico'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem'
import DeleteIcon from '@material-ui/icons/Delete'
import AddIcon from '@material-ui/icons/Add'
import Button from '@material-ui/core/Button'
import { typesActions } from '../../../../actions'
import { removeDataBOBEngines } from '../../../../actions/bob.actions'
import ModalDeleteEngine from './ModalDeleteEngine'

class EngineInfoPage extends BoatOnComponent {
    constructor(props) {
        super(props)
        this.dictionary = dictionary

        this.state = {
            engines: props.engines,
            saveEngineIdDelete: false,
            openModalDeleteEngine: false,
        }

        this.renderAnEngine = this.renderAnEngine.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.addEngine = this.addEngine.bind(this)
        this.deleteEngine = this.deleteEngine.bind(this)
    }

    componentDidMount() {
        const { engineTypes, loadingTypes } = this.props
        if (!engineTypes && !loadingTypes) {
            this.props.dispatch(typesActions.requestEngineTypes())
        }
    }

    componentDidUpdate(prevProps) {
        if (
            JSON.stringify(this.props.engines) !==
            JSON.stringify(prevProps.engines)
        ) {
            this.setState({ engines: this.props.engines })
        }
    }

    handleChange(value, key, i) {
        let { engines } = this.state

        engines[i][key] = value
        this.setState({ engines: engines }, () => {
            this.props.updateEngine(engines)
        })
    }

    addEngine() {
        let { engines } = this.state
        const lastEngine = engines[engines.length - 1]

        if (
            engines.length > 0 &&
            ((lastEngine.EngineMarker === '' && lastEngine.Engine === '') ||
                !lastEngine.EngineMarker ||
                !lastEngine.Engine)
        ) {
            alert(this.displayText('alert'))
            return
        }

        engines.push({
            Engine: null,
            EngineMarker: null,
            EnginePower: null,
            EngineType: -1,
            EngineYear: null,
            EngineSerialNumber: null,
            EngineTime: null,
        })
        this.setState({ engines: engines }, () => {
            this.props.updateEngine(engines)
        })
    }

    deleteEngine({ engineId, tableId, valid }) {
        const {
            openModalDeleteEngine,
            saveEngineIdDelete,
            engines,
        } = this.state

        if (saveEngineIdDelete && openModalDeleteEngine && valid) {
            this.props.dispatch(
                removeDataBOBEngines(
                    { engineId: saveEngineIdDelete, engines: engines },
                    this.context,
                ),
            )
            this.setState({
                openModalDeleteEngine: false,
                saveEngineIdDelete: false,
            })
        } else if (engineId && !openModalDeleteEngine) {
            this.setState({
                openModalDeleteEngine: true,
                saveEngineIdDelete: engineId,
            })
        } else if (tableId) {
            let { engines } = this.state

            engines.splice(tableId, 1)
            this.setState({ engines: engines }, () => {
                this.props.updateEngine(engines)
            })
        } else if (!valid) {
            this.setState({
                openModalDeleteEngine: false,
                saveEngineIdDelete: false,
            })
        }
    }

    renderAnEngine(engine, i) {
        const { classes, engineTypes } = this.props
        const infoDelete = {
            engineId: engine.id ? engine.id : null,
            tableId: engine.id ? null : i,
        }

        return (
            <div key={i} style={{ marginTop: '15px' }}>
                <div className={classes.engineAndDelete}>
                    <Typography className={classes.subtitle}>
                        {this.displayText('engine')} {i + 1}
                    </Typography>
                    {i >= 0 && (
                        <DeleteIcon
                            className={classes.delete}
                            onClick={() => this.deleteEngine(infoDelete)}
                        />
                    )}
                </div>
                <div className={classes.line}>
                    <div className={classes.block}>
                        <Typography className={classes.text}>
                            {this.displayText('engineType')}:{' '}
                        </Typography>
                        <TextField
                            id="EngineType"
                            margin="dense"
                            required
                            select
                            variant="outlined"
                            value={engine.EngineType}
                            onChange={e => {
                                this.handleChange(
                                    e.target.value,
                                    'EngineType',
                                    i,
                                )
                            }}
                            className={classes.field}
                        >
                            <MenuItem value={-1}>
                                <em>Choisissez un type de moteur</em>
                            </MenuItem>
                            {engineTypes.map(type => (
                                <MenuItem key={type.id} value={type.id}>
                                    {type.typeFR}
                                </MenuItem>
                            ))}
                        </TextField>
                    </div>
                    <div className={classes.block}>
                        <Typography className={classes.text}>
                            {this.displayText('engineYear')}:{' '}
                        </Typography>
                        <TextField
                            id="engineYear"
                            margin="dense"
                            required
                            variant="outlined"
                            type="number"
                            placeholder="2000"
                            value={engine.EngineYear || ''}
                            onChange={e => {
                                this.handleChange(
                                    e.target.value,
                                    'EngineYear',
                                    i,
                                )
                            }}
                            className={classes.field}
                        />
                    </div>
                </div>
                <div className={classes.line}>
                    <div className={classes.block}>
                        <Typography className={classes.text}>
                            {this.displayText('engineMaker')}:{' '}
                        </Typography>
                        <TextField
                            id="engineMaker"
                            margin="dense"
                            required
                            variant="outlined"
                            value={engine.EngineMarker || ''}
                            onChange={e => {
                                this.handleChange(
                                    e.target.value,
                                    'EngineMarker',
                                    i,
                                )
                            }}
                            className={classes.field}
                        />
                    </div>
                    <div className={classes.block}>
                        <Typography className={classes.text}>
                            {this.displayText('engineModel')}:{' '}
                        </Typography>
                        <TextField
                            id="engineModel"
                            margin="dense"
                            required
                            variant="outlined"
                            value={engine.Engine || ''}
                            onChange={e => {
                                this.handleChange(e.target.value, 'Engine', i)
                            }}
                            className={classes.field}
                        />
                    </div>
                </div>
                <div className={classes.line}>
                    <div className={classes.block}>
                        <Typography className={classes.text}>
                            {this.displayText('enginePower')}:{' '}
                        </Typography>
                        <TextField
                            id="enginePower"
                            margin="dense"
                            required
                            variant="outlined"
                            type="number"
                            placeholder="50"
                            value={engine.EnginePower || ''}
                            onChange={e => {
                                this.handleChange(
                                    e.target.value,
                                    'EnginePower',
                                    i,
                                )
                            }}
                            className={classes.field}
                        />
                    </div>
                    <div className={classes.block}>
                        <Typography className={classes.text}>
                            {this.displayText('engineTime')}:{' '}
                        </Typography>
                        <TextField
                            id="engineTime"
                            margin="dense"
                            required
                            variant="outlined"
                            type="number"
                            placeholder="10"
                            value={engine.EngineTime || ''}
                            onChange={e => {
                                this.handleChange(
                                    e.target.value,
                                    'EngineTime',
                                    i,
                                )
                            }}
                            className={classes.field}
                        />
                    </div>
                </div>
                <div className={classes.line}>
                    <Typography className={classes.textSolo}>
                        {this.displayText('serialNumber')}:{' '}
                    </Typography>
                    <TextField
                        id="serialNumber"
                        margin="dense"
                        required
                        variant="outlined"
                        placeholder="XXXXXXXX"
                        value={engine.EngineSerialNumber || ''}
                        onChange={e => {
                            this.handleChange(
                                e.target.value,
                                'EngineSerialNumber',
                                i,
                            )
                        }}
                        className={classes.fieldSolo}
                    />
                </div>
            </div>
        )
    }

    render() {
        const { engines, classes, engineTypes, loadingTypes } = this.props
        const { openModalDeleteEngine } = this.state

        if (loadingTypes !== 0 || !engineTypes) return <></>

        return (
            <div className={classes.root}>
                <Typography className={classes.title}>
                    {this.displayText('engines')}
                </Typography>
                {engines.map((engine, i) => this.renderAnEngine(engine, i))}
                <Button
                    className={classes.buttonAdd}
                    variant="contained"
                    color="primary"
                    onClick={this.addEngine}
                    endIcon={<AddIcon className={classes.buttonSvg} />}
                >
                    {this.displayText('addEngine')}
                </Button>
                <ModalDeleteEngine
                    show={openModalDeleteEngine}
                    handleClose={this.deleteEngine}
                />
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        engineTypes: state.types.engineTypes,
        loadingTypes: state.types.loading,
    }
}

export default connect(mapStateToProps)(withStyles(styles)(EngineInfoPage))
