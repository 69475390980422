import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import BoatOnComponent from '../../common/BoatOnComponent'

import styles from './Styles/CaracteristicsTabCss'
import dictionary from './Dictionary/CaracteristicsTabDico'

import SearchBar from '../../common/UsefullComponents/SearchBarV2'
import { addressActions } from '../../../actions/address.actions'

class AddressSearch extends BoatOnComponent {
    constructor(props) {
        super(props)

        this.dictionary = dictionary
        this.handleSearchChange = this.handleSearchChange.bind(this)
        this.renderAutocomplete = this.renderAutocomplete.bind(this)

        const fullAddressInfo = this.props.fullAddressInfo
        const value = fullAddressInfo || null
        this.state = {
            value: value,
            selected: value,
        }
    }

    render() {
        const {
            classes,
            style,
            notOutlined,
            fullWidth,
            labelHide,
            placeholder,
            label,
            margin,
            InputProps,
        } = this.props

        return (
            <div className={classes.addressGroup} style={style}>
                {
                    <div className={classes.addressField}>
                        <SearchBar
                            value={this.state.value}
                            selected={this.state.selected}
                            loading={false}
                            label={labelHide ? placeholder : label}
                            labelHide={labelHide}
                            onChange={this.handleSearchChange}
                            autocomplete={this.autocomplete}
                            renderAutocomplete={this.renderAutocomplete}
                            className={classes.field}
                            notOutlined={notOutlined}
                            fullWidth={fullWidth}
                            placeholder={placeholder}
                            margin={margin}
                            InputProps={InputProps}
                        />
                    </div>
                }
            </div>
        )
    }

    handleSearchChange(value) {
        this.setState({
            value: value,
        })
        if (this.props.onChange) {
            this.props.onChange()
        }
    }

    autocomplete(value) {
        if (value.length >= 4) {
            this.props.dispatch(addressActions.requestAddressData(value))
        }
    }

    renderAutocomplete(results, classes) {
        if (results && this.state.value != this.state.selected) {
            return (
                <ul
                    className={classes.autoCompletion}
                    style={{ listStyle: 'none' }}
                >
                    {results.length > 0 ? (
                        results.map((address, index) => {
                            return (
                                <li
                                    className={classes.field}
                                    key={index}
                                    onClick={() => {
                                        this.select(address)
                                    }}
                                >
                                    {address.fulltext}
                                </li>
                            )
                        })
                    ) : (
                        <li
                            className={classes.field}
                            onClick={() => {
                                this.create(this.state.value)
                            }}
                        >
                            {
                                <>
                                    Ajouter cette adresse:{' '}
                                    <span
                                        style={{
                                            fontSize: '0.8em',
                                            fontWeight: 200,
                                        }}
                                    >
                                        {this.state.value}
                                    </span>
                                </>
                            }
                        </li>
                    )}
                </ul>
            )
        }
        return <div />
    }

    select(element) {
        const value = {
            fullText: element.fulltext,
            latitude: element.y,
            longitude: element.x,
        }
        this.setState({
            value: value,
            selected: value,
        })
        this.props.setAddress(value)
    }

    create(query) {
        const value = {
            fullText: query,
            latitude: -1,
            longitude: -1,
        }
        this.setState({
            value: value,
            selected: value,
        })
        this.props.setAddress(value)
    }
}

export default withStyles(styles)(AddressSearch)
