export default {
    electricityTab: {
        FR: 'Eclair',
        EN: 'ELECTRICITY',
    },
    engineTab: {
        FR: 'MOTEUR',
        EN: 'ENGINE',
    },
    electronicTab: {
        FR: 'ELECTRONIQUE',
        EN: 'ELECTRONIC',
    },
    waterTab: {
        FR: 'CIRCUIT EAU DOUCE',
        EN: 'FRESHWATER CIRCUIT',
    },
    hullTab: {
        FR: 'COQUE',
        EN: 'HULL',
    },
    sailingTab: {
        FR: 'ACCASTILLAGE ET GREEMENT',
        EN: 'FITTINGS AND RIGGING',
    },
    comfortTab: {
        FR: 'CONFORT ET SECURITE',
        EN: 'COMFORT AND SAFETY',
    },
    other: {
        FR: 'AUTRE',
        EN: 'OTHER',
    },
    parts:{
        FR: 'pièces en stocks',
        EN: 'parts in stock',
    },
    missingParts:{
        FR: 'pièces manquantes',
        EN: 'missing parts',
    },
    renewedParts:{
        FR: 'pièces à renouveler',
        EN: 'parts to be renewed',
    },

    renewedPartsMonth:{
        FR: 'pièces à renouveler dans les 3 mois',
        EN: 'parts to be renewed within 3 months',
    },

    moreFilters: {
        FR: 'Plus de filtres',
        EN: 'More filters',
    },

    selectAll: {
        FR: 'Tout sélectionner',
        EN: 'Select all',
    },

    addEquipement: {
        FR: 'Ajoutez une pièce ou un équipement',
        EN: 'Add a part or equipment',
    },
    userAddsAlert: {
        FR:
            "Vous êtes en train d'ajouter un équipement à contrôler, à vous de jouer !",
        EN:
            'You are adding adding an equipment to control, it is up to you to play !',
    },
    search: {
        FR: 'Je recherche',
        EN: 'Search',
    },
    dateCommissioning: {
        FR: 'Date de mise en service',
        EN: 'Date of commissioning',
    },
    dateReplacement: {
        FR: 'Date de remplacement',
        EN: 'Date of replacement',
    },

    commissioning: {
        FR: 'Mise en service',
        EN: 'Commissioning',
    },
    replacement: {
        FR: 'Remplacement',
        EN: 'Replacement',
    },
    replacementHours: {
        FR: 'Remplacement (h)',
        EN: 'Replacement (h)',
    },
    stock: {
        FR: 'En stock',
        EN: 'In stock',
    },
    filterBtn:{
        FR: 'Stock',
        EN: 'Stock',
    },
    familyEquipement:{
        FR: 'Famille d\'équipements',
        EN: 'Family of equipment',
    },
    unfoldAll:{
        FR: 'Tout déplier',
        EN: 'Unfold all',
    },
    dateMise:{
        FR: 'Date mise en service',
        EN: 'Commissioning date'
    },
    dateRemplacement:{
        FR: 'Date de remplacement',
        EN: 'Replacement date',
    },
    validateDelete:{
        FR: 'Voulez-vous vraiment supprimer ce document ?',
        EN: 'Do you really want to delete this document?'
    },
    completingInventory:{
        FR: 'Vous êtes entrain de compléter votre inventaire, bien joué !',
        EN: 'You are completing your inventory, well done!'
    },
    dateModal:{
        FR: 'Dates et nombres d\'heures',
        EN: 'Dates and number of hours'
    },
    details:{
        FR: 'Détails',
        EN: 'Details'
    },
    brand:{
        FR: 'Marque',
        EN: 'Brand'
    },
    model:{
        FR: 'Modèle',
        EN: 'Model'
    },
    serialNumber:{
        FR: 'Numéro de série',
        EN: 'Serial number'
    },
    stockModal:{
        FR: 'Stock',
        EN: 'Stock'
    },
    supplier:{
        FR: 'Fournisseur',
        EN: 'Supplier'
    },
    amountVAT:{
        FR: 'Montant (TTC)',
        EN: 'Amount (incl. VAT)'
    },
    docPho:{
        FR: 'Documents et photos',
        EN: 'Documents and photos'
    },
    notes:{
        FR: 'Notes',
        EN: 'Notes'
    },
    importantInformation:{
        FR: 'Ecrivez ici d\autres informations importantes',
        EN: 'Write here other important information'
    },
    internalReference:{
        FR: 'Référence interne  ⓘ',
        EN: 'Internal reference'
    },
    datePurchase:{
        FR: 'Date d\'achat',
        EN: 'Date of purchase'
    },
    createReference:{
        FR: 'Créez une référence qui vous permettra de retrouver cet élément facilement.',
        EN: 'Create a reference that will allow you to find this item easily.'
    },
    indicateDate:{
        FR: 'Indiquez ici les dates d\'installation et de changements de cet élement. Vous pouvez également ajouter le nombre d\'heures.',
        EN: 'Indicate here the dates of installation and changes of this element. You can also add the number of hours.'
    },
    actuelNbrHours:{
        FR: 'Nombre d\'heures actuel',
        EN: 'Current number of hours'
    },
    replaceHours:{
        FR: 'Echéance de remplacement (h)',
        EN: 'Replacement time (h)'
    },

}
