export default {
    received: {
        FR: 'Reçue',
        EN: 'Received',
    },
    sent: {
        FR: 'Envoyée',
        EN: 'Sent',
    },
    traited: {
        FR: 'Traitée',
        EN: 'Processed',
    },
    waiting: {
        FR: 'En attente',
        EN: 'Waiting',
    },
    accepted: {
        FR: 'Acceptée',
        EN: 'Accepted',
    },
    declined: {
        FR: 'Déclinée',
        EN: 'Declined',
    },
    canceled: {
        FR: 'Annulée',
        EN: 'Canceled',
    },
}
