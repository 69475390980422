import React from 'react'
import BoatOnComponent from '../../../common/BoatOnComponent'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import { fetchData } from '../../../../actions/api.actions'
import styles from './Styles/RepairListCss'
import dictionary from './Dictionary/RepairListDico'
import Typography from '@material-ui/core/Typography'
import stockageIcon from '../../../../images/storageIcon.png'
import insuranceIcon from '../../../../images/iconInsurance.png'
import repairIcon from '../../../../images/repairIcon.png'
import expertiseIcon from '../../../../images/expertiseIcon.png'
import equipmentBuyIcon from '../../../../images/equipmentBuyIcon.png'
import amenagementIcon from '../../../../images/amenagementIcon.png'
import carburantIcon from '../../../../images/carburantIcon.png'
import adminIcon from '../../../../images/adminIcon.png'
import achatIcon from '../../../../images/achatIcon.png'
import locationIcon from '../../../../images/locationIcon.png'
import venteIcon from '../../../../images/venteIcon.png'
import otherIcon from '../../../../images/otherIcon.png'
import permisIcon from '../../../../images/permisIcon.png'
import carteCircuIcon from '../../../../images/carteCircuIcon.png'
import attestationIcon from '../../../../images/attestationIcon.png'
import manuelMoteurIcon from '../../../../images/manuelMoteurIcon.png'
import manuelProIcon from '../../../../images/manuelProIcon.png'
import reportIcon from '../../../../images/reportIcon.png'
import maintenanceIcon from '../../../../images/MaintenanceIcon.png'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import AttachFile from '@material-ui/icons/AttachFile'
import Delete from '@material-ui/icons/Delete'
import Edit from '@material-ui/icons/Edit'
import { API_URL } from '../../../../services/config.service'
import RepairModal from './Modal/RepairModal'
import AddIcon from '@material-ui/icons/Add'
import { removeDataBOBRepairsDate } from '../../../../actions/bob.actions'
import BoatOnModal from '../../../common/BoatOnModal'
import BoatOnImageRender from '../../../common/BoatOnImageRender'
import RangDate from '../../../common/UsefullComponents/RangDate'

class RepairList extends BoatOnComponent {
    constructor(props) {
        super(props)
        this.dictionary = dictionary

        this.state = {
            repairID: null,
            name: '',
            dateLast: null,
            dateNext: null,
            dateLastSearch: null,
            dateNextSearch: null,
            linkLast: null,
            linkNext: null,
            amountLast: null,
            amountNext: null,
            idTransaction: null,
            idDocuments: null,
            openAddModal: false,
            openEditModal: false,
            openEditModalDouble: false,
            search: null,
            type: '',
            typefamily: '',
            detailsLast: '',
            detailsNext: '',
            fileLast: [],
            fileNext: [],
            fileNoUrlLast: [],
            fileNoUrlNext: [],
            idcheckuptype: null,
            openMenu: false,
            secondTableOpen: false,
            firstTableOpen: false,

            modalOpen: false,
            modalIDRepair: null,
            modalIDDateRepair: null,
        }
        this.handleCloseModal = this.handleCloseModal.bind(this)
        this.handleClickMenu = this.handleClickMenu.bind(this)
        this.handleOpenModal = this.handleOpenModal.bind(this)

        this.deleteDocumentNoUp = this.deleteDocumentNoUp.bind(this)
        this.addDocumentLast = this.addDocumentLast.bind(this)
        this.addDocumentNext = this.addDocumentNext.bind(this)
        this.clickDocumentLast = this.clickDocumentLast.bind(this)
        this.clickDocumentNext = this.clickDocumentNext.bind(this)
        this.handleChangeRangeDate = this.handleChangeRangeDate.bind(this)
        this.handleTablesOpen = this.handleTablesOpen.bind(this)
        this.checkSameDate = this.checkSameDate.bind(this)
        this.renderDay = this.renderDay.bind(this)
        this.reset = this.reset.bind(this)
        this.isFile = this.isFile.bind(this)
        this.newRepair = this.newRepair.bind(this)
        this.handleChangeDate = this.handleChangeDate.bind(this)
        this.handleOpenModalMyDocument = this.handleOpenModalMyDocument.bind(
            this,
        )
    }
    componentWillMount() {
        if (this.props.dateLastSearch)
            this.setState({ dateLastSearch: this.props.dateLastSearch })
        if (this.props.dateNextSearch)
            this.setState({ dateNextSearch: this.props.dateNextSearch })

        if (!this.props.checkupType) {
            this.getCheckupType()
        }
    }

    componentWillReceiveProps(props) {
        if (props.reset) {
            this.setState({
                dateLastSearch: new Date(),
                dateNextSearch: new Date(),
            })
        }
    }

    handleOpenModalMyDocument(document) {
        this.setState({ modalOpen: 'showDocs', openDoc: document })
    }

    reset() {
        this.handleChangeRangeDate(null, 'dateLastSearch')
        this.handleChangeRangeDate(null, 'dateNextSearch')
    }

    handleChangeRangeDate(value, key) {
        const { desactiveUnavailable } = this.props

        const today = new Date()
        if (key === 'dateLastSearch' && !this.checkSameDate(value, key)) {
            if (
                new Date(value).getTime() <= today.getTime() &&
                desactiveUnavailable
            ) {
                value = today
            } else if (
                new Date(value).getTime() >
                    new Date(this.state.dateNextSearch).getTime() &&
                this.state.dateNextSearch
            ) {
                this.setState({ dateNextSearch: value })
            }
        } else if (
            key === 'dateNextSearch' &&
            !this.checkSameDate(value, key)
        ) {
            if (
                new Date(value).getTime() <
                new Date(this.state.dateLastSearch).getTime()
            ) {
                this.setState({ dateLastSearch: value })
            }
        }
        if (!this.checkSameDate(value, key)) {
            this.setState({ [key]: value }, () => {
                this.handleChange(value, key)
                if (this.props.onDateChange)
                    this.props.onDateChange(
                        this.state.dateLastSearch,
                        this.state.dateNextSearch,
                    )
            })
        }
    }

    handleChange(value, key) {
        this.setState({ [key]: value })
        if (key == 'dateLast') {
            const types = this.props.checkupType.checkupType
            let checkupType = null
            if (value != '' && this.state.idcheckuptype != null) {
                const nextControl = new Date(value)
                for (const i in types) {
                    if (types[i].CheckupTypeID == this.state.idcheckuptype) {
                        checkupType = types[i]
                    }
                }
                if (checkupType.CycleType === '1') {
                    nextControl.setFullYear(
                        nextControl.getFullYear(),
                        nextControl.getMonth() +
                            parseFloat(checkupType.CycleLength),
                    )
                } else {
                    nextControl.setTime(
                        nextControl.getTime() +
                            checkupType.CycleLength * 60 * 60 * 1000,
                    )
                }
                // tmp[id].dateNext = nextControl.toISOString().split('T')[0]
                this.setState({
                    dateNext: nextControl.toISOString().split('T')[0],
                })
            }
        }
    }

    daySelected(date, currentMonth) {
        date = date.setHours(0, 0, 0, 0)
        const startDate = new Date(this.state.dateLastSearch).setHours(
            0,
            0,
            0,
            0,
        )
        const endDate = new Date(this.state.dateNextSearch).setHours(0, 0, 0, 0)

        if (date && date >= startDate && date <= endDate) {
            return true
        }
        return false
    }

    checkSameDate(newDate, key) {
        const { desactiveUnavailable } = this.props
        const checkDate = new Date(newDate)

        if (key === 'startDate' && this.state.dateNextSearch) {
            while (checkDate <= this.state.dateNextSearch) {
                if (this.unavaibale(checkDate) && desactiveUnavailable) {
                    return true
                }
                checkDate.setDate(checkDate.getDate() + 1)
            }
        } else if (key === 'endDate' && this.state.dateLastSearch) {
            while (checkDate >= this.state.dateLastSearch) {
                if (this.unavaibale(checkDate) && desactiveUnavailable) {
                    return true
                }
                checkDate.setDate(checkDate.getDate() - 1)
            }
        }
        return false
    }

    unavaibale(date) {
        const { indisponibility } = this.props
        if (date.setHours(0, 0, 0, 0) < new Date().setHours(0, 0, 0, 0)) {
            return true
        }
        if (indisponibility) {
            for (const i in indisponibility) {
                const startIndispo =
                    indisponibility[i].OrderStartDate ||
                    indisponibility[i].dateLastSearch
                const endIndispo =
                    indisponibility[i].OrderEndDate ||
                    indisponibility[i].dateNextSearch
                if (
                    date.setHours(0, 0, 0, 0) >=
                        new Date(startIndispo).setHours(0, 0, 0, 0) &&
                    date.setHours(0, 0, 0, 0) <=
                        new Date(endIndispo).setHours(0, 0, 0, 0)
                )
                    return true
            }
        }
        return false
    }

    renderDay(date, selectedDate, currentMonth) {
        const { classes, desactiveUnavailable } = this.props
        let day = date.getDate()

        if (!currentMonth) {
            day = ''
        }

        return (
            <div className={classes.case}>
                {this.unavaibale(date) && desactiveUnavailable ? (
                    <Button disabled className={classes.unavailable}>
                        {day}
                    </Button>
                ) : this.daySelected(date, currentMonth) && currentMonth ? (
                    <Button
                        onClick={() => this.handleTablesOpen('second')}
                        className={classes.daySelected}
                    >
                        {day}
                    </Button>
                ) : (
                    <Button
                        onClick={() => this.handleTablesOpen('second')}
                        className={classes.day}
                    >
                        {day}
                    </Button>
                )}
            </div>
        )
    }

    handleTablesOpen(table) {
        if (table === 'first') {
            this.setState({ firstTableOpen: true })
            this.handleTablesClose('second')
        } else if (table === 'second') {
            this.setState({ secondTableOpen: true })
            this.handleTablesClose('first')
        }
    }

    handleTablesClose(table) {
        if (table === 'second') {
            this.setState({ secondTableOpen: false })
        } else if (table === 'first') {
            this.setState({ firstTableOpen: false })
        } else {
            this.setState({ secondTableOpen: false })
            this.setState({ firstTableOpen: false })
        }
    }

    addDocumentLast(event) {
        var newArrayLast = this.state.fileLast.slice()
        var arrayNoUrlLast = this.state.fileNoUrlLast.slice()
        if (arrayNoUrlLast.length == 0) {
            if (event.target.files[0].size < 4000000) {
                newArrayLast.push(URL.createObjectURL(event.target.files[0]))
                arrayNoUrlLast.push(event.target.files[0])
                this.setState({ fileLast: newArrayLast })
                this.setState({ fileNoUrlLast: arrayNoUrlLast })
            } else {
                alert('Votre fichier ne doit pas dépasser 4 MO')
            }
        } else {
            alert(
                "Vous ne pouvez pas ajouter plusieurs fichiers à un document. Vous pouvez cependant associer vos fichiers ensemble pour qu'ils n'en fassent qu'un.",
            )
        }
    }
    addDocumentNext(event) {
        var newArrayNext = this.state.fileNext.slice()
        var arrayNoUrlNext = this.state.fileNoUrlNext.slice()
        if (arrayNoUrlNext.length == 0) {
            if (event.target.files[0].size < 4000000) {
                newArrayNext.push(URL.createObjectURL(event.target.files[0]))
                arrayNoUrlNext.push(event.target.files[0])
                this.setState({ fileNext: newArrayNext })
                this.setState({ fileNoUrlNext: arrayNoUrlNext })
            } else {
                alert('Votre fichier ne doit pas dépasser 4 MO')
            }
        } else {
            alert(
                "Vous ne pouvez pas ajouter plusieurs fichiers à un document. Vous pouvez cependant associer vos fichiers ensemble pour qu'ils n'en fassent qu'un.",
            )
        }
    }

    handleClickMenu() {
        this.setState({ openMenu: !this.state.openMenu })
    }

    handleOpenModal() {
        this.setState({ openAddModal: true })
    }

    getCheckupType() {
        var data = new FormData()

        this.props.dispatch(
            fetchData(
                API_URL + '/getcheckuptype.php',
                'checkupType',
                'POST',
                data,
            ),
        )
    }

    handleChangeDate(startDate, endDate) {
        this.setState({ startDate: startDate, endDate: endDate })
    }

    checkID = (array, type, ID) => {
        if (type === 'transaction') {
            for (let i = 0; i < array.length; i++) {
                if (array[i].idTransaction === ID) {
                    return i
                }
            }
        } else if (type === 'document') {
            for (let i = 0; i < array.length; i++) {
                if (array[i].idDocuments === ID) {
                    return i
                }
            }
        }
        return -1
    }

    clickDocumentLast() {
        this.refs.fileUploaderLast.click()
    }
    clickDocumentNext() {
        this.refs.fileUploaderNext.click()
    }

    getType(intType) {
        if (intType === '2') {
            return this.displayText('repair')
        } else if (intType === '3') {
            return this.displayText('storage')
        } else if (intType === '4') {
            return this.displayText('insurance')
        } else if (intType === '5') {
            return this.displayText('layout')
        } else if (intType === '6') {
            return this.displayText('expertise')
        } else if (intType === '7') {
            return this.displayText('equipmentBuy')
        } else if (intType === '8') {
            return this.displayText('fuel')
        } else if (intType === '9') {
            return this.displayText('adminFees')
        } else if (intType === '10') {
            return this.displayText('buyBoat')
        } else if (intType === '11') {
            return this.displayText('rentBoat')
        } else if (intType === '12') {
            return this.displayText('sellBoat')
        } else if (intType === '13') {
            return this.displayText('permisNav')
        } else if (intType === '14') {
            return this.displayText('circulationCard')
        } else if (intType === '15') {
            return this.displayText('francizationAct')
        } else if (intType === '16') {
            return this.displayText('certificateConformity')
        } else if (intType === '17') {
            return this.displayText('engineManual')
        } else if (intType === '18') {
            return this.displayText('ownerManual')
        } else if (intType === '19') {
            return this.displayText('expertReport')
        } else if (intType === '20') {
            return this.displayText('maintenance')
        } else {
            return this.displayText('other')
        }
    }

    deleteDocumentNoUp(i) {
        this.setState({ fileLast: [] })
        this.setState({ fileNoUrlLast: [] })
        this.setState({ fileNext: [] })
        this.setState({ fileNoUrlNext: [] })
    }

    getIconType(intType) {
        if (intType === '2') {
            return repairIcon
        } else if (intType === '3') {
            return stockageIcon
        } else if (intType === '4') {
            return insuranceIcon
        } else if (intType === '5') {
            return amenagementIcon
        } else if (intType === '6') {
            return expertiseIcon
        } else if (intType === '7') {
            return equipmentBuyIcon
        } else if (intType === '8') {
            return carburantIcon
        } else if (intType === '9') {
            return adminIcon
        } else if (intType === '10') {
            return achatIcon
        } else if (intType === '11') {
            return locationIcon
        } else if (intType === '12') {
            return venteIcon
        } else if (intType === '13') {
            return permisIcon
        } else if (intType === '14') {
            return carteCircuIcon
        } else if (intType === '15') {
            return carteCircuIcon
        } else if (intType === '16') {
            return attestationIcon
        } else if (intType === '17') {
            return manuelMoteurIcon
        } else if (intType === '18') {
            return manuelProIcon
        } else if (intType === '19') {
            return reportIcon
        } else if (intType === '20') {
            return maintenanceIcon
        } else {
            return otherIcon
        }
    }

    newRepair() {
        this.setState({
            modalOpen: 'repair',
            modalIDRepair: null,
            modalIDDateRepair: null,
        })
    }

    edit(repair) {
        if (repair != null) {
            this.setState({
                modalOpen: 'repair',
                modalIDRepair: repair.CheckupID,
                modalIDDateRepair: repair.DateId,
            })
        }
    }

    handleCloseModal() {
        this.setState({
            modalOpen: false,
            modalIDRepair: null,
            modalIDDateRepair: null,
            openDoc: null,
        })
    }

    delete(repair) {
        this.props.dispatch(
            removeDataBOBRepairsDate(repair.DateId, repair.idCategory),
        )
    }

    isFile(openDoc) {
        const { documents } = this.props
        if (documents && openDoc) {
            for (let i = 0; i < documents.length; i++) {
                if (documents[i].TransactionID === openDoc.transactionID) {
                    return true
                }
            }
        }
        return false
    }

    renderDocuments() {
        const { classes, documents } = this.props
        const { openDoc } = this.state

        let imgs = []
        if (documents && openDoc) {
            for (let i = 0; i < documents.length; i++) {
                if (documents[i].TransactionID === openDoc.transactionID) {
                    imgs = documents[i].Files.map(f => {
                        return {
                            link: API_URL + '/documents/' + f.Link,
                            id: f.FileID,
                        }
                    })
                    break
                }
            }
        }

        return (
            <div className={classes.imagesModal}>
                <BoatOnImageRender vertical multi bottomImage imgs={imgs} />
            </div>
        )
    }

    render() {
        const { classes } = this.props
        var { repair } = this.props
        const {
            dateLastSearch,
            dateNextSearch,
            search,
            startDate,
            endDate,
        } = this.state
        const currentDate = new Date()
        var timelineSplited = false

        if (!repair) {
            repair = []
        }

        return (
            <div className={classes.root}>
                <div className={classes.firstLine}>
                    <Typography className={classes.title}>
                        {this.displayText('entretiens')}
                    </Typography>
                </div>
                <div className={classes.texte}>
                    <Typography>{this.displayText('gerer')}</Typography>
                </div>

                <div className={classes.secondLine}>
                    <RangDate
                        onDateChange={this.handleChangeDate}
                        desactiveUnavailable
                    />
                    <div className={classes.recherche}>
                        <TextField
                            id="search"
                            margin="dense"
                            required
                            variant="outlined"
                            placeholder={this.displayText('search')}
                            value={this.state.search || ''}
                            onChange={e => {
                                this.handleChange(e.target.value, 'search')
                            }}
                            className={classes.field}
                        />
                    </div>
                </div>

                {/*
                    LISTE DES ENTRETIENS
                */}

                <div className={classes.info}>
                    <p style={{ fontWeight: 600 }}>
                        {this.displayText('upcoming')}
                    </p>
                    {repair.length === 0 ? (
                        <Typography className={classes.noRepair}>
                            {this.displayText('recorded')}
                        </Typography>
                    ) : (
                        repair
                            .filter(
                                onerepair =>
                                    onerepair.CheckupDate &&
                                    new Date(onerepair.CheckupDate).getTime(),
                            )
                            .filter(
                                onerepair =>
                                    onerepair.titleRepair ||
                                    onerepair.CheckupTypeFR,
                            )
                            .filter(repair => {
                                if (
                                    search &&
                                    repair.titleRepair &&
                                    !RegExp(search.toLowerCase()).test(
                                        repair.titleRepair.toLowerCase(),
                                    )
                                ) {
                                    return false
                                }
                                if (
                                    search &&
                                    repair.CheckupTypeFR &&
                                    !RegExp(search.toLowerCase()).test(
                                        repair.CheckupTypeFR.toLowerCase(),
                                    )
                                ) {
                                    return false
                                }
                                if (
                                    startDate &&
                                    new Date(startDate).getTime() >
                                        new Date(repair.CheckupDate).getTime()
                                )
                                    return false
                                if (
                                    endDate &&
                                    new Date(endDate).getTime() <
                                        new Date(repair.CheckupDate).getTime()
                                )
                                    return false
                                return true
                            })
                            .sort((a, b) => {
                                return (
                                    new Date(b.CheckupDate).getTime() -
                                    new Date(a.CheckupDate).getTime()
                                )
                            })
                            .map((onerepair, index) => {
                                var checkupDate = new Date(
                                    onerepair.CheckupDate,
                                )
                                var splitTimeline = false
                                if (
                                    !timelineSplited &&
                                    currentDate.getTime() -
                                        checkupDate.getTime() -
                                        24 * 3600000 >
                                        0
                                ) {
                                    timelineSplited = true
                                    splitTimeline = true
                                } else {
                                    splitTimeline = false
                                }

                                return (
                                    <React.Fragment key={index}>
                                        {splitTimeline && (
                                            <div>
                                                <br />
                                                <p style={{ fontWeight: 600 }}>
                                                    {this.displayText('past')}
                                                </p>
                                            </div>
                                        )}
                                        <div
                                            key={'div1' + index}
                                            className={
                                                index % 2 === 1
                                                    ? classes.line
                                                    : classes.blueLine
                                            }
                                        >
                                            <div className={classes.dates}>
                                                <div
                                                    className={
                                                        classes.datesDayMonth
                                                    }
                                                >
                                                    <Typography
                                                        className={classes.date}
                                                    >
                                                        <b>
                                                            {new Date(
                                                                onerepair.CheckupDate,
                                                            ).getDate() +
                                                                ' ' +
                                                                new Date(
                                                                    onerepair.CheckupDate,
                                                                ).toLocaleString(
                                                                    'default',
                                                                    {
                                                                        month:
                                                                            'short',
                                                                    },
                                                                )}
                                                        </b>
                                                    </Typography>
                                                </div>
                                                <Typography
                                                    className={classes.date}
                                                >
                                                    {new Date(
                                                        onerepair.CheckupDate,
                                                    ).toLocaleString(
                                                        'default',
                                                        {
                                                            year: 'numeric',
                                                        },
                                                    )}
                                                </Typography>
                                            </div>

                                            <img
                                                src={this.getIconType(
                                                    onerepair.idTypeRepair,
                                                )}
                                                alt="stockage"
                                                className={classes.icon}
                                            />
                                            <div
                                                className={classes.titleAndType}
                                            >
                                                {onerepair.titleRepair ? (
                                                    <Typography
                                                        noWrap
                                                        className={
                                                            classes.subtitle
                                                        }
                                                    >
                                                        {onerepair.titleRepair}
                                                    </Typography>
                                                ) : (
                                                    <Typography
                                                        noWrap
                                                        className={
                                                            classes.subtitle
                                                        }
                                                    >
                                                        {
                                                            onerepair.CheckupTypeFR
                                                        }
                                                    </Typography>
                                                )}
                                                <Typography
                                                    className={classes.type}
                                                >
                                                    {this.getType(
                                                        onerepair.idTypeRepair,
                                                    )}
                                                </Typography>
                                            </div>
                                            {onerepair.Amount
                                                ? [
                                                      <div
                                                          className={
                                                              classes.dividerH
                                                          }
                                                          key={'div2' + index}
                                                      ></div>,
                                                      <Typography
                                                          key={'typo' + index}
                                                          className={
                                                              classes.amount
                                                          }
                                                      >
                                                          {onerepair.Amount}{' '}
                                                          {this.displayText(
                                                              'euro',
                                                          )}
                                                      </Typography>,
                                                      <div
                                                          key={'div3' + index}
                                                          className={
                                                              classes.dividerH
                                                          }
                                                      ></div>,
                                                  ]
                                                : [
                                                      <div
                                                          className={
                                                              classes.dividerHidden
                                                          }
                                                          key={'div2' + index}
                                                      ></div>,
                                                      <Typography
                                                          key={'typo' + index}
                                                          className={
                                                              classes.amountHidden
                                                          }
                                                      >
                                                          {onerepair.Amount}{' '}
                                                          {this.displayText(
                                                              'euro',
                                                          )}
                                                      </Typography>,
                                                      <div
                                                          key={'div3' + index}
                                                          className={
                                                              classes.dividerHidden
                                                          }
                                                      ></div>,
                                                  ]}

                                            {this.isFile(onerepair) ? (
                                                <div className={classes.icone}>
                                                    <AttachFile
                                                        onClick={() =>
                                                            this.handleOpenModalMyDocument(
                                                                onerepair,
                                                            )
                                                        }
                                                    />
                                                </div>
                                            ) : (
                                                <div
                                                    className={
                                                        classes.iconDisabled
                                                    }
                                                >
                                                    <AttachFile />
                                                </div>
                                            )}
                                            <Edit
                                                className={classes.link}
                                                onClick={() => {
                                                    this.edit(onerepair)
                                                }}
                                            />
                                            <Delete
                                                className={classes.link}
                                                onClick={() => {
                                                    if (
                                                        window.confirm(
                                                            this.displayText(
                                                                'validateDelete',
                                                            ),
                                                        )
                                                    )
                                                        this.delete(onerepair)
                                                }}
                                            />
                                        </div>
                                    </React.Fragment>
                                )
                            })
                    )}
                </div>

                <div>
                    <Button
                        className={classes.buttonAdd}
                        variant="contained"
                        color="primary"
                        onClick={this.newRepair}
                        endIcon={<AddIcon className={classes.buttonSvg} />}
                    >
                        {this.displayText('addRepair')}
                    </Button>
                </div>
                {/* ZONE MODAL */}
                <BoatOnModal
                    openCondition={this.state.modalOpen}
                    handleClose={this.handleCloseModal}
                    rendersModal={{
                        showDocs: this.renderDocuments(),
                        repair: (
                            <RepairModal
                                checkupTypes={this.props.checkupTypes}
                                checkupCategories={this.props.checkupCategories}
                                repairID={this.state.modalIDRepair}
                                dateID={this.state.modalIDDateRepair}
                                closeModal={this.handleCloseModal}
                            ></RepairModal>
                        ),
                    }}
                    titles={{
                        repair: 'Informations',
                        showDocs: 'Mes documents',
                    }}
                />
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        user: state.authentication.user,
        boats: state.fetch.boats,
        repair: state.bob.repairs,
        checkupType: state.fetch.checkupType,
        bob: state.bob,
        documents: state.bob.documents,
        checkupCategories: state.fetch.checkupCategories,
    }
}

export default connect(mapStateToProps)(withStyles(styles)(RepairList))
