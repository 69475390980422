import React from 'react'
import BoatOnComponent from '../BoatOnComponent'

import { withStyles } from '@material-ui/core/styles'
import styles from './Styles/InputDateHoursCss'
import dictionary from './Dictionary/InputDateHours'
import Button from '@material-ui/core/Button'

class InputDateHours extends BoatOnComponent {
    constructor(props) {
        super(props)
        this.dictionary = dictionary
        this.state = {}
    }

    render() {
        const { classes } = this.props

        return (
            <div className={classes.root}>
                <div className={classes.borderDiv}>
                    <p className={classes.textDateHours}>
                        <button className={classes.btnDate}>
                            {this.displayText('date')}
                        </button>
                        {this.displayText('or')}
                        <button className={classes.btnHours}>
                            {this.displayText('hours')}
                        </button>
                    </p>
                </div>
            </div>
        )
    }
}

export default withStyles(styles)(InputDateHours)
