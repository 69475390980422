export default theme => ({
    root: {
        display: 'flex',
        flexFlow: 'row ',
        alignItems: 'center',
        marginRight: '10px',
        marginLeft: '10px',
        width: 'calc(100% - 20px)',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },

    testGlobal:{
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        marginTop:'5px',
    },


    btnView:{
        marginTop:'8px',
        marginLeft:'8px',
        height: '38px',
        boxShadow:'none',
    },

    test1:{
        width:'calc(100% - 95px)',
        [theme.breakpoints.down('sm')]: {
            width: '65%',
        },
    },

    test2:{
        marginLeft:'1%'
    },

    textTextfield:{
        backgrounColor:'red',
        [theme.breakpoints.down('sm')]: {
            fontSize:'0.4em'
        },
    },

    attachRotate:{
        rotate:'25deg',
        width: '18px',
        height: '18px',
    },
    
    deleteIcon:{
        width: '15px',
        height: '15px',
    },
     
})
