export default {
    accountNeeded: {
        FR: 'Il faut être connecté à un compte pour pouvoir continuer',
        EN: 'You need to be connected to continue',
    },
    connection: {
        FR: 'Connexion',
        EN: 'Connection',
    },
    createAccount: {
        FR: 'Créer un compte',
        EN: 'Create an account',
    },
    or: {
        FR: 'ou',
        EN: 'or',
    },
    selection: {
        FR: 'Comment voulez-vous continuer ?',
        EN: 'How do you want to continue?',
    },
}
