import React from 'react'
import BoatOnComponent from '../../../../common/BoatOnComponent'

import { withStyles } from '@material-ui/core/styles'
import styles from '../Styles/RepairListCss'
import TextField from '@material-ui/core/TextField'
import dictionary from '../Dictionary/RepairListDico'
import MenuItem from '@material-ui/core/MenuItem'
import Button from '@material-ui/core/Button'
import InputLabel from '../../../../common/UsefullComponents/InputLabel'

const categoriesName = {
    1: 'engine',
    2: 'electricity',
    3: 'electronic',
    4: 'freshWaterCircuit',
    5: 'shell',
    6: 'other',
    7: 'hardwareAndRigging',
    8: 'comfortAndSafety',
}
class RepairModalTop extends BoatOnComponent {
    constructor(props) {
        super(props)
        this.dictionary = dictionary
        this.handleChange = this.handleChange.bind(this)
    }

    handleChange(value, key) {
        this.props.handleChange(value, key)
    }

    render() {
        const {
            classes,
            type,
            typefamily,
            idcheckuptype,
            checkupTypes,
            checkupCategories,
            name,
        } = this.props

        const types = [
            {
                intType: 20,
                name: this.displayText('maintenance'),
                type: 'maintenance',
            },
            { intType: 2, name: this.displayText('repair'), type: 'repair' },
            { intType: 5, name: this.displayText('layout'), type: 'layout' },
        ]

        return (
            <>
                {/* <InputDateHours /> */}

                <TextField
                    id="type"
                    variant="outlined"
                    label={this.displayText('typeRepair')}
                    margin="dense"
                    required
                    select
                    value={
                        type == 20
                            ? types[0].name
                            : type == 2
                            ? types[1].name
                            : type == 5
                            ? types[2].name
                            : ''
                    }
                    onChange={(e, d) => {
                        this.handleChange(d.props.id, 'type')
                    }}
                    className={classes.textField}
                    InputProps={{
                        classes: {
                            input: classes.input,
                            marginDense: classes.marginInput,
                        },
                    }}
                    InputLabelProps={{
                        classes: {
                            root: classes.labelInput,
                        },
                    }}
                >
                    {types.map(type => {
                        return (
                            <MenuItem
                                id={type.intType}
                                key={type.intType}
                                value={type.name}
                            >
                                {type.name}
                            </MenuItem>
                        )
                    })}
                </TextField>

                <InputLabel />
            </>
        )
    }

    getCheckupcategoryText(Checkupcategory) {
        if (Checkupcategory == null) return null
        return this.displayText(categoriesName[Checkupcategory.CheckupTypeID])
    }

    getCheckuptypeText(checkupType) {
        if (checkupType == null) return null
        return this.displayText(checkupType)
    }
}

export default withStyles(styles)(RepairModalTop)
