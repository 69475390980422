export default theme => ({
    modal: {
        display: 'flex',
        flexDirection: 'column',
        width: '80%',
        alignItems: 'center',
        textAlign: 'center',
        justifyContent: 'center',
        marginRight: 'auto',
        marginLeft: 'auto',
        paddingTop: '15px',
    },
    button: Object.assign({ marginTop: '20px' }, theme.button.primary),
})
