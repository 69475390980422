import { userConstants } from '../constants'

const initialState = {
    user: null,
    subscriptions: null,
    loading: 0,
}

const getPhoneNumber = () => {
    const contact = this.map(contactDetail => {
        if (contactDetail.contactType && contactDetail.contactType.id === 1) {
            return contactDetail.contact
        }
        return false
    })
    return contact[0]
}

export default function(prevState = initialState, action) {
    const address =
        action.user && action.user.address && action.user.address.fullAddress
            ? {
                  fullText: action.user.address.fullAddress,
                  latitude: action.user.address.latitude.toString(),
                  longitude: action.user.address.longitude.toString(),
              }
            : null
    const birthday =
        action.user && action.user.birthday && new Date(action.user.birthday)
            ? new Date(action.user.birthday).toISOString().split('T')[0]
            : null
    const user = action.user
        ? {
              firstName: action.user.firstName || null,
              lastName: action.user.lastName || null,
              email: action.user.email || null,
              birthday: birthday,
              address: address,
              contactDetails: action.user.contactDetails,
              description: action.user.description || null,
              get indexPhoneNumber() {
                  const index = this.contactDetails.findIndex(
                      contactDetail =>
                          contactDetail.contactType &&
                          contactDetail.contactType.id === 1,
                  )
                  return index
              },
          }
        : null

    switch (action.type) {
        case userConstants.LOGIN_REQUEST:
            return Object.assign(prevState, {
                user: null,
                subscriptions: null,
                loading: prevState.loading + 1,
            })

        case userConstants.UPDATE_USER:
            return Object.assign(prevState, {
                user: null,
                loading: prevState.loading + 1,
            })
        case userConstants.REQUEST_USER:
            return Object.assign(prevState, {
                user: null,
                loading: prevState.loading + 1,
            })
        case userConstants.SUBSCRIBE_SUCCESS:
            return {
                ...prevState,
                loading: prevState.loading - 1,
            }
        case userConstants.LOGIN_SUCCESS:
            if (!action.user) return { ...prevState }
            return {
                ...prevState,
                user: user,
                loading: prevState.loading - 1,
            }
        case userConstants.UPDATE_SUCCESS:
            if (!action.user) return { ...prevState }
            return {
                ...prevState,
                user: user,
                loading: prevState.loading - 1,
            }
        case userConstants.LOGIN_FAILURE:
            return {
                ...prevState,
                user: null,
                subscriptions: null,
                loading: prevState.loading - 1,
            }
        case userConstants.UPDATE_FAILURE:
            return {
                ...prevState,
                user: null,
                subscriptions: null,
                loading: prevState.loading - 1,
            }
        case userConstants.LOGOUT:
            return {
                ...prevState,
                user: null,
                subscriptions: null,
                loading: prevState.loading - 1,
            }
        case userConstants.REFRESH_TOKEN:
            return {
                ...prevState,
                loading: prevState.loading + 1,
            }
        case userConstants.TOKEN_REFRESH_SUCCESS:
            if (!action.user) return { ...prevState }
            return {
                ...prevState,
                user: user,
                loading: prevState.loading - 1,
            }
        case userConstants.TOKEN_REFRESH_FAILURE:
            return {
                ...prevState,
                user: null,
                subscriptions: null,
                loading: prevState.loading - 1,
            }
        case userConstants.SUBSCRIPTIONS_REQUEST:
            return {
                ...prevState,
                subscriptions: null,
                loading: prevState.loading + 1,
            }
        case userConstants.SUBSCRIPTIONS_SUCCESS:
            if (!action.subscriptions) return { ...prevState }
            return {
                ...prevState,
                subscriptions: action.subscriptions || [],
                loading: prevState.loading - 1,
            }
        case userConstants.SUBSCRIPTIONS_FAILURE:
            return {
                ...prevState,
                subscriptions: null,
                loading: prevState.loading - 1,
            }
        default:
            return prevState
    }
}
