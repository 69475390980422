export default {
    firstName: {
        FR: 'Prénom',
        EN: 'First name',
    },
    lastName: {
        FR: 'Nom',
        EN: 'Last name',
    },
    email: {
        FR: 'E-Mail',
        EN: 'Email',
    },
    phoneNumber: {
        FR: 'Numéro de téléphone',
        EN: 'Phone number',
    },
    address: {
        FR: 'Adresse',
        EN: 'Address',
    },
    description: {
        FR: 'Description',
        EN: 'Description',
    },
    birthday: {
        FR: 'Date de naissance',
        EN: 'Birthday',
    },
    presentationSentence: {
        FR:
            'Présentez-vous en quelques mots pour aider les autres utilisateurs à mieux vous connaître',
        EN:
            'Introduce yourself in a few words to help other users get to know you better',
    },
    forgetPassword: {
        FR: 'Modifiez votre Mot de Passe',
        EN: 'Change your Password',
    },
    emptyMail: {
        FR: 'Vous ne pouvez pas enregistrer une email vide',
        EN: 'You cannot save an empty email',
    },
    emptyFirstName: {
        FR:
            'Désolé mais il nous faut au moins votre nom et prénom, on ne pourra pas vous reconnaître sinon !',
        EN:
            "Sorry but we need at least your first and last name, we won't be able to recognize you otherwise!",
    },
    emptyLastName: {
        FR:
            'Désolé mais il nous faut au moins votre nom et prénom, on ne pourra pas vous reconnaître sinon !',
        EN:
            "Sorry but we need at least your first and last name, we won't be able to recognize you otherwise!",
    },
    emptyPhone: {
        FR:
            'Il faut renseigner un numéro de téléphone pour identifier votre compte',
        EN: 'You must enter a phone number to identify your account.',
    },
    emptyAddress: {
        FR: 'Vous ne pouvez pas enregistrer une adresse vide',
        EN: 'You cannot save an empty address',
    },
}
