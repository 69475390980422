export default theme => ({
    modal: {
        display: 'flex',
        flexDirection: 'column',
        width: '80%',
        alignItems: 'center',
        textAlign: 'center',
        justifyContent: 'center',
        marginRight: 'auto',
        marginLeft: 'auto',
        paddingTop: '15px',
    },
    button: theme.button.primary,
    buttons: Object.assign(
        { width: '100%', paddingTop: '20px' },
        theme.flex.rowSpacearound,
    ),
})
