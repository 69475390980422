import React from 'react'
import { connect } from 'react-redux'
import BoatOnComponent from '../common/BoatOnComponent'
import { fetchData } from '../../actions/api.actions'
import { withStyles } from '@material-ui/core/styles'
import styles from './Styles/PaymentTabCss'
import { API_URL, CLIENT_ID } from '../../services/config.service'
import dictionary from './Dictionary/PaymentTabDico'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'

class PaymentTab extends BoatOnComponent {
    constructor(props) {
        super(props)
        this.dictionary = dictionary

        this.state = {
            stripeAccountID: null,
            urlStripeAccount: null,
            urlStripeCustomer: null,
            urlStripeSetup: null,
            locationDone: null,
        }
    }

    componentDidMount() {
        if (!this.props.userMore) {
            const data = new FormData()
            data.append('UserID', this.props.user.UserID)
            this.props
                .dispatch(
                    fetchData(
                        API_URL + '/userdetails.php',
                        'userdetails',
                        'POST',
                        data,
                    ),
                )
                .then(() => {
                    if (
                        !this.state.urlStripeSetup &&
                        (!this.state.urlStripeAccount ||
                            !this.state.urlStripeCustomer)
                    )
                        this.calculateUrlToStripe()
                })
        } else {
            if (
                !this.state.urlStripeSetup &&
                (!this.state.urlStripeAccount || !this.state.urlStripeCustomer)
            )
                this.calculateUrlToStripe()
        }
        this.props.dispatch(
            fetchData(
                API_URL + '/useHaveDoneLocationParking.php',
                'locationDone',
                'POST',
            ),
        )
        this.props.dispatch(
            fetchData(
                API_URL + '/getStripePortailCustomer.php',
                'portail',
                'POST',
            ),
        )
    }

    componentDidUpdate = prevProps => {
        if (
            !this.state.urlStripeSetup &&
            (!this.state.urlStripeAccount || !this.state.urlStripeCustomer) &&
            prevProps.update &&
            !this.props.update
        )
            this.calculateUrlToStripe()
    }

    async calculateUrlToStripe() {
        const { dispatch, userMore } = this.props

        if (!userMore || !userMore.user) {
            return
        }

        const { StripeAccountID } = userMore.user

        if (StripeAccountID) {
            const data = new FormData()
            data.append('StripeAccountID', StripeAccountID)
            // calculate  urlDashboard
            const urlStripeAccount = await dispatch(
                fetchData(
                    `${API_URL}/stripeloginlink.php`,
                    'stripe',
                    'POST',
                    data,
                ),
            ).then(response => {
                if (response) {
                    this.setState({
                        urlStripeAccount: response.urlStripe,
                    })
                }
            })
            return
        }

        const EmailAddress = userMore.user.EmailAddress || ''
        const FirstName = userMore.user.FirstName || ''
        const LasterName = userMore.user.LasterName || ''
        let PhoneNumber = userMore.user.PhoneNumber || ''
        const city = userMore.user.City || ''
        const zip = userMore.user.PostalCode || ''
        const street_address = userMore.user.FullAddress || ''

        PhoneNumber =
            PhoneNumber.indexOf('+33') === 0
                ? PhoneNumber.substr(3) // format phone number de stripe +33 x xx xx xx, il n'y a pas 0
                : PhoneNumber.substr(1)

        if (EmailAddress) {
            const urlStripeSetup = `https://connect.stripe.com/express/oauth/authorize?response_type=code&amp;client_id=${CLIENT_ID}&amp;&stripe_user[first_name]=${FirstName}&amp;&stripe_user[last_name]=${LasterName}&amp;stripe_user[email]=${EmailAddress}&amp;stripe_user[country]=FR&amp;stripe_user[phone_number]=${PhoneNumber}&amp;stripe_user[url]=https%3A%2F%2Fboaton.fr&amp;stripe_user[city]=${city}&amp;stripe_user[zip]=${zip}&amp;stripe_user[street_address]=${street_address}&amp;redirect_uri=https://boaton.fr/profile&amp;scope=read_write`
            this.setState({ urlStripeSetup })
        }
    }

    renderSub() {
        const { classes, userMore, portail } = this.props
        if (
            userMore &&
            userMore.user &&
            userMore.user.StripeCustomerID &&
            userMore.user.LogbookSubscribed &&
            portail &&
            portail.success
        ) {
            const url = portail.session.url
            return (
                <>
                    <Typography className={classes.subtitle}>
                        {this.displayText('subBob')}
                    </Typography>
                    <Typography>
                        {this.displayText('textSubBobFirst')}{' '}
                        {this.displayText('textSubBobSecond')}
                    </Typography>
                    <a
                        target="_blank"
                        rel="noopener noreferrer"
                        alt="connection"
                        title="connection"
                        href={url}
                        className={classes.connectButton}
                    >
                        <Button
                            color="primary"
                            variant="contained"
                            className={classes.button}
                        >
                            {this.displayText('subButton')}
                        </Button>
                    </a>
                </>
            )
        } else return <></>
    }

    renderPaiement() {
        const { classes, locationDone, portail } = this.props
        if (locationDone && portail && portail.success) {
            const url = portail.session.url
            // a changer avec la reponse du nombre de parking > 0 && urlStripeCustomer
            return (
                <>
                    <Typography className={classes.subtitle}>
                        {this.displayText('paymentHistory')}
                    </Typography>
                    <Typography>
                        {this.displayText('textPaymentHistory')}
                    </Typography>
                    <a
                        target="_blank"
                        rel="noopener noreferrer"
                        alt="connection"
                        title="connection"
                        href={url}
                        className={classes.connectButton}
                    >
                        <Button
                            color="primary"
                            variant="contained"
                            className={classes.button}
                        >
                            {this.displayText('paiementButton')}
                        </Button>
                    </a>
                </>
            )
        } else return <></>
    }

    renderMode() {
        const { classes, portail } = this.props
        if (portail && portail.success) {
            const url = portail.session.url
            return (
                <>
                    <Typography className={classes.subtitle}>
                        {this.displayText('mode')}
                    </Typography>
                    <Typography>{this.displayText('textMode')}</Typography>
                    <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={url}
                        alt="connection"
                        title="connection"
                        className={classes.connectButton}
                    >
                        <Button
                            color="primary"
                            variant="contained"
                            className={classes.button}
                        >
                            {this.displayText('modeButton')}
                        </Button>
                    </a>
                </>
            )
        } else return <></>
    }

    renderReceive() {
        const { classes, userMore } = this.props
        const { urlStripeSetup, urlStripeAccount } = this.state

        if (urlStripeAccount || urlStripeSetup) {
            return (
                <>
                    <Typography className={classes.subtitle}>
                        {this.displayText('transferHistory')}
                    </Typography>
                    <Typography>
                        {this.displayText('textTransferHistory')}
                    </Typography>
                    <a
                        target="_blank"
                        rel="noopener noreferrer"
                        title="transfert"
                        alt="transfert"
                        href={urlStripeAccount || urlStripeSetup}
                        className={classes.connectButton}
                    >
                        <Button
                            color="primary"
                            variant="contained"
                            className={classes.button}
                        >
                            {this.displayText('receiveButton')}
                        </Button>
                    </a>
                </>
            )
        } else return <></>
    }

    render() {
        const { classes, portail } = this.props

        return (
            <div className={classes.root}>
                <Typography className={classes.title}>
                    {this.displayText('paiementAndReceive')}
                </Typography>
                {this.renderMode()}
                {this.renderSub()}
                {this.renderPaiement()}
                {this.renderReceive()}
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        user: state.authentication.user,
        userMore: state.fetch.userdetails,
        locationDone: state.fetch.locationDone
            ? state.fetch.locationDone.success
            : null,
        portail: state.fetch.portail,
    }
}

export default withStyles(styles)(connect(mapStateToProps)(PaymentTab))
