export default {
    subButton: {
        FR: 'GÉRER MON ABONNEMENT',
        EN: 'MANAGE MY SUBSCRIPTION',
    },
    paiementButton: {
        FR: 'VOIR MES PAIEMENTS',
        EN: 'SEE MY PAYMENTS',
    },
    modeButton: {
        FR: 'GÉRER MON MODE DE PAIEMENT',
        EN: 'MANAGE MY PAYMENT MEAN',
    },
    receiveButton: {
        FR: 'VOIR MES VIREMENTS',
        EN: 'SEE MY PAYOUTS',
    },
    paiementAndReceive: {
        FR: 'Paiements et versements',
        EN: 'Payments and installments',
    },
    mode: {
        FR: 'Modes de paiement',
        EN: 'Payment means',
    },
    subBob: {
        FR: 'Abonnement BoatOn Book',
        EN: 'BoatOn Book Subscription',
    },
    paymentHistory: {
        FR: 'Historique des paiements',
        EN: 'Payment History',
    },
    transferHistory: {
        FR: 'Historique des virements',
        EN: 'Payouts history',
    },
    textMode: {
        FR:
            "Ajoutez ou modifiez un mode de paiement à l'aide de notre système de paiement sécurisé puis faites votre demande en 1 clic.",
        EN:
            'Add or modify a payment method using our secure payment system and make your request in 1 click.',
    },
    textSubBobFirst: {
        FR: 'Gérez votre abonnement au BoatOn Book, le 1er ',
        EN: 'Manage your subscription to the BoatOn Book, the 1st',
    },
    textSubBobSecond: {
        FR: " carnet d'entretien en ligne spécialisé bateau.",
        EN: ' online service book dedicated to boats.',
    },
    textPaymentHistory: {
        FR: 'Retrouvez tous les paiements que vous avez effectué.',
        EN: 'Retrieve all the payments you have made.',
    },
    textTransferHistory: {
        FR:
            'Consultez tous les versements que vous avez reçu et modifiez ou ajoutez un mode de virement. Toutes les informations demandées sont indispensables pour procéder à ces virements sur votre compte en banque.',
        EN:
            'View all the payments you have received and change or add a payout mean. All the information requested is essential to make these payouts to your bank account.',
    },
}
