import { typesConstants } from '../constants'
import { typesService } from '../services/types.service'
import { requestApi } from '../services/api.service'

const requestBoatTypes = () => {
    return dispatch => {
        dispatch({
            type: typesConstants.REQUEST_BOATTYPES,
        })
        return typesService.requestBoatTypes().then(
            result => {
                dispatch({
                    type: typesConstants.REQUEST_BOATTYPES_SUCCESS,
                    boatTypes: result,
                })
                return result
            },
            error => {
                dispatch({
                    type: typesConstants.REQUEST_BOATTYPES_FAILURE,
                    error,
                })
            },
        )
    }
}

const requestEngineTypes = () => {
    return dispatch => {
        dispatch({
            type: typesConstants.REQUEST_ENGINETYPES,
        })
        return requestApi('/types/engine', 'GET').then(
            result => {
                dispatch({
                    type: typesConstants.REQUEST_ENGINETYPES_SUCCESS,
                    engineTypes: result,
                })
                return result
            },
            error => {
                dispatch({
                    type: typesConstants.REQUEST_ENGINETYPES_FAILURE,
                    error,
                })
            },
        )
    }
}

const requestEventTypes = () => {
    return dispatch => {
        dispatch({
            type: typesConstants.REQUEST_EVENTTYPES,
        })
        return requestApi('/event-types', 'GET').then(
            result => {
                dispatch({
                    type: typesConstants.REQUEST_EVENTTYPES_SUCCESS,
                    eventTypes: result,
                })
                return result
            },
            error => {
                dispatch({
                    type: typesConstants.REQUEST_EVENTTYPES_FAILURE,
                    error,
                })
            },
        )
    }
}

const getEventTypeById = (eventTypes, id) => {
    const index = eventTypes.find(eventType => eventType.id === id)
    return index
}

const getEventTypeDocument = eventTypes => {
    if (!eventTypes || !Array.isArray(eventTypes)) return null
    return eventTypes.filter(eventType => eventType.document)
}

const getEventTypeTransaction = eventTypes => {
    if (!eventTypes || !Array.isArray(eventTypes)) return null
    return eventTypes.filter(
        eventType => eventType.earning || eventType.spending,
    )
}

export const typesActions = {
    requestBoatTypes,
    requestEngineTypes,
    requestEventTypes,
    getEventTypeById,
    getEventTypeTransaction,
    getEventTypeDocument,
}
