export default theme => ({
    line: theme.flex.row,
    statusWaiting: {
        margin: '0',
        fontStyle: 'italic',
        color: theme.palette.waiting.main,
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
    statusSend: {
        margin: '0',
        fontStyle: 'italic',
        color: theme.palette.secondary.main,
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
    statusTreated: {
        margin: '0',
        fontStyle: 'italic',
        color: theme.palette.accepted.main,
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
    statusAccepted: {
        margin: '0',
        fontStyle: 'italic',
        color: theme.palette.primary.main,
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
    statusDeclined: {
        margin: '0',
        fontStyle: 'italic',
        color: theme.palette.declined.main,
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
})
