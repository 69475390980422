export default theme => ({
    modal: {
        display: 'flex',
        flexDirection: 'column',
        width: 'calc(80% + 60px)',
        alignItems: 'center',
        textAlign: 'center',
        justifyContent: 'center',
        marginRight: 'auto',
        marginLeft: 'auto',
        paddingTop: '15px',
    },
    infos: {
        maxHeight: '80vh',
        overflowY: 'auto',
        width: '100%',
        padding: '0 30px',
    },
    info: {
        marginBottom: '20px',
    },
    button: theme.button.primary,
    buttons: Object.assign(
        { width: '100%', paddingTop: '20px' },
        theme.flex.rowSpacearound,
    ),
    typeAndYear: Object.assign({ width: '100%' }, theme.flex.rowSpacebetween),
    delete: {
        color: theme.palette.declined.main,
        cursor: 'pointer',
    },
    add: { color: theme.palette.primary.main, cursor: 'pointer' },
    engineAndDelete: Object.assign(
        { margin: 'auto', width: '100%' },
        theme.flex.rowAlignCenter,
    ),
    subtitle: {
        marginLeft: '20px',
        marginRight: '20px',
        fontWeight: '500',
        [theme.breakpoints.down('sm')]: {
            marginLeft: 0,
        },
    },
})
