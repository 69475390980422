import React from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem'
import ReactGA from 'react-ga'
import ReactPixel from 'react-facebook-pixel'
import BoatOnComponent from '../../../common/BoatOnComponent'
import AutoComplete from '../../../common/AutoComplete'
import SaveBar from '../../../common/SaveBar'
import { fetchData } from '../../../../actions/api.actions'

import styles from './Styles/BoatInfoPageCss'
import dictionary from './Dictionary/BoatInfoPageDico'
import ModalConnection from '../../../common/UsefullComponents/ModalConnection/ModalConnection'
import BoatOnModal from '../../../common/BoatOnModal'
import { API_URL } from '../../../../services/config.service'
import AddressSearch from '../../../RentParkingPage/RentNavigationPages/AddressSearch'
import EngineInfoPage from './EngineInfoPage'
import Button from '@material-ui/core/Button'
import AddIcon from '@material-ui/icons/Add'
import {
    updateBOBBoat,
    fetchDataBOB,
    updateBOBEngines,
} from '../../../../actions/bob.actions'
import RenderEngineCreate from '../../../common/UsefullComponents/RenderEngineCreate'
import RenderBoatCreate from '../../../common/UsefullComponents/RenderBoatCreate'
import { typesActions } from '../../../../actions'

class BoatInfoPage extends BoatOnComponent {
    constructor(props) {
        super(props)
        this.dictionary = dictionary
        this.initializeReactGA()
        this.state = {
            boats: null,
            resetSave: false,
            openModalConnection: true,
            openModalNoBoat: false,
            openModalNewBoat: false,
            openModalDeleteBoat: false,
            boatNameCreate: '',
            makerCreate: '',
            modelCreate: '',
            yearCreate: '',
            engineCreate: '',
            stockageCreate: '',
            boatTypeCreate: '',
            boatCreation: '',
            searchResult: [],
            searchResultMaker: [],
            boatid: '',
            engines: null,
        }

        const options = {
            autoConfig: true,
            debug: false,
        }

        ReactPixel.init('181677145678471', options)

        ReactPixel.pageView()

        this.save = this.save.bind(this)
        this.handleOpenModalNewBoat = this.handleOpenModalNewBoat.bind(this)
        this.updateEngine = this.updateEngine.bind(this)
        this.handleResetSave = this.handleResetSave.bind(this)
        this.handleClose = this.handleClose.bind(this)
        this.handleListSelection = this.handleListSelection.bind(this)
        this.setAddress = this.setAddress.bind(this)
        this.renderNoBoatModal = this.renderNoBoatModal.bind(this)
    }

    initializeReactGA() {
        ReactGA.initialize('UA-93941518-1')
        ReactGA.pageview('/my-boat-logbook/my-informations')
    }

    componentDidMount() {
        const { boatTypes, loadingTypes } = this.props

        if (!boatTypes && !loadingTypes) {
            this.props.dispatch(typesActions.requestBoatTypes())
        }

        if (
            (!this.state.boats && this.props.bob.boat) ||
            JSON.stringify(this.state.boats) !==
                JSON.stringify(this.props.bob.boat)
        ) {
            this.setBoat()
        }
        if (
            (!this.state.engines && this.props.bob.engines) ||
            JSON.stringify(this.state.engines) !==
                JSON.stringify(this.props.bob.engines)
        ) {
            this.setEngine()
        }
    }

    componentDidUpdate(prevProps) {
        const { boatTypes, loadingTypes } = this.props
        if (this.state.resetSave) {
            this.setState({ resetSave: false })
        }

        if (!boatTypes && !loadingTypes) {
            this.props.dispatch(typesActions.requestBoatTypes())
        }

        if (
            this.props.bob.loading === 0 &&
            prevProps.bob.loading !== 0 &&
            ((!this.state.boats && this.props.bob.boat) ||
                JSON.stringify(this.state.boats) !==
                    JSON.stringify(this.props.bob.boat))
        ) {
            this.setBoat()
            this.setState({ resetSave: true })
        }
        if (
            this.props.bob.loading === 0 &&
            prevProps.bob.loading !== 0 &&
            ((!this.state.engines && this.props.bob.engines) ||
                JSON.stringify(this.state.engines) !==
                    JSON.stringify(this.props.bob.engines))
        ) {
            this.setEngine()
            this.setState({ resetSave: true })
        }
    }

    updateEngine(engines) {
        this.setState({ engines: engines })
    }

    handleClose() {
        this.setState({
            openModalConnection: false,
            openModalNewBoat: false,
            newBoatId: false,
        })
    }

    setBoat() {
        const { bob } = this.props
        this.setState(prevState => ({
            boats: bob.boat,
        }))
    }

    setEngine() {
        const { engines } = this.props.bob
        this.setState({
            engines: engines,
        })
    }

    handleResetSave() {
        this.setState({ resetSave: false })
    }

    handleChange(value, key) {
        this.setState(prevState => ({
            boats: {
                ...prevState.boats,
                [key]: value,
            },
        }))
    }

    setAddress(address) {
        this.setState(prevState => ({
            boats: {
                ...prevState.boats,
                address: address,
            },
        }))
    }

    handleSearchList(value, key) {
        if (!value) {
            // no value no results
            return this.setState({ searchResult: [], modelCreate: value })
        }

        const { listBoats } = this.props
        let matches = []

        if (key === 'boatModel') {
            for (let i in listBoats.boatModels) {
                if (
                    listBoats.boatModels[i].libelleModeleBateau
                        .toLowerCase()
                        .includes(value.toLowerCase())
                ) {
                    matches.push(listBoats.boatModels[i])
                }
            }
            this.setState({
                searchResult: matches.length < 50 ? matches : [],
                modelCreate: value,
            })
        } else {
            for (let i in listBoats.boatModels) {
                if (
                    listBoats.boatModels[i].libelleConstructeur
                        .toLowerCase()
                        .includes(value.toLowerCase())
                ) {
                    matches.push(listBoats.boatModels[i].libelleConstructeur)
                }
            }
            matches = [...new Set(matches)]
            this.setState({
                searchResultMaker: matches.length < 50 ? matches : [],
                makerCreate: value,
            })
        }
    }

    handleListSelection(value, type) {
        if (value.libelleGenre === 'Bateau à moteur / jetski')
            value.libelleGenre = this.state.boats.boatType
        if (type === 'maker') {
            this.setState(prevState => ({
                boats: {
                    ...prevState.boats,
                    boatMaker: value,
                },
                searchResultMaker: [],
            }))
        } else {
            this.setState(prevState => ({
                boats: {
                    ...prevState.boats,
                    boatModel: value.libelleModeleBateau,
                    boatMaker: value.libelleConstructeur,
                    boatType: value.libelleGenre,
                },
                searchResult: [],
            }))
        }
    }

    handleOpenModalNewBoat(step, boatId) {
        const { user } = this.props
        if (step) this.setState({ openModalNewBoat: step, newBoatId: boatId })
        else {
            if (user) {
                var data = new FormData()
                data.append('UserID', user.UserID)
                this.props
                    .dispatch(
                        fetchData(
                            API_URL + '/boatgetall.php',
                            'boats',
                            'POST',
                            data,
                        ),
                    )
                    .then(() => {
                        this.handleClose()
                        this.getBOB()
                    })
            }
        }
    }

    getBOB() {
        const { boats, user } = this.props
        console.log('update bob data')
        if (boats && boats.success && boats.boats.length > 0) {
            this.props.dispatch(
                fetchDataBOB(boats.boats[boats.boats.length - 1], user),
            )
        }
    }

    save() {
        const { boats, engines } = this.state

        this.setState({ resetSave: true }, () => {
            this.props.dispatch(updateBOBEngines(engines, boats.id))
            this.props.dispatch(updateBOBBoat(boats))
        })
    }

    renderNoBoatModal() {
        return (
            <BoatOnModal
                openCondition={this.state.openModalNewBoat}
                handleClose={this.handleClose}
                rendersModal={{
                    boatInfos: (
                        <RenderBoatCreate valid={this.handleOpenModalNewBoat} />
                    ),
                    enginesInfos: (
                        <RenderEngineCreate
                            valid={this.handleOpenModalNewBoat}
                            boatId={this.state.newBoatId}
                        />
                    ),
                }}
                titles={{
                    boatInfos: this.displayText('boatAdd'),
                    enginesInfos: this.displayText('enginesAdd'),
                }}
                disableFullscreen
            />
        )
    }

    render() {
        const {
            classes,
            user,
            loading,
            userdetails,
            boatTypes,
            loadingTypes,
            loadingBoat,
        } = this.props
        const { engines } = this.state
        const isMobile = window.innerWidth <= 500

        if (loading !== 0 || loadingTypes !== 0 || loadingBoat !== 0) {
            return <></>
        }

        if (user === null) {
            return (
                <ModalConnection
                    open={this.state.openModalConnection}
                    handleClose={() => {
                        this.handleClose()
                    }}
                />
            )
        }

        if (!this.state.boats) {
            return (
                <>
                    <Typography className={classes.title}>
                        {this.displayText('noboat')}
                    </Typography>
                    <Button
                        className={classes.buttonAddCenter}
                        variant="contained"
                        color="primary"
                        onClick={() => this.handleOpenModalNewBoat('boatInfos')}
                        endIcon={<AddIcon className={classes.buttonSvg} />}
                    >
                        {this.displayText('addBoat')}
                    </Button>
                    {this.renderNoBoatModal()}
                </>
            )
        }

        return (
            <div className={classes.root}>
                {loading === 0 ? (
                    <div className={classes.infos}>
                        <div className={classes.boat}>
                            <div className={classes.titleAndButton}>
                                <Typography className={classes.title}>
                                    {this.displayText('boat')}
                                </Typography>
                            </div>
                            <div className={classes.textAndField}>
                                <Typography className={classes.text}>
                                    {this.displayText('boatName')}:{' '}
                                </Typography>
                                <TextField
                                    id="name"
                                    margin="dense"
                                    required
                                    variant="outlined"
                                    placeholder="BoatOn"
                                    value={this.state.boats.name || ''}
                                    onChange={e => {
                                        this.handleChange(
                                            e.target.value,
                                            'name',
                                        )
                                    }}
                                    className={classes.field}
                                />
                            </div>
                            <div className={classes.textAndField}>
                                <div className={classes.firstPart}>
                                    <Typography
                                        className={
                                            classes.lineWithMultiTextLeft
                                        }
                                    >
                                        {this.displayText('boatType')}:{' '}
                                    </Typography>
                                    <TextField
                                        id="boatTypeId"
                                        margin="dense"
                                        required
                                        select
                                        variant="outlined"
                                        value={this.state.boats.boatTypeId}
                                        onChange={e => {
                                            this.handleChange(
                                                e.target.value,
                                                'boatTypeId',
                                            )
                                        }}
                                        className={classes.lineWithMultiField}
                                    >
                                        <MenuItem value={-1}>
                                            <em>
                                                Choisissez un type de bateau
                                            </em>
                                        </MenuItem>
                                        {boatTypes.map(type => (
                                            <MenuItem
                                                key={type.id}
                                                value={type.id}
                                            >
                                                {type.typeFR.toLowerCase()}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </div>
                                <div className={classes.secondPart}>
                                    <Typography
                                        className={
                                            classes.lineWithMultiTextRight
                                        }
                                    >
                                        {this.displayText('boatYear')}:{' '}
                                    </Typography>
                                    <TextField
                                        id="year"
                                        margin="dense"
                                        required
                                        variant="outlined"
                                        type="number"
                                        placeholder="2000"
                                        value={this.state.boats.year || ''}
                                        onChange={e => {
                                            this.handleChange(
                                                e.target.value,
                                                'year',
                                            )
                                        }}
                                        className={classes.lineWithMultiField}
                                    />
                                </div>
                            </div>
                            <AutoComplete
                                page="BoatInfoPageMaker"
                                type="maker"
                                searchResult={this.state.searchResultMaker}
                                handleListSelection={this.handleListSelection}
                            />
                            {!isMobile ? (
                                <AutoComplete
                                    page="BoatInfoPageModel"
                                    searchResult={this.state.searchResult}
                                    handleListSelection={
                                        this.handleListSelection
                                    }
                                />
                            ) : (
                                <></>
                            )}
                            <div className={classes.textAndField}>
                                <div className={classes.firstPart}>
                                    <Typography
                                        className={
                                            classes.lineWithMultiTextLeft
                                        }
                                    >
                                        {this.displayText('boatMaker')}:{' '}
                                    </Typography>
                                    <TextField
                                        id="maker"
                                        margin="dense"
                                        required
                                        variant="outlined"
                                        placeholder="Beneteau"
                                        value={this.state.boats.maker || ''}
                                        onChange={e => {
                                            this.handleChange(
                                                e.target.value,
                                                'maker',
                                            )
                                        }}
                                        className={classes.lineWithMultiField}
                                    />
                                </div>
                                {isMobile ? (
                                    <AutoComplete
                                        page="BoatInfoPageModel"
                                        searchResult={this.state.searchResult}
                                        handleListSelection={
                                            this.handleListSelection
                                        }
                                    />
                                ) : (
                                    <></>
                                )}
                                <div className={classes.secondPart}>
                                    <Typography
                                        className={
                                            classes.lineWithMultiTextRight
                                        }
                                    >
                                        {this.displayText('boatModel')}:{' '}
                                    </Typography>
                                    <TextField
                                        id="model"
                                        margin="dense"
                                        required
                                        variant="outlined"
                                        value={this.state.boats.model || ''}
                                        onChange={e => {
                                            this.handleChange(
                                                e.target.value,
                                                'model',
                                            )
                                        }}
                                        className={classes.lineWithMultiField}
                                    />
                                </div>
                            </div>
                            <div className={classes.textAndField}>
                                <Typography className={classes.text}>
                                    {this.displayText('boatRegistration')}:{' '}
                                </Typography>
                                <TextField
                                    id="serialNumber"
                                    margin="dense"
                                    required
                                    variant="outlined"
                                    placeholder="XX00000"
                                    value={this.state.boats.serialNumber || ''}
                                    onChange={e => {
                                        this.handleChange(
                                            e.target.value,
                                            'serialNumber',
                                        )
                                    }}
                                    className={classes.field}
                                />
                            </div>
                            <div className={classes.textAndField}>
                                <Typography className={classes.text}>
                                    {this.displayText('homePortOrStorage')}:{' '}
                                </Typography>
                                <AddressSearch
                                    className={classes.searchAddress}
                                    setAddress={this.setAddress}
                                    fullAddressInfo={this.state.boats.address}
                                    labelHide
                                    style={{ width: '70%', margin: 'auto' }}
                                ></AddressSearch>
                            </div>
                            <div className={classes.textAndField}>
                                <Typography className={classes.text}>
                                    {this.displayText('boatMoreInformations')}:{' '}
                                </Typography>
                                <TextField
                                    id="description"
                                    margin="dense"
                                    required
                                    variant="outlined"
                                    placeholder={this.displayText(
                                        'importantInformations',
                                    )}
                                    multiline
                                    value={this.state.boats.description || ''}
                                    onChange={e => {
                                        this.handleChange(
                                            e.target.value,
                                            'description',
                                        )
                                    }}
                                    className={classes.field}
                                />
                            </div>
                        </div>
                        <EngineInfoPage
                            engines={engines}
                            updateEngine={this.updateEngine}
                            deleteEngine={this.deleteEngine}
                        />
                    </div>
                ) : (
                    this.renderLoading('5%')
                )}
                {userdetails &&
                userdetails.success &&
                userdetails.user.MultiBoatSubscribed === '1' ? (
                    <div className={classes.bottom}>
                        <Button
                            className={classes.buttonAdd}
                            variant="contained"
                            color="primary"
                            onClick={() =>
                                this.handleOpenModalNewBoat('boatInfos')
                            }
                            endIcon={<AddIcon className={classes.buttonSvg} />}
                        >
                            {this.displayText('addBoat')}
                        </Button>
                    </div>
                ) : (
                    <></>
                )}
                <SaveBar
                    save={{ boats: this.state.boats, engines: engines }}
                    new={{ boats: this.state.boats, engines: engines }}
                    onCancel={save =>
                        this.setState({
                            boats: save.boats,
                            engines: save.engines,
                        })
                    }
                    onSave={this.save}
                    handleResetSave={this.handleResetSave}
                    resetSave={this.state.resetSave}
                    style={{ marginLeft: '20%' }}
                />

                {this.renderNoBoatModal()}
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        user: state.authentication.user,
        userdetails: state.fetch.userdetails,
        boats: state.boat.boats,
        alert: state.alert,
        state: state.open,
        listBoats: state.fetch.listboatmodels,
        engines: state.bob.engines,
        loading: state.bob.loading,
        bob: state.bob,
        boatTypes: state.types.boatTypes,
        loadingTypes: state.types.loading,
        loadingBoat: state.boat.loading,
    }
}

export default connect(mapStateToProps)(withStyles(styles)(BoatInfoPage))
