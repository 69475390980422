export default {
    date: {
        FR: 'Date',
        EN: 'Date',
    },
    or: {
        FR: 'ou',
        EN: 'or',
    },
    hours: {
        FR: "nombre d'heures",
        EN: 'Hours',
    },
}
