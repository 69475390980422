export default {
    error: {
        FR: 'E-Mail ou mot de passe incorrect',
        EN: 'Incorrect E-Mail or password',
    },
    connection: {
        FR: 'Connexion',
        EN: 'Connection',
    },
    invalidEmail: {
        FR: "L'email entré n'est pas valide !",
        EN: 'The email entered is not valid',
    },
    nbCharacterPassword: {
        FR: 'Votre mot de passe doit faire au moins 6 caractères',
        EN: 'Your password must be at least 6 characters',
    },
}
