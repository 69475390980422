import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { LS_USER_KEY } from '../../services/config.service'
import AppRoute from '../../constants/AppRoute'
import { useIntl } from 'react-intl'

export const PrivateRoute = ({ component: Component, ...rest }) => {
    const { formatMessage, locale } = useIntl()

    return (
        <Route
            {...rest}
            render={props =>
                localStorage.getItem(LS_USER_KEY) ? (
                    <Component {...props} />
                ) : (
                    <Redirect
                        to={{
                            pathname:
                                '/' +
                                locale +
                                formatMessage({ id: AppRoute.Login }),
                            state: { from: props.location },
                        }}
                    />
                )
            }
        />
    )
}
