let appConfig = {
    API_URL: 'https://newapi.boaton.fr',
    LS_USER_KEY: 'boaton_public_user',
    STRIPE_PUBLIC_KEY: 'stripe_public_key',
    FB_PIXEL_KEY: 'facebook_pixel_id',
    GA_KEY: 'google_analytics_key',
    CLIENT_ID: 'ca_AiRAJVt08r4KKe9nDT4xj881b78N05np',
}

try {
    // first we override with ENV config
    appConfig = Object.assign(appConfig, {
        API_URL: process.env.REACT_APP_API_URL,
        STRIPE_PUBLIC_KEY: process.env.REACT_APP_STRIPE_PUBLIC_KEY,
        FB_PIXEL_KEY: process.env.REACT_APP_FB_PIXEL_KEY,
        GA_KEY: process.env.REACT_APP_GA_KEY,
    })
    console.info('[config] config was parsed from ENV')
} catch (ex) {
    console.error('[config] error while parsing ENV config', ex)
}

try {
    let configFile = require('../config.json')
    console.info('[config] a configuration file was found')
    appConfig = Object.assign(appConfig, configFile)
} catch (ex) {
    console.error('[config] no config.json, ignoring')
}

export const {
    API_URL,
    LS_USER_KEY,
    STRIPE_PUBLIC_KEY,
    FB_PIXEL_KEY,
    GA_KEY,
    CLIENT_ID,
    FILES,
} = appConfig
