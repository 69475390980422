import React from 'react'
import { connect } from 'react-redux'
import MenuItem from '@material-ui/core/MenuItem'
import { withStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import BoatOnComponent from '../BoatOnComponent'
import styles from './Styles/RenderBoatCreateCss'
import dictionary from './Dictionary/RenderBoatCreateDico'
import AutoComplete from '../AutoComplete'
import AddressSearch from '../../RentParkingPage/RentNavigationPages/AddressSearch'
import InputAdornment from '@material-ui/core/InputAdornment'
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd'
import { postData } from '../../../actions/api.actions'
import { API_URL } from '../../../services/config.service'
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import HomeIcon from '@material-ui/icons/Home'
import DirectionsBoatIcon from '@material-ui/icons/DirectionsBoat'
import InfoIcon from '@material-ui/icons/Info'
import EventIcon from '@material-ui/icons/Event'
import { typesActions } from '../../../actions'

class RenderBoatCreate extends BoatOnComponent {
    constructor(props) {
        super(props)
        this.dictionary = dictionary

        this.state = {
            boatNameCreate: '',
            makerCreate: '',
            modelCreate: '',
            yearCreate: '1999',
            boatTypeCreate: -1,
            address: '',
            searchResult: [],
            searchResultMaker: [],
            focused: false,
        }

        this.handleChange = this.handleChange.bind(this)
        this.handleChangeYear = this.handleChangeYear.bind(this)
        this.handleListSelection = this.handleListSelection.bind(this)
        this.setAddress = this.setAddress.bind(this)
        this.valid = this.valid.bind(this)
        this.handleAddressChange = this.handleAddressChange.bind(this)
    }

    componentDidMount() {
        const { boatTypes } = this.props
        if (!boatTypes) {
            this.props.dispatch(typesActions.requestBoatTypes())
        }
    }

    handleSearchList(value, key) {
        if (!value) {
            // no value no results
            return this.setState({ searchResult: [], modelCreate: value })
        }

        const { listBoats } = this.props
        let matches = []
        if (key === 'modelCreate') {
            for (let i in listBoats.boatModels) {
                if (
                    listBoats.boatModels[i].libelleModeleBateau
                        .toLowerCase()
                        .includes(value.toLowerCase())
                ) {
                    matches.push(listBoats.boatModels[i])
                }
            }
            this.setState({
                searchResult: matches.length < 50 ? matches : [],
                modelCreate: value,
            })
        } else {
            for (let i in listBoats.boatModels) {
                if (
                    listBoats.boatModels[i].libelleConstructeur
                        .toLowerCase()
                        .includes(value.toLowerCase())
                ) {
                    matches.push(listBoats.boatModels[i].libelleConstructeur)
                }
            }
            matches = [...new Set(matches)]
            this.setState({
                searchResultMaker: matches.length < 50 ? matches : [],
                makerCreate: value,
            })
        }
    }

    handleAddressChange() {
        this.setState({ focused: 'address' })
    }

    handleChange(value, key) {
        this.setState({ focused: key })
        /*if (key === 'modelCreate' || key === 'makerCreate')
            this.handleSearchList(value, key)*/
        this.setState({ [key]: value })
    }

    handleChangeYear(value, key) {
        this.setState({ focused: key })
        this.setState({ [key]: value.getFullYear().toString() })
    }

    setAddress(address) {
        this.setState({
            address: address,
        })
    }

    handleListSelection(value, type) {
        if (value.libelleGenre === 'Bateau à moteur / jetski')
            value.libelleGenre = this.state.boatTypeCreate

        if (type === 'maker') {
            this.setState({
                makerCreate: value,
                searchResultMaker: [],
            })
        } else {
            this.setState({
                modelCreate: value.libelleModeleBateau,
                boatTypeCreate: value.libelleGenre,
                makerCreate: value.libelleConstructeur,
                searchResult: [],
            })
        }
    }

    valid(e) {
        const { valid } = this.props
        this.setState({ focused: false })
        e.preventDefault()
        let body = {
            name: this.state.boatNameCreate,
            maker: this.state.makerCreate || null,
            model: this.state.modelCreate || null,
            year: this.state.yearCreate
                ? parseInt(this.state.yearCreate)
                : null,
            boatType: {
                id:
                    this.state.boatTypeCreate && this.state.boatTypeCreate >= 0
                        ? parseInt(this.state.boatTypeCreate)
                        : null,
            },
        }
        const address =
            this.state.address && this.state.address.fullText
                ? {
                      fullAddress: this.state.address.fullText,
                      latitude: this.state.address.latitude.toString(),
                      longitude: this.state.address.longitude.toString(),
                  }
                : null

        if (address) body.address = address
        this.props
            .dispatch(postData(API_URL + '/boats', body))
            .then(data => {
                if (data && data[0] && data[0].id)
                    valid('enginesInfos', data[0].id)
                else console.error('error when creating the boat')
            })
            .catch(error => {
                console.error(error)
            })
        valid('enginesInfos')
    }

    render() {
        const { classes, boatTypes, loadingTypes } = this.props

        if (loadingTypes !== 0 || !boatTypes) {
            return <></>
        }

        return (
            <form key="boat" onSubmit={this.valid}>
                <div className={classes.modal}>
                    <TextField
                        id="boatName"
                        label={this.displayText('boatName')}
                        margin="normal"
                        placeholder="Albatros"
                        required
                        variant="outlined"
                        value={this.state.boatNameCreate}
                        onChange={e => {
                            this.handleChange(e.target.value, 'boatNameCreate')
                        }}
                        fullWidth
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <AssignmentIndIcon />
                                </InputAdornment>
                            ),
                        }}
                    />
                    <TextField
                        id="type"
                        select
                        required
                        variant="outlined"
                        label={this.displayText('boatType')}
                        value={this.state.boatTypeCreate}
                        onChange={e => {
                            this.handleChange(e.target.value, 'boatTypeCreate')
                        }}
                        margin="normal"
                        fullWidth
                        style={{
                            textAlign: 'left',
                        }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <DirectionsBoatIcon />
                                </InputAdornment>
                            ),
                        }}
                    >
                        <MenuItem value={-1}>
                            <em>Choisissez un type de bateau</em>
                        </MenuItem>
                        {boatTypes.map(type => (
                            <MenuItem key={type.id} value={type.id}>
                                {type.typeFR.toLowerCase()}
                            </MenuItem>
                        ))}
                    </TextField>
                    <AutoComplete
                        type="maker"
                        searchResult={this.state.searchResultMaker}
                        handleListSelection={this.handleListSelection}
                    />
                    <TextField
                        id="maker"
                        label={this.displayText('boatMaker')}
                        margin="normal"
                        required
                        placeholder="Beneteau"
                        variant="outlined"
                        value={this.state.makerCreate}
                        onChange={e => {
                            this.handleChange(e.target.value, 'makerCreate')
                        }}
                        fullWidth
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <InfoIcon />
                                </InputAdornment>
                            ),
                        }}
                    />
                    <AutoComplete
                        searchResult={this.state.searchResult}
                        handleListSelection={this.handleListSelection}
                    />
                    <TextField
                        id="model"
                        label={this.displayText('boatModel')}
                        margin="normal"
                        required
                        placeholder="Sun Odyssey 40"
                        variant="outlined"
                        value={this.state.modelCreate}
                        onChange={e => {
                            this.handleChange(e.target.value, 'modelCreate')
                        }}
                        fullWidth
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <InfoIcon />
                                </InputAdornment>
                            ),
                        }}
                    />
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <DatePicker
                            autoOk
                            okLabel=""
                            cancelLabel=""
                            views={['year']}
                            label={this.displayText('boatYear')}
                            value={new Date(this.state.yearCreate)}
                            onChange={e => {
                                this.handleChangeYear(e, 'yearCreate')
                            }}
                            fullWidth
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <EventIcon />
                                    </InputAdornment>
                                ),
                            }}
                            required
                            inputVariant="outlined"
                            placeholder="1999"
                            margin="normal"
                        />
                    </MuiPickersUtilsProvider>
                    <AddressSearch
                        id="port"
                        margin="normal"
                        style={{
                            width: '100%',
                            marginTop: '16px',
                            marginBottom: '8px',
                            textAlign: 'left',
                        }}
                        setAddress={this.setAddress}
                        parkingAddress={null}
                        fullAddressInfo={this.state.address}
                        fullWidth
                        onChange={this.handleAddressChange}
                        label={this.displayText('homePortOrStorage')}
                        placeholder="Port des Minimes, La Rochelle"
                        variant="outlined"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <HomeIcon />
                                </InputAdornment>
                            ),
                        }}
                    />

                    <div className={classes.buttons}>
                        <Button
                            variant="contained"
                            onClick={() => {
                                this.props.valid()
                            }}
                        >
                            {' '}
                            {this.displayText('quit')}{' '}
                        </Button>
                        <Button
                            className={classes.button}
                            type="submit"
                            color="primary"
                            variant="contained"
                        >
                            {' '}
                            {this.displayText('createBoat')}{' '}
                        </Button>
                    </div>
                </div>
            </form>
        )
    }
}

function mapStateToProps(state) {
    return {
        alert: state.alert,
        state: state.open,
        listBoats: state.fetch.listboatmodels,
        boatTypes: state.types.boatTypes,
        loadingTypes: state.types.loading,
    }
}

export default connect(mapStateToProps)(withStyles(styles)(RenderBoatCreate))
