import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import { addressActions } from '../../../actions/address.actions'
import styles from './Styles/SearchBarCss'
import CircularProgress from '@material-ui/core/CircularProgress'

class SearchBar extends Component {
    constructor(props) {
        super(props)

        this.state = {
            loading: false,
        }

        this.handleChange = this.handleChange.bind(this)

        this.cache = {}
    }

    autoCompletion(value) {
        if (!value) {
            this.setState({
                loading: false,
            })
        } else {
            this.props.dispatch(addressActions.requestAddressData(value))
        }
    }

    handleChange(value) {
        this.autoCompletion(value)
        this.props.onChange(value)
    }

    renderAutoCompletion() {
        const { classes } = this.props
        const autoCompletion = this.props.autoCompletion
            ? this.props.autoCompletion
            : null

        return this.props.renderAutocomplete(
            autoCompletion && autoCompletion.status === 'OK'
                ? autoCompletion.results
                : [],
            classes,
        )
    }

    render() {
        const {
            classes,
            notOutlined,
            value,
            labelHide,
            margin,
            InputProps,
        } = this.props
        const InputPropsFinal = Object.assign(
            {
                endAdornment: (
                    <React.Fragment>
                        {this.state.loading ? (
                            <CircularProgress color="inherit" size={20} />
                        ) : null}
                    </React.Fragment>
                ),
            },
            InputProps,
        )
        return (
            <div className={classes.root}>
                <div className={classes.search}>
                    <TextField
                        id="searchBar"
                        margin={margin ? margin : 'dense'}
                        required
                        variant={notOutlined ? 'standard' : 'outlined'}
                        label={labelHide ? null : this.props.label}
                        placeholder={
                            labelHide
                                ? this.props.label
                                : this.props.placeholder
                        }
                        value={value ? value.fullText : null}
                        onChange={e => {
                            this.handleChange(e.target.value)
                        }}
                        className={classes.bar}
                        InputProps={InputPropsFinal}
                    />
                </div>

                {this.renderAutoCompletion()}
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        autoCompletion: state.address.autoComplet,
    }
}

export default connect(mapStateToProps)(withStyles(styles)(SearchBar))
