export default {
    boatOnBook: {
        FR: " carnet d'entretien numérique intelligent",
        EN: 'The 1st intelligent digital service book',
    },
    offers: {
        FR: 'Choisissez votre offre pour gérer votre bateau en toute sérénité',
        EN:
            'Choose your offer to manage your boat with complete peace of mind.',
    },
    monthFree: {
        FR: '1 mois gratuit',
        EN: '1 month free',
    },
    gosubscribe: {
        FR: "C'est parti !",
        EN: "Let's go!",
    },
    offerTitleYear: {
        FR: 'Annuel',
        EN: 'Yearly',
    },
    offerDescYear: {
        FR: "19.99 € pour l'année après le mois gratuit",
        EN: '19.99 € for the year after the free month',
    },
    offerAdvantages1Year: {
        FR: 'Économisez près de 4 euros en optant pour un paiement en une fois',
        EN: 'Save close to 4 euros by opting for a one-time payment',
    },
    offerAdvantages2Year: {
        FR: "Profitez du BoatOn Book toute l'année",
        EN: 'Enjoy the BoatOn Book all year round',
    },
    offerAdvantages3Year: {
        FR:
            "Bénéficiez d'avant-premières et de nouvelles fonctionnalités avant tout le monde",
        EN: 'Get sneak previews and new features before everyone else',
    },
    offerTitleMonth: {
        FR: 'Mensuel',
        EN: 'Monthly',
    },
    offerDescMonth: {
        FR: '1.99 €/mois après le mois gratuit',
        EN: '1.99 €/month after the month for free',
    },
    offerAdvantages1Month: {
        FR: 'Résiliez à tout moment votre abonnement',
        EN: 'Cancel your subscription at any time',
    },
    offerAdvantages2Month: {
        FR: 'Testez le Boaton Book pour la durée que vous souhaitez',
        EN: 'Test the Boaton Book for as long as you like',
    },
    allNeed: {
        FR:
            'entretiens passés et à venir, sorties que vous avez renseignées, dépenses et revenus.',
        EN:
            'entretiens passés et à venir, sorties que vous avez renseignées, dépenses et revenus.',
    },
    noBoat: {
        FR:
            'Bravo, vous pouvez dès à présent profiter du BoatOn Book ! Commencez par ajouter les informations sur votre bateau en cliquant sur le bouton ci-dessous.',
        EN:
            'Bravo, vous pouvez dès à présent profiter du BoatOn Book ! Commencez par ajouter les informations sur votre bateau en cliquant sur le bouton ci-dessous.',
    },
    documents: {
        FR: 'Mes Documents',
        EN: 'My Documents',
    },
    budgets: {
        FR: 'Mon Budget',
        EN: 'My Budget',
    },
    repair: {
        FR: 'Mes entretiens',
        EN: 'My repairs',
    },
    alerts: {
        FR: 'Mes Alertes',
        EN: 'My Alerts',
    },
    logbook: {
        FR: 'Mon BoatOn Book',
        EN: 'My Boat Logbook',
    },
    st: {
        FR: 'er',
        EN: 'st',
    },
    first: {
        FR: 'Le 1',
        EN: 'The 1',
    },
}
