import { enginesConstants } from '../../constants/engines.constants'
import { requestApi } from '../../services/api.service'

// UPDATE THE DATA OF BOB ENGINES //

export const removeDataBOBEngines = ({
    engineId,
    engines,
    handleAlert = json => {},
}) => {
    return dispatch => {
        dispatch({ type: enginesConstants.REQUEST_ENGINES })
        return requestApi('/engines/' + engineId, 'DELETE').then(
            result => {
                dispatch({
                    type: enginesConstants.ENGINES_SUCCESS,
                    engines: engines.filter(engine => engine.id != engineId),
                })
            },
            error => {
                dispatch({ type: enginesConstants.ENGINES_FAILURE, error })
            },
        )
    }
}

export const getBOBEngines = (boatId, handleAlert = json => {}) => {
    return dispatch => {
        dispatch({ type: enginesConstants.REQUEST_ENGINES })
        return requestApi('/engines/boat/' + boatId, 'GET').then(
            result => {
                dispatch({
                    type: enginesConstants.ENGINES_SUCCESS,
                    engines: result,
                })
            },
            error => {
                dispatch({ type: enginesConstants.ENGINES_FAILURE, error })
            },
        )
    }
}

export const postBOBEngines = (engines, boatId, handleAlert = json => {}) => {
    return dispatch => {
        dispatch({ type: enginesConstants.POST_ENGINES })
        const body = engines.map(engine => ({
            model: engine.model,
            maker: engine.maker,
            power: parseInt(engine.power),
            engineType: {
                id:
                    engine.engineTypeId && engine.engineTypeId >= 0
                        ? parseInt(engine.engineTypeId)
                        : null,
            },
            year: parseInt(engine.year),
        }))
        return requestApi('/engines/boat/' + boatId, 'POST', body).then(
            result => {
                dispatch({
                    type: enginesConstants.ENGINES_SUCCESS,
                    engines: result,
                })
            },
            error => {
                dispatch({ type: enginesConstants.ENGINES_FAILURE, error })
            },
        )
    }
}

export const updateBOBEngines = (engines, boatId, handleAlert = json => {}) => {
    return dispatch => {
        dispatch({ type: enginesConstants.PUT_ENGINES })
        const body = engines.map(engine => ({
            id: engine.id || null,
            model: engine.Engine,
            maker: engine.EngineMarker,
            power: parseInt(engine.EnginePower),
            engineType: {
                id:
                    engine.EngineType && engine.EngineType >= 0
                        ? parseInt(engine.EngineType)
                        : null,
            },
            year: parseInt(engine.EngineYear),
            serialNumber: engine.EngineSerialNumber,
            totalTime: parseInt(engine.EngineTime),
        }))
        return requestApi('/engines/boat/' + boatId, 'PUT', body).then(
            result => {
                dispatch({
                    type: enginesConstants.ENGINES_SUCCESS,
                    engines: result,
                })
            },
            error => {
                dispatch({ type: enginesConstants.ENGINES_FAILURE, error })
            },
        )
    }
}
