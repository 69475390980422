import React, { useState } from 'react'
import { connect } from 'react-redux'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Switch from '@material-ui/core/Switch'
import CategorieSelecter from '../../../common/UsefullComponents/CategorieSelecter'

import { withStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import Checkbox from '@material-ui/core/Checkbox'
import dictionary from './Dictionary/InventoryListDico'
import styles from './Styles/TopInventoryCss'
import Button from '@material-ui/core/Button'
import Tooltip from '@material-ui/core/Tooltip'
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'

import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import {
    postDataBOBDocuments,
    removeDataBOBDocuments,
} from '../../../../actions/bob.actions'
import Popover from '@material-ui/core/Popover'
import ButtonFilterPopOver from '../../../ParkingSearchPage/ButtonFilterPopOver'

import Divider from '@material-ui/core/Divider'

import BoatOnComponent from '../../../common/BoatOnComponent'

import Edit from '@material-ui/icons/Edit'
import AddIcon from '@material-ui/icons/Add'
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowUp from '@material-ui/icons/KeyboardArrowUp'
import shoppingcart from '../../../../images/shoppingcart.png'
import instockicon from '../../../../images/instockicon.png'
import shoppingbasket from '../../../../images/shoppingbasket.png'
import bellicon from '../../../../images/bellicon.png'
import Typography from '@material-ui/core/typography'
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers'
import dateUtils from '@date-io/date-fns'
import Locale from 'date-fns/locale/fr'
import VisibilityIcon from '@material-ui/icons/Visibility'
import MoreHorizIcon from '@material-ui/icons/MoreHoriz'
import BoatOnModal from '../../../common/BoatOnModal'
import InventoryModal from './InventoryModal/InventoryModal'

class TopInventory extends BoatOnComponent {
    constructor(props) {
        super(props)
        this.dictionary = dictionary
        this.handleFilters = this.handleFilters.bind(this)
        this.handleOpenPopOver = this.handleOpenPopOver.bind(this)
        this.handleOpenPopOverMore = this.handleOpenPopOverMore.bind(this)
        this.handleClosePopOver = this.handleClosePopOver.bind(this)
        this.handleClosePopOverMore = this.handleClosePopOverMore.bind(this)
        this.handleStockUp = this.handleStockUp.bind(this)
        this.handleStockMissing = this.handleStockMissing.bind(this)
        this.handleStockRenewed = this.handleStockRenewed.bind(this)
        this.filterStockUp = this.filterStockUp.bind(this)
        this.filterStockMissing = this.filterStockMissing.bind(this)
        this.filterStockRenewed = this.filterStockRenewed.bind(this)
        this.handleChangeSearch = this.handleChangeSearch.bind(this)
        this.handleCloseModal = this.handleCloseModal.bind(this)
        this.newInventory = this.newInventory.bind(this)

        this.state = {
            count: 0,
            allEquipement: [
                {
                    inventoryTopTable: {
                        id: 1,
                        categorie: {
                            id: 1,
                            name: 'ELECTRONIQUE',
                            icon: 'ICON',
                        },
                        equipement: 'GPS',
                        typeEquipement: 'Raymarine TX350',

                        date: {
                            startDate: new Date('2021-01-22'),
                            endDate: new Date('2021-06-22'),
                            heure: '1250 h',
                        },
                        stock: 3,
                    },

                    inventoryBottomTable: [
                        {
                            id: 1,
                            equipement: 'Carte graphique',
                            typeEquipement: 'TDR 2356',
                            date: {
                                startDate: new Date('2021-02-23'),
                                endDate: new Date('2021-12-25'),
                            },
                            stock: 2,
                        },

                        {
                            id: 2,
                            equipement: 'Ecran LED',
                            typeEquipement: 'TDR 2356',
                            date: {
                                startDate: new Date('2021-01-22'),
                                endDate: new Date('2021-04-22'),
                            },
                            stock: 0,
                        },
                    ],
                },

                {
                    inventoryTopTable: {
                        id: 2,
                        categorie: {
                            id: 2,
                            name: 'MOTEUR',
                            icon: 'ICON',
                        },
                        equipement: 'Pilote automatique',
                        typeEquipement: 'Furuno HUY122',

                        date: {
                            startDate: new Date('2022-01-22'),
                            endDate: new Date('2022-04-22'),
                            heure: '1250 h',
                        },
                        stock: 1,
                    },

                    inventoryBottomTable: [
                        {
                            id: 1,
                            equipement: 'Répétieur extérieur',
                            typeEquipement: 'Furuno 1234',
                            date: {
                                startDate: new Date('2021-01-22'),
                                endDate: new Date('2021-01-22'),
                            },
                            stock: 0,
                        },

                        {
                            id: 2,
                            equipement: 'Cable',
                            typeEquipement: 'TDX XXXX',
                            date: {
                                startDate: new Date('2021-01-22'),
                                endDate: new Date('2021-04-22'),
                            },
                            stock: 2,
                        },
                    ],
                },
            ],
            propsAllEquipement: [
                {
                    inventoryTopTable: {
                        categorie: {
                            id: 1,
                            name: 'ELECTRONIQUE',
                            icon: 'ICON',
                        },
                        equipement: 'GPS',
                        typeEquipement: 'Raymarine TX350',

                        date: {
                            startDate: new Date('2021-01-22'),
                            endDate: new Date('2021-04-22'),
                        },
                        stock: 3,
                    },

                    inventoryBottomTable: [
                        {
                            equipement: 'Carte graphique',
                            typeEquipement: 'TDR 2356',
                            date: {
                                startDate: new Date('2021-02-23'),
                                endDate: new Date('2021-05-25'),
                            },
                            stock: 2,
                        },

                        {
                            equipement: 'Ecran LED',
                            typeEquipement: 'TDR 2356',
                            date: {
                                startDate: new Date('2021-03-23'),
                                endDate: new Date('2021-04-22'),
                            },
                            stock: 0,
                        },
                    ],
                },

                {
                    inventoryTopTable: {
                        categorie: {
                            id: 2,
                            name: 'MOTEUR',
                            icon: 'ICON',
                        },
                        equipement: 'Pilote automatique',
                        typeEquipement: 'Furuno HUY122',

                        date: {
                            startDate: new Date('2022-01-22'),
                            endDate: new Date('2022-04-22'),
                        },
                        stock: 1,
                    },

                    inventoryBottomTable: [
                        {
                            equipement: 'Répétieur extérieur',
                            typeEquipement: 'Furuno 1234',
                            date: {
                                startDate: new Date('2021-01-22'),
                                endDate: new Date('2021-01-22'),
                            },
                            stock: 2,
                        },

                        {
                            equipement: 'Cable',
                            typeEquipement: 'TDX XXXX',
                            date: {
                                startDate: new Date('2022-01-22'),
                                endDate: new Date('2022-04-22'),
                            },
                            stock: 0,
                        },
                    ],
                },
            ],

            isLoggedIn: '0',
            stockUp: false,
            stockMissing: false,
            stockRenewed: false,
            unfoldAll: [false, false],
            categoriesChecked: [
                false,
                false,
                false,
                false,
                false,
                false,
                false,
            ],
            modalOpen: false,
        }
    }

    handleChangeDate(startDate, endDate, i) {
        this.setState(prevState => {
            let allEquipement = prevState.allEquipement
            allEquipement[i].inventoryTopTable.date = {
                startDate: startDate,
                endDate: endDate,
            }
            return {
                allEquipement: allEquipement,
            }
        })
    }

    handleFilters() {
        this.setState({ filters: !this.state.filters })
    }

    handleOpenPopOver(e, child) {
        this.setState({
            openPopOver: true,
            anchorEl: e.currentTarget,
            childPopOver: child,
        })
    }

    handleClosePopOver() {
        this.setState({
            openPopOver: false,
            anchorEl: null,
            childPopOver: null,
        })
    }

    handleOpenPopOverMore(e, child) {
        this.setState({
            openPopOverMore: true,
            anchorEl: e.currentTarget,
            childPopOver: child,
        })
    }

    handleClosePopOverMore() {
        this.setState({
            openPopOverMore: false,
            anchorEl: null,
            childPopOver: null,
        })
    }

    handleUnfoldAll(value) {
        var { unfoldAll } = this.state

        if (value) {
            unfoldAll.forEach((value, i, array) => {
                array[i] = true
            })
            this.setState({ unfoldAll: unfoldAll })
        } else {
            unfoldAll.forEach((value, i, array) => {
                array[i] = false
            })
            this.setState({ unfoldAll: unfoldAll })
        }
    }

    handleUnfold(value, i) {
        var { unfoldAll } = this.state
        unfoldAll[i] = value
        this.setState({ unfoldAll: unfoldAll })
    }

    handleChangeDateDetails(startDate, endDate, i, y) {
        this.setState(prevState => {
            let allEquipement = prevState.allEquipement
            allEquipement[i].inventoryBottomTable[y].date = {
                startDate: startDate,
                endDate: endDate,
            }
            return {
                allEquipement: allEquipement,
            }
        })
    }

    handleChangeStock(stock, i) {
        this.setState(prevState => {
            let allEquipement = prevState.allEquipement
            allEquipement[i].inventoryTopTable.stock = stock
            return {
                allEquipement: allEquipement,
            }
        })
    }

    handleChangeStockDetails(stock, i, y) {
        this.setState(prevState => {
            let allEquipement = prevState.allEquipement
            allEquipement[i].inventoryBottomTable[y].stock = stock
            return {
                allEquipement: allEquipement,
            }
        })
    }

    handleChangeStock(stock) {
        this.setState(prevState => ({
            ...prevState,
            allEquipement: {
                ...prevState.allEquipement,
                inventoryTopTable: {
                    ...prevState.inventoryTopTable,
                    stock: stock,
                },
            },
        }))
    }

    handleChangeSearch(value) {
        this.setState({ search: value }, () => {
            this.filterSearch()
        })
    }

    handleStockUp() {
        this.setState({ stockUp: !this.state.stockUp }, () => {
            this.filterStockUp(this.state.stockUp)
        })
    }

    stockUp(i, y) {
        this.setState(prevState => {
            let allEquipement = prevState.allEquipement
            if (allEquipement[i].inventoryBottomTable[y].stock > 0) {
                return {
                    allEquipement: allEquipement,
                }
            }
        })
    }

    handleStockMissing() {
        this.setState({ stockMissing: !this.state.stockMissing }, () => {
            this.filterStockMissing(this.state.stockMissing)
        })
    }

    stockMissing(i, y) {
        this.setState(prevState => {
            let allEquipement = prevState.allEquipement
            if (allEquipement[i].inventoryBottomTable[y].stock == 0) {
                return {
                    allEquipement: allEquipement,
                }
            }
        })
    }

    handleStockRenewed() {
        this.setState({ stockRenewed: !this.state.stockRenewed }, () => {
            this.filterStockRenewed(this.state.stockRenewed)
        })
    }

    stockRenewed(i, y) {
        this.setState(prevState => {
            let allEquipement = prevState.allEquipement
            if (
                allEquipement[i].inventoryBottomTable[y].date.endDate &&
                allEquipement[i].inventoryBottomTable[
                    y
                ].date.endDate.getTime() &&
                new Date().getTime() <=
                    allEquipement[i].inventoryBottomTable[
                        y
                    ].date.endDate.getTime()
            ) {
                return {
                    allEquipement: allEquipement,
                }
            }
        })
    }

    handleCategorieSelecter(categoriesChecked) {
        let { propsAllEquipement } = this.state

        const edit = propsAllEquipement.filter(equipement => {
            console.log(
                categoriesChecked[
                    equipement.inventoryTopTable.categorie.id - 1
                ],
            )
            return categoriesChecked[
                equipement.inventoryTopTable.categorie.id - 1
            ]
        })

        this.setState({
            allEquipement: edit.length === 0 ? propsAllEquipement : edit,
            categoriesChecked: categoriesChecked,
        })
    }

    renderChildStock() {
        return (
            <ButtonFilterPopOver
                body={
                    <div>
                        <CheckboxFilter
                            up={this.handleStockUp}
                            value={this.state.stockUp}
                        ></CheckboxFilter>
                        <Typography>{this.displayText('parts')}</Typography>

                        <CheckboxFilterMissing
                            missing={this.handleStockMissing}
                            value={this.state.stockMissing}
                        ></CheckboxFilterMissing>
                        <Typography>
                            {this.displayText('missingParts')}
                        </Typography>

                        <CheckboxFilterRenewed
                            renewed={this.handleStockRenewed}
                            value={this.state.stockRenewed}
                        ></CheckboxFilterRenewed>
                        <Typography>
                            {this.displayText('renewedParts')}
                        </Typography>
                    </div>
                }
            ></ButtonFilterPopOver>
        )
    }
    renderChildFamillyEquipement() {
        return (
            <ButtonFilterPopOver
                body={
                    <CategorieSelecter
                        onChange={categoriesChecked => {
                            this.handleCategorieSelecter(categoriesChecked)
                        }}
                        categoriesChecked={this.state.categoriesChecked}
                    />
                }
            />
        )
    }

    renderChildCommissioningDate() {
        return <ButtonFilterPopOver></ButtonFilterPopOver>
    }

    renderChildDateReplacement() {
        return <ButtonFilterPopOver></ButtonFilterPopOver>
    }

    filterStockUp(value) {
        let { allEquipement } = this.state

        for (let i = 0; i < allEquipement.length; i++) {
            allEquipement[i].inventoryBottomTable = allEquipement[
                i
            ].inventoryBottomTable.filter(bottom => {
                if (bottom.stock > 0) return true
                return false
            })
        }

        if (value) {
            this.setState({ allEquipement: allEquipement })
        } else {
            const { propsAllEquipement } = this.state

            this.setState({ allEquipement: propsAllEquipement })
        }
    }

    filterStockMissing(value) {
        let { allEquipement } = this.state

        for (let i = 0; i < allEquipement.length; i++) {
            allEquipement[i].inventoryBottomTable = allEquipement[
                i
            ].inventoryBottomTable.filter(bottom => {
                if (bottom.stock == 0) return true
                return false
            })
        }

        if (value) {
            this.setState({ allEquipement: allEquipement })
        } else {
            const { propsAllEquipement } = this.state

            this.setState({ allEquipement: propsAllEquipement })
        }
    }

    filterStockRenewed(value) {
        let { allEquipement } = this.state
        var dateThreeMonth = new Date(
            new Date().setMonth(new Date().getMonth() + 3),
        )
        var dateToday = new Date()

        for (let i = 0; i < allEquipement.length; i++) {
            allEquipement[i].inventoryBottomTable = allEquipement[
                i
            ].inventoryBottomTable.filter(bottom => {
                if (
                    bottom.date.endDate &&
                    bottom.date.endDate.getTime() &&
                    dateToday.getTime() <= bottom.date.endDate.getTime() &&
                    dateThreeMonth.getTime() >= bottom.date.endDate.getTime()
                )
                    return true
                return false
            })
        }

        if (value) {
            this.setState({ allEquipement: allEquipement })
        } else {
            const { propsAllEquipement } = this.state

            this.setState({ allEquipement: propsAllEquipement })
        }
    }

    filterSearch() {
        const { allEquipement, search } = this.state

        let filterEquipement = allEquipement.filter(equipement => {
            if (
                equipement.inventoryTopTable.categorie.name
                    .toLowerCase()
                    .includes(search.toLowerCase())
            )
                return true
            else if (
                equipement.inventoryTopTable.equipement
                    .toLowerCase()
                    .includes(search.toLowerCase())
            )
                return true
            else if (
                equipement.inventoryTopTable.typeEquipement
                    .toLowerCase()
                    .includes(search.toLowerCase())
            )
                return true
            else if (
                equipement.inventoryBottomTable.find(equipementBottom => {
                    if (
                        equipementBottom.equipement
                            .toLowerCase()
                            .includes(search.toLowerCase())
                    )
                        return true
                    else if (
                        equipementBottom.typeEquipement
                            .toLowerCase()
                            .includes(search.toLowerCase())
                    )
                        return true
                })
            )
                return true
            return false
        })

        this.setState({ allEquipement: filterEquipement })
    }

    filterDate() {
        const { allEquipement, date } = this.state

        let filterDate = allEquipement.filter(date => {
            if (
                date.inventoryTopTable.date.startDate <
                date.inventoryTopTable.date.endDate
            )
                return true
            else if (date.inventoryTopTable.date.endDate.includes(date))
                return true
            else if (
                date.inventoryBottomTable.find(dateBottom => {
                    if (dateBottom.date.startDate.includes(date)) return true
                    else if (dateBottom.date.endDate.inclubdes(date))
                        return true
                })
            )
                return false
        })

        this.setState({ allEquipement: filterDate })
    }

    handleCloseModal() {
        this.setState({
            modalOpen: false,
            modalIDRepair: null,
            modalIDDateRepair: null,
            openDoc: null,
        })
    }

    newInventory() {
        this.setState({
            modalOpen: 'repair',
            modalIDRepair: null,
            modalIDDateRepair: null,
        })
    }

    handleCloseModal() {
        this.setState({
            modalOpen: false,
            modalIDRepair: null,
            modalIDDateRepair: null,
            openDoc: null,
        })
    }

    delete(document) {
        const { user } = this.props
        var data = new FormData()

        this.props.dispatch(removeDataBOBDocuments(document.DocumentID))
    }

    render() {
        const HtmlTooltip = withStyles(theme => ({
            tooltip: {
                backgroundColor: '#f5f5f9',
                color: 'rgba(0, 0, 0, 0.87)',
                maxWidth: 220,
                fontSize: theme.typography.pxToRem(12),
                border: '1px solid #dadde9',
            },
        }))(Tooltip)

        const { classes } = this.props
        const { allEquipement } = this.state
        var allStockTop = 0
        var allStockBottom = []
        var noStockTop = 0
        var noStockBottom = []
        var reNewStockTop = 0
        var reNewStockBottom = []
        var dateThreeMonth = new Date(
            new Date().setMonth(new Date().getMonth() + 3),
        )

        for (let i = 0; i < allEquipement.length; i++) {
            allStockBottom = [
                ...allStockBottom,
                ...allEquipement[i].inventoryBottomTable.filter(
                    entity => entity.stock > 0,
                ),
            ]
            if (allEquipement[i].inventoryTopTable.stock > 0) {
                allStockTop = allStockTop + 1
            }

            noStockBottom = [
                ...noStockBottom,
                ...allEquipement[i].inventoryBottomTable.filter(
                    entity => entity.stock == 0,
                ),
            ]
            if (allEquipement[i].inventoryTopTable.stock == 0) {
                noStockTop = noStockTop + 1
            }

            reNewStockBottom = [
                ...reNewStockBottom,
                ...allEquipement[i].inventoryBottomTable.filter(
                    entity =>
                        entity.stock === '0' &&
                        entity.date.endDate &&
                        dateThreeMonth.getTime() >
                            entity.date.endDate.getTime() &&
                        new Date().getTime() <= entity.date.endDate.getTime(),
                ),
            ]

            if (
                allEquipement[i].inventoryTopTable.stock === '0' &&
                allEquipement[i].inventoryTopTable.date.endDate &&
                dateThreeMonth.getTime() >
                    allEquipement[i].inventoryTopTable.date.endDate.getTime() &&
                new Date().getTime() <=
                    allEquipement[i].inventoryTopTable.date.endDate.getTime()
            ) {
                reNewStockTop = reNewStockTop + 1
            }
        }

        var filtersButton = [
            {
                type: 'price',
                child: this.renderChildStock(),
                name: this.displayText('filterBtn'),
            },
            {
                type: 'equipement',
                child: this.renderChildFamillyEquipement(),
                name: this.displayText('familyEquipement'),
            },
            {
                type: 'date',
                child: this.renderChildCommissioningDate(),
                name: this.displayText('dateMise'),
            },
            {
                type: 'date',
                child: this.renderChildDateReplacement(),
                name: this.displayText('dateRemplacement'),
            },
        ]

        return (
            <div className={classes.root}>
                <div className={classes.blocs}>
                    <div className={`${classes.bloc} ${classes.blocGreen}`}>
                        <div className={classes.icons}>
                            <img
                                src={instockicon}
                                className={classes.iconMenu}
                            ></img>
                        </div>
                        <div className={classes.infoText}>
                            <div className={classes.numberStock}>
                                {allStockTop + allStockBottom.length}
                            </div>
                            <div className={classes.textStock}>
                                {this.displayText('parts')}
                            </div>
                        </div>
                    </div>

                    <div
                        className={classes.bloc}
                        style={{
                            backgroundColor: '#E36B61',
                            marginRight: '10px',
                            marginLeft: '10px',
                        }}
                    >
                        <div className={classes.icons}>
                            <img
                                src={shoppingcart}
                                className={classes.iconMenu}
                            ></img>
                        </div>
                        <div className={classes.infoText}>
                            <div className={classes.numberStock}>
                                {noStockTop + noStockBottom.length}
                            </div>
                            <div className={classes.textStock}>
                                {this.displayText('missingParts')}
                            </div>
                        </div>
                    </div>

                    <div className={`${classes.bloc} ${classes.blocYellow}`}>
                        <div className={classes.icons}>
                            <img
                                src={shoppingbasket}
                                className={classes.iconMenu}
                            ></img>
                        </div>
                        <div className={classes.infoText}>
                            <div className={classes.numberStock}>
                                {reNewStockBottom.length + reNewStockTop}
                            </div>
                            <div className={classes.textStock}>
                                {this.displayText('renewedParts')}
                            </div>
                        </div>
                    </div>
                </div>

                <div className={classes.secondLine}>
                    <div className={classes.searchFilterUnfold}>
                        {/* Partie rechercher */}
                        <div className={classes.recherche}>
                            <TextField
                                id="search"
                                margin="dense"
                                variant="outlined"
                                placeholder={this.displayText('search')}
                                className={classes.field}
                                value={this.state.search || ''}
                                onChange={e => {
                                    this.handleChangeSearch(e.target.value)
                                }}
                            />
                        </div>
                        {/* Fin partie rechercher */}

                        {/* Bouton "Plus de filtre" */}
                        <div>
                            <Button
                                className={classes.filtersButton}
                                variant="contained"
                                color="secondary"
                                size="small"
                                onClick={this.handleFilters}
                                startIcon={
                                    !this.state.filters ? (
                                        <KeyboardArrowDown />
                                    ) : (
                                        <KeyboardArrowUp />
                                    )
                                }
                            >
                                {this.displayText('moreFilters')}
                            </Button>
                            <Button
                                className={classes.filtersMobileButton}
                                onClick={this.handleFilters}
                            >
                                {!this.state.filters ? (
                                    <KeyboardArrowDown />
                                ) : (
                                    <KeyboardArrowUp />
                                )}
                            </Button>
                        </div>
                        {/* Fin bouton "Plus de filtre" */}
                    </div>

                    <div className={classes.unfoldAll}>
                        {this.displayText('unfoldAll')}
                        <FormControlLabel
                            style={{ marginLeft: '10px' }}
                            control={
                                <Switch
                                    color="primary"
                                    onChange={e => {
                                        this.handleUnfoldAll(e.target.checked)
                                    }}
                                    checked={
                                        this.state.unfoldAll.filter(
                                            element => element === false,
                                        ).length > 0
                                            ? false
                                            : true
                                    }
                                />
                            }
                        />

                        {/* Section checkbox "Tout sélectionner" */}
                        {this.displayText('selectAll')}
                        <Checkbox></Checkbox>
                    </div>
                    {/* Fin section checkbox "Tout sélectionner" */}
                </div>

                <div
                    className={
                        this.state.filters ? classes.filtersBar : classes.hidde
                    }
                >
                    {filtersButton.map(filter => (
                        <Button
                            className={
                                this.state[filter.type]
                                    ? classes.filterButtonFill
                                    : classes.filterButton
                            }
                            variant="contained"
                            size="small"
                            style={{ marginLeft: 0, marginBlock: '3px' }}
                            onClick={e =>
                                this.handleOpenPopOver(e, filter.child)
                            }
                        >
                            {filter.name}
                        </Button>
                    ))}
                    <Popover
                        id={'popover'}
                        open={this.state.openPopOver}
                        anchorEl={this.state.anchorEl}
                        onClose={this.handleClosePopOver}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                        }}
                        style={{ marginTop: '5px' }}
                    >
                        {this.state.childPopOver}
                    </Popover>
                </div>

                <Divider className={classes.divider} />

                {/* Ici le tableau des équipements  */}

                {allEquipement.map((equipement, i) => (
                    <div className={classes.panel}>
                        <Accordion
                            expanded={this.state.unfoldAll[i]}
                            onChange={(e, expanded) => {
                                this.handleUnfold(expanded, i)
                            }}
                            classes={{ root: classes.extension }}
                        >
                            <AccordionSummary
                                className={classes.PanelSummary}
                                expandIcon={<ExpandMoreIcon color="primary" />}
                                style={{ height: '55px' }}
                            >
                                <div className={classes.firstPanel}>
                                    <div className={classes.test123456}>
                                        <div className={classes.categories}>
                                            <Typography
                                                className={classes.categorie}
                                            >
                                                {
                                                    equipement.inventoryTopTable
                                                        .categorie.name
                                                }
                                            </Typography>
                                            {this.state.allEquipement[0]
                                                .inventoryTopTable.date
                                                .endDate &&
                                            dateThreeMonth.getTime() <
                                                this.state.allEquipement[0].inventoryTopTable.date.endDate.getTime() &&
                                            new Date().getTime() <=
                                                this.state.allEquipement[0].inventoryTopTable.date.endDate.getTime() ? (
                                                <HtmlTooltip
                                                    title={
                                                        <React.Fragment>
                                                            <Typography color="inherit">
                                                                {this.displayText(
                                                                    'parts',
                                                                )}
                                                            </Typography>
                                                        </React.Fragment>
                                                    }
                                                >
                                                    <Button>
                                                        <div
                                                            className={
                                                                classes.dateAlertGreen
                                                            }
                                                        >
                                                            <img
                                                                src={bellicon}
                                                                className={
                                                                    classes.bellIconTop
                                                                }
                                                            ></img>
                                                        </div>
                                                    </Button>
                                                </HtmlTooltip>
                                            ) : (
                                                <HtmlTooltip
                                                    title={
                                                        <React.Fragment>
                                                            <Typography color="inherit">
                                                                {this.displayText(
                                                                    'renewedPartsMonth',
                                                                )}
                                                            </Typography>
                                                        </React.Fragment>
                                                    }
                                                >
                                                    <Button>
                                                        <div
                                                            className={
                                                                classes.dateTopOrange
                                                            }
                                                        >
                                                            <img
                                                                src={bellicon}
                                                                className={
                                                                    classes.bellIconTop
                                                                }
                                                            ></img>
                                                        </div>
                                                    </Button>
                                                </HtmlTooltip>
                                            )}
                                        </div>
                                    </div>

                                    <div className={classes.equipementType}>
                                        <div className={classes.equipements}>
                                            <Typography
                                                className={classes.equipement}
                                            >
                                                {
                                                    equipement.inventoryTopTable
                                                        .equipement
                                                }
                                            </Typography>
                                        </div>
                                        <div>
                                            <Typography
                                                className={classes.type}
                                            >
                                                {
                                                    equipement.inventoryTopTable
                                                        .typeEquipement
                                                }
                                            </Typography>
                                        </div>
                                    </div>

                                    <div className={classes.cssDate}>
                                        <MuiPickersUtilsProvider
                                            utils={dateUtils}
                                            locale={Locale}
                                        >
                                            <DatePicker
                                                margin="dense"
                                                variant="inline"
                                                label={this.displayText(
                                                    'commissioning',
                                                )}
                                                value={
                                                    equipement.inventoryTopTable
                                                        .date.startDate
                                                }
                                                className={classes.startDate}
                                                format="dd/MM/yyyy"
                                                disableToolbar
                                                inputVariant="outlined"
                                            />
                                            <DatePicker
                                                margin="dense"
                                                variant="inline"
                                                label={this.displayText(
                                                    'replacement',
                                                )}
                                                value={
                                                    equipement.inventoryTopTable
                                                        .date.endDate
                                                }
                                                className={classes.endDate}
                                                format="dd/MM/yyyy"
                                                disableToolbar
                                                inputVariant="outlined"
                                            />
                                        </MuiPickersUtilsProvider>

                                        {/* champs pour les remplacements par heures */}

                                        {/* <div className={classes.replacehours}>
                                        <TextField
                                            margin="dense"
                                            variant="outlined"
                                            value={
                                                equipement.inventoryTopTable
                                                    .date.heure
                                            }
                                            label={this.displayText(
                                                'replacementHours',
                                            )}
                                        ></TextField>
                                    </div> */}

                                        <div className={classes.stock}>
                                            {equipement.inventoryTopTable
                                                .stock > 0 &&
                                            equipement.inventoryTopTable.date
                                                .endDate ? (
                                                <TextField
                                                    margin="dense"
                                                    variant="outlined"
                                                    value={
                                                        equipement
                                                            .inventoryTopTable
                                                            .stock
                                                    }
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    label={this.displayText(
                                                        'stock',
                                                    )}
                                                    onChange={stock =>
                                                        this.handleChangeStock(
                                                            stock,
                                                            i,
                                                        )
                                                    }
                                                ></TextField>
                                            ) : (
                                                <TextField
                                                    margin="dense"
                                                    variant="outlined"
                                                    value={
                                                        equipement
                                                            .inventoryTopTable
                                                            .stock
                                                    }
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    label={this.displayText(
                                                        'stock',
                                                    )}
                                                    className={classes.stock}
                                                    InputProps={{
                                                        classes: {
                                                            marginDense:
                                                                classes.redStock,
                                                        },
                                                    }}
                                                    onChange={stock =>
                                                        this.handleChangeStock(
                                                            stock,
                                                            i,
                                                        )
                                                    }
                                                ></TextField>
                                            )}
                                        </div>
                                    </div>

                                    <VisibilityIcon
                                        className={classes.iconsEdit}
                                    ></VisibilityIcon>

                                    <div className={classes.dividerH}></div>

                                    <Edit className={classes.iconsEdit}></Edit>

                                    <div className={classes.dividerH}></div>

                                    <Checkbox
                                        className={classes.iconsEdit}
                                        onChange={e =>
                                            this.props.handleCheckBox(
                                                equipement.id,
                                                e,
                                            )
                                        }
                                    ></Checkbox>

                                    <Button
                                        className={classes.moreHoriz}
                                        onClick={e => this.handleOpenPopOverMore(e)}
                                    >
                                        <MoreHorizIcon></MoreHorizIcon>
                                    </Button>

                                    <Popover
                                        id={'popover'}
                                        open={this.state.openPopOverMore}
                                        anchorEl={this.state.anchorEl}
                                        onClose={this.handleClosePopOverMore}
                                        anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'center',
                                        }}
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'center',
                                        }}
                                        style={{ marginTop: '5px' }}
                                    >
                                        <div className={classes.globalIcon}>
                                            <div
                                                className={
                                                    classes.iconVisibilityEdit
                                                }
                                            >
                                                <VisibilityIcon></VisibilityIcon>

                                                <Edit></Edit>
                                            </div>
                                            <div>
                                                <Checkbox></Checkbox>
                                            </div>
                                        </div>
                                    </Popover>
                                </div>
                            </AccordionSummary>
                            <div className={classes.container}>
                                {equipement.inventoryBottomTable.map(
                                    (inventory, y) => (
                                        <AccordionDetails
                                            className={classes.panelDetail}
                                        >
                                            <div
                                                className={classes.detailPanel}
                                            >
                                                <div className={classes.bell}>
                                                    {inventory.date.endDate &&
                                                    dateThreeMonth.getTime() <
                                                        inventory.date.endDate.getTime() &&
                                                    new Date().getTime() <=
                                                        inventory.date.endDate.getTime() ? (
                                                        <HtmlTooltip
                                                            title={
                                                                <React.Fragment>
                                                                    <Typography color="inherit">
                                                                        {this.displayText(
                                                                            'parts',
                                                                        )}
                                                                    </Typography>
                                                                </React.Fragment>
                                                            }
                                                        >
                                                            <Button
                                                                className={
                                                                    classes.btnBellIcon
                                                                }
                                                            >
                                                                <div
                                                                    className={
                                                                        classes.dateAlertGreen
                                                                    }
                                                                >
                                                                    <img
                                                                        src={
                                                                            bellicon
                                                                        }
                                                                        className={
                                                                            classes.bellIcon
                                                                        }
                                                                    ></img>
                                                                </div>
                                                            </Button>
                                                        </HtmlTooltip>
                                                    ) : (
                                                        <HtmlTooltip
                                                            title={
                                                                <React.Fragment>
                                                                    <Typography color="inherit">
                                                                        {this.displayText(
                                                                            'renewedPartsMonth',
                                                                        )}
                                                                    </Typography>
                                                                </React.Fragment>
                                                            }
                                                        >
                                                            <Button>
                                                                <div
                                                                    className={
                                                                        classes.dateAlertOrange
                                                                    }
                                                                >
                                                                    <img
                                                                        src={
                                                                            bellicon
                                                                        }
                                                                        className={
                                                                            classes.bellIcon
                                                                        }
                                                                    ></img>
                                                                </div>
                                                            </Button>
                                                        </HtmlTooltip>
                                                    )}
                                                </div>

                                                <div
                                                    className={
                                                        classes.equipementTypeDetail
                                                    }
                                                >
                                                    <div
                                                        className={
                                                            classes.equipementDetails
                                                        }
                                                    >
                                                        <Typography
                                                            className={
                                                                classes.equipementDetail
                                                            }
                                                        >
                                                            {
                                                                inventory.equipement
                                                            }
                                                        </Typography>
                                                    </div>
                                                    <div>
                                                        <Typography
                                                            className={
                                                                classes.typeEquipement
                                                            }
                                                        >
                                                            {
                                                                inventory.typeEquipement
                                                            }
                                                        </Typography>
                                                    </div>
                                                </div>

                                                <div
                                                    className={
                                                        classes.dateBottom
                                                    }
                                                >
                                                    <MuiPickersUtilsProvider
                                                        utils={dateUtils}
                                                        locale={Locale}
                                                    >
                                                        <DatePicker
                                                            margin="dense"
                                                            variant="inline"
                                                            label={this.displayText(
                                                                'commissioning',
                                                            )}
                                                            value={
                                                                equipement
                                                                    .inventoryTopTable
                                                                    .date
                                                                    .startDate
                                                            }
                                                            className={
                                                                classes.startDate
                                                            }
                                                            format="dd/MM/yyyy"
                                                            disableToolbar
                                                            inputVariant="outlined"
                                                        />
                                                        <DatePicker
                                                            margin="dense"
                                                            variant="inline"
                                                            label={this.displayText(
                                                                'replacement',
                                                            )}
                                                            value={
                                                                equipement
                                                                    .inventoryTopTable
                                                                    .date
                                                                    .endDate
                                                            }
                                                            className={
                                                                classes.endDate
                                                            }
                                                            format="dd/MM/yyyy"
                                                            disableToolbar
                                                            inputVariant="outlined"
                                                        />
                                                    </MuiPickersUtilsProvider>

                                                    <div
                                                        className={
                                                            classes.stock
                                                        }
                                                    >
                                                        {inventory.stock == 0 &&
                                                        inventory.date
                                                            .endDate ? (
                                                            <TextField
                                                                margin="dense"
                                                                variant="outlined"
                                                                value={
                                                                    inventory.stock
                                                                }
                                                                InputLabelProps={{
                                                                    shrink: true,
                                                                }}
                                                                label={this.displayText(
                                                                    'stock',
                                                                )}
                                                                className={
                                                                    classes.stockDetails
                                                                }
                                                                InputProps={{
                                                                    classes: {
                                                                        marginDense:
                                                                            classes.redStock,
                                                                    },
                                                                }}
                                                                onChange={stock =>
                                                                    this.handleChangeStock(
                                                                        stock,
                                                                        i,
                                                                        y,
                                                                    )
                                                                }
                                                            ></TextField>
                                                        ) : (
                                                            <TextField
                                                                margin="dense"
                                                                variant="outlined"
                                                                value={
                                                                    inventory.stock
                                                                }
                                                                InputLabelProps={{
                                                                    shrink: true,
                                                                }}
                                                                label={this.displayText(
                                                                    'stock',
                                                                )}
                                                                className={
                                                                    classes.stockDetails
                                                                }
                                                                onChange={stock =>
                                                                    this.handleChangeStock(
                                                                        stock,
                                                                        i,
                                                                        y,
                                                                    )
                                                                }
                                                            ></TextField>
                                                        )}
                                                    </div>
                                                </div>
                                                <VisibilityIcon
                                                    className={
                                                        classes.iconsEdit
                                                    }
                                                ></VisibilityIcon>

                                                <div
                                                    className={classes.dividerH}
                                                ></div>

                                                <Edit
                                                    className={
                                                        classes.iconsEdit
                                                    }
                                                ></Edit>

                                                <div
                                                    className={classes.dividerH}
                                                ></div>

                                                <Checkbox
                                                    className={
                                                        classes.iconsEdit
                                                    }
                                                    onChange={e =>
                                                        this.props.handleCheckBoxBottom(
                                                            equipement.id,
                                                            inventory.id,
                                                            e,
                                                        )
                                                    }
                                                ></Checkbox>

<Button
                                        className={classes.moreHoriz}
                                        onClick={e => this.handleOpenPopOverMore(e)}
                                    >
                                        <MoreHorizIcon></MoreHorizIcon>
                                    </Button>

                                    <Popover
                                        id={'popover'}
                                        open={this.state.openPopOverMore}
                                        anchorEl={this.state.anchorEl}
                                        onClose={this.handleClosePopOverMore}
                                        anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'center',
                                        }}
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'center',
                                        }}
                                        style={{ marginTop: '5px' }}
                                    >
                                        <div className={classes.globalIcon}>
                                            <div
                                                className={
                                                    classes.iconVisibilityEdit
                                                }
                                            >
                                                <VisibilityIcon></VisibilityIcon>

                                                <Edit></Edit>
                                            </div>
                                            <div>
                                                <Checkbox></Checkbox>
                                            </div>
                                        </div>
                                    </Popover>
                                            </div>
                                        </AccordionDetails>
                                    ),
                                )}
                            </div>
                        </Accordion>
                    </div>
                ))}
                {/* Fin tableau équipements */}

                {/* Bouton ajoutez une pièce ou un équipement */}
                <div>
                    <Button
                        className={classes.buttonAdd}
                        variant="contained"
                        color="primary"
                        onClick={this.newInventory}
                        endIcon={<AddIcon className={classes.buttonSvg} />}
                    >
                        {this.displayText('addEquipement')}
                    </Button>
                </div>
                <BoatOnModal
                    openCondition={this.state.modalOpen}
                    handleClose={this.handleCloseModal}
                    rendersModal={{
                        repair: <InventoryModal></InventoryModal>,
                    }}
                    titles={{
                        repair: 'Mon inventaire',
                    }}
                />
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {}
}

export default connect(mapStateToProps)(withStyles(styles)(TopInventory))

function CheckboxFilter({ up, value }) {
    const [stockUp, setStockUp] = useState(value)
    return (
        <Checkbox
            checked={stockUp}
            onChange={e => {
                up(e.target.value)
                setStockUp(!stockUp)
            }}
        ></Checkbox>
    )
}

function CheckboxFilterMissing({ missing, value }) {
    const [stockMissing, setStockMissing] = useState(value)
    return (
        <Checkbox
            checked={stockMissing}
            onChange={e => {
                missing(e.target.value)
                setStockMissing(!stockMissing)
            }}
        ></Checkbox>
    )
}

function CheckboxFilterRenewed({ renewed, value }) {
    const [stockRenewed, setStockRenewed] = useState(value)
    return (
        <Checkbox
            checked={stockRenewed}
            onChange={e => {
                renewed(e.target.value)
                setStockRenewed(!stockRenewed)
            }}
        ></Checkbox>
    )
}
