import { combineReducers } from 'redux'

import authentication from './authentication.reducer'
import alert from './alert.reducer'
import filter from './filter.reducer'
import fetch from './fetch.reducer'
import address from './address.reducer'
import post from './post.reducer'
import bob from './bob.reducer'
import types from './types.reducer'
import boat from './boat.reducer'

const rootReducer = combineReducers({
    authentication,
    alert,
    fetch,
    filter,
    post,
    address,
    bob,
    types,
    boat,
})

export default rootReducer
