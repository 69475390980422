export default {
    connection: {
        FR: 'Connexion',
        EN: 'Connection',
    },
    // PERSONAL INFOS //
    firstName: {
        FR: 'Prénom',
        EN: 'Firstname',
    },
    lastName: {
        FR: 'Nom',
        EN: 'Lastname',
    },
    email: {
        FR: 'E-Mail',
        EN: 'E-Mail',
    },
    phoneNumber: {
        FR: 'Téléphone',
        EN: 'Number phone',
    },
    password: {
        FR: 'Mot de passe',
        EN: 'Password',
    },
    // BOAT INFOS //
    boatName: {
        FR: 'Nom du bateau',
        EN: 'Boat name',
    },
    boatType: {
        FR: 'Type du bateau',
        EN: 'Boat type',
    },
    boatYear: {
        FR: 'Année du bateau',
        EN: 'Boat year',
    },
    boatMaker: {
        FR: 'Marque du bateau',
        EN: 'Boat Maker',
    },
    boatModel: {
        FR: 'Modèle du bateau',
        EN: 'Boat model',
    },
    boatRegistration: {
        FR: 'Immatriculation',
        EN: 'Boat registration',
    },
    boatEngine: {
        FR: 'Moteur',
        EN: 'Engine',
    },
    homePortOrStorage: {
        FR: 'Port ou lieu de stockage',
        EN: 'Harbour or storage area',
    },
    // BOAT TYPE //
    engine: {
        FR: 'Moteur',
        EN: 'Engine',
    },
    sailing: {
        FR: 'Voilier',
        EN: 'Sailing',
    },
    pneumatic: {
        FR: 'Semi-Rigide',
        EN: 'Pneumatic',
    },
    catamaran: {
        FR: 'Catamaran',
        EN: 'Catamaran',
    },
    barge: {
        FR: 'Péniche',
        EN: 'Barge',
    },
    jetSki: {
        FR: 'Jet-Ski',
        EN: 'Jet-Ski',
    },
    // TRANSACTION TYPE
    repair: {
        FR: 'Réparation',
        EN: 'Repair',
    },
    insurance: {
        FR: 'Assurance',
        EN: 'Insurance',
    },
    storage: {
        FR: 'Stockage',
        EN: 'Storage',
    },
    layout: {
        FR: 'Aménagement',
        EN: 'Layout',
    },
    expertise: {
        FR: 'Expertise',
        EN: 'Expertise',
    },
    equipmentBuy: {
        FR: "Achat d'équipement",
        EN: 'Purchase of equipment',
    },
    fuel: {
        FR: 'Carburant',
        EN: 'Fuel',
    },
    adminFees: {
        FR: 'Frais administratifs',
        EN: 'Administrative expenses',
    },
    buyBoat: {
        FR: 'Achat du bateau',
        EN: 'Purchase of the boat',
    },
    rentBoat: {
        FR: 'Location du bateau',
        EN: 'Boat rental',
    },
    sellBoat: {
        FR: 'Vente du bateau',
        EN: 'Boat sale',
    },
    permisNav: {
        FR: 'Permis de navigation',
        EN: 'Navigation permits',
    },
    circulationCard: {
        FR: 'Carte de circulation',
        EN: 'Circulation card',
    },
    francizationAct: {
        FR: 'Acte de francisation',
        EN: 'Act of francization',
    },
    certificateConformity: {
        FR: 'Attestation de conformité',
        EN: 'Certificate of conformity',
    },
    engineManual: {
        FR: 'Manuel du moteur',
        EN: 'Engine manual',
    },
    ownerManual: {
        FR: 'Manuel du propriétaire',
        EN: 'Owner manual',
    },
    expertReport: {
        FR: "Rapport d'expertise",
        EN: 'Expert report',
    },
    maintenance: {
        FR: 'Maintenance',
        EN: 'Maintenance',
    },
    other: {
        FR: 'Autre',
        EN: 'Other',
    },
    // CHECKUP LABEL
    remplacementLiquideRefroidissement: {
        FR: 'Remplacement du liquide de refroidissement',
        EN: 'Coolant Replacement',
    },
    nettoyageFiltreCarburant: {
        FR: 'Nettoyage du filtre à carburant',
        EN: 'Cleaning the fuel filter',
    },
    nettoyageFiltreEauCrepine: {
        FR: 'Nettoyage du filtre à eau et crépine',
        EN: 'Cleaning the water filter and strainer',
    },
    vidangeHuileMoteur: {
        FR: "Vidange de l'huile moteur",
        EN: 'Changing engine oil',
    },
    remplacementFiltreHuile: {
        FR: 'Remplacement du filtre à huile',
        EN: 'Replacing the oil filter',
    },
    niveauHuileInverseur: {
        FR: "Niveau d'huile de l'inverseur",
        EN: 'Inverter oil level',
    },
    vidangeHuileInverseur: {
        FR: "Vidange de l'inverseur",
        EN: 'Changing the inverter',
    },
    remplacementFiltreGazoil: {
        FR: 'Remplacement du filtre à gasoil',
        EN: 'Replacing the oil filter',
    },
    nettoyageVidangeFiltreDecanteurCarburant: {
        FR: 'Nettoyage et vidange du filtre décanteur de carburant',
        EN: 'Cleaning and emptying the fuel settling filter',
    },
    nettoyageReservoirCarburant: {
        FR: 'Nettoyage du réservoir de carburant',
        EN: 'Cleaning the fuel tank',
    },
    nettoyageThermostat: {
        FR: 'Nettoyage du thermostat',
        EN: 'Cleaning the thermostat',
    },
    reglageTensionsCourroies: {
        FR: 'Réglage des tensions de courroies',
        EN: 'Adjustment of belt tension',
    },
    remplacementCourroies: {
        FR: 'Remplacement des courroies',
        EN: 'Replacing belts',
    },
    controleCablageElectrique: {
        FR: 'Contrôle du câblage électrique',
        EN: 'Control of electrical wiring',
    },
    remplacementAnodesAnticorrosion: {
        FR: 'Remplacement des anodes anticorrosion',
        EN: 'Replacement of anti-corrosion anodes',
    },
    remplacementTurbinePompeAEau: {
        FR: 'Remplacement de la turbine de pompe à eau',
        EN: 'Replacement of the water pump turbine',
    },
    serrageBoulonsFixationMoteur: {
        FR: 'Serrage des boulons de fixation moteur',
        EN: 'Tightening the motor mounting bolts',
    },
    verificationSilentblocs: {
        FR: 'Vérification des Silentblocs',
        EN: 'Checking Silentblocs',
    },
    reglageJeuSoupapes: {
        FR: 'Réglage du jeu aux soupapes',
        EN: 'Adjusting the clearance to the valves',
    },
    revisionLigneArbre: {
        FR: "Révision de la ligne d'arbre",
        EN: 'Revision of the tree line',
    },
    revisionHelices: {
        FR: 'Révision des hélices',
        EN: 'Overhaul of the propellers',
    },
    remplacementBagueHydrolube: {
        FR: 'Remplacement de la bague hydrolube',
        EN: 'Replacement of the hydrolube ring',
    },
    remplacementJointsSafran: {
        FR: 'Remplacement des joints de safran',
        EN: 'Replacement of rudder seals',
    },
    protectionContactsElectriques: {
        FR: 'Protection des contacts électriques (aérosol)',
        EN: 'Protection of electrical contacts (aerosol)',
    },
    remplacementBatterieMoteur: {
        FR: 'Remplacement de la batterie moteur',
        EN: 'Replacing the engine battery',
    },
    remplacementBatterieServitude: {
        FR: 'Remplacement de la batterie servitude',
        EN: 'Replacement of the servitude battery',
    },
    revisionFeuxNavigation: {
        FR: 'Révision des feux de navigation',
        EN: 'Overhaul of navigation lights',
    },
    revisionPanneauxSolaires: {
        FR: 'Révision des panneaux solaires',
        EN: 'Overhaul of solar panels',
    },
    verificationFixationAeriens: {
        FR:
            'Vérification de la fixation des aériens (antenne VHF, girouette, radar...)',
        EN:
            'Checking of the fixation of the aerials (VHF antenna, weather vane, radar ...)',
    },
    deposeProtectionContacts: {
        FR: 'Dépose et protection des contacts (prises)',
        EN: 'Removing and protecting contacts (sockets)',
    },
    nettoyageHeliceLochSpeedo: {
        FR: "Nettoyage de l'hélice du loch-speedo",
        EN: 'Cleaning of the loch-speedo propeller',
    },
    graissageVannesEvacuation: {
        FR: "Graissage des vannes d'évacuation",
        EN: 'Lubricating the exhaust valves',
    },
    serrageColliers: {
        FR: 'Serrage de tous les colliers',
        EN: 'Tightening of all collars',
    },
    nettoyageFiltresPompes: {
        FR: 'Nettoyage des filtres des pompes',
        EN: 'Cleaning pump filters',
    },
    traitementAntibacterienReservoirs: {
        FR: 'Traitement antibactérien dans les réservoirs',
        EN: 'Antibacterial treatment in tanks',
    },
    purificationGraissagePompesWC: {
        FR: 'Purification et graissage des pompes de WC',
        EN: 'Purification and lubrication of toilet pumps',
    },
    verificationChandeliersBalconsFilieres: {
        FR: 'Vérification des chandeliers, des balcons et des filières',
        EN: 'Verification of candlesticks, balconies and dies',
    },
    carenage: {
        FR: 'Carénage',
        EN: 'Fairing',
    },
    antifouling: {
        FR: 'Antifouling',
        EN: 'Antifouling',
    },
    remplacementAnodes: {
        FR: 'Remplacement des anodes',
        EN: 'Replacement of anodes',
    },
    changementVannesPasseCoque: {
        FR: 'Changement des vannes et des passe-coque',
        EN: 'Changing valves and thru-hulls',
    },
    refectionVernis: {
        FR: 'Réfection des vernis',
        EN: 'Repair of varnishes',
    },
    entretienTeck: {
        FR: 'Entretien du teck',
        EN: 'Maintenance of teak',
    },
    polishOeuvresMortes: {
        FR: 'Polish sur les œuvres mortes',
        EN: 'Polish on dead works',
    },
    demontageGraissageWinches: {
        FR: 'Démontage et graissage des winches',
        EN: 'Dismantling and lubrication of winches',
    },
    verificationGoupillesRidoirs: {
        FR: 'Vérification des goupilles de ridoirs',
        EN: 'Checking the turnbuckle pins',
    },
    verificationVisuelHaubannage: {
        FR: 'Vérification visuel du haubannage',
        EN: 'Visual verification of cable management',
    },
    verificationGreementCourant: {
        FR: 'Vérification du gréement courant',
        EN: 'Checking the current rig',
    },
    verificationVoiles: {
        FR: 'Vérification des voiles',
        EN: 'Checking the sails',
    },
    verficatonInstallationGaz: {
        FR: "Vérificaton de l'installation au gaz",
        EN: 'Verification of the gas installation ',
    },
    verificationExtincteurs: {
        FR: 'Vérification des extincteurs',
        EN: 'Verification of fire extinguishers',
    },
    verificationLignesVie: {
        FR: 'Vérification des lignes de vie',
        EN: 'Lifeline verification',
    },
    verificationGiletsSauvetage: {
        FR: 'Vérification des gilets de sauvetage',
        EN: 'Lifejacket check',
    },
    revisionDessalinisateur: {
        FR: 'Révision du dessalinisateur',
        EN: 'Review of the watermaker',
    },
    lastControl: {
        FR: 'Dernier contrôle',
        EN: 'Last control',
    },
    nextControl: {
        FR: 'Prochain contrôle',
        EN: 'Next control',
    },
    // POPULAR WORDS
    next: {
        FR: 'Suivant',
        EN: 'Next',
    },
    previous: {
        FR: 'Précédent',
        EN: 'Previous',
    },
    price: {
        FR: 'Prix',
        EN: 'Price',
    },
    month: {
        FR: 'mois',
        EN: 'month',
    },
    day: {
        FR: 'jour',
        EN: 'day',
    },
    year: {
        FR: 'an',
        EN: 'year',
    },
    boatOnPro: {
        FR: 'BoatOn Pro',
        EN: 'BoatOn Pro',
    },
}
