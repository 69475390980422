import React from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import ReactGA from 'react-ga'
import ReactPixel from 'react-facebook-pixel'
import BoatOnComponent from '../../../common/BoatOnComponent'
import styles from './Styles/AlertPageCss'
import dictionary from './Dictionary/AlertListDico'
import { fetchData } from '../../../../actions/api.actions'
import SaveBar from '../../../common/SaveBar'
import AlertList from './AlertList'
import AlertBar from './AlertBar'
import ModalConnection from '../../../common/UsefullComponents/ModalConnection/ModalConnection'
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers'
import dateUtils from '@date-io/date-fns'
import Locale from 'date-fns/locale/fr'
import PickerToolbar from '@material-ui/pickers/_shared/PickerToolbar'
import Typography from '@material-ui/core/Typography'
import { createMuiTheme, ThemeProvider } from '@material-ui/core'
import { API_URL } from '../../../../services/config.service'
import moment from 'moment'
import { postDataBOBCheckups } from '../../../../actions/bob.actions'

const materialTheme = createMuiTheme({
    overrides: {
        MuiPickersBasePicker: {
            container: {
                maxWidth: 'fit-content',
                width: 'min-content',
            },
        },
        MuiPickersToolbar: {
            toolbar: {
                padding: '10px',
            },
        },
    },
})

const customToolbar = function(typeCalendar) {
    const title =
        typeCalendar === 'last'
            ? 'Vous pouvez modifier ici la date de la dernière révision ou du remplacement des équipements cochés, à vous de jouer !'
            : 'Vous pouvez modifier ici la date de la prochaine révision des équipements cochés, à vous de jouer !'
    return (
        <PickerToolbar styles={{ backgroundColor: '#3040a0' }}>
            <Typography
                style={{
                    textAlign: 'center',
                    color: 'white',
                    fontSize: '16px',
                }}
            >
                {title}
            </Typography>
        </PickerToolbar>
    )
}
class AlertPage extends BoatOnComponent {
    constructor(props) {
        super(props)
        this.dictionary = dictionary

        this.initializeReactGA()
        this.state = {
            openModalConnection: true, //Si l'utilisateur n'est pas connecté, définit l'ouverture ou la fermeture du modal de connexion
            openCalendar: false, //Le calendrier est ouvert ?

            checkupsChange: null,
            checkupsValue: null,
            checkedBoxes: [],
            date: new Date(), // Date dans le datepicker

            noDoubleValidation: false, //La bar de changement multiple est masqué ?
            typeCalendar: null, //next or last,

            startDate: null,
            endDate: null,
            textSearch: null,
        }

        const options = {
            autoConfig: true,
            debug: false,
        }
        ReactPixel.init('181677145678471', options)

        ReactPixel.pageView()

        this.reset = this.reset.bind(this)
        this.save = this.save.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.handleChangeDate = this.handleChangeDate.bind(this)
        this.handleCheckBox = this.handleCheckBox.bind(this)
        this.changeAllCheckedBoxesDate = this.changeAllCheckedBoxesDate.bind(
            this,
        )
        this.handleClose = this.handleClose.bind(this)
        this.handleResetSave = this.handleResetSave.bind(this)
        this.onDateChange = this.onDateChange.bind(this)
        this.handleChangeAll = this.handleChangeAll.bind(this)
        this.openCalendar = this.openCalendar.bind(this)
        this.closeCalendar = this.closeCalendar.bind(this)
        this.sendMail = this.sendMail.bind(this)
    }

    initializeReactGA() {
        ReactGA.initialize('UA-93941518-1')
        ReactGA.pageview('/logbook/my-alerts')
    }

    componentWillMount() {
        const { user } = this.props

        if (user) {
            let data = new FormData()
            data.append('UserID', user.UserID)

            data.append('appversion', '0')
            this.props.dispatch(
                fetchData(
                    API_URL + '/listcheckuptypes.php',
                    'checkupTypes',
                    'POST',
                    data,
                ),
            )
            this.props.dispatch(
                fetchData(
                    API_URL + '/listcheckupcategories.php',
                    'checkupCategories',
                    'POST',
                    data,
                ),
            )
            this.reset()
        }
    }

    componentDidUpdate(prevProps) {
        if (!this.props.bob.loading && prevProps.bob.loading) {
            this.reset()
        }
    }

    handleClose() {
        this.setState({ openModalConnection: false })
        const { user } = this.props
        if (user) {
            const data = new FormData()
            data.append('UserID', user.UserID)
            this.props.dispatch(
                fetchData(API_URL + '/boatgetall.php', 'boats', 'POST', data),
            )
        }
    }

    handleChange(value, key) {
        this.setState({
            [key]: value,
        })
    }

    handleChangeDate(value, checkupID, dateType, callback = () => {}) {
        if (checkupID === 'changeAllTab' || checkupID === '*')
            this.changeAllCheckedBoxesDate(value)

        const { checkupTypes } = this.props

        this.setState(prevState => {
            const { types } = checkupTypes
            const checkupsChange = prevState.checkupsChange || {}
            const checkupChange = checkupsChange[checkupID] || {}

            if (value === '') {
                if (dateType === 'last') checkupChange.CheckupLastDate = value
                if (dateType === 'next') checkupChange.CheckupNextDate = value
            } else if (dateType === 'last') {
                checkupChange.CheckupLastDate = value

                const nextControl = new Date(value)
                let checkupType = {}
                for (const i in types) {
                    if (types[i].CheckupTypeID === checkupID) {
                        checkupType = types[i]
                    }
                }
                if (checkupType.CycleType === '1') {
                    nextControl.setFullYear(
                        nextControl.getFullYear(),
                        nextControl.getMonth() +
                            parseFloat(checkupType.CycleLength),
                    )
                } else {
                    nextControl.setTime(
                        nextControl.getTime() +
                            checkupType.CycleLength * 60 * 60 * 1000,
                    )
                }
                checkupChange.CheckupNextDate = nextControl
                    .toISOString()
                    .split('T')[0]
            } else if (dateType === 'next')
                checkupChange.CheckupNextDate = value

            checkupsChange[checkupID] = checkupChange

            const checkupsValue = this.state.checkupsValue.map(function(e) {
                if (e.CheckupType == checkupID) {
                    const newe = e
                    if (checkupChange.CheckupLastDate)
                        newe.CheckupLastDate = checkupChange.CheckupLastDate
                    if (checkupChange.CheckupNextDate)
                        newe.CheckupNextDate = checkupChange.CheckupNextDate
                    return newe
                } else {
                    return e
                }
            })

            return {
                checkupsChange: checkupsChange,
                checkupsValue: checkupsValue,
            }
        }, callback)
    }

    handleCheckBox(idCheckUp, event) {
        if (!event.target.checked) {
            this.setState(prevState => ({
                checkedBoxes: prevState.checkedBoxes.filter(
                    boxID => boxID !== idCheckUp,
                ),
            }))
        } else {
            this.setState(prevState => ({
                checkedBoxes: prevState.checkedBoxes.concat([idCheckUp]),
            }))
        }
    }

    changeAllCheckedBoxesDate(newDate, type) {
        this.state.checkedBoxes.map(checkupID =>
            this.handleChangeDate(newDate, checkupID, type),
        )
    }

    sendMail() {
        const checkedBoxes = this.state.checkedBoxes
        const { types } = this.props.checkupTypes

        let body = 'Bonjour Paul,%0A%0A'
        body +=
            "J'utilise le BoatOn Book et aurais besoin d'un devis pour mon bateau. En détails,%0A%0A"
        body += 'Travaux nécessaires : %0A'

        for (const j in checkedBoxes) {
            for (const i in types) {
                if (types[i].CheckupTypeID === checkedBoxes[j]) {
                    body += this.displayText(types[i].CheckupType) + ',%0A'
                }
            }
        }
        body += "%0ADate souhaitée d'intervention : %0A%0A"
        body += 'Notes complémentaires : %0A%0A'
        body += 'Merci et à bientôt !'

        window.location.href = `mailto:paul@boaton.fr?subject=Nouvelle demande d'entretien&body=${body}`
    }

    openCalendar(type) {
        this.setState({
            openCalendar: true,
            typeCalendar: type,
        })
    }

    closeCalendar() {
        this.setState({
            openCalendar: false,
            typeCalendar: '',
        })
    }

    onDateChange(startDate, endDate) {
        this.setState({
            startDate: startDate
                ? moment(startDate).format('YYYY-MM-DD')
                : null,
            endDate: endDate ? moment(endDate).format('YYYY-MM-DD') : endDate,
        })
    }

    handleChangeAll(value) {
        this.changeAllCheckedBoxesDate(
            value.toISOString().split('T')[0],
            this.state.typeCalendar,
        )
    }

    handleResetSave() {
        this.setState({ resetSave: false })
    }

    save() {
        const { checkupTypes, bob } = this.props
        const { checkupsChange } = this.state

        this.props.dispatch(
            postDataBOBCheckups(
                checkupTypes,
                checkupsChange,
                bob.boat,
                this.props.checkups,
            ),
        )
        return true
    }

    reset() {
        const checkupsCopy = JSON.parse(JSON.stringify(this.props.checkups))
        this.setState({
            checkupsChange: [],
            checkedBoxes: [],
            checkupsValue: checkupsCopy,
        })
    }

    render() {
        const {
            classes,
            user,
            checkupCategories,
            checkupTypes,
            loading,
            boats,
        } = this.props
        const { checkupsChange, checkedBoxes } = this.state

        if (user === null) {
            return (
                <ModalConnection
                    open={this.state.openModalConnection}
                    handleClose={() => {
                        this.handleClose()
                    }}
                />
            )
        }

        var nbModif = 0
        for (const i in checkupsChange) {
            nbModif += Object.values(checkupsChange[i]).length
        }

        if (loading || !boats) return <></>

        var maxDate =
            this.state.typeCalendar === 'last'
                ? moment().format('YYYY-MM-DD')
                : '2100-01-01'
        var minDate =
            this.state.typeCalendar === 'next'
                ? moment().format('YYYY-MM-DD')
                : '2000-01-01'

        return (
            <div className={classes.root} key="alerts">
                <div className={classes.main}>
                    <AlertList
                        handleChange={this.handleChange}
                        handleChangeDate={this.handleChangeDate}
                        handleCheckBox={this.handleCheckBox}
                        checkups={this.state.checkupsValue}
                        checkupsothers={this.props.checkupsothers}
                        checkupCategories={checkupCategories}
                        checkupTypes={checkupTypes}
                        onDateChange={this.onDateChange}
                        selectedChekups={checkedBoxes}
                        startDate={this.state.startDate}
                        endDate={this.state.endDate}
                        textSearch={this.state.textSearch}
                        handleResetSave={this.handleResetSave}
                    />
                </div>
                <AlertBar
                    open={this.state.checkedBoxes.length}
                    sendMail={this.sendMail}
                    changeAllCheckedBoxesDate={this.changeAllCheckedBoxesDate}
                    openCalendar={this.openCalendar}
                />
                {checkupsChange && Object.values(checkupsChange).length > 0 ? (
                    <SaveBar
                        onCancel={this.reset}
                        onSave={this.save}
                        nbModif={nbModif}
                        resetSave={this.state.resetSave}
                        checkupsChange={this.state.checkupsChange}
                    />
                ) : (
                    <></>
                )}
                <ThemeProvider theme={materialTheme}>
                    <MuiPickersUtilsProvider utils={dateUtils} locale={Locale}>
                        <DatePicker
                            emptyLabel="empty"
                            margin="dense"
                            label="date"
                            //disableToolbar
                            cancelLabel="Annuler"
                            okLabel="Confirmer"
                            open={this.state.openCalendar}
                            onClose={this.closeCalendar}
                            onChange={date => {
                                this.handleChangeAll(date)
                            }}
                            maxDate={maxDate}
                            minDate={minDate}
                            value={this.state.date}
                            style={{ marginLeft: 'auto', marginRight: '2%' }}
                            TextFieldComponent={() => null}
                            ToolbarComponent={() =>
                                customToolbar(this.state.typeCalendar)
                            }
                        />
                    </MuiPickersUtilsProvider>
                </ThemeProvider>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        user: state.authentication.user,
        boats: state.fetch.boats,

        checkups: state.bob.checkups,
        checkupsothers: state.bob.checkupsothers,
        checkupTypes: state.fetch.checkupTypes,
        checkupCategories: state.fetch.checkupCategories,
        loading: state.bob.loading,
        bob: state.bob,
    }
}

export default connect(mapStateToProps)(withStyles(styles)(AlertPage))
