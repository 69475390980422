export default theme => ({
    root: Object.assign(theme.flex.row, { flexWrap: 'wrap', flexGrow: 1 }),
    picto: {
        margin: '20px',
        marginBottom: 0,
        flex: '1 0 25%',
        maxWidth: '100px',
        maxHeight: '100px',
    },
    pictoAndText: {
        width: '100%',
        height: '100%',
        textAlign: 'center',
        flexBasis: '50%',
    },
    text: Object.assign(theme.flex.rowAlignCenter, {
        justifyContent: 'center',
    }),
})
