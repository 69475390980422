export default theme => ({
    page: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
        marginTop: '-45px',
    },
    title: {
        fontSize: '38px',
        fontWeight: 500,
        marginTop: '10px',
        [theme.breakpoints.down('sm')]: {
            fontSize: '28px',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: '-webkit-box',
            marginTop: '0',
            '-webkit-line-clamp': '3',
            '-webkit-box-orient': 'vertical',
        },
    },
    subtitle: {
        margin: '0 8%',
        fontSize: '24px',
        lineHeight: 1.4,
        [theme.breakpoints.down('sm')]: {
            marginTop: '-8%',
            marginBottom: '-2%',
            fontSize: '17px',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: '-webkit-box',
            '-webkit-line-clamp': '7',
            '-webkit-box-orient': 'vertical',
        },
    },
    secondaryTitle: {
        fontSize: '38px',
        fontWeight: 500,
        marginTop: '15px',
        [theme.breakpoints.down('sm')]: {
            fontSize: '19px',
            marginTop: '-18%',
            marginBottom: '4%',
        },
    },
    secondarySubtitle: {
        margin: '0 8%',
        fontSize: '24px',
        lineHeight: 1.4,
        [theme.breakpoints.down('sm')]: {
            marginBottom: '7%',
            fontSize: '15px',
        },
    },
    punchline: {
        fontWeight: '500',
        fontSize: '0.9em',
    },
    blue: {
        color: '#3040a0',
    },
    freetrial: {
        color: '#303F9F',
        fontSize: '32px',
        lineHeight: '1.2',
        fontWeight: '400',
        backgroundColor: theme.palette.secondary.main,
        padding: '0 10px',
        [theme.breakpoints.down('sm')]: {
            fontSize: '24px',
        },
    },
    pressLogos: {
        width: '80%',
        objectFit: 'contain',
        marginBottom: '40px',
        maxWidth: '1300px',
        [theme.breakpoints.down('sm')]: {
            marginBottom: '70px',
            width: '95%',
        },
    },
    pricing: {
        fontSize: '0.6em',
    },
    buttonTry: {
        fontSize: '26px',
        backgroundColor: '#303F9F',
        color: 'white',
        margin: '40px 0',
        '&:hover': {
            backgroundColor: '#fec107',
            color: 'black',
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: '18px',
            marginTop: '-2%',
            marginBottom: '50px',
        },
    },
    screenViewer: {
        margin: '24px',
        maxWidth: '1300px',
    },
    screenViewerMobile: {
        margin: '0 24px 30px',
    },
    separator: {
        width: '100px',
        height: '2px',
        background: '#3040a0',
        [theme.breakpoints.down('sm')]: {
            marginTop: '-5%',
        },
    },
    share: {
        marginTop: '30px',
        fontSize: '20px',
        [theme.breakpoints.down('sm')]: {
            marginTop: '-5%',
            marginBottom: '20px',
            fontSize: '15px',
        },
    },
    SEO: {
        textAlign: 'left',
        margin: '30px 70px',
        lineHeight: '1.2',
        [theme.breakpoints.down('sm')]: {
            fontSize: '14px',
            width: '95%',
            marginTop: '8%',
        },
    },
    SEOtitle: {
        fontSize: '20px',
        marginBottom: 0,
        [theme.breakpoints.down('sm')]: {
            fontSize: '15px',
        },
    },
    SETtext: {
        fontSize: '18px',
        [theme.breakpoints.down('sm')]: {
            fontSize: '13px',
        },
    },
    st: {
        verticalAlign: 'super',
        fontSize: '70%',
    },
    iconLogbookBox: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '80px',
        width: '80px',
        backgroundColor: '#303F9F',
        borderRadius: '50%',
        margin: '0 20px',
        [theme.breakpoints.down('sm')]: {
            width: '70px',
            height: '70px',
        },
        [theme.breakpoints.down('xs')]: {
            width: '50px',
            height: '50px',
            margin: '0 10px',
        },
    },
    iconlogbookText: {
        textAlign: 'center',
        margin: '15px 0',
        fontWeight: '600',
        [theme.breakpoints.down('xs')]: {
            fontSize: '14px',
        },
    },
    icons: {
        display: 'flex',
        justifyContent: 'center',
        [theme.breakpoints.down('xs')]: {
            justifyContent: 'space-around',
            flexWrap: 'wrap',
        },
    },
    boatIcon: {
        width: '35px',
        height: '35px',
        marginRight: 'auto',
        marginLeft: 'auto',
        zIndex: '2',
        [theme.breakpoints.down('sm')]: {
            width: '40px',
            height: '40px',
        },
        [theme.breakpoints.down('xs')]: {
            width: '20px',
            height: '20px',
        },
    },
    flexRow: Object.assign(theme.flex.columnSpacebetween, {
        [theme.breakpoints.down('xs')]: {
            alignItems: 'center',
        },
    }),
    SEOTypography: {
        lineHeight: '1.2',
    },
    linked: {
        textDecoration: 'none',
    },
})
