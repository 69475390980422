import React from 'react'

import { withStyles } from '@material-ui/core/styles'
import {
    FaFacebookF,
    FaTwitter,
    FaInstagram,
    FaLinkedinIn,
} from 'react-icons/fa'

import Typography from '@material-ui/core/Typography'

import BoatOnComponent from './BoatOnComponent'

import googleplay from '../../images/googleplay.png'
import appstore from '../../images/appstore.png'
import styles from './Styles/FooterCss'
import dictionary from './Dictionary/FooterDico'

import AppRoute from './../../constants/AppRoute'

import BoatonBreadcrumbs from './BoatonBreadcrumbs'
import { Container, Grid, Link } from '@material-ui/core'
import { LocalizedLink } from '../../languages'
import group from '../../images/partner/group.png'

class Footer extends BoatOnComponent {
    constructor(props) {
        super(props)
        this.dictionary = dictionary
        this.parameters = props.parameters
    }

    render() {
        const { classes, sideMenu } = this.props

        return (
            <div style={{ zIndex: 1, position: 'sticky' }}>
                <div
                    className={
                        sideMenu
                            ? classes.RowBreadCrumbsWSideMenu
                            : classes.RowBreadCrumbs
                    }
                >
                    <Container>
                        <BoatonBreadcrumbs
                            parameters={this.parameters}
                        ></BoatonBreadcrumbs>
                    </Container>
                </div>
                <div className={classes.RowFooter}>
                    <Container>
                        <Grid container className={classes.RowFooter}>
                            <Grid
                                item
                                xs={12}
                                sm={4}
                                className={classes.column}
                            >
                                <div>
                                    <Typography className={classes.title}>
                                        {this.displayText('usServices')}
                                    </Typography>

                                    <LocalizedLink
                                        to={AppRoute.ParkingSearchPage}
                                        className={classes.footerLinkText}
                                    >
                                        {this.displayText('storage')}
                                    </LocalizedLink>
                                    <LocalizedLink
                                        to={AppRoute.InsurancePage}
                                        className={classes.footerLinkText}
                                    >
                                        {this.displayText('insurance')}
                                    </LocalizedLink>
                                    <LocalizedLink
                                        to={AppRoute.LogBook.Home}
                                        className={classes.footerLinkText}
                                    >
                                        {this.displayText('logbook')}
                                    </LocalizedLink>
                                    <Link
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        href="https://www.info.boaton.fr/reparer-mon-bateau"
                                        title="reparation"
                                        className={classes.footerLinkText}
                                    >
                                        {this.displayText('maintenanceRequest')}
                                    </Link>

                                    <Typography className={classes.title}>
                                        {this.displayText('professional')}
                                    </Typography>

                                    <LocalizedLink
                                        to={AppRoute.RentMyParking}
                                        className={classes.footerLinkText}
                                    >
                                        {this.displayText('rentPlace')}
                                    </LocalizedLink>
                                    <Link
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        href="https://pro.boaton.fr"
                                        title="boaton pro"
                                        className={classes.footerLinkText}
                                    >
                                        {this.displayText('accessPro')}
                                    </Link>
                                </div>
                                <Typography className={classes.boatonText}>
                                    {this.displayText('boaton')}
                                </Typography>
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                sm={4}
                                className={classes.column}
                            >
                                <div>
                                    <Typography className={classes.title}>
                                        {this.displayText('goodPlan')}
                                    </Typography>

                                    <Link
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        href="https://www.info.boaton.fr/le-blog/"
                                        title="blog"
                                        className={classes.footerLinkText}
                                    >
                                        {this.displayText('blog')}
                                    </Link>
                                    <Link
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        href="https://www.info.boaton.fr/le-quizz-entretien-de-votre-bateau"
                                        title="quizz"
                                        className={classes.footerLinkText}
                                    >
                                        {this.displayText('quizz')}
                                    </Link>
                                    <Link
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        href="https://www.info.boaton.fr/enquete"
                                        title="enquete"
                                        className={classes.footerLinkText}
                                    >
                                        {this.displayText('nationalSurvey')}
                                    </Link>
                                    <Link
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        href="https://www.info.boaton.fr/le-blog/assurer-mon-bateau-au-meilleur-tarif-avec-boaton-pro"
                                        title="assurer mon bateau"
                                        className={classes.footerLinkText}
                                    >
                                        {this.displayText('insureBoatPrice')}
                                    </Link>
                                    <Link
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        href="https://www.info.boaton.fr/le-blog/10-conseils-pour-r%C3%A9ussir-la-mise-%C3%A0-l-eau-de-votre-voilier"
                                        title="10 conseils"
                                        className={classes.footerLinkText}
                                    >
                                        {this.displayText('launchSail')}
                                    </Link>
                                    <Link
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        href="https://www.info.boaton.fr/le-blog/5-conseils-pour-un-car%C3%A9nage-r%C3%A9ussi"
                                        title="5 conseils"
                                        className={classes.footerLinkText}
                                    >
                                        {this.displayText('fairing')}
                                    </Link>
                                    <Link
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        href="https://www.info.boaton.fr/le-blog/les-10-questions-essentielles-a-se-poser-pour-acheter-le-bateau-qu-il-vous-faut"
                                        title="10 questions essentielles"
                                        className={classes.footerLinkText}
                                    >
                                        {this.displayText('boatNeeded')}
                                    </Link>
                                </div>
                                <div className={classes.linkAndApps}>
                                    <div className={classes.links}>
                                        <Typography className={classes.more}>
                                            {this.displayText('followUs')}
                                        </Typography>
                                        <a
                                            href="https://www.facebook.com/boatonpro/"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            title="facebook"
                                        >
                                            <FaFacebookF
                                                size="20px"
                                                className={classes.icon}
                                            />
                                        </a>
                                        <a
                                            href="https://twitter.com/BoatOn_Pro"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            title="twitter"
                                        >
                                            <FaTwitter
                                                size="20px"
                                                className={classes.icon}
                                            />
                                        </a>
                                        <a
                                            href="https://www.instagram.com/boaton_pro/"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            title="instagram"
                                        >
                                            <FaInstagram
                                                size="20px"
                                                className={classes.icon}
                                            />
                                        </a>
                                        <a
                                            href="https://www.linkedin.com/company/24968652/"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            title="linkedin"
                                        >
                                            <FaLinkedinIn
                                                size="20px"
                                                className={classes.icon}
                                            />
                                        </a>
                                    </div>
                                    <div className={classes.applications}>
                                        <a
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            href="https://apps.apple.com/us/app/boaton-pro/id1268005694"
                                            title="appstore"
                                        >
                                            <img
                                                src={appstore}
                                                alt="appstore"
                                                title="appstore"
                                                className={classes.image}
                                            />
                                        </a>
                                        <a
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            href="https://play.google.com/store/apps/details?id=boatonpro.boaton.com.boatonpro&hl=fr"
                                            title="googleplay"
                                        >
                                            <img
                                                src={googleplay}
                                                alt="googleplay"
                                                title="googleplay"
                                                className={classes.image}
                                            />
                                        </a>
                                    </div>
                                </div>
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                sm={4}
                                className={classes.column}
                            >
                                <div>
                                    <Typography className={classes.title}>
                                        {this.displayText('aboutUs')}
                                    </Typography>

                                    <Link
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        href="https://www.info.boaton.fr/contactez-nous"
                                        title="contactez-nous"
                                        className={classes.footerLinkText}
                                    >
                                        {this.displayText('contact')}
                                    </Link>
                                    <Link
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        href="https://www.info.boaton.fr/cgv"
                                        title="cgv"
                                        className={classes.footerLinkText}
                                    >
                                        {this.displayText('gtcs')}
                                    </Link>
                                </div>
                                <div>
                                    <Typography className={classes.title}>
                                        {this.displayText('partners')}
                                    </Typography>
                                    <div className={classes.logoPartner}>
                                        <img
                                            src={group}
                                            alt="group"
                                            title="group"
                                            className={classes.logo}
                                        />
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                    </Container>
                </div>
            </div>
        )
    }
}

export default withStyles(styles)(Footer)
