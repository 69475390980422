import React, { Component } from 'react'
import Translater from './Translater'
import CommonDictionary from './CommonDictionary'
import anchorLoader from '../../images/anchorLoader.gif'
import { LangContext } from '../../languages/LocalizerContext'
import AppRoute from '../../constants/AppRoute'

class BoatOnComponent extends Component {
    static contextType = LangContext

    constructor(props) {
        super(props)

        this.translater = new Translater()
        this.dictionary = CommonDictionary
        this.commonDictionary = CommonDictionary
        this.AppRoute = AppRoute

        this.displayText = this.displayText.bind(this)
        this.getLanguage = this.getLanguage.bind(this)
        this.getFrenchTranslate = this.getFrenchTranslate.bind(this)
        this.getEnglishTranslate = this.getEnglishTranslate.bind(this)
    }

    displayText(key) {
        return this.dictionary[key]
            ? this.translater.displayText(this.dictionary[key], this.context)
            : this.translater.displayText(
                  this.commonDictionary[key],
                  this.context,
              )
    }

    getContext() {
        return this.context
    }

    getPathForRoute(routeKey) {
        return this.translater.getRoute(this.context, routeKey)
    }

    getContextLink(link) {
        return '/' + this.getContext() + link
    }

    getLanguage() {
        return this.translater.getLanguage(this.context)
    }

    getFrenchTranslate(text) {
        return (
            this.translater.getFrenchTranslate(this.dictionary, text) ||
            this.translater.getFrenchTranslate(this.commonDictionary, text)
        )
    }

    getEnglishTranslate(text) {
        return (
            this.translater.getEnglishTranslate(this.dictionary, text) ||
            this.translater.getEnglishTranslate(this.commonDictionary, text)
        )
    }

    renderLoading(pixels, height) {
        return (
            <div
                style={{
                    width: '100%',
                    height: '100%',
                    minHeight: '50vh',
                }}
            >
                <img
                    src={anchorLoader}
                    alt="anchor"
                    margin="auto"
                    width={pixels}
                    height={height || pixels}
                    style={{
                        position: 'absolute',
                        transform: 'translate(-50%, -50%)',
                        top: '50%',
                        left: '50%',
                    }}
                />
            </div>
        )
    }

    historyPush(
        history,
        routeKey,
        additionalURL = '',
        historyData = null,
        routeParameters = {},
    ) {
        let pathname =
            this.translater.getRoute(this.context, routeKey) + additionalURL
        pathname = this.replaceParameters(pathname, routeParameters)
        if (historyData) {
            historyData.pathname = pathname
            history.push(historyData)
        } else {
            history.push(pathname)
        }
    }

    replaceParameters(pathname, routeParameters) {
        for (const parameterKey in routeParameters) {
            pathname = pathname.replace(
                `:${parameterKey}`,
                routeParameters[parameterKey],
            )
        }
        return pathname
    }

    getMessages() {
        return this.translater.getMessages()[this.context]
    }

    getBobEventIcon(eventId) {
        return true
    }
}

export default BoatOnComponent
