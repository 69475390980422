import React from 'react'
import { PropTypes } from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import classNames from 'classnames'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import MenuItem from '@material-ui/core/MenuItem'
import { Container, Grid, ButtonGroup, Button } from '@material-ui/core'
import { connect } from 'react-redux'
import IconButton from '@material-ui/core/IconButton'
import MenuIcon from '@material-ui/icons/Menu'
import Tel from '@material-ui/icons/LocalPhone'
import Drawer from '@material-ui/core/Drawer'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Divider from '@material-ui/core/Divider'
import styles from './Styles/NavivationCss'
import dictionary from './Dictionary/NavigationDico'
import logo from '../../images/logoBleu.png'
import BoatOnComponent from './BoatOnComponent'
import { LocalizedLink, LocalizedSwitcher } from '../../languages'
import AppRoute from './../../constants/AppRoute'
import { getCurrentRootRouteKey } from '../../languages/LocalizerUtils'

import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'

import Badge from '@material-ui/core/Badge'
import NotificationsIcon from '@material-ui/icons/Notifications'

import Grow from '@material-ui/core/Grow'
import Paper from '@material-ui/core/Paper'
import Popper from '@material-ui/core/Popper'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import MenuList from '@material-ui/core/MenuList'

import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import { userActions, resetDataBOB, resetFetch } from '../../actions'
import { history } from '../../helpers'

class Navigation extends BoatOnComponent {
    constructor(props) {
        super(props)
        this.dictionary = dictionary
        this.location = props.location

        this.state = {
            hideCategory: props.hideCategory ? props.hideCategory : false,
            mobileOpen: false,
            profileOpen: false,
            notificationOpen: false,
        }
        this.handleDrawerToggle = this.handleDrawerToggle.bind(this)
        this.toggleProfileButton = this.toggleProfileButton.bind(this)
        this.closeProfileButton = this.closeProfileButton.bind(this)
        this.toggleNotificationButton = this.toggleNotificationButton.bind(this)
        this.closeNotificationButton = this.closeNotificationButton.bind(this)
        this.logout = this.logout.bind(this)

        this.anchorProfilRef = React.createRef()
        this.anchorNotificationRef = React.createRef()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.hideCategory !== this.state.hideCategory) {
            this.setState({ hideCategory: this.props.hideCategory })
        }
    }

    componentDidMount() {
        window.scrollTo(0, 0)
    }

    handleDrawerToggle() {
        this.setState({ mobileOpen: !this.state.mobileOpen })
    }

    drawer() {
        const { classes, user, loadingUser } = this.props

        const url = window.location.pathname
        const pathname = getCurrentRootRouteKey(url, this.getMessages())

        const firstMenu = [
            {
                title: this.displayText('bob'),
                link: '/my-boat-logbook',
                route: AppRoute.LogBook.Home,
            },
        ]
        const secondMenu = user
            ? [
                  { title: 'Le blog', link: '/le-blog' },
                  {
                      title: this.displayText('profile'),
                      link: '/profile',
                      route: AppRoute.Profile,
                  },
              ]
            : [
                  { title: this.displayText('blog'), link: '/le-blog' },
                  {
                      title: this.displayText('login'),
                      link: '/login',
                      route: AppRoute.Login,
                  },
              ]

        return (
            <div className={classes.drawerBox}>
                <div>
                    <div className={classes.toolbar} />
                    <Divider />
                    <List>
                        {firstMenu.map((item, index) => (
                            <ListItem button key={item.title}>
                                <LocalizedLink
                                    variant="body1"
                                    to={item.route}
                                    link={item.link}
                                    style={{
                                        textDecoration: 'none',
                                        display: 'flex',
                                        flexDirection: 'row',
                                        color: '#000',
                                    }}
                                >
                                    <ListItemText
                                        className={
                                            pathname && pathname === item.route
                                                ? classes.activeTextCategory
                                                : null
                                        }
                                        primary={item.title}
                                    />
                                </LocalizedLink>
                            </ListItem>
                        ))}
                    </List>
                    <Divider />
                    <List>
                        {!loadingUser &&
                            secondMenu.map((item, index) => (
                                <ListItem button key={item.title}>
                                    <LocalizedLink
                                        variant="body1"
                                        to={item.route}
                                        link={item.link}
                                        style={{
                                            textDecoration: 'none',
                                            display: 'flex',
                                            flexDirection: 'row',
                                            color: '#000',
                                        }}
                                    >
                                        <ListItemText primary={item.title} />
                                    </LocalizedLink>
                                </ListItem>
                            ))}
                    </List>
                    {user && (
                        <>
                            <Divider />
                            <MenuItem
                                classes={{
                                    root: classes.profileMenuItem,
                                }}
                                onClick={this.logout}
                            >
                                {this.displayText('disconnect')}
                                <ExitToAppIcon />
                            </MenuItem>
                        </>
                    )}
                </div>
                <div className={classes.drawerPhone}>{this.getPhoneInfo()}</div>
            </div>
        )
    }

    getPhoneInfo() {
        const { classes } = this.props

        return (
            <>
                <Tel className={classes.logotel} />
                <a
                    href="tel:+33678763698"
                    className={classes.topBarPhone}
                    title="telephone"
                >
                    <span className={classes.span}>+33 6 78 76 36 98</span>
                </a>
            </>
        )
    }

    renderDependingOnSize() {
        const { classes, loadingUser } = this.props
        const { mobileOpen } = this.state
        const url = window.location.pathname
        const pathname = getCurrentRootRouteKey(url, this.getMessages())
        const categorys = [
            {
                name: 'bob',
                link: '/my-boat-logbook',
                route: AppRoute.LogBook.Home,
            },
            { name: 'blog', link: '/le-blog' },
        ]

        return (
            <Container maxWidth="lg">
                <Grid container spacing={3}>
                    <Grid
                        container
                        item
                        xs={5}
                        sm={4}
                        md={2}
                        alignItems="center"
                    >
                        <LocalizedLink to={AppRoute.MainSite}>
                            <img
                                src={logo}
                                width="120px"
                                alt="Boaton"
                                title="Boaton"
                            />
                        </LocalizedLink>
                    </Grid>
                    <Grid
                        container
                        spacing={3}
                        item
                        xs
                        className={classes.hideMobile}
                    >
                        {categorys.map((category, id) => (
                            <Grid item key={id}>
                                <LocalizedLink
                                    to={category.route}
                                    link={category.link}
                                    style={{
                                        textDecoration: 'none',
                                        display: 'flex',
                                        flexDirection: 'row',
                                    }}
                                >
                                    <Typography
                                        className={
                                            pathname &&
                                            pathname === category.route
                                                ? classes.activeTextCategory
                                                : classes.textCategory
                                        }
                                    >
                                        {this.displayText(category.name)}
                                    </Typography>
                                </LocalizedLink>
                            </Grid>
                        ))}
                    </Grid>
                    <Grid
                        container
                        spacing={3}
                        item
                        sm={4}
                        md={3}
                        alignItems="center"
                        justify="flex-end"
                        className={classes.hideMobile}
                    >
                        {!loadingUser && this.renderProfileNavigation()}
                    </Grid>
                    <Grid
                        container
                        spacing={3}
                        item
                        xs
                        className={classes.menuMobile}
                    >
                        <div className={classes.menuMobileItem}>
                            <LocalizedSwitcher />
                        </div>
                        <IconButton
                            color="inherit"
                            aria-label="Open drawer"
                            edge="start"
                            onClick={this.handleDrawerToggle}
                            className={classes.menuMobileItem}
                        >
                            <MenuIcon />
                        </IconButton>
                        <Drawer
                            variant="temporary"
                            anchor="right"
                            open={mobileOpen}
                            onClose={this.handleDrawerToggle}
                            classes={{
                                paper: classes.drawerPaper,
                            }}
                            ModalProps={{
                                keepMounted: true, // Better open performance on mobile.
                            }}
                        >
                            {this.drawer()}
                        </Drawer>
                    </Grid>
                </Grid>
            </Container>
        )
    }

    toggleProfileButton() {
        this.setState(prevState => ({
            profileOpen: !prevState.profileOpen,
        }))
    }

    toggleNotificationButton() {
        this.setState(prevState => ({
            notificationOpen: !prevState.notificationOpen,
        }))
    }

    closeProfileButton() {
        this.setState(prevState => ({
            profileOpen: false,
        }))
    }

    closeNotificationButton() {
        this.setState(prevState => ({
            notificationOpen: false,
        }))
    }

    logout() {
        this.props.dispatch(userActions.logout())
        this.props.dispatch(resetDataBOB())
        this.props.dispatch(resetFetch())
        this.historyPush(history, AppRoute.Login)
    }

    renderProfileNavigation() {
        const { classes, user } = this.props
        const { profileOpen, notificationOpen } = this.state

        if (user) {
            return (
                <>
                    <IconButton
                        aria-label="show 0 new notifications"
                        color="inherit"
                        onClick={this.toggleNotificationButton}
                        ref={this.anchorNotificationRef}
                    >
                        <Badge badgeContent={0} color="secondary">
                            <NotificationsIcon />
                        </Badge>
                    </IconButton>
                    <Grid item>
                        <ButtonGroup
                            variant="contained"
                            color="primary"
                            aria-label="split button"
                            size="small"
                            ref={this.anchorProfilRef}
                        >
                            <Button
                                className={classes.buttonProfil}
                                size="small"
                            >
                                <LocalizedLink
                                    to={AppRoute.Profile}
                                    classes={{
                                        containedSizeSmall:
                                            classes.containedSizeSmall,
                                    }}
                                    style={{
                                        textDecoration: 'none',
                                        color: 'white',
                                        padding: '0px 20px',
                                    }}
                                >
                                    Profil
                                </LocalizedLink>
                            </Button>
                            <Button
                                color="primary"
                                size="small"
                                aria-controls={
                                    profileOpen
                                        ? 'split-button-menu'
                                        : undefined
                                }
                                aria-expanded={profileOpen ? 'true' : undefined}
                                aria-label="select merge strategy"
                                aria-haspopup="menu"
                                onClick={this.toggleProfileButton}
                            >
                                <ArrowDropDownIcon />
                            </Button>
                        </ButtonGroup>
                        <Popper
                            open={profileOpen}
                            anchorEl={this.anchorProfilRef.current}
                            role={undefined}
                            transition
                            disablePortal
                            placement="bottom-end"
                        >
                            {({ TransitionProps, placement }) => (
                                <Grow
                                    {...TransitionProps}
                                    style={{
                                        transformOrigin: 'center top',
                                    }}
                                >
                                    <Paper elevation={2}>
                                        <ClickAwayListener
                                            onClickAway={
                                                this.closeProfileButton
                                            }
                                        >
                                            <MenuList
                                                id="split-button-menu"
                                                className={classes.profileList}
                                            >
                                                <LocalizedLink
                                                    to={AppRoute.Profile}
                                                    className={
                                                        classes.profileItem
                                                    }
                                                >
                                                    <MenuItem>
                                                        {this.displayText(
                                                            'myAccount',
                                                        )}
                                                    </MenuItem>
                                                </LocalizedLink>
                                                <LocalizedLink
                                                    to={AppRoute.LogBook.Home}
                                                    className={
                                                        classes.profileItem
                                                    }
                                                >
                                                    <MenuItem>
                                                        {this.displayText(
                                                            'myBOB',
                                                        )}
                                                    </MenuItem>
                                                </LocalizedLink>
                                                <Divider
                                                    classes={{
                                                        root: classes.divider,
                                                    }}
                                                />
                                                <LocalizedLink
                                                    to={AppRoute.RentMyParking}
                                                    className={
                                                        classes.profileItem
                                                    }
                                                >
                                                    <MenuItem>
                                                        {this.displayText(
                                                            'rentMyPlace',
                                                        )}
                                                    </MenuItem>
                                                </LocalizedLink>
                                                <Divider
                                                    classes={{
                                                        root: classes.divider,
                                                    }}
                                                />
                                                <LocalizedLink
                                                    to={AppRoute.Forum}
                                                    className={
                                                        classes.profileItem
                                                    }
                                                >
                                                    <MenuItem>
                                                        {this.displayText(
                                                            'forum',
                                                        )}
                                                    </MenuItem>
                                                </LocalizedLink>
                                                <MenuItem
                                                    classes={{
                                                        root:
                                                            classes.profileMenuItem,
                                                    }}
                                                    onClick={this.logout}
                                                >
                                                    {this.displayText(
                                                        'disconnect',
                                                    )}
                                                    <ExitToAppIcon />
                                                </MenuItem>
                                            </MenuList>
                                        </ClickAwayListener>
                                    </Paper>
                                </Grow>
                            )}
                        </Popper>
                        <Popper
                            open={notificationOpen}
                            anchorEl={this.anchorNotificationRef.current}
                            role={undefined}
                            transition
                            disablePortal
                            placement="bottom-end"
                        >
                            {({ TransitionProps, placement }) => (
                                <Grow
                                    {...TransitionProps}
                                    style={{
                                        transformOrigin: 'center top',
                                    }}
                                >
                                    <Paper elevation={2}>
                                        <ClickAwayListener
                                            onClickAway={
                                                this.closeNotificationButton
                                            }
                                        >
                                            <div
                                                className={
                                                    classes.notificationBox
                                                }
                                            >
                                                {this.displayText(
                                                    'notification',
                                                )}
                                            </div>
                                        </ClickAwayListener>
                                    </Paper>
                                </Grow>
                            )}
                        </Popper>
                    </Grid>
                </>
            )
        } else {
            return (
                <div className={classes.loginSection}>
                    <LocalizedLink
                        to={AppRoute.Login}
                        className={classes.signinTextButton}
                    >
                        <Button
                            size="small"
                            variant="contained"
                            classes={{
                                root: classes.signinButton,
                                containedSizeSmall: classes.containedSizeSmall,
                            }}
                        >
                            {this.displayText('login')}
                        </Button>
                    </LocalizedLink>
                    <LocalizedLink
                        to={AppRoute.Register}
                        className={classes.signupTextButton}
                        onClick={this.props.onRegisterClick}
                    >
                        <Button
                            size="small"
                            color="primary"
                            variant="contained"
                            classes={{
                                root: classes.signupButton,
                                containedSizeSmall: classes.containedSizeSmall,
                            }}
                        >
                            {this.displayText('register')}
                        </Button>
                    </LocalizedLink>
                </div>
            )
        }
    }

    render() {
        const { classes } = this.props

        return (
            <div className={classes.root}>
                <div className={classNames(classes.appBar)}>
                    <div className={classes.topbarcontainer}>
                        <Container maxWidth="lg" className={classes.topbar}>
                            <div className={classes.infosBox}>
                                {this.getPhoneInfo()}
                                <p className={classes.topBarText}>
                                    <span className={classes.span}>|</span>
                                    <span className={classes.span}>
                                        {this.displayText('topBar')}
                                    </span>
                                </p>
                            </div>
                            <div>
                                <LocalizedSwitcher />
                            </div>
                        </Container>
                    </div>

                    <AppBar color="inherit" position="sticky">
                        <Toolbar disableGutters>
                            {this.renderDependingOnSize()}
                        </Toolbar>
                    </AppBar>
                </div>

                <main className={classes.content}>
                    <div className={classes.toolbar}>{this.props.children}</div>
                </main>
            </div>
        )
    }
}

Navigation.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
}

function mapStateToProps(state) {
    return {
        user: state.authentication.user,
        loadingUser: state.authentication.loading,
    }
}

const NavigationConnect = connect(mapStateToProps)(
    withStyles(styles, { withTheme: true })(Navigation),
)

export default NavigationConnect
