export default {
    entretiens: {
        FR: 'Mes entretiens',
        EN: 'My repair',
    },
    engine: {
        FR: 'Moteur',
        EN: 'Engine',
    },
    electricity: {
        FR: 'Electricité',
        EN: 'Electricity',
    },
    electronic: {
        FR: 'Electronique',
        EN: 'Electronic',
    },
    freshWaterCircuit: {
        FR: 'Circuit eau douce',
        EN: 'Fresh water circuit',
    },
    hardwareAndRigging: {
        FR: 'Accastillage et gréement',
        EN: 'Fittings and rigging',
    },
    comfortAndSafety: {
        FR: 'Confort et sécurité',
        EN: 'Comfort and safety',
    },
    shell: {
        FR: 'Coque',
        EN: 'Hull',
    },
    other: {
        FR: 'Autre',
        EN: 'Other',
    },
    search: {
        FR: 'Je recherche',
        EN: 'Search',
    },
    userAddsRepair: {
        FR: "Vous êtes en train d'ajouter un entretien, bien joué !",
        EN: 'You are currently adding a checkup, well done!',
    },
    userEditRepair: {
        FR: 'Vous êtes en train de modifier un entretien, bien joué !',
        EN: 'You are currently edit a checkup, well done !',
    },
    userAddsMaintenance: {
        FR: 'Ajouter une maintenance',
        EN: 'add maintenance',
    },
    userAddsRepairLayout: {
        FR: 'Ajouter une réparation ou un aménagement',
        EN: 'Add repair or layout',
    },
    typeRepair: {
        FR: "Choisir un type d'entretien",
        EN: 'Choose a repair type',
    },
    operationRepair: {
        FR: "Renseigner l'opération à effectuer",
        EN: 'Enter the operation to be performed',
    },

    familyRepair: {
        FR: "Choisir une famille d'équipements",
        EN: 'Choose a family of equipment ,',
    },
    equipement: {
        FR: 'Choisir un equipement',
        EN: 'Choose an equipment',
    },
    piece: {
        FR: 'Choisir une piece',
        EN: 'Choose a piece',
    },
    dateLast: {
        FR: 'Dernier contrôle',
        EN: 'Last date',
    },
    dateNext: {
        FR: 'Prochain contrôle',
        EN: 'Next date',
    },
    amount: {
        FR: 'Montant',
        EN: 'Amount',
    },
    detailsDernierControle: {
        FR: 'Détails du dernier contrôle',
        EN: 'Commentary of the last check',
    },
    detailsProchainControle: {
        FR: 'Détails du prochain contrôle',
        EN: 'Commentary of the next check',
    },
    addDocumentLast: {
        FR: 'Documents du dernier contrôle',
        EN: 'Document of the last check',
    },
    addDocumentNext: {
        FR: 'Documents du prochain contrôle',
        EN: 'Document of the next check',
    },
    addRepair: {
        FR: 'Ajoutez un entretien',
        EN: 'Create new repair',
    },
    validate: {
        FR: 'Je valide',
        EN: 'Validate',
    },
    gerer: {
        FR: 'Vous pouvez visualiser et gérer tous vos entretiens ici.',
        EN: 'You can view and manage all your repairs here',
    },
    validateDelete: {
        FR: 'Voulez-vous vraiment supprimer cet entretien ?',
        EN: 'Do you really want to delete this repair ?',
    },
    alert: {
        FR:
            "Vous devez remplir les champs type d'entretien, famille d'équipements, nom, dernier contrôle et prochain contrôle",
        EN:
            'You must fill in the mantenance type, equipment family, name, last check and next check',
    },
    alert1: {
        FR:
            'Vous devez remplir les champs dernier contrôle et prochain contrôle',
        EN: 'You must fill in the last check and next check',
    },
    alert2: {
        FR: 'Vous devez remplir le champs dernier contrôle',
        EN: 'You must fill in the last check',
    },
    alert3: {
        FR: 'Vous devez remplir le champs prochain contrôle',
        EN: 'You must fill in the next check',
    },
    euro: {
        FR: '€',
        EN: '€',
    },
    empty: {
        FR: 'Tout',
        EN: 'All',
    },
    upcoming: {
        FR: 'Mes entretiens à venir',
        EN: 'My upcoming',
    },
    past: {
        FR: 'Mes entretiens passés',
        EN: 'My past interviews',
    },
    recorded: {
        FR: "Vous n'avez aucun entretien enregistré",
        EN: 'You have no recorded interviews',
    },
    chosePerson: {
        FR: "Choisir le responsable de l'opération",
        EN: 'Choose the person in charge of the operation',
    },
    controlCarried: {
        FR: 'Contrôle effectué dans les délais ?',
        EN: 'Control carried out in time?',
    },
    reasonsDelay: {
        FR:
            'Si besoin, vous pouvez écrire ici les raisons du retard du contrôle',
        EN:
            'If necessary, you can write here the reasons for the delay of the control',
    },
}
