import React from 'react'
import BoatOnComponent from '../../../common/BoatOnComponent'
import { connect } from 'react-redux'

import { withStyles } from '@material-ui/core/styles'
import styles from './Styles/BudgetTabCss'
import dictionary from './Dictionary/BudgetTabDico'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import { Doughnut } from 'react-chartjs-2'
import RangDate from '../../../common/UsefullComponents/RangDate'
import TextField from '@material-ui/core/TextField'
import { typesActions } from '../../../../actions/types.actions'

class BudgetTab extends BoatOnComponent {
    constructor(props) {
        super(props)
        this.dictionary = dictionary

        this.handleChangeDate = this.handleChangeDate.bind(this)
    }

    componentWillReceiveProps(props) {
        if (
            props.boats &&
            (!props.spendings || props.update > 0 || !props.documents)
        ) {
            if (props.update > 0) this.props.updated()
        }
    }

    handleChangeDate(startDate, endDate) {
        this.props.handleChangeDate(startDate, endDate)
    }

    handleChangeSearch(search) {
        this.props.handleChangeSearch(search)
    }

    getTotalIncome() {
        var { spendings, filter, eventTypes } = this.props
        var total = 0

        spendings = spendings.filter(spending => {
            if (
                filter &&
                filter.search &&
                !RegExp(filter.search.toLowerCase()).test(
                    spending.Title.toLowerCase(),
                )
            ) {
                return false
            }
            if (
                filter &&
                filter.startDate &&
                new Date(filter.startDate).getTime() >
                    new Date(spending.Date).getTime()
            )
                return false
            if (
                filter &&
                filter.endDate &&
                new Date(filter.endDate).getTime() <
                    new Date(spending.Date).getTime()
            )
                return false
            return true
        })

        spendings.forEach(spending => {
            const eventType = typesActions.getEventTypeById(
                eventTypes,
                spending.eventType.id,
            )
            if (
                eventTypes &&
                eventType &&
                spending.transaction.amountIt > 0 &&
                !eventType.spending &&
                eventType.earning
            ) {
                total += parseFloat(spending.transaction.amountIt)
            }
        })

        return total
    }

    getTotalExpense(eventTypeId = null) {
        var { spendings, filter, eventTypes } = this.props
        var total = 0

        spendings = spendings.filter(spending => {
            if (
                filter &&
                filter.search &&
                !RegExp(filter.search.toLowerCase()).test(
                    spending.Title.toLowerCase(),
                )
            ) {
                return false
            }
            if (
                filter &&
                filter.startDate &&
                new Date(filter.startDate).getTime() >
                    new Date(spending.Date).getTime()
            )
                return false
            if (
                filter &&
                filter.endDate &&
                new Date(filter.endDate).getTime() <
                    new Date(spending.Date).getTime()
            )
                return false
            return true
        })

        spendings.forEach(spending => {
            const eventType = typesActions.getEventTypeById(
                eventTypes,
                spending.eventType.id,
            )
            if (
                !eventTypeId &&
                eventTypes &&
                eventType &&
                spending.transaction.amountIt > 0 &&
                eventType.spending &&
                !eventType.earning
            ) {
                total += parseFloat(spending.transaction.amountIt * -1)
            } else if (
                eventTypeId &&
                eventType &&
                eventTypeId === spending.eventType.id &&
                eventTypes &&
                spending.transaction.amountIt > 0 &&
                eventType.spending &&
                !eventType.earning
            ) {
                total += parseFloat(spending.transaction.amountIt * -1)
            }
        })
        return total
    }

    render() {
        const { classes, loading, filter, eventTypes } = this.props

        if (loading !== 0 || !eventTypes) return <></>

        const colorTab = eventTypes.map(eventType => {
            if (this.getTotalExpense(eventType.id) < 0) {
                return eventType.color
            }
        })

        const totalTab = eventTypes.map(eventType => {
            return this.getTotalExpense(eventType.id)
        })
        const nameTab = eventTypes.map(eventType => {
            if (this.getTotalExpense(eventType.id) < 0) {
                return eventType.eventTypeFr
            }
        })

        return (
            <div className={classes.root}>
                <Typography className={classes.title}>
                    {this.displayText('budget')}
                </Typography>
                <div className={classes.firstLine}>
                    <RangDate
                        onDateChange={this.handleChangeDate}
                        desactiveUnavailable
                    />
                    <div className={classes.recherche}>
                        <TextField
                            id="search"
                            margin="dense"
                            required
                            variant="outlined"
                            placeholder={this.displayText('search')}
                            value={this.props.filter.search || ''}
                            onChange={e => {
                                this.handleChangeSearch(e.target.value)
                            }}
                            className={classes.field}
                        />
                    </div>
                </div>
                <div className={classes.infos}>
                    <div className={classes.income}>
                        <Typography className={classes.subtitle}>
                            {this.displayText('income')}
                        </Typography>
                        <Typography className={classes.priceTotal}>
                            {this.getTotalIncome()} €
                        </Typography>
                    </div>
                    <Divider className={classes.divider} />
                    <div className={classes.expense}>
                        <div className={classes.expenseHead}>
                            <Typography className={classes.subtitle}>
                                {this.displayText('expense')}
                            </Typography>
                            <Typography className={classes.priceTotal}>
                                {this.getTotalExpense()} €
                            </Typography>
                        </div>
                        <div className={classes.expenseBody}>
                            <div className={classes.doughnut}>
                                <Doughnut
                                    legend={null}
                                    data={{
                                        datasets: [
                                            {
                                                data: totalTab,
                                                backgroundColor: colorTab,
                                            },
                                        ],
                                        labels: nameTab,
                                    }}
                                    options={{
                                        responsive: true,
                                        maintainAspectRatio: true,
                                    }}
                                />
                            </div>
                            <div className={classes.compte}>
                                {eventTypes.map((type, index) => {
                                    var total = this.getTotalExpense(type.id)

                                    if (total === 0) {
                                        return <div key={index} />
                                    }
                                    return (
                                        <div
                                            className={classes.line}
                                            key={index}
                                        >
                                            <img
                                                src={
                                                    type.photo
                                                        ? require(`images/EventBobIcon/${type.photo}`)
                                                        : ''
                                                }
                                                alt={type.eventTypeFr}
                                                width="40px"
                                                height="40px"
                                                className={classes.icon}
                                            />
                                            <div className={classes.texts}>
                                                <Typography
                                                    className={classes.text}
                                                >
                                                    {type.eventTypeFr}
                                                </Typography>
                                                <Typography
                                                    className={classes.price}
                                                >
                                                    {total} €
                                                </Typography>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        boats: state.fetch.boats,
        documents: state.bob.documents,
        bob: state.bob,
        user: state.authentication.user,
        events: state.bob.events,
        loading: state.bob.loading,
        eventTypes: typesActions.getEventTypeTransaction(
            state.types.eventTypes,
        ),
    }
}

export default connect(mapStateToProps)(withStyles(styles)(BudgetTab))
