export const typesConstants = {
    REQUEST_LISTBOATMODELS: 'REQUEST_LISTBOATMODELS',
    LISTBOATMODELS_SUCCESS: 'LISTBOATMODELS_SUCCESS',
    LISTBOATMODELS_FAILURE: 'LISTBOATMODELS_FAILURE',
    REQUEST_BOATTYPES: 'REQUEST_REQUEST_BOATTYPES',
    REQUEST_BOATTYPES_SUCCESS: 'REQUEST_BOATTYPES_SUCCESS',
    REQUEST_BOATTYPES_FAILURE: 'REQUEST_BOATTYPES_FAILURE',
    REQUEST_ENGINETYPES: 'REQUEST_ENGINETYPES',
    REQUEST_ENGINETYPES_SUCCESS: 'REQUEST_ENGINETYPES_SUCCESS',
    REQUEST_ENGINETYPES_FAILURE: 'REQUEST_ENGINETYPES_FAILURE',
    REQUEST_EVENTTYPES: 'REQUEST_EVENTTYPES',
    REQUEST_EVENTTYPES_SUCCESS: 'REQUEST_EVENTTYPES_SUCCESS',
    REQUEST_EVENTTYPES_FAILURE: 'REQUEST_EVENTTYPES_FAILURE',
}
