import prevu_bob from '../images/prevu_bob.png'

const AppMeta = {
    fr: {
        'routes.root': {
            title: 'Boaton',
            description:
                'Trouvez des emplacements, entretenir, assurer son bateau, Boaton est votre partenaire.',
            type: '',
            image: '',
        },
        'routes.logbook.home': {
            title: 'Boaton Book',
            description:
                "Le Journal de Bord intelligent BoatOn facilite l'entretien et la gestion de votre bateau. Soyez alerté de vos échéances, contrôlez votre budget et stockez vos documents.",
            type: '',
            image: prevu_bob,
        },
        'routes.profile': {
            title: 'Votre profil Boaton',
            description: 'Votre profil Boaton',
            type: '',
            image: '',
        },
    },
    en: {
        'routes.root': {
            title: 'Boaton',
            description:
                'Find locations, maintain, insure your boat, Boaton is your partner.',
            type: '',
            image: '',
        },
        'routes.logbook.home': {
            title: 'Boaton Book',
            description:
                'The BoatOn Smart Logbook makes it easy to maintain and manage your boat. Be alerted to your deadlines, control your budget and store your documents.',
            type: '',
            image: prevu_bob,
        },
    },
}

export default AppMeta
