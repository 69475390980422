import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import StarIcon from '@material-ui/icons/Star'
import IconButton from '@material-ui/core/IconButton'
import styles from './Styles/StarsCss'

class Stars extends Component {
    constructor(props) {
        super(props)

        this.state = {
            star: props.star || 0,
        }
    }

    starClicked(star) {
        this.setState({ star })
        this.forceUpdate()
    }

    render() {
        const { classes } = this.props

        if (this.props.clickable === true) {
            return (
                <div className={classes.stars}>
                    <IconButton onClick={() => this.starClicked(1)}>
                        {this.state.star >= 1 ? (
                            <StarIcon className={classes.starOnButton} />
                        ) : (
                            <StarIcon
                                className={classes.starOffButton}
                                color="secondary"
                            />
                        )}
                    </IconButton>
                    <IconButton onClick={() => this.starClicked(2)}>
                        {this.state.star >= 2 ? (
                            <StarIcon className={classes.starOnButton} />
                        ) : (
                            <StarIcon
                                className={classes.starOffButton}
                                color="secondary"
                            />
                        )}
                    </IconButton>
                    <IconButton onClick={() => this.starClicked(3)}>
                        {this.state.star >= 3 ? (
                            <StarIcon className={classes.starOnButton} />
                        ) : (
                            <StarIcon
                                className={classes.starOffButton}
                                color="secondary"
                            />
                        )}
                    </IconButton>
                    <IconButton onClick={() => this.starClicked(4)}>
                        {this.state.star >= 4 ? (
                            <StarIcon className={classes.starOnButton} />
                        ) : (
                            <StarIcon
                                className={classes.starOffButton}
                                color="secondary"
                            />
                        )}
                    </IconButton>
                    <IconButton onClick={() => this.starClicked(5)}>
                        {this.state.star >= 5 ? (
                            <StarIcon className={classes.starOnButton} />
                        ) : (
                            <StarIcon
                                className={classes.starOffButton}
                                color="secondary"
                            />
                        )}
                    </IconButton>
                </div>
            )
        }
        return (
            <div className={classes.stars}>
                {this.state.star >= 1 ? (
                    <StarIcon
                        iconstyle={{ width: '10px' }}
                        className={classes.starOn}
                        color="secondary"
                    />
                ) : (
                    <StarIcon
                        iconstyle={{ width: '10px', height: '10px' }}
                        className={classes.starOff}
                    />
                )}
                {this.state.star >= 2 ? (
                    <StarIcon className={classes.starOn} color="secondary" />
                ) : (
                    <StarIcon
                        iconstyle={{ width: '10px', height: '10px' }}
                        className={classes.starOff}
                    />
                )}
                {this.state.star >= 3 ? (
                    <StarIcon className={classes.starOn} color="secondary" />
                ) : (
                    <StarIcon
                        iconstyle={{ width: '10px', height: '10px' }}
                        className={classes.starOff}
                    />
                )}
                {this.state.star >= 4 ? (
                    <StarIcon className={classes.starOn} color="secondary" />
                ) : (
                    <StarIcon
                        iconstyle={{ width: '10px', height: '10px' }}
                        className={classes.starOff}
                    />
                )}
                {this.state.star >= 5 ? (
                    <StarIcon className={classes.starOn} color="secondary" />
                ) : (
                    <StarIcon
                        iconstyle={{ width: '10px', height: '10px' }}
                        className={classes.starOff}
                    />
                )}
            </div>
        )
    }
}

export default withStyles(styles)(Stars)
