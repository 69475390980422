export default {
    selectBoat: {
        FR: 'Nom de votre bateau',
        EN: 'Name of your boat',
    },
    noMaintenance: {
        FR: "Pas d'entretien à venir dans les 3 prochains mois",
        EN: 'No maintenance to come in the next 3 months',
    },
    hours: {
        FR: 'heures moteur au compteur au',
        EN: 'engine hours on',
    },
    spent: {
        FR: '€ dépensés en',
        EN: '€ spent in',
    },
    earned: {
        FR: '€ gagnés en',
        EN: '€ earned in',
    },
}
