export default theme => ({
    root: Object.assign(
        {
            margin: '10px',
            height: '100%',
            position: 'sticky',
            top: 0,
            right: 0,
            marginTop: '-10px',
        },
        theme.boxBoatOn,
        {
            width: '300px',
            padding: 0,
            [theme.breakpoints.down('sm')]: {
                width: 'calc(100% - 60px)',
                marginLeft: '60px',
                border: 0,
                top: 10,
                position: 'relative',
                zIndex: '3',
                marginBottom: '10px',
            },
        },
    ),
    title: {
        marginBottom: '10px',
        position: 'relative',
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
    text: {
        textAlign: 'left',
        width: '90%',
        padding: '10px',
        fontSize: '18px',
        marginLeft: '1%',
        backgroundColor: 'white',
        zIndex: '2',
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
    name: {
        padding: '10px',
        width: 'calc(100% - 20px)',
        marginBottom: '2%',
        [theme.breakpoints.down('sm')]: {
            marginBottom: '1%',
            marginTop: '20px',
        },
    },
    iconBoat: {
        position: 'absolute',
        width: '25%',
        top: '5%',
        left: '37%',
    },
    info: {
        color: '#303f9f',
        fontWeight: 'bold',
    },
})
