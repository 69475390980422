import React from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import BoatOnComponent from '../BoatOnComponent'
import styles from './Styles/RenderAccountCreateCss'
import dictionary from './Dictionary/RenderAccountCreateDico'
import InputAdornment from '@material-ui/core/InputAdornment'
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd'
import EmailIcon from '@material-ui/icons/Email'
import LockIcon from '@material-ui/icons/Lock'
import { userActions } from '../../../actions'
import { isValidPhoneNumber } from 'react-phone-number-input'
import PhoneField from '../PhoneField'

class RenderAccountCreate extends BoatOnComponent {
    constructor(props) {
        super(props)
        this.dictionary = dictionary

        this.state = {
            showPassword: false,
            firstNameCreate: '',
            lastNameCreate: '',
            emailCreate: '',
            phoneCreate: '',
            passwordCreate: '',
            passwordCreateValid: '',
            focused: false,
        }

        this.handleChange = this.handleChange.bind(this)
        this.signin = this.signin.bind(this)
        this.valid = this.valid.bind(this)
    }

    handleChange(value, key) {
        this.setState({ focused: key })
        this.setState({ [key]: value })
    }

    signin() {
        const { valid } = this.props
        new Promise((resolve, reject) => {
            this.props.dispatch(
                userActions.signin(
                    {
                        email: this.state.emailCreate,
                        password: this.state.passwordCreate.trim(),
                        firstName: this.state.firstNameCreate,
                        lastName: this.state.lastNameCreate,
                        contactDetails: [
                            {
                                contact: this.state.phoneCreate,
                                contactType: { id: 1 },
                            },
                        ],
                    },
                    resolve,
                    reject,
                ),
            )
        })
            .then(() => valid('boatInfos'))
            .catch(() => valid('personnalInfos'))
    }

    valid(e) {
        this.setState({ focused: false })
        e.preventDefault()
        if (
            /^\w+([-.]?\w+)*@\w+([-.]?\w+)*(\.\w{2,3})+$/.test(
                this.state.emailCreate,
            ) &&
            this.state.passwordCreate.length >= 6 &&
            this.state.passwordCreateValid === this.state.passwordCreate &&
            this.state.phoneCreate &&
            isValidPhoneNumber(this.state.phoneCreate)
        ) {
            this.signin()
        }
    }

    render() {
        const { classes } = this.props

        return (
            <form name="signin" onSubmit={this.valid}>
                <div id="firstEtape" className={classes.modal}>
                    <TextField
                        id="firstName"
                        label={this.displayText('firstName')}
                        margin="normal"
                        placeholder="Paul"
                        required
                        variant="outlined"
                        value={this.state.firstNameCreate}
                        onChange={e => {
                            this.handleChange(e.target.value, 'firstNameCreate')
                        }}
                        fullWidth
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <AssignmentIndIcon />
                                </InputAdornment>
                            ),
                        }}
                    />
                    <TextField
                        id="lastName"
                        label={this.displayText('lastName')}
                        margin="normal"
                        placeholder="Grenier"
                        required
                        variant="outlined"
                        value={this.state.lastNameCreate}
                        onChange={e => {
                            this.handleChange(e.target.value, 'lastNameCreate')
                        }}
                        fullWidth
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <AssignmentIndIcon />
                                </InputAdornment>
                            ),
                        }}
                    />
                    <TextField
                        id="email"
                        label={this.displayText('email')}
                        margin="normal"
                        placeholder="paulgrenier@email.com"
                        error={
                            !/^\w+([-.]?\w+)*@\w+([-.]?\w+)*(\.\w{2,3})+$/.test(
                                this.state.emailCreate,
                            ) &&
                            this.state.emailCreate !== '' &&
                            this.state.focused !== 'emailCreate'
                        }
                        helperText={
                            !/^\w+([-.]?\w+)*@\w+([-.]?\w+)*(\.\w{2,3})+$/.test(
                                this.state.emailCreate,
                            ) &&
                            this.state.emailCreate !== '' &&
                            this.state.focused !== 'emailCreate'
                                ? this.displayText('invalidEmail')
                                : ''
                        }
                        required
                        variant="outlined"
                        value={this.state.emailCreate}
                        onChange={e => {
                            this.handleChange(e.target.value, 'emailCreate')
                        }}
                        fullWidth
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <EmailIcon />
                                </InputAdornment>
                            ),
                        }}
                    />
                    <PhoneField
                        value={this.state.phoneCreate}
                        placeholder="+33 6 06 06 06 06"
                        onChange={value => {
                            this.handleChange(value, 'phoneCreate')
                        }}
                        error={
                            (this.state.passwordCreate !== '' ||
                                this.state.passwordCreateValid !== '') &&
                            !isValidPhoneNumber(this.state.phoneCreate) &&
                            this.state.focused !== 'phoneCreate'
                        }
                    />
                    <TextField
                        id="password"
                        label={this.displayText('password')}
                        margin="normal"
                        type="password"
                        required
                        variant="outlined"
                        placeholder="**********"
                        error={
                            this.state.passwordCreate.length < 6 &&
                            this.state.passwordCreate !== '' &&
                            this.state.focused !== 'passwordCreate'
                        }
                        helperText={
                            this.state.passwordCreate.length < 6 &&
                            this.state.passwordCreate !== '' &&
                            this.state.focused !== 'passwordCreate'
                                ? this.displayText('nbCharacterPassword')
                                : ''
                        }
                        value={this.state.passwordCreate}
                        onChange={e => {
                            this.handleChange(e.target.value, 'passwordCreate')
                        }}
                        fullWidth
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <LockIcon />
                                </InputAdornment>
                            ),
                        }}
                    />
                    <TextField
                        id="password"
                        label={this.displayText('repeatPassword')}
                        margin="normal"
                        type="password"
                        required
                        variant="outlined"
                        placeholder="**********"
                        error={
                            this.state.passwordCreate !==
                                this.state.passwordCreateValid &&
                            this.state.passwordCreateValid !== '' &&
                            this.state.focused !== 'passwordCreateValid'
                        }
                        helperText={
                            this.state.passwordCreate !==
                                this.state.passwordCreateValid &&
                            this.state.passwordCreateValid !== '' &&
                            this.state.focused !== 'passwordCreateValid'
                                ? this.displayText('diffPassword')
                                : ''
                        }
                        value={this.state.passwordCreateValid}
                        onChange={e => {
                            this.handleChange(
                                e.target.value,
                                'passwordCreateValid',
                            )
                        }}
                        fullWidth
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <LockIcon />
                                </InputAdornment>
                            ),
                        }}
                    />
                    <div>
                        <Button
                            className={classes.button}
                            type="submit"
                            color="primary"
                            variant="contained"
                        >
                            {' '}
                            {this.displayText('register')}{' '}
                        </Button>
                    </div>
                </div>
            </form>
        )
    }
}

export default connect()(withStyles(styles)(RenderAccountCreate))
