import React from 'react'
import { connect } from 'react-redux'

import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import Divider from '@material-ui/core/Divider'
import { Link } from 'react-router-dom'
import InsertPhoto from '@material-ui/icons/InsertPhoto'
import dictionary from './Dictionary/ParkingTabDico'
import styles from './Styles/ParkingTabCss'
import { fetchData } from '../../actions/api.actions'
import BoatOnComponent from '../common/BoatOnComponent'
import { history } from '../../helpers'
import { postData } from '../../actions/api.actions'
import { API_URL } from '../../services/config.service'
import AppRoute from '../../constants/AppRoute'
import {
    Card,
    CardMedia,
    CardContent,
    CardActions,
    CardHeader,
} from '@material-ui/core'
import Masonry from 'react-masonry-css'
import StatusTitle from './../common/StatusTitle'
import ChevronLeft from '@material-ui/icons/ChevronLeft'
import { LazyLoadImage } from '../ParkingPage/ParkingPage'
import ChevronRight from '@material-ui/icons/ChevronRight'

class ParkingTab extends BoatOnComponent {
    constructor(props) {
        super(props)
        this.dictionary = dictionary

        this.state = {
            parkings: [],
            parkingPictures: '',
        }

        this.getParkingWithParkingId = this.getParkingWithParkingId.bind(this)
        this.renderCurrency = this.renderCurrency.bind(this)
        this.renderOrderDetails = this.renderOrderDetails.bind(this)
        this.updateStatus = this.updateStatus.bind(this)
        this.updateParking = this.updateParking.bind(this)
    }

    componentWillMount() {
        const { user } = this.props

        const data1 = new FormData()
        const data2 = new FormData()

        data1.append('UserID', user.UserID)
        data2.append('sellerid', user.UserID)

        this.props.dispatch(
            fetchData(
                API_URL + '/parkinggetallfromuser.php',
                'parking',
                'POST',
                data1,
            ),
        )

        this.props.dispatch(
            fetchData(API_URL + '/ordersget.php', 'orders', 'POST', data2),
        )
    }

    updateParking() {
        const data = new FormData()
        this.props.dispatch(
            fetchData(API_URL + '/parkinglist.php', 'parking', 'POST', data),
        )
    }

    updateStatus(status, parkingId) {
        const data = new FormData()
        data.append('status', status)
        data.append('parkingid', parkingId)
        if (status === 1) alert(this.displayText('alertActivate'))
        else alert(this.displayText('alertDesactivate'))
        this.props
            .dispatch(postData(API_URL + '/parkingupdate.php', data))
            .then(() => {
                this.updateParking()
            })
    }

    getParkingWithParkingId(parkingId) {
        const { parkings } = this.state

        for (const key in parkings) {
            if (parkings[key].ParkingID === parkingId) {
                return parkings[key]
            }
        }
        return null
    }

    renderCurrency(currency) {
        if (parseInt(currency) === 1) {
            return 'eur'
        }
        if (parseInt(currency) === 2) {
            return 'usd'
        }
        if (parseInt(currency) === 3) {
            return 'chf'
        }
    }

    renderOrderDetails(order) {
        this.historyPush(history, AppRoute.Inquery, '', { state: { order } })
    }

    render() {
        const { classes } = this.props
        const parkings = this.props.parking ? this.props.parking.parkings : []
        const orders = this.props.orders ? this.props.orders.orders : []

        if (!parkings) {
            return <div />
        }

        if (parkings && parkings.length === 0) {
            return (
                <div>
                    <Typography className={classes.nothing}>
                        {this.displayText('noParking')}
                    </Typography>
                </div>
            )
        }
        return (
            <div className={classes.root}>
                <div className={classes.currentlyBox}>
                    <Typography className={classes.currently}>
                        {this.displayText('currently')}
                    </Typography>
                    <Typography className={classes.nbEmplacements}>
                        {this.displayText('have')} {parkings.length}{' '}
                        {this.displayText('location')}
                    </Typography>
                </div>

                <Masonry
                    breakpointCols={{
                        default: 2,
                        1200: 1,
                    }}
                    className={classes.myMasonryGrid}
                    columnClassName={classes.myMasonryGridColumn}
                >
                    {parkings.map((parking, i) => (
                        <React.Fragment key={i}>
                            <ParkingCard
                                parent={this}
                                parking={parking}
                                orders={orders}
                            />
                        </React.Fragment>
                    ))}
                </Masonry>
            </div>
        )
    }
}

function ParkingCard({ parent, parking, orders }) {
    const { classes } = parent.props
    const img =
        parking.Pictures.length > 0
            ? API_URL + `/parkingpictures/${parking.Pictures[0].PictureURL}`
            : ''
    const imgId =
        parking.Pictures.length > 0 ? parking.Pictures[0].ParkingPictureID : ''
    const full =
        parking.Pictures.length > 0
            ? parking.Pictures[0].PictureURL.split('.')
            : []
    const video = ['mp4', 'mov', 'mpg'].includes(full[full.length - 1])

    return (
        <Card className={classes.cardRoot} elevation={0} square>
            <CardHeader
                title={
                    <div className={classes.cardTitle}>
                        {parking.Title}
                        {parking.Status === '2' ? (
                            <span className={classes.disableTag}>
                                {parent.displayText('desactiveBis')}
                            </span>
                        ) : (
                            <span className={classes.activeTag}>
                                {parent.displayText('activeBis')}
                            </span>
                        )}
                    </div>
                }
                subheader={
                    <Typography
                        variant="body2"
                        color="textSecondary"
                        component="p"
                    >
                        {parent.displayText('ad')} {parking.TotalView}{' '}
                        {parent.displayText('times')}
                    </Typography>
                }
            ></CardHeader>
            {parking.Pictures[0] ? (
                <div className={classes.pictures}>
                    <div className={classes.importantImage}>
                        {video ? (
                            <video
                                controls
                                style={{
                                    maxWidth: 'calc(100% - 14vw)',
                                    maxHeight: '100%',
                                    margin: 'auto',
                                    position: 'absolute',
                                    top: 0,
                                    bottom: 0,
                                    right: 0,
                                    left: 0,
                                }}
                            >
                                <source
                                    src={img}
                                    alt={imgId}
                                    type={'video/' + full[full.length - 1]}
                                ></source>
                            </video>
                        ) : (
                            <CardMedia
                                component="img"
                                alt={parking.Pictures[0].ParkingPictureID}
                                height="200"
                                image={
                                    API_URL +
                                    `/parkingpictures/${parking.Pictures[0].PictureURL}`
                                }
                            />
                        )}
                    </div>
                </div>
            ) : (
                <div className={classes.noImage}>
                    <InsertPhoto
                        style={{
                            width: '30%',
                            height: '200px',
                            margin: 'auto',
                            display: 'block',
                        }}
                    />
                </div>
            )}

            <CardContent>
                {orders ? (
                    <div>
                        {orders.map((orders, i) => (
                            <React.Fragment key={i}>
                                <OrderLine
                                    parent={parent}
                                    orders={orders}
                                    parking={parking}
                                />
                            </React.Fragment>
                        ))}
                    </div>
                ) : (
                    <div />
                )}
            </CardContent>
            <CardActions>
                <Button
                    size="small"
                    color="primary"
                    variant="contained"
                    component={Link}
                    to={`/edit-my-parking/${parking.ParkingID}`}
                >
                    {parent.displayText('edit')}
                </Button>
                <Button
                    size="small"
                    color="primary"
                    component={Link}
                    to={`/duplicate-my-parking/${parking.ParkingID}`}
                >
                    {parent.displayText('clone')}
                </Button>
                {parking.Status === '2' ? (
                    <Button
                        size="small"
                        color="primary"
                        onClick={() => {
                            parent.updateStatus('1', parking.ParkingID)
                        }}
                    >
                        {parent.displayText('activate')}
                    </Button>
                ) : (
                    <Button
                        size="small"
                        color="primary"
                        onClick={() => {
                            parent.updateStatus('2', parking.ParkingID)
                        }}
                    >
                        {parent.displayText('desactivate')}
                    </Button>
                )}
            </CardActions>
        </Card>
    )
}

function OrderLine({ parent, orders, parking, id }) {
    const { classes } = parent.props

    const start =
        orders && orders.OrderStartDate
            ? new Date(orders.OrderStartDate.split(' ')[0])
            : new Date('')
    const end =
        orders && orders.OrderEndDate
            ? new Date(orders.OrderEndDate.split(' ')[0])
            : new Date('')
    const orderStartDate = start.toLocaleDateString()
    const orderEndDate = end.toLocaleDateString()
    const orderCancelDate = orders.OrderDeclineTimestamp
        ? new Date(
              orders.OrderDeclineTimestamp.split(' ')[0],
          ).toLocaleDateString()
        : null

    return (
        <div key={id}>
            {orders.ParkingID === parking.ParkingID ? (
                <div>
                    <div className={classes.inquery}>
                        <div className={classes.nameAndDate}>
                            <Typography className={classes.wantsToRent}>
                                {orders.FirstName} {parent.displayText('pitch')}{' '}
                            </Typography>
                            <div className={classes.labelAndDate}>
                                <StatusTitle
                                    status={
                                        orders.OrderStatus === '3'
                                            ? '2'
                                            : orders.OrderStatus
                                    }
                                />
                                {orders.OrderStatus != '3' ? (
                                    orders.Mensualisation != 0 ? (
                                        <Typography className={classes.dates}>
                                            {parent.displayText('fromThe')}{' '}
                                            {orderStartDate}
                                        </Typography>
                                    ) : (
                                        <Typography className={classes.dates}>
                                            {parent.displayText('from')}{' '}
                                            {orderStartDate}{' '}
                                            {parent.displayText('at')}{' '}
                                            {orderEndDate}
                                        </Typography>
                                    )
                                ) : (
                                    <Typography className={classes.dates}>
                                        {parent.displayText('the')}{' '}
                                        {orderCancelDate}
                                    </Typography>
                                )}
                            </div>
                        </div>
                        <div className={classes.priceAndButton}>
                            {orders.Mensualisation != 0 ? (
                                <div className={classes.quotation}>
                                    <Typography
                                        className={classes.quotationText}
                                    >
                                        {orders.OrderPrice}{' '}
                                        {parent.renderCurrency(orders.Currency)}{' '}
                                        {parent.displayText('month')}
                                    </Typography>
                                </div>
                            ) : (
                                <div className={classes.quotation}>
                                    <Typography
                                        className={classes.quotationText}
                                    >
                                        {(orders.OrderPrice *
                                            (end.getTime() - start.getTime())) /
                                            (1000 * 60 * 60 * 24)}{' '}
                                        {parent.renderCurrency(orders.Currency)}
                                    </Typography>
                                </div>
                            )}
                            <div className={classes.answerButton}>
                                <Button
                                    color="secondary"
                                    size="small"
                                    variant="contained"
                                    className={classes.button}
                                    onClick={() =>
                                        parent.renderOrderDetails(orders)
                                    }
                                >
                                    {parent.displayText('answer')}
                                </Button>
                            </div>
                        </div>
                    </div>
                    <Divider className={classes.divider} />
                </div>
            ) : (
                <div />
            )}
        </div>
    )
}

function mapStateToProps(state) {
    return {
        user: state.authentication.user,
        orders: state.fetch.orders,
        parking: state.fetch.parking,
        parkingPictures: state.fetch.parkingPictures,
    }
}

export default connect(mapStateToProps)(withStyles(styles)(ParkingTab))
