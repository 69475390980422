import { API_URL, LS_USER_KEY } from '../services/config.service'
import { history } from '../helpers'

export const userService = {
    login,
    logout,
    forget,
    signin,
    subscribe,
    getFromLS,
    getAuthHeader,
    refreshToken,
    requestSubscriptions,
    getWithoutAuthHeader,
}

let userToken

function getAuthHeader() {
    return {
        Authorization: 'Bearer ' + userToken,
        Accept: 'application/json',
        'Content-Type': 'application/json',
    }
}

function getAuthHeaderRefresh() {
    const localStorageData = JSON.parse(localStorage.getItem(LS_USER_KEY))
    if (localStorageData && localStorageData.refreshToken) {
        return {
            Authorization: 'Bearer ' + localStorageData.refreshToken,
            Accept: 'application/json',
            'Content-Type': 'application/json',
        }
    }
    return getWithoutAuthHeader()
}

function getWithoutAuthHeader() {
    return {
        Accept: 'application/json',
        'Content-Type': 'application/json',
    }
}

function getFromLS() {
    const localStorageData = JSON.parse(localStorage.getItem(LS_USER_KEY))

    if (localStorageData && localStorageData.accessToken) {
        userToken = localStorageData.accessToken
    }

    return localStorageData
}

function login(email, password) {
    const body = { email: email, password: password }
    return fetch(API_URL + '/auth/login', {
        method: 'POST',
        headers: getWithoutAuthHeader(),
        body: JSON.stringify(body),
    })
        .then(async result => {
            const json = await result.json()
            if (json.statusCode === 401) {
                return Promise.reject(json.message)
            }
            if (json.accessToken) {
                userToken = json.accessToken
            }
            // we save auth data to local storage
            localStorage.setItem(
                LS_USER_KEY,
                JSON.stringify({
                    accessToken: json.accessToken || null,
                    refreshToken: json.refreshToken || null,
                }),
            )
            return json
        })
        .catch(error => {
            console.error('Cannot login')
            return Promise.reject(error)
        })
}

function refreshToken() {
    const localStorageData = JSON.parse(localStorage.getItem(LS_USER_KEY))
    const body =
        localStorageData && localStorageData.refreshToken
            ? { refreshToken: localStorageData.refreshToken }
            : {}
    return fetch(API_URL + '/auth/refresh', {
        method: 'POST',
        headers: getAuthHeaderRefresh(),
        body: JSON.stringify(body),
    })
        .then(async result => {
            const json = await result.json()
            if (json.statusCode === 401) {
                return Promise.reject(json.message)
            }
            if (json.accessToken) {
                userToken = json.accessToken
            }
            // we save auth data to local storage
            localStorage.setItem(
                LS_USER_KEY,
                JSON.stringify({
                    accessToken: json.accessToken || null,
                    refreshToken: json.refreshToken || null,
                }),
            )
            return json
        })
        .catch(error => {
            history.push('/connexion')
            console.error('Refresh token out dated')
            return Promise.reject(error)
        })
}

function forget(email) {
    const data = new FormData()
    data.append('email', email)

    return fetch(API_URL + '/userresetpassword.php', {
        method: 'POST',
        headers: {},
        body: data,
    })
        .then(async result => {
            const json = await result.json()
            if (json.statusCode === 401) {
                return Promise.reject(json.message)
            }
            return json
        })
        .catch(error => {
            console.error('Refresh token out dated')
            return Promise.reject(error)
        })
}

function signin(body) {
    return fetch(API_URL + '/users', {
        method: 'POST',
        headers: getWithoutAuthHeader(),
        body: JSON.stringify(body),
    })
        .then(async result => {
            const json = await result.json()
            if (json.statusCode === 401) {
                return Promise.reject(json.message)
            }
            if (json.accessToken) {
                userToken = json.accessToken
            }
            localStorage.setItem(
                LS_USER_KEY,
                JSON.stringify({
                    accessToken: json.accessToken || null,
                    refreshToken: json.refreshToken || null,
                }),
            )
            return json
        })
        .catch(error => {
            console.error('Cannot signin')
            return Promise.reject(error)
        })
}

function logout() {
    localStorage.removeItem(LS_USER_KEY)
    localStorage.removeItem('company')
    localStorage.removeItem('bookingInfo')
}

function requestSubscriptions() {
    return fetch(API_URL + '/users/subscriptions', {
        method: 'GET',
        headers: userService.getAuthHeader(),
    })
        .then(async result => {
            const json = await result.json()
            if (json.statusCode === 401) {
                return Promise.reject(json.message)
            }
            return json
        })
        .catch(error => {
            return Promise.reject(error)
        })
}

//TODO: a refacto
function subscribe() {
    return fetch(API_URL + '/logbook/subscribe.php', {
        method: 'POST',
        headers: userService.getAuthHeader(),
    })
        .then(response => {
            if (!response.ok) {
                return Promise.reject(response.statusText)
            }
            return response.json()
        })
        .then(result => {
            const cachedUser = JSON.parse(localStorage.getItem(LS_USER_KEY))
            cachedUser.User = Object.assign(cachedUser.User, result.User)
            console.info(
                'user is now subscribed, updating cache',
                result.User,
                cachedUser.User,
            )
            localStorage.setItem(LS_USER_KEY, JSON.stringify(cachedUser))
            return cachedUser
        })
}
