export default {
    engines: {
        FR: 'Mes moteurs',
        EN: 'My engines',
    },
    engine: {
        FR: 'Moteur',
        EN: 'Engine',
    },
    engineType: {
        FR: 'Type',
        EN: 'Type',
    },
    engineYear: {
        FR: 'Année',
        EN: 'Year',
    },
    engineMaker: {
        FR: 'Marque',
        EN: 'Maker',
    },
    engineModel: {
        FR: 'Modèle',
        EN: 'Model',
    },
    enginePower: {
        FR: 'Puissance (CV)',
        EN: 'Power (CV)',
    },
    engineTime: {
        FR: 'Heures',
        EN: 'Hours',
    },
    inBoard: {
        FR: 'In-Board',
        EN: 'Inboard',
    },
    outBoard: {
        FR: 'Hors-Bord',
        EN: 'Outboard',
    },
    serialNumber: {
        FR: 'Numéro de série',
        EN: 'Serial number',
    },
    addEngine: {
        FR: 'Ajouter un moteur',
        EN: 'Add an engine',
    },
    alert: {
        FR:
            'Vous devez au moins renseigner le modèle et la marque du moteur pour en ajouter un nouveau',
        EN:
            'You must at least enter the model and make of the engine to add a new one.',
    },
}
