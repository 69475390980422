import React from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import BoatOnComponent from './BoatOnComponent'
import styles from './Styles/BoatOnModalCss'
import dictionary from './Dictionary/BoatOnModalDico'
import Modal from '@material-ui/core/Modal'
import Divider from '@material-ui/core/Divider'
import CloseIcon from '@material-ui/icons/Close'
import FullscreenIcon from '@material-ui/icons/Fullscreen'
import FullscreenExitIcon from '@material-ui/icons/FullscreenExit'

class BoatOnModal extends BoatOnComponent {
    constructor(props) {
        super(props)
        this.dictionary = dictionary

        this.state = {
            fullscreen: false,
        }

        this.handleFullscreen = this.handleFullscreen.bind(this)
        this.handleFullscreenExit = this.handleFullscreenExit.bind(this)
    }

    handleFullscreen() {
        this.setState({ fullscreen: true })
    }

    handleFullscreenExit() {
        this.setState({ fullscreen: false })
    }

    renderModal() {
        const {
            classes,
            rendersModal,
            openCondition,
            titles,
            disableFullscreen,
        } = this.props
        const { fullscreen } = this.state

        return (
            <div>
                <div className={classes.boxTitle}>
                    <CloseIcon
                        className={classes.cross}
                        onClick={this.props.handleClose}
                    />
                    <Typography className={classes.title}>
                        {titles[openCondition]}
                    </Typography>
                    {disableFullscreen ? (
                        <></>
                    ) : fullscreen ? (
                        <FullscreenExitIcon
                            className={classes.fullscreen}
                            onClick={this.handleFullscreenExit}
                        />
                    ) : (
                        <FullscreenIcon
                            className={classes.fullscreen}
                            onClick={this.handleFullscreen}
                        />
                    )}
                    <Divider />
                </div>
                <div className={classes.core}>
                    {rendersModal[openCondition]}
                </div>
            </div>
        )
    }

    render() {
        const {
            classes,
            openCondition,
            handleClose,
            titles,
            rendersModal,
        } = this.props
        const { fullscreen } = this.state

        if (
            openCondition === undefined ||
            handleClose === undefined ||
            titles === undefined ||
            rendersModal === undefined
        )
            console.error(
                'missing parameters: ',
                'openCondition:',
                openCondition === undefined,
                'handleClose:',
                handleClose === undefined,
                'titles:',
                titles === undefined,
                'rendersModal:',
                rendersModal === undefined,
            )

        return (
            <Modal
                aria-labelledby="boaton-modal"
                aria-describedby="boaton-modal-description"
                open={openCondition ? true : false}
                onClose={handleClose}
            >
                <div
                    id="modal"
                    className={
                        fullscreen ? classes.paperFullscreen : classes.paper
                    }
                >
                    <div>{openCondition && this.renderModal()}</div>
                </div>
            </Modal>
        )
    }
}

function mapStateToProps(state) {
    return {
        loading: state.fetch.loading,
    }
}

export default connect(mapStateToProps)(withStyles(styles)(BoatOnModal))
