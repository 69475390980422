import React from 'react'

import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import BoatOnComponent from './BoatOnComponent'

import styles from './Styles/CommentaryCss'
import dictionary from './Dictionary/CommentaryDico'

class Commentary extends BoatOnComponent {
    constructor(props) {
        super(props)
        this.dictionary = dictionary
    }

    render() {
        const { commentarys } = this.props
        return (
            <div>
                {commentarys.map((commentary, id) => {
                    return <Typography key={id}>{commentary}</Typography>
                })}
            </div>
        )
    }
}

export default withStyles(styles)(Commentary)
