export default {
    startDate: {
        FR: 'Du',
        EN: 'From',
    },
    endDate: {
        FR: 'Au',
        EN: 'All',
    },
    empty: {
        FR: 'Tout',
        EN: 'All',
    },
}
