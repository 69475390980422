import { enginesConstants } from '../../constants'

export const engineReducer = (prevState, action) => {
    switch (action.type) {
        case enginesConstants.REQUEST_ENGINES:
            return {
                ...prevState,
                engines: null,
                loading: prevState.loading + 1,
            }
        case enginesConstants.PUT_ENGINES:
            return {
                ...prevState,
                engines: null,
                loading: prevState.loading + 1,
            }
        case enginesConstants.POST_ENGINES:
            return {
                ...prevState,
                engines: null,
                loading: prevState.loading + 1,
            }
        case enginesConstants.ENGINES_SUCCESS:
            const newEngines = action.engines.map(engine => ({
                id: engine.id,
                Engine: engine.model || engine.Engine,
                EngineMarker: engine.maker || engine.EngineMarker,
                EnginePower: engine.power || engine.EnginePower,
                EngineType:
                    engine.engineType && engine.engineType.id
                        ? engine.engineType.id
                        : engine.EngineType || -1,
                EngineYear: engine.year || engine.EngineYear,
                EngineSerialNumber:
                    engine.serialNumber || engine.EngineSerialNumber,
                EngineTime: engine.totalTime || engine.EngineTime,
            }))
            return {
                ...prevState,
                engines: newEngines,
                loading: prevState.loading - 1,
            }
        case enginesConstants.ENGINES_FAILURE:
            return {
                ...prevState,
                engines: null,
                loading: prevState.loading - 1,
            }
        default:
            return prevState
    }
}
