import React from 'react'
import BoatOnComponent from '../../../common/BoatOnComponent'
import styles from './Styles/ModalDeleteEngineCss'
import dictionary from './Dictionary/ModalDeleteEngineDico'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import BoatOnModal from '../../../common/BoatOnModal'

class ModalDeleteEngine extends BoatOnComponent {
    constructor(props) {
        super(props)

        this.dictionary = dictionary
    }

    renderModal() {
        const { classes, handleClose } = this.props

        return (
            <div className={classes.infos}>
                <Typography style={{ fontWeight: '500' }}>
                    {this.displayText('care')}
                </Typography>
                <Typography>{this.displayText('question')}</Typography>
                <div>
                    <Button
                        variant="contained"
                        color="secondary"
                        size="medium"
                        className={classes.buttonAccept}
                        onClick={() => handleClose({ valid: true })}
                    >
                        {this.displayText('accept')}
                    </Button>
                    <Button
                        variant="contained"
                        size="medium"
                        className={classes.buttonCancel}
                        onClick={() => handleClose({ valid: false })}
                    >
                        {this.displayText('cancel')}
                    </Button>
                </div>
            </div>
        )
    }

    render() {
        const { show, handleClose, classes } = this.props

        if (!show) {
            return <div />
        }

        return (
            <div className={classes.root}>
                <BoatOnModal
                    openCondition={show ? 'delete' : false}
                    handleClose={() => handleClose({ valid: false })}
                    titles={{ delete: this.displayText('title') }}
                    rendersModal={{ delete: this.renderModal() }}
                ></BoatOnModal>
            </div>
        )
    }
}

export default withStyles(styles)(ModalDeleteEngine)
