import { boatsConstants } from '../constants'
import { boatsService } from '../services/boats.service'

const requestBoats = () => {
    return dispatch => {
        dispatch({ type: boatsConstants.REQUEST_BOATS })
        return boatsService.requestBoats().then(
            result => {
                dispatch({
                    type: boatsConstants.BOATS_SUCCESS,
                    boats: result,
                })
            },
            error => {
                dispatch({ type: boatsConstants.BOATS_FAILURE, error })
            },
        )
    }
}

export const boatActions = {
    requestBoats,
}
