export default theme => ({
    root: {
        display: 'flex',
        flexFlow: 'row wrap',
        alignItems: 'center',
        marginRight: '10px',
        marginLeft: '10px',
        width: 'calc(100% - 20px)',
        [theme.breakpoints.down('sm')]: {
            maxWidth: '300px',
            width: '100%',
        },
    },
    borderDiv: {
        border: '1px solid #c5c5c5 ',
        borderRadius: '3px',
        width: '100%',
        height: '38px',
        marginTop: '3px',
    },
    textDateHours: {
        marginTop: '10px',
        marginLeft: '5px',
        color: '#757575',
        [theme.breakpoints.down('sm')]: {
            fontSize: '0.6em',
        },
    },

    btnDate: {
        backgroundColor: 'white',
        border: 'none',
        fontFamily: 'roboto',
        color: '#757575',
        fontSize: '1em',
        textDecoration: 'underline',
        [theme.breakpoints.down('sm')]: {
            fontSize: '1em',
        },
    },
    btnHours: {
        backgroundColor: 'white',
        border: 'none',
        fontFamily: 'roboto',
        color: '#757575',
        fontSize: '1em',
        textDecoration: 'underline',
        [theme.breakpoints.down('sm')]: {
            fontSize: '0.9em',
        },
    },
})
