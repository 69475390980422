import React from 'react'
import BoatOnComponent from '../../../common/BoatOnComponent'
import { connect } from 'react-redux'

import { withStyles } from '@material-ui/core/styles'
import styles from './Styles/MyDocumentPageCss'
import { fetchData } from '../../../../actions/api.actions'
import MyDocumentList from './MyDocumentList'
import ModalConnection from '../../../common/UsefullComponents/ModalConnection/ModalConnection'
import ReactGA from 'react-ga'
import ReactPixel from 'react-facebook-pixel'
import { API_URL } from '../../../../services/config.service'

class MyDocumentPage extends BoatOnComponent {
    constructor(props) {
        super(props)

        this.initializeReactGA()

        this.state = {
            selectedBoat: 0,
            resetSave: false,
            openModalConnection: true,
        }

        const options = {
            autoConfig: true,
            debug: false,
        }
        ReactPixel.init('181677145678471', options)

        ReactPixel.pageView()

        this.handleClose = this.handleClose.bind(this)
        this.handleUpdated = this.handleUpdated.bind(this)
    }

    componentWillMount = props => {
        let { user } = this.props
        if (user) {
            var data = new FormData()
            data.append('UserID', user.UserID)
            this.props.dispatch(
                fetchData(API_URL + '/boatgetall.php', 'boats', 'POST', data),
            )
        }
    }

    initializeReactGA() {
        ReactGA.initialize('UA-93941518-1')
        ReactGA.pageview('/logbook/my-documents')
    }

    handleClose() {
        this.setState({ openModalConnection: false })
        let { user } = this.props
        if (user) {
            var data = new FormData()
            data.append('UserID', user.UserID)
            this.props.dispatch(
                fetchData(API_URL + '/boatgetall.php', 'boats', 'POST', data),
            )
        }
    }

    handleUpdated() {
        this.setState({ resetSave: false })
    }

    render() {
        const { classes, user } = this.props
        const { resetSave } = this.state

        if (!user) {
            return (
                <ModalConnection
                    open={this.state.openModalConnection}
                    handleClose={() => {
                        this.handleClose()
                    }}
                />
            )
        }

        return (
            <div className={classes.root} key="alerts">
                <div className={classes.main}>
                    <MyDocumentList
                        selectedBoat={this.state.selectedBoat}
                        update={resetSave}
                        handleUpdated={this.handleUpdated}
                    />
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        user: state.authentication.user,
        boats: state.fetch.boats,
    }
}

export default connect(mapStateToProps)(withStyles(styles)(MyDocumentPage))
