export default {
    service: {
        FR: 'Nos services',
        EN: 'Our service',
    },
    requests: {
        FR: 'Mes demandes',
        EN: 'Requests',
    },
    profile: {
        FR: 'Mon profil',
        EN: 'Profile',
    },
    messages: {
        FR: 'Mes messages',
        EN: 'My messages',
    },
    login: {
        FR: 'Connexion',
        EN: 'Login',
    },
    register: {
        FR: 'Inscription',
        EN: 'Sign up',
    },
    rentParking: {
        FR: 'Louer mon emplacement',
        EN: 'Rent my place',
    },
    topBar: {
        FR: 'La 1ère application gratuite pour gérer votre bateau de A à Z',
        EN: 'The 1st free application to manage your boat from A to Z',
    },
    stockage: {
        FR: 'Stockage',
        EN: 'Storage',
    },
    assurance: {
        FR: 'Assurance',
        EN: 'Insurance',
    },
    bob: {
        FR: "Carnet d'entretien",
        EN: 'Logbook',
    },
    repair: {
        FR: "Demande d'entretien",
        EN: 'Maintenance request',
    },
    blog: {
        FR: 'Le blog',
        EN: 'Blog',
    },
    myAccount: {
        FR: 'Mon compte',
        EN: 'My account',
    },
    myBoat: {
        FR: 'Mon bateau',
        EN: 'My boat',
    },
    myBOB: {
        FR: 'Mon BoatOn Book',
        EN: 'My BoatOn Book',
    },
    myRequest: {
        FR: 'Mes demandes',
        EN: 'Requests',
    },
    myMessage: {
        FR: 'Mes messages',
        EN: 'Messages',
    },
    rentMyPlace: {
        FR: 'Louer mon emplacement',
        EN: 'Rent my place',
    },
    forum: {
        FR: 'Le forum',
        EN: 'Forum',
    },
    disconnect: {
        FR: 'Déconnexion',
        EN: 'Log out',
    },
    notification: {
        FR: "Vous n'avez pas de notification",
        EN: 'You do not have a notification',
    },
}
