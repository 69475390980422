export default theme => ({
    root: {
        // margin: 'auto',
        marginTop: '10px',
        width: '100%',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
    cardRoot: {
        border: '1px solid #c4c4c4',
        marginBottom: '10px',
    },
    currentlyBox: {
        display: 'flex',
        flexDirection: 'column',
        marginBottom: '2%',
    },
    currently: {
        fontSize: '26px',
        color: '#303F9F',
    },
    nbEmplacements: {
        fontSize: '20px',
    },
    divider: {
        marginLeft: '5px',
        marginRight: '5px',
        height: '0.01em',
    },
    parking: {
        minHeight: '100px',
        marginTop: '1%',
        border: '2px solid #c4c4c4',
    },
    parkingName: {
        marginLeft: '2%',
        display: 'flex',
        flexDirection: 'row',
        marginBottom: '10px',
    },
    nameAndStatus: {
        display: 'flex',
        flexDirection: 'column',
    },
    image: {
        height: '14em',
    },
    inquery: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        marginBottom: '5px',
        justifyContent: 'space-between',
        // border: '1px solid #c4c4c4',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
        },
    },
    inqueryText: {
        width: '100%',
        fontSize: '22px',
        textAlign: 'center',
    },
    nothing: {
        width: '100%',
        fontSize: '18px',
        textAlign: 'center',
    },
    parkingTitle: {
        fontSize: '22px',
    },
    picture: {
        width: '30%',
        height: '70%',
        margin: 'auto',
        display: 'block',
    },
    nameAndDate: {
        display: 'flex',
        flexDirection: 'column',
        marginLeft: '10px',
        marginRight: '20px',
        [theme.breakpoints.down('sm')]: {
            margin: '0',
        },
    },
    quotation: {
        marginLeft: 'auto',
        marginTop: 'auto',
        marginBottom: 'auto',
        fontWeight: 'bold',
        fontSize: '20px',
        [theme.breakpoints.down('sm')]: {
            margin: '0',
            fontSize: '16px',
        },
    },
    quotationText: {
        fontWeight: 'bold',
        fontSize: '18px',
    },
    answerButton: {
        marginLeft: 'auto',
        marginRight: '2%',
        marginTop: 'auto',
        marginBottom: 'auto',
        [theme.breakpoints.down('sm')]: {
            margin: '0',
        },
    },
    button: {
        fontSize: '14px',
    },
    wantsToRent: {
        fontSize: '18px',
        marginBottom: 'auto',
        marginTop: '3%',
        [theme.breakpoints.down('sm')]: {
            fontSize: '14px',
        },
    },
    dates: {
        marginBottom: '3%',
        [theme.breakpoints.down('sm')]: {
            fontSize: '12px',
            margin: '0',
        },
    },
    totalView: {
        marginLeft: '2%',
        marginRight: 'auto',
        marginBottom: '1%',
        marginTop: '1%',
    },
    parkingBottom: {
        display: 'flex',
        flexDirection: 'row',
        padding: '10px',
        width: '100%',
        marginLeft: '1%',
    },
    buttonBottom: {
        textDecoration: 'none',
        color: '#3040a0',
        fontSize: '14px',
        lineHeight: '1.5',
        marginRight: '15px',
        '&:hover': {
            cursor: 'pointer',
        },
    },
    myMasonryGrid: {
        display: 'flex',
        marginLeft: '-15px',
        width: 'auto',
    },
    myMasonryGridColumn: {
        paddingLeft: '15px',
        backgroundClip: 'paddingBox',
    },
    cardTitle: {
        display: 'flex',
        alignItems: 'center',
    },
    disableTag: {
        fontSize: '14px',
        padding: '5px 10px',
        margin: '0 10px',
        backgroundColor: '#ef5350',
        borderRadius: '14px',
        color: 'white',
    },
    activeTag: {
        fontSize: '14px',
        padding: '5px 10px',
        margin: '0 10px',
        backgroundColor: '#3040a0',
        borderRadius: '14px',
        color: 'white',
    },
    accepted: {
        color: '#3040a0',
    },
    declined: {
        color: '#ef5350',
    },
    waiting: {
        color: '#fec107',
    },
    labelAndDate: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        [theme.breakpoints.down('sm')]: {
            alignItems: 'center',
            marginBottom: '5px',
        },
    },
    priceAndButton: {
        display: 'flex',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'row',
            justifyContent: 'space-between',
            marginBottom: '5px',
            alignItems: 'center',
        },
    },
    pictures: {
        margin: 'auto',
        height: '50vh',
        maxHeight: '250px',
        display: 'flex',
        justifyContent: 'center',
    },
    importantImage: {
        width: '100%',
        margin: 'auto',
        height: '50vh',
        maxHeight: '250px',
        position: 'relative',
    },
})
