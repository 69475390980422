import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers'
import dateUtils from '@date-io/date-fns'
import Locale from 'date-fns/locale/fr'
import dictionary from './Dictionary/RangDateDico'
import styles from './Styles/RangDateCss'
import BoatOnComponent from '../BoatOnComponent'

class RangDate extends BoatOnComponent {
    constructor(props) {
        super(props)
        this.dictionary = dictionary

        this.state = {
            // startDate: null,
            // endDate: null,
            startDate: props.searchedDateFirst || null,
            endDate: props.searchedDateEnd || null,
            secondTableOpen: false,
            firstTableOpen: false,
        }
        this.handleChange = this.handleChange.bind(this)
        this.handleTablesOpen = this.handleTablesOpen.bind(this)
        this.checkSameDate = this.checkSameDate.bind(this)
        this.renderDay = this.renderDay.bind(this)
        this.reset = this.reset.bind(this)
    }

    componentWillMount() {
        if (this.props.startDate)
            this.setState({ startDate: this.props.startDate })
        if (this.props.endDate) this.setState({ endDate: this.props.endDate })
    }

    componentWillReceiveProps(props) {
        if (props.reset) {
            this.setState({ startDate: new Date(), endDate: new Date() })
        }
    }

    reset() {
        this.handleChange(null, 'startDate')
        this.handleChange(null, 'endDate')
        this.props.onDateChange(null, null)
    }

    handleChange(value, key) {
        const { desactiveUnavailable } = this.props

        const today = new Date()
        if (key === 'startDate' && !this.checkSameDate(value, key)) {
            if (
                new Date(value).getTime() <= today.getTime() &&
                !desactiveUnavailable
            ) {
                value = today
            } else if (
                new Date(value).getTime() >
                    new Date(this.state.endDate).getTime() &&
                this.state.endDate
            ) {
                this.setState({ endDate: value })
            }
        } else if (key === 'endDate' && !this.checkSameDate(value, key)) {
            if (
                new Date(value).getTime() <
                new Date(this.state.startDate).getTime()
            ) {
                this.setState({ startDate: value })
            }
        }
        if (!this.checkSameDate(value, key)) {
            this.setState({ [key]: value }, () => {
                if (this.props.onDateChange)
                    this.props.onDateChange(
                        this.state.startDate,
                        this.state.endDate,
                    )
            })
        }
    }

    daySelected(date, currentMonth) {
        date = date.setHours(0, 0, 0, 0)
        const startDate = new Date(this.state.startDate).setHours(0, 0, 0, 0)
        const endDate = new Date(this.state.endDate).setHours(0, 0, 0, 0)

        if (date && date >= startDate && date <= endDate) {
            return true
        }
        return false
    }

    checkSameDate(newDate, key) {
        const { desactiveUnavailable } = this.props
        const checkDate = new Date(newDate)

        if (key === 'startDate' && this.state.endDate) {
            while (checkDate <= this.state.endDate) {
                if (this.unavaibale(checkDate) && !desactiveUnavailable) {
                    return true
                }
                checkDate.setDate(checkDate.getDate() + 1)
            }
        } else if (key === 'endDate' && this.state.startDate) {
            while (checkDate >= this.state.startDate) {
                if (this.unavaibale(checkDate) && !desactiveUnavailable) {
                    return true
                }
                checkDate.setDate(checkDate.getDate() - 1)
            }
        }
        return false
    }

    unavaibale(date) {
        const { indisponibility } = this.props
        if (date.setHours(0, 0, 0, 0) < new Date().setHours(0, 0, 0, 0)) {
            return true
        }
        if (indisponibility) {
            for (const i in indisponibility) {
                const startIndispo =
                    indisponibility[i].OrderStartDate ||
                    indisponibility[i].startDate
                const endIndispo =
                    indisponibility[i].OrderEndDate ||
                    indisponibility[i].endDate
                if (
                    date.setHours(0, 0, 0, 0) >=
                        new Date(startIndispo).setHours(0, 0, 0, 0) &&
                    date.setHours(0, 0, 0, 0) <=
                        new Date(endIndispo).setHours(0, 0, 0, 0)
                )
                    return true
            }
        }
        return false
    }

    renderDay(date, selectedDate, currentMonth) {
        const { classes, desactiveUnavailable } = this.props
        let day = date.getDate()

        if (!currentMonth) {
            day = ''
        }

        return (
            <div className={classes.case}>
                {this.unavaibale(date) && !desactiveUnavailable ? (
                    <Button disabled className={classes.unavailable}>
                        {day}
                    </Button>
                ) : this.daySelected(date, currentMonth) && currentMonth ? (
                    <Button
                        onClick={() => this.handleTablesOpen('second')}
                        className={classes.daySelected}
                    >
                        {day}
                    </Button>
                ) : (
                    <Button
                        onClick={() => this.handleTablesOpen('second')}
                        className={classes.day}
                    >
                        {day}
                    </Button>
                )}
            </div>
        )
    }

    handleTablesOpen(table) {
        if (table === 'first') {
            this.setState({ firstTableOpen: true })
            this.handleTablesClose('second')
        } else if (table === 'second') {
            this.setState({ secondTableOpen: true })
            this.handleTablesClose('first')
        }
    }

    handleTablesClose(table) {
        if (table === 'second') {
            this.setState({ secondTableOpen: false })
        } else if (table === 'first') {
            this.setState({ firstTableOpen: false })
        } else {
            this.setState({ secondTableOpen: false })
            this.setState({ firstTableOpen: false })
        }
    }

    render() {
        const {
            classes,
            persoStyle,
            dateEquipement,
            labelStart,
            labelEnd,
        } = this.props
        const { startDate, endDate, disableAllButton } = this.state

        return (
            <div className={classes.root}>
                <MuiPickersUtilsProvider utils={dateUtils} locale={Locale}>
                    <div className={classes.page}>
                        {(startDate || endDate) && disableAllButton ? (
                            <Button
                                className={classes.all}
                                variant="contained"
                                color="primary"
                                onClick={this.reset}
                            >
                                {this.displayText('empty')}
                            </Button>
                        ) : (
                            <div style={{ display: 'none' }} />
                        )}
                        <DatePicker
                            emptyLabel={this.displayText('empty')}
                            className={
                                persoStyle ? persoStyle : classes.startDate
                            }
                            margin="dense"
                            variant="inline"
                            label={labelStart || this.displayText('startDate')}
                            open={this.state.firstTableOpen}
                            onOpen={() => this.handleTablesOpen('first')}
                            onClose={() => this.handleTablesClose('first')}
                            renderDay={this.renderDay}
                            format="dd/MM/yyyy"
                            disableToolbar
                            inputVariant="outlined"
                            value={this.state.startDate}
                            onChange={date => {
                                this.handleChange(date, 'startDate')
                            }}
                        />
                        <DatePicker
                            emptyLabel={this.displayText('empty')}
                            className={
                                persoStyle ? persoStyle : classes.endDate
                            }
                            margin="dense"
                            variant="inline"
                            label={labelEnd || this.displayText('endDate')}
                            open={this.state.secondTableOpen}
                            onOpen={() => this.handleTablesOpen('second')}
                            onClose={() => this.handleTablesClose('second')}
                            autoOk
                            renderDay={this.renderDay}
                            format="dd/MM/yyyy"
                            disableToolbar
                            inputVariant="outlined"
                            value={this.state.endDate}
                            onChange={date => {
                                this.handleChange(date, 'endDate')
                            }}
                        />
                    </div>
                </MuiPickersUtilsProvider>
            </div>
        )
    }
}

export default withStyles(styles)(RangDate)
