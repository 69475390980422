import React from 'react'
import ReactGA from 'react-ga'
import { connect } from 'react-redux'
import ReactPixel from 'react-facebook-pixel'
import { withStyles } from '@material-ui/core/styles'

import Navigation from '../common/Navigation'
import BoatOnComponent from '../common/BoatOnComponent'
import Footer from '../common/Footer'
import LogbookNavigation from './LogbookNavigation'
import styles from './Styles/LogbookPageCss'
import { fetchData } from '../../actions/api.actions'
import { fetchDataBOB } from '../../actions/bob.actions'
import { API_URL } from '../../services/config.service'
import ModalNoBoat from '../common/UsefullComponents/ModalConnection/ModalNoBoat'

class LogbookPage extends BoatOnComponent {
    constructor(props) {
        super(props)

        const options = {
            autoConfig: true,
            debug: false,
        }

        this.state = {
            openModalNoBoat: false,
        }

        ReactPixel.init('181677145678471', options)

        ReactPixel.pageView()
        ReactGA.initialize('UA-93941518-1')
        ReactGA.pageview('/my-boat-logbook')

        this.handleClose = this.handleClose.bind(this)
        this.updateBOB = this.updateBOB.bind(this)
        this.getBOB = this.getBOB.bind(this)
    }

    componentDidUpdate(prevProps) {
        const { boats, user, location, loading, loadingUser } = this.props
        if (!loadingUser && user) {
            this.updateBOB()
        }
        if (!loading) {
            if (
                user &&
                (!boats || boats.success === false) &&
                prevProps.location &&
                prevProps.location.pathname &&
                location.pathname.toString() !==
                    prevProps.location.pathname.toString()
            ) {
                this.setState({ openModalNoBoat: true })
            }
            if (
                (!prevProps.boats || prevProps.boats.success === false) &&
                boats
            ) {
                this.getBOB()
            }
        }
    }

    getBOB() {
        const { boats, user } = this.props
        if (boats && boats.length > 0) {
            this.setState({ openModalNoBoat: false }, () =>
                this.props.dispatch(fetchDataBOB(boats[0], user)),
            )
        } else {
            this.setState({ openModalNoBoat: true })
        }
    }

    updateBOB() {
        const { boats, user } = this.props
        if (user && !boats) {
            this.props
                .dispatch(fetchData(API_URL + '/boats/user', 'boats', 'GET'))
                .then(() => {
                    this.getBOB()
                })
        }
    }

    handleClose() {
        this.updateBOB()
        this.setState({ openModalNoBoat: false })
    }

    render() {
        const { classes, boats, loading } = this.props

        return (
            <div className={classes.root}>
                <Navigation>
                    <LogbookNavigation
                        pathname={this.props.location.pathname}
                    />
                </Navigation>
                <Footer sideMenu />
                <ModalNoBoat
                    open={this.state.openModalNoBoat && !loading}
                    handleClose={() => {
                        this.handleClose()
                    }}
                />
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        user: state.authentication.user,
        boats: state.fetch.boats,
        loading: state.fetch.loading,
        bob: state.bob || null,
        loadingUser: state.authentication.loading,
    }
}

export default connect(mapStateToProps)(withStyles(styles)(LogbookPage))
