export default {
    quotation: {
        FR: 'Demander un devis',
        EN: 'Demander un devis',
    },
    lastControl: {
        FR: 'Modifier le dernier contrôle',
        EN: 'Modifier le dernier contrôle',
    },
    lastControlMobile: {
        FR: 'Modifier dernier contrôle',
        EN: 'Modifier dernier contrôle',
    },
    nextControl: {
        FR: 'Modifier le prochain contrôle',
        EN: 'Modifier le prochain contrôle',
    },
    nextControlMobile: {
        FR: 'Modifier prochain contrôle',
        EN: 'Modifier prochain contrôle',
    },
}
