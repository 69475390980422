import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import styles from './Styles/BoatOnImageRenderCss'
import ChevronLeft from '@material-ui/icons/ChevronLeft'
import ChevronRight from '@material-ui/icons/ChevronRight'
import Button from '@material-ui/core/Button'
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf'
import VideoLibraryIcon from '@material-ui/icons/VideoLibrary'

class BoatOnImageRender extends Component {
    constructor(props) {
        super(props)

        this.state = {
            actualImg: props.index || 0,
        }
    }

    handleChangeImg(change) {
        const { imgs } = this.props

        if (change < 0 || change >= imgs.length) return
        this.setState({ actualImg: change })
    }

    renderBottomBar(pdf, video) {
        const { classes, imgs } = this.props
        const { actualImg } = this.state
        const videoExtAllowed = ['mp4', 'mov', 'mpg']

        return (
            <div className={classes.bottomBar}>
                {imgs.map((img, i) => {
                    let full = img.link.split('.')
                    let pdf = img.infos
                        ? img.infos.type.split('/')[1]
                        : full[full.length - 1]

                    let video = false
                    let ext = false
                    if (
                        img &&
                        img.infos &&
                        img.infos.type.split('/')[0] === 'video'
                    ) {
                        video = true
                        ext = img.infos.type.split('/')[1]
                    } else if (img) {
                        if (img.infos) ext = img.infos.type.split('/')[1]
                        else {
                            if (
                                full &&
                                videoExtAllowed.includes(full[full.length - 1])
                            ) {
                                video = true
                                ext = full[full.length - 1]
                            }
                        }
                    }

                    if (video) {
                        return (
                            <React.Fragment key={i}>
                                <VideoLibraryIcon
                                    className={
                                        i === actualImg
                                            ? classes.imageBottomSelected
                                            : classes.imageBottom
                                    }
                                    onClick={() => this.handleChangeImg(i)}
                                />
                            </React.Fragment>
                        )
                    } else if (pdf === 'pdf') {
                        return (
                            <React.Fragment key={i}>
                                <PictureAsPdfIcon
                                    className={
                                        i === actualImg
                                            ? classes.imageBottomSelected
                                            : classes.imageBottom
                                    }
                                    onClick={() => this.handleChangeImg(i)}
                                />
                            </React.Fragment>
                        )
                    } else {
                        return (
                            <React.Fragment key={i}>
                                <img
                                    src={img.link}
                                    alt={img.link}
                                    className={
                                        i === actualImg
                                            ? classes.imageBottomSelected
                                            : classes.imageBottom
                                    }
                                    onClick={() => this.handleChangeImg(i)}
                                ></img>
                            </React.Fragment>
                        )
                    }
                })}
            </div>
        )
    }

    render() {
        const { classes, multi, imgs, bottomImage } = this.props
        const videoExtAllowed = ['mp4', 'mov', 'mpg']
        const { actualImg } = this.state
        let full =
            imgs && imgs.length > 0 && imgs[actualImg].link
                ? imgs[actualImg].link.split('.')
                : null
        const preTb =
            imgs[actualImg] && imgs[actualImg].infos
                ? imgs[actualImg].infos.type.split('/')
                : null
        var pdf =
            preTb && preTb.length >= 1
                ? imgs[actualImg].infos.type.split('/')[1]
                : null
        if (pdf == null) pdf = full ? full[full.length - 1] : null

        if (!full) {
            return <div />
        }

        var video = false
        var ext = false
        if (
            imgs[actualImg] &&
            imgs[actualImg].infos &&
            imgs[actualImg].infos.type.split('/')[0] === 'video'
        ) {
            video = true
            ext = imgs[actualImg].infos.type.split('/')[1]
        } else if (imgs[actualImg]) {
            if (imgs[actualImg].infos)
                ext = imgs[actualImg].infos.type.split('/')[1]
            else {
                if (full && videoExtAllowed.includes(full[full.length - 1])) {
                    video = true
                    ext = full[full.length - 1]
                }
            }
        }

        return (
            <div className={classes.pictures}>
                {multi ? (
                    <Button
                        className={classes.chevronLeft}
                        onClick={() => {
                            this.handleChangeImg(this.state.actualImg - 1)
                        }}
                    >
                        <ChevronLeft />
                    </Button>
                ) : (
                    <div />
                )}
                <div
                    className={classes.behind}
                    style={{ backgroundImage: `url(${imgs[actualImg].link})` }}
                />
                <div
                    className={
                        pdf === 'pdf'
                            ? classes.importantPdf
                            : classes.importantImage
                    }
                >
                    {pdf === 'pdf' ? (
                        <iframe
                            src={imgs[actualImg].link}
                            type="application/pdf"
                            width="100%"
                            frameBorder="0"
                            scrolling="auto"
                            title="document"
                        />
                    ) : !video ? (
                        <img
                            src={imgs[actualImg].link}
                            alt={imgs[actualImg].link}
                            style={{
                                maxWidth: '100%',
                                maxHeight: '100%',
                                margin: 'auto',
                                position: 'absolute',
                                top: 0,
                                bottom: 0,
                                right: 0,
                                left: 0,
                            }}
                        />
                    ) : (
                        <video
                            controls
                            style={{
                                maxWidth: '100%',
                                maxHeight: '100%',
                                margin: 'auto',
                                position: 'absolute',
                                top: 0,
                                bottom: 0,
                                right: 0,
                                left: 0,
                            }}
                        >
                            <source
                                src={
                                    imgs[actualImg].blop
                                        ? imgs[actualImg].blop
                                        : imgs[actualImg].link
                                }
                                type={'video/' + ext}
                            ></source>
                        </video>
                    )}
                </div>
                {multi ? (
                    <Button
                        className={classes.chevronRight}
                        onClick={() => {
                            this.handleChangeImg(this.state.actualImg + 1)
                        }}
                    >
                        <ChevronRight />
                    </Button>
                ) : (
                    <div />
                )}
                {bottomImage && this.renderBottomBar(pdf, video)}
            </div>
        )
    }
}

export default withStyles(styles)(BoatOnImageRender)
