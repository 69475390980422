import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import styles from './Styles/ButtonFilterPopOverCss'
import BoatOnComponent from '../common/BoatOnComponent'
import { Button, Typography } from '@material-ui/core'
import dictionary from './Dictionary/ButtonFilterPopOverDico'

class ButtonFilterPopOver extends BoatOnComponent {
    constructor(props) {
        super(props)

        this.dictionary = dictionary
    }

    render() {
        const { reset, save, body, classes, title } = this.props
        const style = title ? {} : { margin: 0, marginBottom: '15px' }

        return (
            <div className={classes.root}>
                {title ? <Typography>{title}</Typography> : <></>}
                <div className={classes.body} style={style}>
                    {body}
                </div>
                <div className={classes.buttons}>
                    <Button
                        variant="contained"
                        size="small"
                        onClick={reset}
                        style={{ marginRight: '5px' }}
                    >
                        {this.displayText('delete')}
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        onClick={save}
                        style={{ marginLeft: '5px' }}
                    >
                        {this.displayText('save')}
                    </Button>
                </div>
            </div>
        )
    }
}

export default withStyles(styles)(ButtonFilterPopOver)
