export default theme => ({
    line: {
        width: 'calc(100% - 20px)',
        padding: '10px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        height: '50px',
    },
    blueLine: {
        width: 'calc(100% - 20px)',
        padding: '10px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        height: '50px',
        backgroundColor: 'rgba(48,64,160,0.1)',
    },
    infos: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        border: '1px solid #c4c4c4',
        boxShadow: '0px 2px 10px 0.3px #c4c4c4',
    },
    root: {
        width: 'calc(100% - 50px)',
        margin: '25px',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            margin: 0,
        },
    },
    title: {
        fontWeight: 500,
        margin: '10px',
    },
    subtitle: {
        textAlign: 'left',
        marginTop: 'auto',
        marginBottom: 'auto',
        fontSize: '22px',
        [theme.breakpoints.down('sm')]: {
            fontSize: '18px',
        },
    },
    type: {
        textAlign: 'left',
        marginTop: 'auto',
        marginBottom: 'auto',
        fontSize: '16px',
        [theme.breakpoints.down('sm')]: {
            fontSize: '12px',
        },
    },
    titleAndType: {
        width: '50%',
        [theme.breakpoints.down('sm')]: {
            width: 'calc(50% - 60px)',
        },
    },
    date: {
        width: '100%',
        fontSize: '20px',
        whiteSpace: 'nowrap',
        [theme.breakpoints.down('sm')]: {
            fontSize: '16px',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'clip',
        },
    },
    dates: {
        textAlign: 'center',
        marginTop: 'auto',
        marginBottom: 'auto',
        width: '8%',
        [theme.breakpoints.down('sm')]: {
            fontSize: '18px',
            width: '15%',
        },
    },
    datesDayMonth: {
        display: 'flex',
        flexDirection: 'row',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
        },
    },
    amount: {
        textAlign: 'center',
        marginTop: 'auto',
        marginBottom: 'auto',
        width: '15%',
        [theme.breakpoints.down('sm')]: {
            fontSize: '13px',
            width: '12%',
        },
    },
    modifyDelete: {
        textAlign: 'center',
        marginTop: 'auto',
        marginBottom: 'auto',
        width: '10%',
        [theme.breakpoints.down('sm')]: {
            fontSize: '16px',
        },
    },
    firstLine: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
        },
    },
    dividerH: {
        border: '0.5px solid #c4c4c4',
        height: '50px',
        marginRight: '10px',
        marginLeft: '10px',
    },
    icon: {
        width: '40px',
        height: '40px',
        marginLeft: 'auto',
        marginRight: 'auto',
        [theme.breakpoints.down('sm')]: {
            width: '30px',
            height: '30px',
            padding: '5px',
        },
    },
    icons: {
        display: 'block',
        marginTop: 'auto',
        marginBottom: 'auto',
        color: '#000',
        alignItems: 'center',
        width: '4%',
        '&:hover': {
            cursor: 'pointer',
        },
    },
    icone: {
        display: 'block',
        marginTop: 'auto',
        marginBottom: 'auto',
        color: '#000',
        alignItems: 'center',
        width: '3%',
        '&:hover': {
            cursor: 'pointer',
        },
    },
    iconDisabled: {
        display: 'block',
        marginTop: 'auto',
        marginBottom: 'auto',
        width: '3%',
        color: '#C1C1C1',
        '&:hover': {
            cursor: 'pointer',
        },
    },
    iconlink: {
        textDecoration: 'none',
    },
})
