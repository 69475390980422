export default {
    addExpense: {
        FR: 'Ajouter une dépense',
        EN: 'Add expense',
    },
    addIncome: {
        FR: 'Ajouter un revenu',
        EN: 'Add income',
    },
    youAddExpense: {
        FR: "Vous êtes en train d'ajouter une dépense, à vous de jouer",
        EN: "Vous êtes en train d'ajouter une dépense, à vous de jouer",
    },
    youEditExpense: {
        FR: 'Vous êtes en train de modifier une dépense',
        EN: 'Vous êtes en train de modifier une dépense',
    },
    youAddIncome: {
        FR: "Vous êtes en train d'ajouter un revenu, bravo",
        EN: "Vous êtes en trai  d'ajouter un revenu, bravo",
    },
    youEditIncome: {
        FR: 'Vous êtes en train de modifier un revenu',
        EN: 'Vous êtes en trai  de modifier un revenu',
    },
    amount: {
        FR: 'Montant',
        EN: 'Amount',
    },
    date: {
        FR: 'Date',
        EN: 'Date',
    },
    choiceTypeExpense: {
        FR: 'Choisir un type de dépense',
        EN: 'Choisir un type de dépense',
    },
    choiceTypeIncome: {
        FR: 'Choisir un type de revenu',
        EN: 'Choisir un type de revenu',
    },
    titleExpense: {
        FR: "Libellé de la dépense (c'est le titre de votre dépense)",
        EN: "Libellé de la dépense (c'est le titre de votre dépense)",
    },
    titleIncome: {
        FR: "Libellé du revenu (c'est le titre de votre revenu)",
        EN: "Libellé du revenu (c'est le titre de votre revenu)",
    },
    detailsExpense: {
        FR: 'Détails de la dépense',
        EN: 'Détails de la dépense',
    },
    detailsIncome: {
        FR: 'Détails du revenu',
        EN: 'Détails du revenu',
    },
    validate: {
        FR: 'Je valide',
        EN: 'Validate',
    },
    addDocumentExpense: {
        FR: 'Ajouter un document pour cette dépense',
        EN: 'Ajouter un document pour cette dépense',
    },
    addDocumentIncome: {
        FR: 'Ajouter un document pour ce revenu',
        EN: 'Ajouter un document pour ce revenu',
    },
}
