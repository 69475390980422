import React from 'react'
import { connect } from 'react-redux'

import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Zoom from '@material-ui/core/Zoom'
import Modal from '@material-ui/core/Modal'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem'
import AddAPhotoIcon from '@material-ui/icons/AddAPhoto'
import ReactGA from 'react-ga'
import ReactPixel from 'react-facebook-pixel'
import ModalConnection from '../../../common/UsefullComponents/ModalConnection/ModalConnection'
import addIcon from '../../../../images/Add_monogram_dark_blue.png'
import minusIcon from '../../../../images/Minus_monogram_dark blue.png'
import BoatOnComponent from '../../../common/BoatOnComponent'
import MovementTab from './MovementTab'
import BudgetTab from './BudgetTab'
import dictionary from './Dictionary/ExpIncPageDico'
import styles from './Styles/ExpIncPageCss'
import {
    putBOBEvents,
    postBOBEvents,
    removeTransactionFromBOBEvent,
    updateStateEvent,
    setBOBStateEvent,
    deleteFileFromBobEvent,
    addFileToBobEvent,
} from '../../../../actions/bob.actions'
import { typesActions } from '../../../../actions/types.actions'
import DisplayerImgModal from '../../DisplayerImgModal'
import InputAdornment from '@material-ui/core/InputAdornment'

class ExpIncPage extends BoatOnComponent {
    constructor(props) {
        super(props)
        this.dictionary = dictionary

        this.state = {
            events: [],
            event: null,
            openModalConnection: true,
            openMenu: false,
            openAddModal: 0,
            transactionId: null,
            type: '',
            date: '',
            amount: '',
            title: '',
            details: '',
            update: 0,
            edit: false,
            displayNewFiles: [],
            fileNoUrl: [],
            deletedFiles: [],
            startDate: null,
            endDate: null,
            search: null,
        }

        const options = {
            autoConfig: true,
            debug: false,
        }
        ReactPixel.init('181677145678471', options)

        ReactPixel.pageView()
        this.initializeReactGA()

        this.handleClickMenu = this.handleClickMenu.bind(this)
        this.handleClose = this.handleClose.bind(this)
        this.handleAddExpense = this.handleAddExpense.bind(this)
        this.handeAddIncome = this.handeAddIncome.bind(this)
        this.handleUpdate = this.handleUpdate.bind(this)
        this.addDocument = this.addDocument.bind(this)
        this.clickDocument = this.clickDocument.bind(this)
        this.deleteDocumentNoUp = this.deleteDocumentNoUp.bind(this)
        this.handleChangeDate = this.handleChangeDate.bind(this)
        this.handleChangeSearch = this.handleChangeSearch.bind(this)
        this.edit = this.edit.bind(this)
        this.delete = this.delete.bind(this)
        this.save = this.save.bind(this)
    }

    componentDidUpdate(prevProps) {
        const { events, eventTypes, loading } = this.props

        if (!this.props.bob.loading && prevProps.bob.loading) {
            this.handleClose()
        }

        if (events && eventTypes && prevProps.loading !== 0 && loading === 0) {
            const transaction = []
            events.forEach(event => {
                const eventType = typesActions.getEventTypeById(
                    eventTypes,
                    event.eventType.id,
                )
                if (
                    event.transaction &&
                    eventType &&
                    (eventType.spending || eventType.earning)
                ) {
                    transaction.push(event)
                }
            })
            this.setState({ events: transaction })
        }
    }

    componentDidMount() {
        const { events, eventTypes } = this.props

        if (events && eventTypes && events.length) {
            const transaction = []
            events.forEach(event => {
                const eventType = typesActions.getEventTypeById(
                    eventTypes,
                    event.eventType.id,
                )
                if (
                    event.transaction &&
                    eventType &&
                    (eventType.spending || eventType.earning)
                ) {
                    transaction.push(event)
                }
            })
            this.setState({ events: transaction })
        }
    }

    initializeReactGA() {
        ReactGA.initialize('UA-93941518-1')
        ReactGA.pageview('/my-boat-logbook/my-expenses-and-incomes')
    }

    deleteDocumentNoUp(i) {
        deleteFileFromBobEvent(this, i, {
            deletedFiles: this.state.deletedFiles,
            displayNewFiles: this.state.displayNewFiles,
            fileNoUrl: this.state.fileNoUrl,
            files: this.state.event.files,
        })
    }

    handleClose() {
        this.setState({
            transactionId: null,
            openModalConnection: false,
            openAddModal: 0,
            type: '',
            date: '',
            amount: '',
            title: '',
            details: '',
            edit: false,
            displayNewFiles: [],
            fileNoUrl: [],
            deletedFiles: [],
        })
    }

    handleChangeDate(startDate, endDate) {
        this.setState({ startDate: startDate, endDate: endDate })
    }

    handleChangeSearch(search) {
        this.setState({ search: search })
    }

    handleClickMenu() {
        this.setState({ openMenu: !this.state.openMenu })
    }

    handleAddExpense() {
        this.setState({ openAddModal: 1 })
        setBOBStateEvent(this)
    }

    handeAddIncome() {
        this.setState({ openAddModal: 2 })
        setBOBStateEvent(this)
    }

    handleUpdate() {
        this.setState({ update: this.state.update - 1 })
    }

    save() {
        const { deletedFiles, fileNoUrl, event } = this.state
        const {
            delimitedDate,
            title,
            description,
            transaction,
            eventType,
        } = event
        const { boat } = this.props
        if (event.id) {
            this.props.dispatch(
                putBOBEvents(event.id, title, {
                    newFiles: fileNoUrl,
                    files: event.files,
                    delimitedDate: delimitedDate,
                    transaction: transaction,
                    description: description,
                    eventTypeId: eventType.id,
                    deletedFiles: deletedFiles,
                }),
            )
        } else {
            this.props.dispatch(
                postBOBEvents(boat.id, eventType.id, title, {
                    files: fileNoUrl,
                    delimitedDate: delimitedDate,
                    transaction: transaction,
                    description: description,
                }),
            )
        }
    }

    renderMenu() {
        const { classes } = this.props
        const { openMenu } = this.state

        return (
            <div className={openMenu ? classes.menu : classes.hidden}>
                <Button
                    className={classes.button}
                    variant="contained"
                    color="primary"
                    onClick={this.handleAddExpense}
                >
                    {this.displayText('addExpense')}
                </Button>
                <Button
                    className={classes.button}
                    variant="contained"
                    color="primary"
                    onClick={this.handeAddIncome}
                >
                    {this.displayText('addIncome')}
                </Button>
            </div>
        )
    }

    clickDocument() {
        this.refs.fileUploader.click()
    }

    addDocument(event) {
        addFileToBobEvent(this, event, {
            fileNoUrl: this.state.fileNoUrl,
            displayNewFiles: this.state.displayNewFiles,
        })
    }

    renderAddModal() {
        const { classes } = this.props
        let { eventTypes } = this.props
        const { openAddModal, event } = this.state

        const eventType = typesActions.getEventTypeById(
            eventTypes,
            event.eventType.id,
        )

        if (!eventType && openAddModal === 1) {
            eventTypes = eventTypes.filter(eventType => eventType.spending)
        } else if (!eventType && openAddModal === 2) {
            eventTypes = eventTypes.filter(eventType => eventType.earning)
        }

        return (
            <>
                <div>
                    <Typography className={classes.title}>
                        {eventType
                            ? eventType.earning
                                ? this.displayText('youEditIncome')
                                : this.displayText('youEditExpense')
                            : openAddModal === 1
                            ? this.displayText('youAddExpense')
                            : this.displayText('youAddIncome')}
                    </Typography>
                    <TextField
                        id="type"
                        variant="outlined"
                        label={
                            openAddModal === 1
                                ? this.displayText('choiceTypeExpense')
                                : this.displayText('choiceTypeIncome')
                        }
                        margin="dense"
                        required
                        select
                        value={
                            event && event.eventType && event.eventType.id
                                ? event.eventType.id
                                : -1
                        }
                        onChange={e => {
                            updateStateEvent(this, {
                                eventTypeId: e.target.value,
                            })
                        }}
                        className={classes.textField}
                        InputProps={{
                            classes: {
                                input: classes.input,
                                marginDense: classes.marginInput,
                            },
                        }}
                        InputLabelProps={{
                            classes: {
                                root: classes.labelInput,
                            },
                        }}
                    >
                        <MenuItem value={-1}>
                            <em>Choisissez un type d'évènement</em>
                        </MenuItem>
                        {eventTypes.map(type => (
                            <MenuItem key={type.id} value={type.id}>
                                {type.eventTypeFr}
                            </MenuItem>
                        ))}
                    </TextField>
                    <div className={classes.twoTextField}>
                        <TextField
                            id="date"
                            variant="outlined"
                            type="date"
                            label={this.displayText('date')}
                            margin="dense"
                            required
                            value={
                                event.delimitedDate &&
                                event.delimitedDate.startDate
                                    ? new Date(event.delimitedDate.startDate)
                                          .toISOString()
                                          .split('T')[0]
                                    : null
                            }
                            onChange={e => {
                                updateStateEvent(this, {
                                    delimitedDate: {
                                        startDate: e.target.value,
                                    },
                                })
                            }}
                            className={classes.textField}
                            InputProps={{
                                classes: {
                                    input: classes.input,
                                    marginDense: classes.marginInput,
                                },
                            }}
                            InputLabelProps={{
                                shrink: true,
                                classes: {
                                    root: classes.labelInput,
                                },
                            }}
                        />
                        <TextField
                            id="amount"
                            variant="outlined"
                            label={this.displayText('amount')}
                            margin="dense"
                            required
                            value={
                                (event.transaction &&
                                    event.transaction.amountIt) ||
                                null
                            }
                            onChange={e => {
                                updateStateEvent(this, {
                                    transaction: { amountIt: e.target.value },
                                })
                            }}
                            className={classes.textField}
                            InputProps={{
                                classes: {
                                    input: classes.input,
                                    marginDense: classes.marginInput,
                                },
                                startAdornment: (
                                    <InputAdornment position="start">
                                        {eventType
                                            ? eventType.spending
                                                ? '-'
                                                : '+'
                                            : ''}
                                    </InputAdornment>
                                ),
                            }}
                            InputLabelProps={{
                                classes: {
                                    root: classes.labelInput,
                                },
                            }}
                        />
                    </div>
                    <TextField
                        id="title"
                        variant="outlined"
                        label={
                            openAddModal === 1
                                ? this.displayText('titleExpense')
                                : this.displayText('titleIncome')
                        }
                        margin="dense"
                        required
                        value={event.title}
                        onChange={e => {
                            updateStateEvent(this, {
                                title: e.target.value,
                            })
                        }}
                        className={classes.textField}
                        InputProps={{
                            classes: {
                                input: classes.input,
                                marginDense: classes.marginInput,
                            },
                        }}
                        InputLabelProps={{
                            classes: {
                                root: classes.labelInput,
                            },
                        }}
                    />
                    <TextField
                        id="details"
                        variant="outlined"
                        label={
                            openAddModal === 1
                                ? this.displayText('detailsExpense')
                                : this.displayText('detailsIncome')
                        }
                        margin="dense"
                        multiline
                        rows={3}
                        value={event.description || ''}
                        onChange={e => {
                            updateStateEvent(this, {
                                description: e.target.value,
                            })
                        }}
                        className={classes.textField}
                        InputProps={{
                            classes: {
                                input: classes.input,
                                marginDense: classes.marginInput,
                            },
                        }}
                        InputLabelProps={{
                            classes: {
                                root: classes.labelInput,
                            },
                        }}
                    />
                    <Typography className={classes.textAdd}>
                        {openAddModal === 1
                            ? this.displayText('addDocumentExpense')
                            : this.displayText('addDocumentIncome')}
                    </Typography>
                    <div
                        className={classes.addDoc}
                        onClick={this.clickDocument}
                    >
                        <AddAPhotoIcon className={classes.doc} />
                    </div>
                    <input
                        onChange={this.addDocument}
                        multiple
                        type="file"
                        id="file"
                        ref="fileUploader"
                        style={{ display: 'none' }}
                    />
                    <DisplayerImgModal
                        file={
                            event && event.files && this.state.displayNewFiles
                                ? [
                                      ...event.files,
                                      ...this.state.displayNewFiles,
                                  ]
                                : []
                        }
                        deleteDocumentNoUp={this.deleteDocumentNoUp}
                    />
                    <Button
                        className={classes.validate}
                        variant="contained"
                        color="secondary"
                        onClick={this.save}
                    >
                        {this.displayText('validate')}
                    </Button>
                </div>
            </>
        )
    }

    edit(transaction) {
        const { eventTypes } = this.props
        const eventType = typesActions.getEventTypeById(
            eventTypes,
            transaction.eventType.id,
        )
        this.setState({
            openAddModal: eventType && eventType.earning ? 2 : 1,
        })

        this.setState({ edit: true, event: transaction })
    }

    delete(transaction) {
        console.log(transaction)
        this.props.dispatch(removeTransactionFromBOBEvent(transaction.id))
    }

    render() {
        const { classes, boats, user, loading } = this.props
        const {
            openMenu,
            openAddModal,
            search,
            startDate,
            endDate,
            events,
        } = this.state

        if (!user) {
            return (
                <ModalConnection
                    open={this.state.openModalConnection}
                    handleClose={() => {
                        this.handleClose()
                    }}
                />
            )
        }

        if (loading !== 0) return <>{this.renderLoading('5%')}</>

        return (
            <div className={classes.root} key="alerts">
                <div className={classes.firstPart}>
                    <BudgetTab
                        boats={boats}
                        update={this.state.update}
                        updated={this.handleUpdate}
                        handleChangeSearch={this.handleChangeSearch}
                        handleChangeDate={this.handleChangeDate}
                        filter={{ search, startDate, endDate }}
                        spendings={this.state.events}
                    />
                    <MovementTab
                        boats={boats}
                        update={this.state.update}
                        updated={this.handleUpdate}
                        edit={this.edit}
                        delete={this.delete}
                        filter={{ search, startDate, endDate }}
                        spendings={this.state.events}
                    />
                    <Zoom direction="down" in={openMenu}>
                        {this.renderMenu()}
                    </Zoom>
                    <img
                        src={openMenu ? minusIcon : addIcon}
                        alt="ajouter"
                        className={classes.icon}
                        onClick={this.handleClickMenu}
                    />
                </div>
                <Modal
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                    open={openAddModal > 0 && openAddModal < 3}
                    onClose={this.handleClose}
                >
                    <div id="modal" className={classes.paper}>
                        <div>
                            {(openAddModal === 1 || openAddModal === 2) &&
                                this.renderAddModal()}
                        </div>
                    </div>
                </Modal>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        user: state.authentication.user,
        boats: state.fetch.boats,
        checkups: state.bob.checkups,
        checkupTypes: state.fetch.checkupTypes,
        boat: state.bob.boat,
        bob: state.bob,
        events: state.bob.events,
        loading: state.bob.loading,
        eventTypes: typesActions.getEventTypeTransaction(
            state.types.eventTypes,
        ),
    }
}

export default connect(mapStateToProps)(withStyles(styles)(ExpIncPage))
