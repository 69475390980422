import React from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import BoatOnComponent from '../../BoatOnComponent'
import styles from './Styles/ModalRenderConnectionCss'
import dictionary from './Dictionary/ModalRenderConnectionDico'
import BoatOnModal from '../../BoatOnModal'
import { userActions } from '../../../../actions'
import InputAdornment from '@material-ui/core/InputAdornment'
import EmailIcon from '@material-ui/icons/Email'
import LockIcon from '@material-ui/icons/Lock'

class ModalRenderConnection extends BoatOnComponent {
    constructor(props) {
        super(props)
        this.dictionary = dictionary

        this.state = {
            email: '',
            password: '',
            focused: false,
            openModal: 'connection',
        }

        this.handleSubmit = this.handleSubmit.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.handleClose = this.handleClose.bind(this)
    }

    handleClose() {
        this.setState({ openModal: false }, () => this.props.handleClose(false))
    }

    handleChange(value, key) {
        this.setState({ [key]: value, focused: key })
    }

    handleSubmit(e) {
        e.preventDefault()

        if (
            /^\w+([-.]?\w+)*@\w+([-.]?\w+)*(\.\w{2,3})+$/.test(
                this.state.email,
            ) &&
            this.state.password.length >= 6
        ) {
            this.setState({ submitted: true })
            const { email, password } = this.state
            const { dispatch } = this.props
            if (email && password) {
                dispatch(userActions.login(email, password.trim()))
                    .then(() => {
                        this.props.handleClose(true)
                    })
                    .catch(() => {
                        alert(this.displayText('error'))
                    })
            }
        }
    }

    renderForm() {
        const { classes } = this.props
        return (
            <form
                name="form"
                onSubmit={this.handleSubmit}
                className={classes.root}
            >
                <TextField
                    id="email"
                    label={this.displayText('email')}
                    margin="normal"
                    placeholder="paulgrenier@email.com"
                    error={
                        !/^\w+([-.]?\w+)*@\w+([-.]?\w+)*(\.\w{2,3})+$/.test(
                            this.state.email,
                        ) &&
                        this.state.email !== '' &&
                        this.state.focused !== 'email'
                    }
                    helperText={
                        !/^\w+([-.]?\w+)*@\w+([-.]?\w+)*(\.\w{2,3})+$/.test(
                            this.state.email,
                        ) &&
                        this.state.email !== '' &&
                        this.state.focused !== 'email'
                            ? this.displayText('invalidEmail')
                            : ''
                    }
                    required
                    variant="outlined"
                    value={this.state.email}
                    onChange={e => {
                        this.handleChange(e.target.value, 'email')
                    }}
                    fullWidth
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <EmailIcon />
                            </InputAdornment>
                        ),
                    }}
                />
                <TextField
                    id="password"
                    label={this.displayText('password')}
                    margin="normal"
                    type="password"
                    required
                    variant="outlined"
                    placeholder="**********"
                    error={
                        this.state.password.length < 6 &&
                        this.state.password !== '' &&
                        this.state.focused !== 'password'
                    }
                    helperText={
                        this.state.password.length < 6 &&
                        this.state.password !== '' &&
                        this.state.focused !== 'password'
                            ? this.displayText('nbCharacterPassword')
                            : ''
                    }
                    value={this.state.password}
                    onChange={e => {
                        this.handleChange(e.target.value, 'password')
                    }}
                    fullWidth
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <LockIcon />
                            </InputAdornment>
                        ),
                    }}
                />
                <div>
                    <Button
                        className={classes.button}
                        type="submit"
                        color="primary"
                        variant="contained"
                        style={{ marginTop: '15px' }}
                    >
                        {' '}
                        {this.displayText('connection')}{' '}
                    </Button>
                </div>
            </form>
        )
    }

    render() {
        return (
            <BoatOnModal
                openCondition={this.state.openModal}
                handleClose={this.handleClose}
                rendersModal={{
                    connection: this.renderForm(),
                }}
                titles={{
                    connection: this.displayText('connection'),
                }}
                disableFullscreen
            />
        )
    }
}

function mapStateToProps(state) {
    return {
        alert: state.alert,
        state: state.open,
    }
}

export default connect(mapStateToProps)(
    withStyles(styles)(ModalRenderConnection),
)
