import React from 'react'
import BoatOnComponent from '../BoatOnComponent'
import { withStyles } from '@material-ui/core/styles'
import styles from './Styles/InputLabelCss'
import dictionary from './Dictionary/InputLabel'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Chip from '@material-ui/core/Chip'
import Autocomplete from '@material-ui/lab/Autocomplete'
import AttachFile from '@material-ui/icons/AttachFile'


class InputLabelInventory extends BoatOnComponent {
    constructor(props) {
        super(props)
        this.dictionary = dictionary
        this.state = {}
    }

    render() {
        const { classes } = this.props

        const tesst =[]

        const nameTextfields = [

            {
                id: 1,
                text: this.displayText('chooseFamilyEquipment'),
            },
            {
                id: 2,
                text: this.displayText('chooseEquipment'),
            },
            {
                id: 3,
                text: this.displayText('choosePart'),
            },

        ]

        return (
            <div>
                {nameTextfields.map(nameTextfield => (
                    <div className={classes.root}>
                        <div className={classes.testGlobal}>
                            <div className={classes.test1}>
                                <Autocomplete
                                    multiple
                                    id="tags-filled"
                                    options={tesst.map(option => option)}
                                    freeSolo
                                    renderTags={(value, getTagProps) =>
                                        value.map((option, index) => (
                                            <Chip
                                                size="small"
                                                variant="outlined"
                                                style={{
                                                    backgroundColor: '#fcd48e',
                                                }}
                                                label={option}
                                                {...getTagProps({ index })}
                                                icon={
                                                    <AttachFile
                                                        className={
                                                            classes.attachRotate
                                                        }
                                                    />
                                                }
                                            />
                                        ))
                                    }
                                    renderInput={params => (
                                        <TextField
                                            {...params}
                                            variant="outlined"
                                            margin="dense"
                                            onDelete
                                            size="small"
                                            label={nameTextfield.text}
                                            className={classes.textTextfield}
                                        />
                                    )}
                                />
                            </div>

                            <Button
                                size="small"
                                color="primary"
                                variant="contained"
                                className={classes.btnView}
                            >
                                {this.displayText('seeAll')}
                            </Button>
                        </div>
                    </div>
                ))}
            </div>
        )
    }
}

export default withStyles(styles)(InputLabelInventory)
