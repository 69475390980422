export default theme => ({
    root: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column-reverse',
        },
    },
    firstPart: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        marginBottom: '20px',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
    icon: {
        marginRight: '50px',
        marginLeft: 'auto',
        '&:hover': {
            cursor: 'pointer',
        },
        width: '80px',
        height: '80px',
        [theme.breakpoints.down('sm')]: {
            marginTop: '15px',
            width: '60px',
            height: '60px',
        },
    },
    iconMinus: {
        marginRight: 0,
        marginLeft: 'auto',
    },
    hidden: {
        display: 'none',
    },
    menu: {
        width: '275px',
        display: 'flex',
        flexDirection: 'column',
        marginRight: '50px',
        marginLeft: 'auto',
        padding: '10px',
        paddingRight: 0,
    },
    button: {
        margin: '10px',
        marginRight: 0,
        padding: '10px',
    },
    validate: {
        display: 'block',
        margin: 'auto',
        marginTop: '20px',
        marginBottom: 0,
    },
    paper: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '40vw',
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(4),
        outline: 'none',
        [theme.breakpoints.down('sm')]: {
            padding: '5px',
            width: '90vw',
        },
    },
    twoTextField: {
        display: 'flex',
        flexDirection: 'row',
    },
    title: {
        fontSize: '24px',
        fontWeight: 500,
        textAlign: 'center',
        marginBottom: '10px',
    },
    textField: {
        marginRight: '10px',
        marginLeft: '10px',
        width: 'calc(100% - 20px)',
    },
    addDoc: {
        border: '1px dashed #c4c4c4',
        margin: '10px',
        marginTop: 0,
        '&:hover': {
            cursor: 'pointer',
        },
    },
    textAdd: {
        margin: '10px',
        fontWeight: 500,
    },
    doc: {
        margin: 'auto',
        display: 'block',
        padding: '10px',
        width: '50px',
        height: '50px',
        color: '#c4c4c4',
    },
    allPhotos: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
    },
    images: {
        width: '100px',
        height: '100px',
        marginRight: '15px',
        position: 'relative',
    },
    pictures: {
        margin: 'auto',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        border: '0.5px solid #C4C4C4',
        position: 'relative',
    },
    importantImage: {
        width: '30rem',
        margin: 'auto',
        height: '100%',
        position: 'relative',
        display: 'inline-block',
    },
    cross: {
        position: 'absolute',
        right: 0,
        top: 0,
        '&:hover': {
            cursor: 'pointer',
        },
        zIndex: 1,
    },
    input: {
        [theme.breakpoints.down('sm')]: {
            fontSize: '14px',
            padding: 0,
            paddingTop: '10px',
            paddingBottom: '10px',
            paddingLeft: '4px',
        },
    },
    labelInput: {
        [theme.breakpoints.down('sm')]: {
            fontSize: '14px',
        },
    },
    marginInput: {
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
    loading: {
        textAlign: 'center',
    },
})
