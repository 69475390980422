import { userConstants } from '../constants'
import { userService } from '../services/user.service'
import { alertActions } from './'
import { history } from '../helpers'
import { requestApi } from '../services/api.service'

function refreshToken(dispatch) {
    dispatch({ type: userConstants.REFRESH_TOKEN })
    return userService.refreshToken().then(
        result => {
            dispatch({
                type: userConstants.TOKEN_REFRESH_SUCCESS,
                user: result,
            })
            return result
        },
        error => {
            dispatch({ type: userConstants.TOKEN_REFRESH_FAILURE, error })
        },
    )
}

function init() {
    return dispatch => {
        dispatch({
            type: userConstants.REQUEST_USER,
        })
        const cached = userService.getFromLS()
        if (cached && cached.accessToken) {
            return requestApi('/users', 'GET').then(
                result => {
                    dispatch({
                        type: userConstants.UPDATE_SUCCESS,
                        user: result,
                    })
                    return result
                },
                error => {
                    dispatch({
                        type: userConstants.UPDATE_FAILURE,
                        payload: { error },
                    })
                },
            )
        } else {
            dispatch({
                type: userConstants.UPDATE_FAILURE,
            })
        }
    }
}

const setupSubscriptions = (stripeToken, { year, month } = false) => {
    let body = { frontStripeToken: stripeToken }
    if (year && !month) {
        body = {
            ...body,
            subscriptionType: { id: 1 },
        }
    } else if (!year && month) {
        body = {
            ...body,
            subscriptionType: { id: 2 },
        }
    }
    return dispatch => {
        dispatch({ type: userConstants.SUBSCRIPTIONS_REQUEST })
        return requestApi('/subscriptions/user', 'POST', body).then(
            result => {
                dispatch({
                    type: userConstants.SUBSCRIPTIONS_SUCCESS,
                    subscriptions: result,
                })
                history.push('/journal-de-bord')
                return result
            },
            error => {
                dispatch({
                    type: userConstants.SUBSCRIPTIONS_FAILURE,
                    error,
                })
            },
        )
    }
}

function requestSubscriptions() {
    return dispatch => {
        dispatch({ type: userConstants.SUBSCRIPTIONS_REQUEST })
        return userService.requestSubscriptions().then(
            result => {
                dispatch({
                    type: userConstants.SUBSCRIPTIONS_SUCCESS,
                    subscriptions: result,
                })
                return result
            },
            error => {
                dispatch({ type: userConstants.SUBSCRIPTIONS_FAILURE, error })
            },
        )
    }
}

function login(email, password) {
    return dispatch => {
        dispatch({ type: userConstants.LOGIN_REQUEST })
        return userService.login(email, password).then(
            result => {
                dispatch({
                    type: userConstants.LOGIN_SUCCESS,
                    user: result,
                })
            },
            error => {
                console.error('[user] login failed')
                dispatch({ type: userConstants.LOGIN_FAILURE, error })
                dispatch(alertActions.error(error))
                return { success: false, user: null }
            },
        )
    }
}

function logout() {
    return dispatch => {
        userService.logout()
        dispatch({ type: userConstants.LOGOUT, user: null })
    }
}

function signin(body, resolve = () => {}, reject = () => {}) {
    return dispatch => {
        dispatch({ type: userConstants.LOGIN_REQUEST, user: { body } })
        return userService.signin(body).then(
            result => {
                dispatch({
                    type: userConstants.LOGIN_SUCCESS,
                    user: result.User,
                })
                resolve(result.user)
                return result.user
            },
            error => {
                dispatch({ type: userConstants.LOGIN_FAILURE, error })
                dispatch(alertActions.error(error))
                reject(error)
            },
        )
    }
}

function forget(email) {
    return dispatch => {
        dispatch({ type: userConstants.LOGIN_REQUEST, user: { email } })
        userService
            .forget(email)
            .then(details => {
                if (details.success) {
                    alert('Email envoyé')
                    history.push('/connexion')
                } else {
                    alert('Aucun compte possède cette adresse email')
                }
            })
            .then(error => {
                dispatch({ type: userConstants.LOGIN_FAILURE, error })
                dispatch(alertActions.error(error))
            })
    }
}

function subscribe(UserID) {
    return dispatch => {
        return userService.subscribe(UserID).then(
            result => {
                dispatch({
                    type: userConstants.SUBSCRIBE_SUCCESS,
                    user: result.User,
                })
                return result.User
            },
            error => {
                dispatch({ type: userConstants.SUBSCRIBE_FAILURE, error })
                dispatch(alertActions.error(error))
            },
        )
    }
}

function updateUser(user) {
    return dispatch => {
        const address =
            user.address && user.address.fullText
                ? {
                      fullAddress: user.address.fullText,
                      latitude: user.address.latitude.toString(),
                      longitude: user.address.longitude.toString(),
                  }
                : null

        let body = {
            firstName: user.firstName,
            lastName: user.lastName,
            email: user.email,
            birthday: user.birthday || null,
            contactDetails: user.contactDetails,
            description: user.description,
        }

        if (user.password) {
            body.password = user.password
        }

        if (address) {
            body.address = address
        }

        dispatch({ type: userConstants.UPDATE_USER })
        return requestApi('/users', 'PUT', body).then(
            result => {
                dispatch({
                    type: userConstants.LOGIN_SUCCESS,
                    user: result,
                })
            },
            error => {
                dispatch({ type: userConstants.LOGIN_FAILURE, error })
            },
        )
    }
}

const checkSubscriptionBOB = subscriptions => {
    const sub =
        (subscriptions && Array.isArray(subscriptions) === false) ||
        (subscriptions &&
            Array.isArray(subscriptions) &&
            subscriptions.filter(
                sub =>
                    sub.subscriptionType &&
                    (sub.subscriptionType.id === 1 ||
                        sub.subscriptionType.id === 2),
            ).length > 0)
    if (sub) return true
    return false
}

const checkSubscriptionMultiBoat = subscriptions => {
    const sub =
        (subscriptions && Array.isArray(subscriptions) === false) ||
        (subscriptions &&
            Array.isArray(subscriptions) &&
            subscriptions.filter(
                sub =>
                    sub.subscriptionType &&
                    (sub.subscriptionType.id === 5 ||
                        sub.subscriptionType.id === 6),
            ).length > 0)
    if (sub) return true
    return false
}

export const userActions = {
    init,
    login,
    logout,
    forget,
    signin,
    subscribe,
    requestSubscriptions,
    setupSubscriptions,
    updateUser,
    refreshToken,
    checkSubscriptionBOB,
    checkSubscriptionMultiBoat,
}
