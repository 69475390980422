export default {
    title: {
        FR: "Suppression d'un moteur",
        EN: 'Deleting an engine',
    },
    question: {
        FR: 'Voulez-vous vraiment supprimer ce moteur ?',
        EN: 'Do you really want to delete this engine ?',
    },
    accept: {
        FR: 'Supprimer',
        EN: 'Delete',
    },
    cancel: {
        FR: 'Annuler',
        EN: 'Cancel',
    },
    care: {
        FR: 'Attention, vous êtes sur le point de supprimer votre moteur !',
        EN: 'Beware, you are about to delete your engine!',
    },
}
