import React from 'react'
import BoatOnComponent from '../../../common/BoatOnComponent'
import { connect } from 'react-redux'

import { withStyles } from '@material-ui/core/styles'
import styles from './Styles/RepairPageCss'
import { fetchData } from '../../../../actions/api.actions'
import RepairList from './RepairList'
import ModalConnection from '../../../common/UsefullComponents/ModalConnection/ModalConnection'
import ReactGA from 'react-ga'
import ReactPixel from 'react-facebook-pixel'
import { API_URL } from '../../../../services/config.service'

class RepairPage extends BoatOnComponent {
    constructor(props) {
        super(props)

        this.initializeReactGA()

        this.state = {
            openModalConnection: true,
        }

        const options = {
            autoConfig: true,
            debug: false,
        }
        ReactPixel.init('181677145678471', options)

        ReactPixel.pageView()
    }

    componentWillMount = props => {
        let { user, boats } = this.props
        var data = new FormData()
        data.append('appversion', 0)
        data.append('UserID', user.UserID)
        if (user && !boats) {
            this.props.dispatch(
                fetchData(API_URL + '/boatgetall.php', 'boats', 'POST', data),
            )
        }
        this.props.dispatch(
            fetchData(
                API_URL + '/listcheckuptypes.php',
                'checkupTypes',
                'POST',
                data,
            ),
        )
        this.props.dispatch(
            fetchData(
                API_URL + '/listcheckupcategories.php',
                'checkupCategories',
                'POST',
                data,
            ),
        )
    }
    initializeReactGA() {
        ReactGA.initialize('UA-93941518-1')
        ReactGA.pageview('/logbook/my-repairs')
    }

    render() {
        const { classes, user, checkupTypes, checkupCategories } = this.props

        if (!user) {
            return (
                <ModalConnection
                    open={this.state.openModalConnection}
                    handleClose={() => {
                        this.handleClose()
                    }}
                />
            )
        }

        return (
            <div className={classes.root}>
                <div className={classes.main}>
                    <RepairList
                        checkupTypes={checkupTypes}
                        checkupCategories={checkupCategories}
                    />
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        user: state.authentication.user,
        boats: state.fetch.boats,
        checkupTypes: state.fetch.checkupTypes,
        checkupCategories: state.fetch.checkupCategories,
        loading: state.fetch.loading,
    }
}

export default connect(mapStateToProps)(withStyles(styles)(RepairPage))
