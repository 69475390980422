import {
    bobEventsConstants,
    enginesConstants,
    boatConstants,
} from '../constants'
import { engineReducer } from './bob/engine.reducer'
import { boatReducer } from './bob/boat.reducer'

const initialState = {
    boat: null,
    events: null,
    repairs: null, //to remove
    spendings: null, //to remove
    checkups: null, //to remove
    documents: null, //to remove
    engines: null,
    activecheckups: null, //to remove
    checkupsothers: null, //to remove
    loading: 0,
    searchData: null,
}

export default function fetchBOB(prevState = initialState, action) {
    if (action.type in enginesConstants) {
        return engineReducer(prevState, action)
    }
    if (action.type in boatConstants) {
        return boatReducer(prevState, action)
    }
    if (action.type in bobEventsConstants) {
        return bobEventReducer(prevState, action)
    }
    return prevState
}

const bobEventReducer = (prevState, action) => {
    switch (action.type) {
        case bobEventsConstants.REQUEST_BOBEVENTS:
            return {
                ...prevState,
                events: null,
                loading: prevState.loading + 1,
            }
        case bobEventsConstants.POST_BOBEVENTS:
            return {
                ...prevState,
                events: null,
                loading: prevState.loading + 1,
            }
        case bobEventsConstants.PUT_BOBEVENTS:
            return {
                ...prevState,
                events: null,
                loading: prevState.loading + 1,
            }
        case bobEventsConstants.BOBEVENTS_SUCCESS:
            return {
                ...prevState,
                events: action.events,
                loading: prevState.loading - 1,
            }
        case bobEventsConstants.BOBEVENTS_FAILURE:
            return {
                ...prevState,
                events: null,
                loading: prevState.loading - 1,
            }
        default:
            return prevState
    }
}
