export default theme => ({
    root: { width: '100%' },
    paper: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '45vw',
        backgroundColor: theme.palette.background.paper,
        boxShadow:
            '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)',
        outline: 'none',
        borderRadius: '5px',
        [theme.breakpoints.down('sm')]: {
            width: '95vw',
            borderRadius: 0,
        },
    },
    paperFullscreen: {
        position: 'absolute',
        top: '0%',
        left: '0%',
        width: '100%',
        height: '100vh',
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        outline: 'none',
    },
    cross: {
        position: 'absolute',
        height: '40px',
        top: 'calc(50% - 20px)',
        left: '2%',
        cursor: 'pointer',
        color: 'white',
    },
    fullscreen: {
        position: 'absolute',
        height: '40px',
        top: 'calc(50% - 20px)',
        right: '2%',
        cursor: 'pointer',
        color: 'white',
    },
    box: Object.assign(
        { width: 'calc(100% - 20px)', padding: '10px' },
        theme.column,
    ),
    boxTitle: Object.assign(
        {
            width: '100%',
            position: 'relative',
            backgroundColor: theme.palette.primary.main,
            borderTopLeftRadius: '5px',
            borderTopRightRadius: '5px',
            [theme.breakpoints.down('sm')]: {
                borderRadius: 0,
            },
        },
        theme.column,
    ),
    title: Object.assign(
        { padding: '10px', textAlign: 'center', color: 'white' },
        theme.typography.title,
        {
            [theme.breakpoints.down('sm')]: {
                fontSize: '18px',
            },
        },
    ),
    core: { padding: '15px' },
})
