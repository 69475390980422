export default {
    phoneNumber: {
        FR: 'Téléphone',
        EN: 'Number phone',
    },
    errorPhone: {
        FR: 'Numéro non valide',
        EN: 'Invalid number',
    },
}
