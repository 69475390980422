import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import BoatOnModal from '../common/BoatOnModal'
import ReactGA from 'react-ga'
import BoatOnComponent from '../common/BoatOnComponent'
import styles from './Styles/LoginPageCss'
import dictionary from './Dictionary/LoginPageDico'
import Navigation from '../common/Navigation'
import Footer from '../common/Footer'
import { userActions } from '../../actions'
import { history } from '../../helpers'
import { postData } from '../../actions/api.actions'
import { API_URL } from '../../services/config.service'
import AppRoute from '../../constants/AppRoute'
import RenderBoatCreate from '../common/UsefullComponents/RenderBoatCreate'
import RenderEngineCreate from '../common/UsefullComponents/RenderEngineCreate'
import RenderAccountCreate from '../common/UsefullComponents/RenderAccountCreate'

class LoginPage extends BoatOnComponent {
    constructor(props) {
        super(props)
        this.dictionary = dictionary

        this.state = {
            email: '',
            password: '',
            boatId: null,
            showPassword: false,
            signinModalOpen: props.register ? 'personnalInfos' : false,
            focused: false,
        }

        this.initializeReactGA()
        this.handleLoginSubmit = this.handleLoginSubmit.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.handleOpen = this.handleOpen.bind(this)
        this.handleSigninClose = this.handleSigninClose.bind(this)
        this.valid = this.valid.bind(this)
        this.signin = this.signin.bind(this)
    }

    componentDidMount() {
        if (this.props.user) {
            this.historyPush(history, AppRoute.Profile)
        }
    }

    componentDidUpdate() {
        if (this.props.user && !this.state.signinModalOpen) {
            this.historyPush(history, AppRoute.Profile)
        }
    }

    initializeReactGA() {
        ReactGA.initialize('UA-93941518-1')
        ReactGA.pageview('/login')
    }

    handleChange(value, key) {
        this.setState({ focused: key })
        this.setState({ [key]: value })
    }

    handleOpen() {
        this.setState({ signinModalOpen: 'personnalInfos' })
    }

    handleSigninClose() {
        // uncomment following lines to reset modal content on close
        this.setState({
            signinModalOpen: false,
        })
    }

    handleLoginSubmit(e) {
        e.preventDefault()
        const { email, password } = this.state
        if (email && password) {
            this.setState({ submitted: true })
            this.props.dispatch(userActions.login(email, password.trim()))
        }
    }

    valid(next, boatId) {
        if (next) this.setState({ signinModalOpen: next, boatId: boatId })
        else {
            this.handleSigninClose()
            this.historyPush(history, AppRoute.Profile)
        }
    }

    signin() {
        const funcVide = () => {}
        this.props.dispatch(
            userActions.signin(
                this.state.emailCreate,
                this.state.passwordCreate.trim(),
                this.state.firstNameCreate,
                this.state.lastNameCreate,
                this.state.phoneCreate,
                funcVide,
                funcVide,
            ),
        )
    }

    render() {
        const { classes } = this.props

        return (
            <div>
                <Navigation
                    location={this.props.location}
                    onRegisterClick={this.handleOpen}
                >
                    <div className={classes.root}>
                        <Typography
                            style={{ textAlign: 'center', fontSize: '26px' }}
                        >
                            {this.displayText('enjoyBoating')}
                        </Typography>
                        <form name="form" onSubmit={this.handleLoginSubmit}>
                            <TextField
                                id="email"
                                label={this.displayText('email')}
                                margin="normal"
                                required
                                value={this.state.email}
                                onChange={e => {
                                    this.handleChange(e.target.value, 'email')
                                }}
                                fullWidth
                            />
                            <TextField
                                id="password"
                                label={this.displayText('password')}
                                margin="normal"
                                required
                                type="password"
                                value={this.state.password}
                                onChange={e => {
                                    this.handleChange(
                                        e.target.value,
                                        'password',
                                    )
                                }}
                                fullWidth
                            />
                            <Button
                                type="submit"
                                color="primary"
                                fullWidth
                                variant="contained"
                                style={{
                                    marginTop: '15px',
                                    marginBottom: '15px',
                                }}
                                className={this.props.classes.button}
                            >
                                {' '}
                                {this.displayText('connection')}{' '}
                            </Button>
                            <div className={classes.newAccount}>
                                <Link to="/forget" className={classes.forget}>
                                    {this.displayText('forgetPassword')}
                                </Link>
                            </div>
                            <div className={classes.newAccount}>
                                <Typography
                                    style={{
                                        marginTop: 'auto',
                                        marginBottom: 'auto',
                                    }}
                                >
                                    {this.displayText('noAccount')}
                                </Typography>
                                <Button
                                    className={classes.buttonCreateAccount}
                                    onClick={this.handleOpen}
                                >
                                    {this.displayText('createOne')}
                                </Button>
                            </div>
                        </form>
                    </div>
                    <BoatOnModal
                        openCondition={this.state.signinModalOpen}
                        handleClose={this.handleSigninClose}
                        rendersModal={{
                            personnalInfos: (
                                <RenderAccountCreate valid={this.valid} />
                            ),
                            boatInfos: <RenderBoatCreate valid={this.valid} />,
                            enginesInfos: (
                                <RenderEngineCreate
                                    valid={this.valid}
                                    boatId={this.state.boatId}
                                />
                            ),
                        }}
                        titles={{
                            personnalInfos: this.displayText('accountCreate'),
                            boatInfos: this.displayText('boatAdd'),
                            enginesInfos: this.displayText('enginesAdd'),
                        }}
                        disableFullscreen
                    />
                </Navigation>
                <Footer />
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        user: state.authentication.user,
    }
}

export default connect(mapStateToProps)(withStyles(styles)(LoginPage))
