import { getBOBBoat } from './bob/boat.actions'
import { getBOBEngines } from './bob/engines.actions'
import { bobEventsConstants } from '../constants/'
import { requestApi } from '../services/api.service'
import {
    postDataBOBDocuments,
    removeDataBOBDocuments,
} from './bob/documents.actions'

export * from './bob/boat.actions'
export * from './bob/engines.actions'
export * from './bob/documents.actions'
export * from './bob/spendings.actions'
export * from './bob/checkups.actions'
export * from './bob/repairs.actions'

export const resetDataBOB = () => {
    return dispatch => {
        dispatch({ type: 'TODO' })
    }
}

export const fetchDataBOB = (boat = {}, fetchCallback = json => {}) => {
    return dispatch => {
        dispatch(getBOBEngines(boat.id))
        dispatch(getBOBBoat(boat.id))
        dispatch(requestBobEvents(boat.id))
    }
}

const requestBobEvents = boatId => {
    return dispatch => {
        dispatch({ type: bobEventsConstants.REQUEST_BOBEVENTS })
        return requestApi('/bob-events/boat/' + boatId, 'GET').then(
            result => {
                dispatch({
                    type: bobEventsConstants.BOBEVENTS_SUCCESS,
                    events: result,
                })
            },
            error => {
                dispatch({ type: bobEventsConstants.BOBEVENTS_FAILURE, error })
            },
        )
    }
}

export const postBOBEvents = (
    boatId,
    eventTypeId,
    title,
    { files, inventory, transaction, delimitedDate, description },
    handleAlert = json => {},
) => {
    return async dispatch => {
        dispatch({ type: bobEventsConstants.POST_BOBEVENTS })
        let body = {
            title: title || 'title',
            description: description || null,
        }

        if (files) {
            body.files = await postDataBOBDocuments(files, 1)
        }
        if (inventory) body.inventory = inventory
        if (transaction)
            body.transaction = {
                ...transaction,
                amountIt:
                    transaction.amountIt && transaction.amountIt.toString(),
                amountEt:
                    transaction.amountEt && transaction.amountEt.toString(),
            }
        if (delimitedDate) body.delimitedDate = delimitedDate

        return requestApi(
            `/bob-events/boat/${boatId}/eventType/${eventTypeId}`,
            'POST',
            body,
        ).then(
            result => {
                dispatch({
                    type: bobEventsConstants.BOBEVENTS_SUCCESS,
                    events: result,
                })
            },
            error => {
                dispatch({ type: bobEventsConstants.BOBEVENTS_FAILURE, error })
            },
        )
    }
}

export const putBOBEvents = (
    eventId,
    title,
    {
        newFiles,
        files,
        checkup,
        transaction,
        delimitedDate,
        description,
        eventTypeId,
        deletedFiles,
    },
    handleAlert = json => {},
) => {
    return async dispatch => {
        dispatch({ type: bobEventsConstants.POST_BOBEVENTS })
        let body = {}
        if (title) body.title = title
        if (description) body.description = description
        if (eventTypeId) body.eventType = { id: eventTypeId }

        if (deletedFiles) {
            await removeDataBOBDocuments(deletedFiles)
        }
        if (newFiles) {
            body.files = [
                ...(await postDataBOBDocuments(newFiles, 1)),
                ...files,
            ]
        }
        if (checkup) body.checkup = checkup
        if (transaction)
            body.transaction = {
                ...transaction,
                amountIt:
                    transaction.amountIt && transaction.amountIt.toString(),
                amountEt:
                    transaction.amountEt && transaction.amountEt.toString(),
            }
        if (delimitedDate) body.delimitedDate = delimitedDate

        return requestApi(`/bob-events/${eventId}`, 'PUT', body).then(
            result => {
                dispatch({
                    type: bobEventsConstants.BOBEVENTS_SUCCESS,
                    events: result,
                })
            },
            error => {
                dispatch({ type: bobEventsConstants.BOBEVENTS_FAILURE, error })
            },
        )
    }
}

export const updateStateEvent = (
    props,
    {
        transaction,
        delimitedDate,
        title,
        description,
        files,
        eventTypeId,
        deletedFiles,
    },
) => {
    var reg = new RegExp('^([0-9.,-])+$')

    if (
        (!transaction ||
            !transaction.amountIt ||
            !reg.test(transaction.amountIt)) &&
        transaction &&
        transaction.amountIt &&
        transaction.amountIt !== ''
    ) {
        transaction = null
    }
    props.setState(prevState => ({
        event: {
            ...prevState.event,
            transaction: transaction
                ? { ...prevState.event.transaction, ...transaction }
                : prevState.event.transaction,
            delimitedDate: delimitedDate
                ? { ...prevState.event.delimitedDate, ...delimitedDate }
                : prevState.event.delimitedDate,
            title: title || prevState.event.title,
            description: description || prevState.event.description,
            files: files || prevState.event.files,
            eventType: eventTypeId
                ? { id: eventTypeId }
                : prevState.event.eventType,
        },
        deletedFiles: deletedFiles || [],
    }))
}

export const setBOBStateEvent = props => {
    props.setState({
        event: {
            transaction: null,
            delimitedDate: {},
            title: '',
            description: '',
            files: [],
            eventType: {},
        },
        deletedFiles: [],
        fileNoUrl: [],
    })
}

export const updateStateEvents = ({
    props,
    idEvent,
    transaction,
    delimitedDate,
    title,
    description,
    files,
    eventTypeId,
}) => {
    props.setState(prevState => ({
        events: [
            ...prevState.events,
            ...([idEvent] = [
                {
                    ...prevState.events[idEvent],
                    transaction: transaction
                        ? {
                              ...prevState.events[idEvent].transaction,
                              ...transaction,
                          }
                        : prevState.events[idEvent].transaction,
                    delimitedDate: delimitedDate
                        ? {
                              ...prevState.events[idEvent].delimitedDate,
                              ...delimitedDate,
                          }
                        : prevState.events[idEvent].delimitedDate,
                    title: title || prevState.events[idEvent].title,
                    description:
                        description || prevState.events[idEvent].description,
                    files: files || prevState.events[idEvent].files,
                    eventType: eventTypeId
                        ? { id: eventTypeId }
                        : prevState.events[idEvent].eventType,
                },
            ]),
        ],
    }))
}

export const removeFilesFromBOBEvent = (
    bobEventId,
    handleAlert = json => {},
) => {
    return dispatch => {
        dispatch({ type: bobEventsConstants.PUT_BOBEVENTS })
        return requestApi('/bob-events/files/' + bobEventId, 'DELETE').then(
            result => {
                dispatch({
                    type: bobEventsConstants.BOBEVENTS_SUCCESS,
                    events: result,
                })
            },
            error => {
                dispatch({ type: bobEventsConstants.BOBEVENTS_FAILURE, error })
            },
        )
    }
}

export const removeTransactionFromBOBEvent = (
    bobEventId,
    handleAlert = json => {},
) => {
    return dispatch => {
        dispatch({ type: bobEventsConstants.PUT_BOBEVENTS })
        return requestApi(
            '/bob-events/transaction/' + bobEventId,
            'DELETE',
        ).then(
            result => {
                dispatch({
                    type: bobEventsConstants.BOBEVENTS_SUCCESS,
                    events: result,
                })
            },
            error => {
                dispatch({ type: bobEventsConstants.BOBEVENTS_FAILURE, error })
            },
        )
    }
}
