export default theme => ({
    root: {
        marginLeft: '25px',
        maxWidth: '1000px',
    },
    subtitle: Object.assign(theme.typography.minititle, { marginTop: '5px' }),
    title: theme.typography.subtitle,
    textAndField: {
        display: 'flex',
        flexDirection: 'row',
        margin: '5px',
        width: '100%',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
        },
    },
    text: {
        width: '25%',
        margin: 'auto',
        textAlign: 'right',
        fontSize: '16px',
        [theme.breakpoints.down('sm')]: {
            textAlign: 'left',
            width: '100%',
        },
    },
    field: {
        width: '70%',
        margin: 'auto',
        fontSize: '16px',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
    changeButton: {
        width: '50%',
        margin: '20px',
    },
    button: {
        marginTop: '5px',
        marginBottom: '15px',
    },
})
