export default theme => ({
    root: {
        width: '60%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        margin: 'auto',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
    button: Object.assign(
        { marginTop: '20px', width: '250px' },
        theme.button.primary,
    ),
})
